define('front-end-cli/transforms/iso8601date', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        // TODO define format (e.g 'YYYY-MM-DD')
        return moment(serialized).toDate();
      }
      return serialized;
    },

    serialize: function serialize(deserialized) {
      if (deserialized) {
        // TODO define format (e.g 'YYYY-MM-DD')
        return moment(deserialized).format();
      }
      return deserialized;
    }
  });
});