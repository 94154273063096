define('front-end-cli/pods/components/income-expenses-header/component', ['exports', 'ember', 'front-end-cli/mixins/scroll-top'], function (exports, _ember, _frontEndCliMixinsScrollTop) {
  exports['default'] = _ember['default'].Component.extend(_frontEndCliMixinsScrollTop['default'], {
    tagName: 'div',
    classNames: ['IncomeExpensesHeader'],
    classNameBindings: ['isSticky', 'hasSidebar'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    categories: null,
    category: null,
    subcategory: null,
    type: null,
    transactions: null,
    labels: null,
    filterLabelsString: null,
    costType: null,

    preferences: null,

    startDate: null,
    endDate: null,
    periodType: null,
    oldestTransactionDate: null,
    hasSidebar: null,

    startDayFinancialMonth: null,

    /**-------------------------
     Calculated Properties
     ---------------------------*/

    hasLabels: (function () {
      return this.get('labels.length') > 0;
    }).property('labels.length'),

    // actions
    transitionAction: null,

    /**-------------------------
      Properties
    ---------------------------*/

    scrollTopDebounceTime: 5,
    isSticky: null,

    /**-------------------------
      Methods
    ---------------------------*/

    _stickyScaler: (function () {

      var scrollTop = _ember['default'].$('#app > main').scrollTop();

      // Scale down the period-select-title
      var a1 = 0,
          a2 = 1,
          b1 = 35,
          b2 = 0.7;

      var componentFontSize;
      if (scrollTop < b1) {

        var c1 = Math.min(Math.max(scrollTop, a1), b1),
            c2 = a2 + (b2 - a2) / b1 * c1;

        componentFontSize = c2;
        this.set('isSticky', false);

        // TODO you know
        var x1 = 0,
            x2 = 35,
            y1 = 30,
            y2 = 80;

        var z1 = Math.min(Math.max(scrollTop, x1), y1),
            z2 = x2 + (y2 - x2) / y1 * z1;

        // TODO you know
        this.get('container').lookup('controller:application').set('topBarHeightAdjustment', z2);
      } else {
        componentFontSize = b2;
        this.set('isSticky', true);

        var x1 = 0,
            x2 = 35,
            y1 = 30,
            y2 = 80;

        var z1 = 30,
            z2 = x2 + (y2 - x2) / y1 * z1;

        // TODO you know
        this.get('container').lookup('controller:application').set('topBarHeightAdjustment', z2);
      }

      this.$('.PeriodSelect-title').css({ fontSize: componentFontSize + 'rem' });
      this.$('.CategorySelect-title').css({ fontSize: componentFontSize + 'rem' });
    }).on('scroll'),

    resetTopBarHeight: (function () {
      this.get('container').lookup('controller:application').set('topBarHeightAdjustment', null);
    }).on('willDestroyElement'),

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {
      transitionAction: function transitionAction(params) {
        this.sendAction('transitionAction', params);
      }
    }
  });
});