define('front-end-cli/models/bank', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    userCount: _emberData['default'].attr('number'),
    legacy: _emberData['default'].attr('boolean'),
    // bankLogin
    autosyncReady: _emberData['default'].attr('boolean'),
    loginLink: _emberData['default'].attr('string'),
    major: _emberData['default'].attr('boolean'),
    imageIcon: (function () {
      return '/images/banklogos/small/' + this.get('name') + '.png';
    }).property('name'),
    preferredFormat: _emberData['default'].attr('string')

  });
});