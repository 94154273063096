define('front-end-cli/controllers/accountmanagement/update', ['exports', 'ember', 'front-end-cli/controllers/accountmanagement/edit/base'], function (exports, _ember, _frontEndCliControllersAccountmanagementEditBase) {
  exports['default'] = _frontEndCliControllersAccountmanagementEditBase['default'].extend({

    application: _ember['default'].inject.controller(),
    isUpdateController: true,
    accountHasBankloginSiblings: false,
    stepLength: 7,

    error: null,
    syncing: null,
    step: null,

    /**
      Decide if we'll run the `auto` or `manual` flow
      or if we'll need the user to choose which
      flow he preferes.
    */
    prepareAccountUpdate: function prepareAccountUpdate() {
      var defer;
      // Transitions between update routes don't
      // trigger currentPath observer.
      // So we'll make sure the sidebar triangle
      // position is updated.

      // TODO: not needed since we dont point out individual account anymore?
      // this.get('controllers.application').send('positionNavTriangle');

      // Get the dynamic segment
      var account = this.get('model');
      if (!account) return;

      var bank = account.get('bank');

      this.get('bankChoice').setProperties({
        account: account,
        name: bank.get('name')
      });

      // Enter auto flow
      if (account.get('syncType') === 'auto' && bank.get('id') !== 2 && bank.get('id') !== 1 && bank.get('id') !== 3) {

        var bankLogin = account.get('bankLogin');

        this.get('bankChoice.data').setProperties({
          username: bankLogin.get('username'),
          passNr: bankLogin.get('passNr')
        });

        this.get('bankChoice').setProperties({
          auto: true,
          bankLogin: bankLogin,
          loginMethod: bankLogin.get('loginMethod')
        });

        // When updating an autosync account, start by resetting the import wizard. This is done in sync, because otherwise the (session-based) reset is overwritten.
        defer = _ember['default'].RSVP.defer();
        this.send('resetImportWizard', defer);

        defer.promise.then((function () {
          this.send('showStep', 4);
        }).bind(this));
      } else {

        // Urge the user to use `autoSync`
        // (choose-synctype.hbs will adapt its text)
        if (account.get('syncType') === 'manual') {
          this.set('bankChoice.urgeAuto', true);
        }

        var chromeBwaCompatible = ['abn', 'asn', 'ing', 'rabobank'],
            firefoxBwaCompatible = [],
            isChromeBwaCompatible = bowser.chrome && chromeBwaCompatible.contains(bank.get('name')),
            isFirefoxBwaCompatible = bowser.firefox && firefoxBwaCompatible.contains(bank.get('name'));

        if (account.get('bank.autosyncReady')) {
          this.get('bankChoice').setProperties({
            auto: null,
            account: account,
            name: bank.get('name'),
            bankLogin: null
          });
          this.send('showStep', 2);
        }
        //As of psd2, BWA is no longer supported. Redirect the user to step 4
        else if (isChromeBwaCompatible || isFirefoxBwaCompatible) {
            this.send('showStep', 4);
          } else {
            this.get('bankChoice').setProperties({
              auto: false,
              account: account,
              name: bank.get('name'),
              bankLogin: null
            });

            if (bank.get('legacy')) {
              // When updating a legacy account (e.g. Bizzner), start by resetting the import wizard. This is done in sync, because otherwise the (session-based) reset is overwritten.
              defer = _ember['default'].RSVP.defer();
              this.send('resetImportWizard', defer);

              defer.promise.then((function () {
                this.send('showStep', 5);
              }).bind(this));
            } else {
              this.send('showStep', 4);
            }
          }
      }
    },

    accountsLocked: (function () {
      if (this.get('currentUser.model.state.hasAccessToAllAccounts')) {
        return false;
      } else {
        if (this.get('bankChoice.account.bankLogin') === null) {
          return this.get('bankChoice.account.locked');
        } else {
          var unlockedAccounts = false;

          this.store.all('account').filterBy('bankLogin', this.get('bankChoice.account.bankLogin')).forEach(function (item, index, enumerable) {
            if (!item.get('locked')) {
              unlockedAccounts = true;
            }
          });

          return !unlockedAccounts;
        }
      }
    }).property('bankChoice.account.id'),

    /*
    Currently updating is done through `/sync`.
     _updateBankLogin: function() {
       var blid = this.get('bankChoice.bankLogin.id'),
          blpass = this.get('bankChoice.data.password');
       if(!blid || !blpass) {
        throw ('missing required data for doing an update call');
      }
       var promise = $.ajax({
        url: 'api/v3/banklogins/update/'+blid,
        type: 'PUT',
        dataType: 'json',
        data: {
          password: '--'+Base64.encode(Base64.encode(blpass))
        },
        context: this
      })
      .always(function() {
        // Flush password
        this.set('bankChoice.data.password', null);
      });
       return promise;
    },
    */

    actions: {

      /*
      Currently updating is done through `/sync`.
       updateBankLogin: function() {
         var promise = this._updateBankLogin();
         promise.done(function() {
          // Save possible changes
          var bankLogin = this.get('bankChoice.bankLogin');
          if(bankLogin.get('isDirty')) {
            bankLogin.save();
          }
          // Refresh `account.lastupdate`
          this.get('currentUser.model.accounts').forEach(function(account) {
            account.reload();
          }, this);
          // Go to success
          this.send('showStep', 6);
        }.bind(this));
         promise.fail(function(jqXHR, textStatus, errorThrown) {
          var error = jqXHR.responseJSON? jqXHR.responseJSON.error : {};
          if(!error.message) {
            error.message = 'Er is een onbekende fout opgetreden tijdens \
            het synchroniseren met de ' + this.get('bankChoice.bank.title') + ' \
            server. Neem contact op <a href="https://help.mijnhuishoudboekje.mijngeldzaken.nl" \
            class="alert-link">support</a>.';
          }
          this.set('error', error);
        }.bind(this));
         promise.always(function() {
          // this.set('syncing', false);
        }.bind(this));
       },
      */

      uploadDone: function uploadDone(accounts) {

        if (this.get('bankChoice.bankLogin')) {

          this.set('savePromises', []);

          // Set syncType for all accounts that where
          // linked to this bankLogin
          this.get('bankChoice.bankLogin.accounts').forEach(function (account) {

            account.set('syncType', 'manual');

            var promise = account.save();
            this.get('savePromises').pushObject(promise);
          });

          _ember['default'].RSVP.all(this.get('savePromises')).then(function () {
            this.store.find('account');
          });

          // Destroy the bankLogin record
          this.get('bankChoice.bankLogin').destroyRecord();
          this.set('syncing', null);
        } else {

          if (this.get('bankChoice.account.syncType') != 'manual') {
            this.set('bankChoice.account.syncType', 'manual');
            this.get('bankChoice.account').save().then((function () {
              this.store.find('account').then((function () {
                this.set('syncing', null);
              }).bind(this));
            }).bind(this));
          } else {
            this.store.find('account').then((function () {
              this.set('syncing', null);
            }).bind(this));
          }
        }

        /* Fix for when adding multiple files for uploading and the responses to these file upload responses are received in the wrong order:
         * check which accounts were already set to be updated, and then add the new ones to it.
         */
        var availableAccounts = this.get('bankChoice.availableAccounts');
        if (availableAccounts !== null) {
          availableAccounts.forEach(function (item, index, enumerable) {
            if (accounts.findBy('accountNumber', item.accountNumber) === undefined) {
              accounts.pushObject(item);
            }
          });
        }

        // only set accounts which where actually updated
        accounts = accounts.filterBy('update', true);
        this.send('setAvailableAccounts', accounts);
      },

      ignoreAllBenchmarks: function ignoreAllBenchmarks() {
        this.transitionToRoute('accounts');
      }
    }
  });
});