define('front-end-cli/components/line-loader', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    /**
      # Line Loader component
      Depends on:
       ## 1
      A component declaration in the application template:
      `{{line-loader isLoading=loading}}`
       The component can optionally have a loadingTimeEstimate attribute
      `{{line-loader isLoading=loading loadingTimeEstimate=1500}}`
       ## 2
      A loading action in the `applicationRoute`. This is automatically fired
      on slow model promises but can also be fired by sending a loading action
      from anywhere in the application:
      `this.send('loading')`
       To change the custom estimate (300ms) you can send
      a `loadingTimeEstimate` argument:
      `this.send('loading', 1000)`
       To trigger the loading state from outside of Ember you can make
      an instrument call:
      `Ember.instrument('startLoading')`
      `Ember.instrument('startLoading', {loadingTimeEstimate: 3333})`
       ## 3
      `lineloader.less`
    */
    tagName: 'div',
    classNames: ['loading-slider'],

    manage: (function () {
      if (this.get('isLoading')) {
        this.animate.call(this);
      } else {
        this.set('isLoaded', true);
      }
    }).observes('isLoading'),

    animate: function animate() {
      this.set('isLoaded', false);
      // isLoading can be either a boolean or a loading time estimate.
      // The estimate can also be set as an attribute within the template.
      var estimate = /^\d+$/.test(this.get('isLoading')) ? this.get('isLoading') : this.get('loadingTimeEstimate');

      if (!this.$()) return;
      var self = this,
          elapsedTime = 0,
          inner = this.$().find('span'),
          loadingTimeEstimate = estimate || 300,
          innerWidth = 0,
          outerWidth = this.$().width(),
          stepWidth = Math.round(outerWidth / 100);

      var interval = window.setInterval(function () {
        elapsedTime = elapsedTime + 10;
        inner.width(innerWidth = innerWidth + stepWidth);

        // slow the animation if we used more than 75% the estimated duration
        // or 66% of the animation width
        if (elapsedTime > loadingTimeEstimate * 0.75 || innerWidth > outerWidth * 0.66) {
          // don't stop the animation completely
          if (stepWidth > 1) {
            stepWidth = stepWidth * 0.97;
          }
        }

        if (innerWidth > outerWidth) {
          _ember['default'].run.later(function () {
            inner.width(0);
            window.clearInterval(interval);
          }, 50);
        }

        // the activity has finished
        if (self.get('isLoaded')) {
          // start with a sizable pixel step
          if (stepWidth < 10) {
            stepWidth = 10;
          }
          // accelerate to completion
          stepWidth = stepWidth + stepWidth;
        }
      }, 10);
    },

    addLoaderSpan: (function () {
      this.$().append('<span></span>');
    }).on('didInsertElement')

  });
});