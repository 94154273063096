define('front-end-cli/views/accountmanagement/edit/manual', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({

    initFileUpload: (function () {
      var self = this;

      var bankId = this.get('controller.bankChoice.account.bank.id') || this.get('controller.parentController.banks').findBy('name', this.get('controller.bankChoice.name')).get('id');

      _ember['default'].assert('We can’t find a Bank id ...', bankId);

      var xhrSupported = new XMLHttpRequest().upload;
      var csrf = this.get('controller.user.csrf');

      _ember['default'].$('#fileupload').fileupload({
        url: 'api/v3/banks/fileupload/' + bankId,
        type: 'POST',
        autoUpload: true,
        sequentialUploads: true,
        dataType: 'json',
        formData: {
          csrf: csrf, // IE 9 does not support custom headers so we send the csrf token as a post variable
          account_id: this.get('controller.bankChoice.account.id') || null // jshint ignore:line
        },

        add: function add(e, data) {

          var acceptFileTypes = /(\.|\/)(xml|txt|asc|csv|pfml|mut|tab|mt940|940|sta|tsv|qif|swi|iif|ofx|TXT|ASC|CSV|MUT|TAB|MT940|940|STA|TSV|QIF|SWI|IIF|OFX|xls|xlsx)$/i;
          var pass = true;

          _ember['default'].$.each(data.files, function (i, file) {
            if (!acceptFileTypes.test(file.name)) pass = false;
          });

          if (pass) {
            data.submit();
            self.get('controller').send('uploadStart');
          } else {
            var error = { message: 'Dit bestandsformaat is niet toegestaan.', dismissable: true };
            self.get('controller').send('uploadInvalid', error);
          }
        },

        done: function done(e, data) {

          _ember['default'].assert('Upload response is not an array.', _ember['default'].$.isArray(data.result.accounts));

          // Only fire the `uploadDone` callback when we are at the last file
          if (data.files[0] == data.originalFiles[data.originalFiles.length - 1]) {
            self.get('controller').send('uploadDone', data.result.accounts);

            if (bowser.msie) {
              _ember['default'].$('body').focus();
            }
          }
        },

        fail: function fail(e, data) {
          // data = errorThrown, textStatus, jqXHR
          var response = data.jqXHR.responseJSON;

          if (response && typeof response.error == 'object') {
            var error = undefined;
            switch (response.error.code) {
              case 0:
                error = {
                  message: response.error.message
                };
                break;
              case 1:
                error = {
                  header: 'Het bestand wordt niet herkend.',
                  message: 'De transacties in dit bestand kunnen niet worden ingelezen, omdat het formaat niet wordt ondersteund. Zou dit wel moeten? Neem dan contact op met support.'
                };
                break;
              case 2:
                error = {
                  header: 'Het bestand bevat alleen transacties van een andere bank.',
                  message: 'Heb je het juiste bestand geselecteerd voor deze rekening?'
                };
                break;
              case 3:
                error = {
                  header: 'Er is een onbekende fout opgetreden.',
                  message: 'Neem contact op met support.'
                };
                break;
              case 4:
                error = {
                  header: 'Het bestand bevat geen transacties voor deze rekening.',
                  message: 'Heb je het juiste bestand geselecteerd voor deze rekening?'
                };
                break;
              case 5:
                error = {
                  header: 'Er zitten geen transacties in dit importbestand.',
                  message: 'Er hoeft dus niks bijgewerkt te worden!'
                };
                break;
              case 6:
                error = {
                  header: 'Het bestand bevat een transactie zonder rekeningnummer.',
                  message: 'Hierdoor kan dit bestand niet goed geïmporteerd worden. Neem contact op met support.'
                };
                break;
              default:
                // This results in a default message being shown.
                error = {};
            }
            self.get('controller').send('uploadFail', error);
          } else if (data.jqXHR.status === 500) {
            var error = {
              status: 500,
              serverError: true
            };
            self.get('controller').send('uploadFail', error, {
              targetName: 'fileupload'
            });
          } else if (data.errorThrown === 'Unauthorized') {
            var reason = {
              status: 401
            };
            var transition = {
              targetName: 'fileupload'
            };
            self.get('controller').send('error', reason, transition);
          } else {
            // This results in a default message being shown.
            var error = {};
            self.get('controller').send('uploadFail', error);
          }
        }
      });
    }).on('didInsertElement')
  });
});