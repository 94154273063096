define('front-end-cli/pods/budget/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    queryParams: ['year', 'month'],

    year: null,
    month: null,

    application: _ember['default'].inject.controller(),
    budgetMatrix: _ember['default'].inject.controller('budget/matrix'),
    budgetIndex: _ember['default'].inject.controller('budget/index'),

    hasSidebar: _ember['default'].computed.alias('application.hasSidebar'),
    currentPath: _ember['default'].computed.alias('application.currentPath'),

    matrixHasDirtyBudgets: null,

    isNotIndex: _ember['default'].computed('currentPath', function () {
      return !/\.index/.test(this.get('currentPath'));
    }),

    hasNoAccounts: (function () {
      return !this.get('currentUser.model.accounts.length');
    }).property('currentUser.model.accounts.[]'),

    hasNoActiveAccounts: (function () {
      return !this.get('currentUser.model.accounts').findBy('activated');
    }).property('currentUser.model.accounts.@each.activated'),

    hasNoBudgets: _ember['default'].computed('budgetIndex.hasNoBudgets', function () {
      return this.get('budgetIndex.hasNoBudgets');
    }),

    showMatrixSave: _ember['default'].computed('matrixHasDirtyBudgets', function () {
      return this.get('matrixHasDirtyBudgets') && /matrix/.test(this.get('currentPath'));
    }),

    /**
      Currently, non-actived accounts are not ignored!
    */
    firstTransactionYear: _ember['default'].computed('currentUser.model.accounts.@each.activated', function () {
      var mFirstTransactionDate = undefined;

      this.get('currentUser.model.accounts').forEach(function (account) {
        var md = undefined;
        if (!account.get('firstTransactionDate')) {
          md = moment();
        } else {
          md = moment(account.get('firstTransactionDate'));
        }

        if (mFirstTransactionDate) {
          if (md.isBefore(mFirstTransactionDate)) {
            mFirstTransactionDate = md;
          }
        } else {
          mFirstTransactionDate = md;
        }
      });

      // Returns a moment object!
      return mFirstTransactionDate.year();
    }),

    actions: {

      /**
        Called from the Budget/PeriodSelect component
      */
      setPeriodParam: function setPeriodParam(year, month) {

        var currentPath = this.get('currentPath'),
            qp = { year: year, month: month };

        if (!month) {
          this.set('month', null);
        }
        this.transitionToRoute(currentPath, {
          queryParams: qp
        });
      }
    }
  });
});