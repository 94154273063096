define('front-end-cli/controllers/profiler/profile', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    needs: ['profiler'],
    isUserProfile: _ember['default'].computed.alias('controllers.profiler.isUserProfile'),
    selectObjects: _ember['default'].computed.alias('controllers.profiler.selectObjects'),

    genderList: _ember['default'].Object.create({
      selected: null,
      content: [_ember['default'].Object.create({ id: 1, label: 'Man' }), _ember['default'].Object.create({ id: 2, label: 'Vrouw' })]
    }),

    actions: {

      deleteRecord: function deleteRecord(model) {
        model.deleteRecord();
        this.get('parentController.deletedRecords').addObject(model);
      },

      addIncomeNowLabor: function addIncomeNowLabor() {
        this.store.createRecord('labor', {
          incomeNow: this.get('model.incomeNow')
        });
      },
      addIncomeLaterLabor: function addIncomeLaterLabor() {
        this.store.createRecord('labor', {
          incomeLater: this.get('model.incomeLater')
        });
      },
      addIncomeNowEmployerRetirement: function addIncomeNowEmployerRetirement() {
        this.store.createRecord('incomeNowRetirementEmployer', {
          incomeNow: this.get('model.incomeNow')
        });
      },
      addIncomeLaterEmployerRetirement: function addIncomeLaterEmployerRetirement() {
        this.store.createRecord('incomeLaterRetirementEmployer', {
          incomeLater: this.get('model.incomeLater')
        });
      },
      addIncomeNowAnnuityRetirement: function addIncomeNowAnnuityRetirement() {
        this.store.createRecord('incomeNowRetirementAnnuity', {
          incomeNow: this.get('model.incomeNow')
        });
      },
      addIncomeLaterAnnuityRetirement: function addIncomeLaterAnnuityRetirement() {
        this.store.createRecord('incomeLaterRetirementAnnuity', {
          incomeLater: this.get('model.incomeLater')
        });
      },
      addInsurance: function addInsurance() {
        this.store.createRecord('insurance', {
          incomeLater: this.get('model.incomeLater')
        });
      },
      addMiscInsurance: function addMiscInsurance() {
        this.store.createRecord('miscInsurance', {
          profile: this.get('model')
        });
      },
      addFinancialMeansMoney: function addFinancialMeansMoney() {
        this.store.createRecord('financialMeansMoney', {
          profile: this.get('model')
        });
      },
      addFinancialMeansAsset: function addFinancialMeansAsset() {
        this.store.createRecord('financialMeansAsset', {
          profile: this.get('model')
        });
      },
      addIncomeNowCapital: function addIncomeNowCapital() {
        this.store.createRecord('capital', {
          incomeNow: this.get('model.incomeNow')
        });
      },
      addIncomeLaterCapital: function addIncomeLaterCapital() {
        this.store.createRecord('capital', {
          incomeLater: this.get('model.incomeLater')
        });
      },
      addChild: function addChild() {
        this.store.createRecord('child', {
          profile: this.get('model')
        });
      },
      addDebt: function addDebt() {
        this.store.createRecord('debt', {
          profile: this.get('model')
        });
      }
    }
  });
});