define('front-end-cli/pods/components/budget/matrix-inputcell/component', ['exports', 'ember'], function (exports, _ember) {
  // import KeyboardShortcuts from 'ember-keyboard-shortcuts/mixins/component';

  exports['default'] = _ember['default'].Component.extend({ //KeyboardShortcuts,
    tagName: 'td',
    classNameBindings: ['category.hasSubBudgets::has-input', 'category.hasSubBudgets:has-subbudgets', 'notCurrentMonth:is-disabled', 'isGenerated', 'isCustom', 'isDirty'],

    // External bindgings
    category: null,
    month: null,
    amount: null,
    prevAmount: null,
    isPlusUser: null,
    budget: null,
    pastStartDayFinancialMonth: null,

    isGenerated: _ember['default'].computed('category.hasSubBudgets', function () {
      return this.get('amount') === 0 || !this.get('category.hasSubBudgets') && this.get('category.isGenerated');
    }),

    isCustom: _ember['default'].computed('category.hasSubBudgets', function () {
      return this.get('amount') && !this.get('category.hasSubBudgets') && !this.get('category.isGenerated');
    }),

    isDirty: _ember['default'].computed('budget.isDirty', 'category.budgetsAreDirty', function () {
      // HACK to overcome an issue with observing individual budgets.
      return this.get('budget.isDirty') && this.get('category.budgetsAreDirty');
    }),

    notCurrentMonth: _ember['default'].computed('month', function () {
      var currentMonth = undefined;
      if (moment().month() === 11 && this.get('pastStartDayFinancialMonth')) {
        currentMonth = 0;
      } else {
        currentMonth = moment().month();
      }
      return !this.get('isPlusUser') && this.get('month') != currentMonth;
    }),

    /**
      More dirty than a crack whore with herpes.
    */
    _equalizeBudgets: function _equalizeBudgets() {
      var amount = this.get('amount'),
          changedAny = false;

      this.get('category.budgets').forEach(function (b) {
        if (b.amount !== amount) {
          changedAny = true;
          b.amount = amount;
        }
      });

      if (changedAny) {
        _ember['default'].run.next(this, function () {
          this.get('category').notifyPropertyChange('budgets');
        });
      }
    },

    /**
      Normalize amount input.
    */
    amountChanged: _ember['default'].observer('amount', function () {
      var amount = this.get('amount');
      if (!amount) {
        amount = 0;
      }

      if (this.get('prevAmount') !== amount && /^-?[0-9]*$/.test(amount)) {
        this.set('prevAmount', amount);
        if (!this.get('isPlusUser')) {
          this._equalizeBudgets();
        }
      } else {
        this.set('amount', this.get('prevAmount'));
      }
    }),

    /**
      When the cell is focussed,
      we’ll select all its content
      for convenience.
    */
    focusIn: function focusIn() {
      var _this = this;

      _ember['default'].run.next(function () {
        if (_this.$('input')) _this.$('input').select();
      });
      this.set('prevAmount', this.get('amount'));
    },

    focusOut: function focusOut() {
      if (this.get('amount') === '-') {
        this.set('amount', 0);
      }
    },

    click: function click() {
      if (!this.get('category.hasSubBudgets') && this.get('notCurrentMonth') && !this.get('isPlusUser')) {
        this.sendAction('showPlusIncentive');
      }
    }

  });
});
// keyboardShortcuts: {
//
//   'down' : function(e) {
//     if (this.$('input').is(':focus')) {
//       e.preventDefault();
//       let amount = this.get('amount') * 1;
//       this.set('amount', amount - 10);
//     }
//   },
//
//   'up' : function(e) {
//     if (this.$('input').is(':focus')) {
//       e.preventDefault();
//       let amount = this.get('amount') * 1;
//       this.set('amount', amount + 10);
//     }
//   }
// }