define('front-end-cli/models/person', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    lastName: _emberData['default'].attr('string'),
    firstName: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    address: _emberData['default'].attr('string'),
    houseNumber: _emberData['default'].attr('number'),
    gender: _emberData['default'].attr('number'),
    birthDate: _emberData['default'].attr('string'),
    houseNumberExt: _emberData['default'].attr('string'),
    postalCode: _emberData['default'].attr('string'),
    city: _emberData['default'].attr('string'),
    civilState: _emberData['default'].attr('number'),
    education: _emberData['default'].attr('number'),
    branch: _emberData['default'].attr('number'),
    work: _emberData['default'].attr('number'),
    livingSituation: _emberData['default'].attr('number'),
    houseType: _emberData['default'].attr('number'),
    fullName: (function () {
      var full = '';
      if (this.get('firstName')) {
        full += this.get('firstName');
      }
      if (this.get('lastName')) {
        if (full) full += ' ';
        full += this.get('lastName');
      }
      if (!full) full = this.get('email');
      if (!full) full = 'Nameless';
      return full;
    }).property('firstName', 'lastName')
  });
});