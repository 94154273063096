define('front-end-cli/pods/components/transaction-details/date/date-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'transaction-detail-row',
    classNames: ['TransactionDetails-row', 'DateRow'],
    classNameBindings: ['isEditing', 'transaction.childTransactions.length:is-splitted'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    isEditing: null,
    transaction: null,

    /**-------------------------
      Properties
    ---------------------------*/

    isEditingTransactionDate: null,

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {

      toggleEditTransactionDate: function toggleEditTransactionDate() {
        if (this.get('isEditing')) {
          this.toggleProperty('isEditingTransactionDate');
        }
      },

      resetUserDate: function resetUserDate() {
        this.set('transaction.userDate', '');
      }
    },

    click: function click(e) {
      if (this.get('isEditing')) {
        if (!this.get('isEditingTransactionDate')) {
          this.set('isEditingTransactionDate', true);
        } else if (!_ember['default'].$(e.target).parents('.DateRow-datePicker').length) {
          this.set('isEditingTransactionDate', false);
        }
      }
    }
  });
});