define('front-end-cli/transitions', ['exports'], function (exports) {
  exports['default'] = function () {

    this.transition(this.hasClass('PeriodSelect-changeFinancialMonthStartDay'), this.toValue(true), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.hasClass('TransactionDetails-liquidPaneAnimation'), this.toValue(true), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.hasClass('DetailRowTransition'), this.toValue(true), this.use('toDown', { duration: 300 }), this.reverse('toUp', { duration: 180 }));

    this.transition(this.hasClass('TransactionDetailsActionsAnimation'), this.toValue(true), this.use('crossFade', { delay: 400, duration: 400 }), this.reverse('crossFade', { duration: 180 }));

    this.transition(this.hasClass('CommentAction'), this.toValue(true), this.use('crossFade', { duration: 200 }), this.reverse('crossFade', { duration: 100 }));

    this.transition(this.childOf('.CategoryDissect-innerChartValue'), this.toValue(function (toValue, fromValue) {
      var tovalue = accounting.unformat(toValue.replace(/\./g, '').replace(/,/g, '.'));
      var fromvalue = accounting.unformat(fromValue.replace(/\./g, '').replace(/,/g, '.'));
      return !tovalue || !fromvalue || tovalue > fromvalue;
    }), this.use('toDown', { duration: 100 }), this.reverse('toUp', { duration: 100 })
    // this.childOf('.CategoryDissect-innerChartValue'),
    // this.use('crossFade', { duration: 120 })
    );
  };
});