define('front-end-cli/models/self-employed', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    labor: _emberData['default'].belongsTo('labor'),
    profitEstimate: _emberData['default'].attr('number'),
    miscVariableIncomeChoice: _emberData['default'].attr('number'),
    miscVariableIncome: _emberData['default'].attr('number'),

    hasMiscVariableIncome: (function () {
      return this.get('miscVariableIncomeChoice') == 1;
    }).property('miscVariableIncomeChoice')
  });
});