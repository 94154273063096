define('front-end-cli/routes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    redirect: function redirect() {

      /**
        When visiting the index route while it was less
        than a day ago the user saw the welcome-message,
        we’ll transition to the timeline route.
         This prevents weird notifications that it was a
        couple of seconds since the user logged in etc.
      */
      var state = this.get('currentUser.model.state');
      if (!state) return;

      var seenWelcomeMessageDate = moment(state.get('seenWelcomeMessage')),
          startOfDay = moment().startOf('day'),
          showWelcomeMessage = seenWelcomeMessageDate.diff(startOfDay) < 0 || !seenWelcomeMessageDate.isValid();

      if (state.get('newUser') || showWelcomeMessage) {
        // Save the datetime when the user was
        // shown the welcomeMessage
        state.set('seenWelcomeMessage', new Date());
        state.save();
      } else {
        this.transitionTo('timeline');
      }
    },

    setupController: function setupController(controller, model) {
      // Reset controller properties
      controller.setProperties({
        isTourVisible: null
      });

      // Call _super for default behavior
      this._super(controller, model);
    }
  });
});