define('front-end-cli/controllers/profiler/incomelater-retirement-annuity', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    needs: ['profiler'],
    selectObjects: _ember['default'].computed.alias('controllers.profiler.selectObjects'),
    hasAnnuityType: (function () {
      return this.get('model.annuityType');
    }).property('model.annuityType'),

    isTypeInsurance: (function () {
      return this.get('model.annuityType') == 1;
    }).property('model.annuityType'),

    isTypeSavings: (function () {
      return this.get('model.annuityType') == 2;
    }).property('model.annuityType'),

    isSingleInvestement: (function () {
      return this.get('model.singleOrPeriodicInvestment') == 1;
    }).property('model.singleOrPeriodicInvestment'),

    isPeriodicInvestment: (function () {
      return this.get('model.singleOrPeriodicInvestment') == 2;
    }).property('model.singleOrPeriodicInvestment'),

    isCapital: (function () {
      return this.get('model.guaranteedCapitalChoice') == 1;
    }).property('model.guaranteedCapitalChoice'),

    hasPaymentWhenDeceased: (function () {
      return this.get('model.paymentWhenDeceasedChoice') == 1;
    }).property('model.paymentWhenDeceasedChoice')
  });
});