define('front-end-cli/controllers/current-user', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    chromeBwaInstalled: null,
    firefoxBwaInstalled: null,

    hasPartner: (function () {
      return this.get('model.profile.personal.civilState') > 1;
    }).property('model.profile.personal.civilState'),

    plusStates: (function () {
      return ['pre-trial', 'during-trial', 'ending-trial', 'after-trial', 'during-payed', 'ending-payed', 'after-payed'];
    }).property(),

    actions: {

      reloadUser: function reloadUser() {
        this.get('model').reload();
      },

      logOut: function logOut() {

        var pfpUrl = this.get('model.state.pfpUrl');

        _ember['default'].$.ajax({
          url: '/app/async/common.logout',
          dataType: 'json',
          success: function success() /*data*/{

            /*
            if(data.error.type == 2);// ok
            else;// already logged out
            */

            // MIJN TO WWW
            // var hostname = window.location.hostname.replace(/local|test|acceptatie|mijn|beta\./,'www');

            // REDIRECT
            window.location = pfpUrl + '/account/logout';
          }
        });
      }
    }
  });
});