define('front-end-cli/components/date-range-select', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    // sticky template name for when this component gets extended
    layoutName: 'components/date-range-select',

    value: null, // two way binding
    min: null,
    max: null,
    mid: null, // one way binding
    prompt: null,

    // date range properties
    isIncomplete: null,
    days: null,
    months: null,
    years: null,

    run: (function () {
      this.send('defineRange');
    }).observes('value', 'mid', 'incomplete').on('init'),

    actions: {
      defineRange: function defineRange() {

        var min = this.get('min'),
            max = this.get('max'),
            mid = this.get('mid');

        var yearsStart, yearsEnd, selected;

        if (_ember['default'].isNone(min) || _ember['default'].isNone(max)) {
          throw new Error('date-range-select component needs `min` and `max` declared in its Handlebars helper');
        }

        // if mid is undefined, min & max need to be in dateformat or we need a midfallback
        if (!mid) {
          yearsStart = this.get('minFallback') ? this.get('minFallback').match(/^[0-9]{4}/)[0] : false;
          yearsEnd = this.get('maxFallback') ? this.get('maxFallback').match(/^[0-9]{4}/)[0] : false;
          if (!yearsStart || !yearsEnd) {
            console.error('date-range-select: `mid` is undefined, and so are the min/max fallbacks.');
            // Die a gracefull death
            mid = new Date().toISOString().slice(0, 10);
          }
        } else {
          // convert `now` shortcut to current yyyy-mm-dd
          if (mid === 'now') mid = new Date().toISOString().slice(0, 10);

          // Figure out the start and end of our range
          var midYear = mid.match(/^[0-9]{4}/) * 1;
          yearsStart = midYear + min;
          yearsEnd = midYear + max;
        }

        // Parse the ranges
        var value, yearValue, monthValue, dayValue, daysInMonth;

        var monthLabels = {
          '1': 'januari',
          '2': 'februari',
          '3': 'maart',
          '4': 'april',
          '5': 'mei',
          '6': 'juni',
          '7': 'juli',
          '8': 'augustus',
          '9': 'september',
          '10': 'oktober',
          '11': 'november',
          '12': 'december'
        };

        value = this.get('value') || this.get('incomplete');
        if (value) {

          var date = value.split('-');
          yearValue = date[0] * 1;
          monthValue = date[1] * 1;
          dayValue = date[2] * 1;

          this.setProperties({
            yearLabel: yearValue || 'Jaar',
            monthLabel: monthValue ? monthLabels[monthValue] : 'Maand',
            dayLabel: dayValue || 'Dag',

            yearValue: yearValue,
            monthValue: monthValue,
            dayValue: dayValue
          });

          // assure right amount of days in current month
          daysInMonth = new Date(yearValue, monthValue, 0).getDate();

          // correct imposible dates
          if (!this.get('incomplete')) {

            var newDate;

            if (dayValue > daysInMonth) {
              newDate = value.replace(/[0-9]{1,2}$/, daysInMonth);
              this.set('value', newDate);
              return false;
            }
            // out of range
            else if (yearsStart > yearValue) {

                // console.log('Year is smaller than range ('+yearsStart+' > '+yearValue+')');

                // newDate = value.replace(/^[0-9]{4}/, yearsStart);
                // console.log(value, newDate);

                // option 1: clear value
                /*
                  this.set('value', '');
                  this.set('incomplete', '');
                */

                // option 2: keep value, clear labels
                this.setProperties({
                  yearLabel: 'Jaar',
                  monthLabel: 'Maand',
                  dayLabel: 'Dag',
                  yearValue: '',
                  monthValue: '',
                  dayValue: ''
                });
                // return false;
              } else if (yearValue > yearsEnd) {

                  // console.log('Year larger than range ('+yearValue+' > '+yearsEnd+')');

                  // newDate = value.replace(/^[0-9]{4}/, yearsEnd);
                  // console.log(value, newDate);

                  // option 1: clear value
                  /*
                    this.set('value', '');
                    this.set('incomplete', '');
                  */

                  this.setProperties({
                    yearLabel: 'Jaar',
                    monthLabel: 'Maand',
                    dayLabel: 'Dag',
                    yearValue: '',
                    monthValue: '',
                    dayValue: ''
                  });
                  // return false;
                }
          }
        } else {
            // prompt labels
            this.setProperties({
              yearLabel: 'Jaar',
              monthLabel: 'Maand',
              dayLabel: 'Dag',
              yearValue: '',
              monthValue: '',
              dayValue: ''
            });

            daysInMonth = 31;
          }

        var day = 1,
            days = [];
        while (day <= daysInMonth) {
          selected = day === dayValue ? 'selected' : '';
          days.push({
            value: day,
            selected: selected
          });
          day++;
        }
        this.set('days', days);

        var month = 1,
            months = [];
        while (month <= 12) {
          selected = month === monthValue ? 'selected' : '';
          months.push({
            value: month,
            label: monthLabels[month],
            selected: selected
          });
          month++;
        }
        this.set('months', months);
        var year = yearsEnd,
            years = [];
        while (year >= yearsStart) {
          selected = year === yearValue ? 'selected' : '';
          years.push({
            value: year,
            selected: selected
          });
          year--;
        }
        this.set('years', years);
      },

      changeValue: function changeValue() {
        var selects = this.$().find('select'),
            value;

        // hbs uses dutch date order (dd-mm-yyyy)
        var dd = selects.eq(0).prop('value'),
            mm = selects.eq(1).prop('value'),
            yyyy = selects.eq(2).prop('value');

        if (!yyyy || !mm || !dd) {
          this.set('incomplete', yyyy + '-' + mm + '-' + dd);
        } else {
          value = yyyy + '-' + mm + '-' + dd;
          this.set('value', value);
        }
        /**
          Until we use ember data's isDirty, the following
          action triggers the action defined in the rage-select
          hbs helper, which marks profile model dirty.
        */
        this.sendAction('action', true);
      }
    }
  });
});
/**
  Range-select ember component

  Basic usage:
  {{range-select min=30 max=45}}
  {{range-select value=35 min=30 max=45}}
  30-45

  `mid` will make `min` and `max` relative to itself:
  {{range-select min=5 mid=2013 max=10}}
  2008-2023

  yyyy-mm-dd format renders 3 select elements: year , month, day
  {{range-select min="2000-01-01" max="2020-01-01"}}

  `value` can be bound, two ways
  changing it from inside update outside value
  changing it from outside updates set selection (and day-select
  if date is impossible like februari 31st)
  {{range-select value=timo min=10 mid=mid max=10}}

  `mid` can be bound, one way
  changing it from outside will redefine the range
  {{range-select value=timo min=10 mid=mid max=10}}

  use 'now' to set `mid` to today:
  {{range-select value=timo min=10 mid='now' max=10}}

  limitations:
  - yyyy-mm-dd format
  - Date-ranges disregard months and days, they will
  allways start at januari 1st and end at december 31.
  - When value is out of range due to changing `mid`
  we should change `value` or prompt user.
  - error handling
  - prompt values for non date ranges aren't handled yet
 */