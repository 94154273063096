define('front-end-cli/pods/components/period-select/component', ['exports', 'ember', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _ember, _emberKeyboardShortcutsMixinsComponent) {
  exports['default'] = _ember['default'].Component.extend(_emberKeyboardShortcutsMixinsComponent['default'], {
    classNames: ['PeriodSelect'],

    /**-------------------------
      External bindings
    ---------------------------*/

    startDate: null,
    endDate: null,
    periodType: null,
    oldestTransactionDate: null,
    startDayFinancialMonth: null,

    // Actions
    transitionAction: null,

    /**-------------------------
      Properties
    ---------------------------*/

    isActive: false,

    // Bound to the bootstrap datepicker
    customStartDate: null,
    customEndDate: null,

    // Bound to period-select-item component
    currentMonth: null,
    currentQuarter: null,
    currentYear: null,

    selectedMonth: null,
    selectedQuarter: null,
    selectedYear: null,

    financialMonthStartDays: ['1', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'],
    isSettingFinancialMonthStartDay: null,
    /*
    financialMonthStartDay: function() {
      return moment(this.get('startDate'), 'YYYY-MM-DD').date();
    }.property('startDate'),
    */

    /*
    hasCustomFinancialMonth: function() {
      return this.get('financialMonthStartDay') >= 15;
    }.property('financialMonthStartDay'),
    */

    /**
      This property translates the components
      startDate and endDate into a human title.
    */
    currentPeriodTitle: (function () {

      var startDate = moment(this.get('startDate'), 'YYYY-MM-DD'),
          endDate = moment(this.get('endDate'), 'YYYY-MM-DD'),
          periodType = this.get('periodType');

      var currentPeriodTitle;

      if (periodType === 1) {
        currentPeriodTitle = endDate.format('MMMM YYYY');
      } else if (periodType === 2) {

        var quarter = endDate.quarter(),
            ordinal;
        if (quarter === 1) {
          ordinal = quarter += 'ste';
        } else {
          ordinal = quarter += 'de';
        }

        currentPeriodTitle = ordinal + ' kwartaal ' + endDate.format('YYYY');
      } else if (periodType === 3) {
        currentPeriodTitle = 'Jaar ' + endDate.format('YYYY');
      } else if (periodType === 4) {
        currentPeriodTitle = 'Aangepaste periode';
      }

      return currentPeriodTitle;
    }).property('startDate', 'endDate'),

    /**
      This property translates the components
      startDate and endDate into a human sub title.
    */
    currentPeriodSubTitle: (function () {
      var startDate = this.get('startDate'),
          endDate = this.get('endDate'),
          periodType = this.get('periodType'),
          format = 'D MMMM';

      if (periodType === 3) {
        if (moment(startDate, 'YYYY-MM-DD').date() !== 1) {
          // Year icw customFinancialMonth
          // gets special formatting.
          format = 'D MMM YYYY';
        }
      } else if (periodType === 4) {
        // Custom periods get special formatting
        // since there is plenty of space.
        format = 'D MMMM YYYY';
      }

      var startFormatted = moment(startDate, 'YYYY-MM-DD').format(format),
          endFormatted = moment(endDate, 'YYYY-MM-DD').format(format),
          joined = startFormatted + ' - ' + endFormatted,
          subTitle = joined.replace(/\./g, ''); // Get rid of the dots (jan.)

      return subTitle;
    }).property('startDate', 'endDate'),

    /**
      Define wich month, quarter and
      year the selectedPeriod is in.
    */

    defineCurrentMonthQuarterYear: (function () {

      var periodType = this.get('periodType'),
          startDate = moment(this.get('startDate'), 'YYYY-MM-DD'),
          endDate = moment(this.get('endDate'), 'YYYY-MM-DD');

      var month, quarter, year;

      if (periodType === 4) {

        month = null;
        quarter = null;
        year = null;
      } else {

        if (periodType === 2) {
          quarter = startDate.add(1, 'month').subtract(1, 'day').quarter();
        } else if (periodType === 1) {
          // quarter = startDate.add(1, 'month').subtract(1, 'day').quarter();
        } else {
            quarter = null;
          }

        if (periodType === 1) {
          var s = '000000000' + (endDate.month() + 1);
          month = s.substr(s.length - 2); // moment months are zero indexed
        } else if (periodType === 2) {
            // Used for setting `subactive` state
            // on all months in the quarter
            month = quarter;
          } else {
            month = null;
          }

        year = endDate.year();
      }

      this.setProperties({
        currentMonth: month,
        currentQuarter: quarter,
        currentYear: year,
        selectedPeriodType: periodType,
        selectedMonth: month,
        selectedQuarter: quarter,
        selectedYear: year,
        customStartDate: null,
        customEndDate: null
      });
    }).observes('startDate', 'endDate', 'periodType', 'isActive'),

    /**
      Used for showing/hiding the
      `setCustomPeriod` button.
    */
    hasCustomStartAndEndDate: (function () {

      var customStartDate = this.get('customStartDate'),
          customEndDate = this.get('customEndDate');

      // When neither is set, reset to current date,
      // taking into consideration any newly selected values.
      if (!customStartDate && !customStartDate) {
        this.setProperties({
          selectedPeriodType: this.get('selectedPeriodType') || this.get('periodType'),
          selectedMonth: this.get('selectedMonth') || this.get('currentMonth'),
          selectedQuarter: this.get('selectedQuarter') || this.get('currentQuarter'),
          selectedYear: this.get('selectedYear') || this.get('currentYear')
        });
      } else if (customStartDate && customEndDate) {
        this.setProperties({
          selectedPeriodType: null,
          selectedMonth: null,
          selectedQuarter: null,
          selectedYear: null
        });
      }
      return !!customStartDate && !!customEndDate;
    }).property('customStartDate', 'customEndDate'),

    /**
      Used for defining the startDate of the custom-date picker.
      TODO make sure transactions API sends
      `oldestTransactionDate`in meta.
    */
    startDateOfCalendar: (function () {
      var oldestTransactionDate = this.get('oldestTransactionDate');
      if (!oldestTransactionDate) {
        oldestTransactionDate = moment().startOf('year').subtract(3, 'years');
      }
      return moment(oldestTransactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }).property('oldestTransactionDate'),

    /**
      Set today as endDate limit for the custom-date picker.
    */
    endDateOfCalendar: (function () {
      return moment().format('DD-MM-YYYY');
    }).property(),

    hasSelectedPeriod: _ember['default'].computed('selectedYear', 'selectedQuarter', 'selectedMonth', 'hasCustomStartAndEndDate', function () {

      if (this.get('hasCustomStartAndEndDate')) return true;

      var periodType = this.get('periodType'),
          selectedPeriodType = this.get('selectedPeriodType'),
          currentYear = this.get('currentYear'),
          currentQuarter = this.get('currentQuarter'),
          currentMonth = this.get('currentMonth'),
          selectedYear = this.get('selectedYear'),
          selectedQuarter = this.get('selectedQuarter'),
          selectedMonth = this.get('selectedMonth');

      var selectedYearIsNotCurrent = selectedYear != currentYear,
          selectedMonthIsNotCurrent = selectedMonth != currentMonth,
          //periodType != 2//selectedQuarter
      selectedPeriodTypeIsNotCurrent = selectedPeriodType != periodType;

      var hasCompleteSelection = !!selectedYear && !!selectedYear && !!selectedMonth || !!selectedYear,
          selectionIsNotCurrent = selectedYearIsNotCurrent || selectedMonthIsNotCurrent || selectedPeriodTypeIsNotCurrent;

      return hasCompleteSelection && selectionIsNotCurrent;
    }),

    yearsWithTransactions: (function () {

      var oldestTransactionDate = moment(this.get('oldestTransactionDate'), 'YYYY-MM-DD'),
          startYear = oldestTransactionDate.year(),
          startMonth = oldestTransactionDate.month() + 1,
          currentDate = moment(),
          currentYear = currentDate.year(),
          currentMonth = currentDate.month() + 1,
          years = [];

      if (currentDate.date() >= this.get('startDayFinancialMonth')) {
        currentMonth++;

        if (currentMonth === 13) {
          currentYear++;
        }
      }

      if (oldestTransactionDate.date() >= this.get('startDayFinancialMonth')) {
        startMonth++;

        if (startMonth === 13) {
          startYear++;
        }
      }

      while (currentYear >= startYear) {
        years.push(currentYear);
        currentYear--;
      }

      return years;
    }).property('oldestTransactionDate', 'startDayFinancialMonth'),

    /**
      HACK to update value when clearing
      a custom date field. This ought to
      be removed when bumping datepicker.
       https://github.com/soulim/ember-cli-bootstrap-datepicker/issues/33
    */
    inputChanged: _ember['default'].on('input', function (e) {
      var input = _ember['default'].$(e.target);
      if (!input.val()) {
        if (input.hasClass('customStartDate')) {
          this.set('customEndDate', null);
        } else if (input.hasClass('customEndDate')) {
          this.set('customEndDate', null);
        }
      }
    }),

    /**-------------------------
      Methods
    ---------------------------*/

    /**
      Hide period-select when the event-target was
      outside the component and outside
      the datepicker.
    */
    _mouseDownResponder: function _mouseDownResponder(e) {
      if (this.get('isActive')) {

        var notWithinPeriodSelect = !_ember['default'].$(e.target).parents('.PeriodSelect').length,
            notWithinDatepicker = !_ember['default'].$(e.target).parents('.datepicker').length,
            onCurtain = _ember['default'].$(e.target).hasClass('PeriodSelect-curtain');

        if (onCurtain || notWithinPeriodSelect && notWithinDatepicker) {
          this.setProperties({
            isActive: false,
            isSettingFinancialMonthStartDay: null
          });
        }
      }
    },

    setMouseDownListeners: (function () {
      var _this = this;

      this.mdr = this._mouseDownResponder.bind(this);
      _ember['default'].$(document).on('mousedown touchend', this.mdr);

      // Hide any bootstrap-datepicker instances when scrolling
      // TODO This is a hack to make sure the bs-datepicker does
      // not scroll independently from the PeriodSelect.
      // The preferred way to solve this, is to inject the picker
      // in the period-select dom-container. Preferably using
      // bs-datepickers container option.
      // https://github.com/soulim/ember-cli-bootstrap-datepicker/issues/27
      _ember['default'].$(window).on('scroll.periodSelect', function (f) {
        if (_this.get('isActive')) _ember['default'].$('.datepicker').remove();
      });
    }).on('didInsertElement'),

    tearDown: (function () {
      _ember['default'].$(document).off('mousedown', this.mdr);
      _ember['default'].$(window).off('scroll.periodSelect');
    }).on('willDestroyElement'),

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {

      toggleActiveState: function toggleActiveState() {
        this.toggleProperty('isActive');
      },

      showMonth: function showMonth(month) {
        this.setProperties({
          selectedPeriodType: 1,
          selectedMonth: month,
          selectedQuarter: null,
          customStartDate: null,
          customEndDate: null
        });
      },

      showQuarter: function showQuarter(quarter) {
        this.setProperties({
          selectedPeriodType: 2,
          selectedMonth: quarter, // HACK
          selectedQuarter: quarter,
          customStartDate: null,
          customEndDate: null
        });
      },

      showYear: function showYear(year) {
        this.setProperties({
          selectedPeriodType: 3,
          selectedMonth: null, // HACK
          selectedQuarter: null,
          selectedYear: year,
          customStartDate: null,
          customEndDate: null
        });
      },
      /**
        The `nudge` actions transitions to next or previous period,
        relative to the current period’s type (month, quarter, year).
      */
      nudge: function nudge(direction) {

        var periodType = this.get('periodType'),
            startDate = this.get('startDate'),
            endDate = this.get('endDate');

        var units, format;
        if (periodType == 1) {
          units = 'months';
          format = 'MM-YYYY';
        } else if (periodType == 2) {
          units = 'quarters';
          format = '[kwartaal]-Q-YYYY';
        } else if (periodType == 3) {
          units = 'years';
          format = 'YYYY';
        }

        var periodParam;
        if (direction === 'forth') {
          periodParam = moment(endDate, 'YYYY-MM-DD').add(1, units).format(format);
        } else {
          periodParam = moment(endDate, 'YYYY-MM-DD').subtract(1, units).format(format);
        }

        this.sendAction('transitionAction', { queryParams: { periode: periodParam } });
      },

      setFinancialMonthStartDay: function setFinancialMonthStartDay() {
        this.set('isSettingFinancialMonthStartDay', true);
      },

      setFinancialMonthStartDayReady: function setFinancialMonthStartDayReady() {
        this.set('isSettingFinancialMonthStartDay', false);
      },

      confirmSelectedPeriod: function confirmSelectedPeriod() {

        if (!this.get('hasSelectedPeriod')) {
          this.set('isActive', false);
          return;
        }

        if (this.get('customStartDate') && this.get('customEndDate')) {
          this.send('setCustomPeriod');
          return;
        }

        var selectedYear = this.get('selectedYear'),
            selectedQuarter = this.get('selectedQuarter'),
            selectedMonth = this.get('selectedMonth');

        var periodParam = undefined;

        if (selectedQuarter) {
          periodParam = 'kwartaal-' + selectedQuarter + '-' + selectedYear;
        } else if (selectedMonth) {
          periodParam = selectedMonth + '-' + selectedYear;
        } else {
          periodParam = selectedYear;
        }

        // Transition with the appropriatre periodParam
        this.sendAction('transitionAction', { queryParams: { periode: periodParam } });

        // Close the period select
        this.set('isActive', false);
      },

      setCustomPeriod: function setCustomPeriod() {

        if (moment(this.get('customStartDate')).isAfter(this.get('customEndDate'))) {
          alert('Einddatum is eerder dan startdatum');
          return;
        }

        var customStartDate = moment(this.get('customStartDate')).format('DD-MM-YYYY'),
            customEndDate = moment(this.get('customEndDate')).format('DD-MM-YYYY'),
            periodParam = customStartDate + '_' + customEndDate;

        // Close the period select
        this.set('isActive', false);

        this.sendAction('transitionAction', { queryParams: { periode: periodParam } });
      },

      cancel: function cancel() {
        this.set('isActive', false);
      }
    },

    keyboardShortcuts: {
      'return': 'confirmSelectedPeriod',
      'esc': 'cancel'
    }
  });
});