define('front-end-cli/mixins/application-instrumentation', ['exports', 'ember'], function (exports, _ember) {

  /*
    In order to trigger an Ember instrumentation call from the BWA,
    we use the 'Window.postMessage' API.
  
    reference:
    https://developer.mozilla.org/en-US/docs/Web/API/Window.postMessage
    https://developer.chrome.com/extensions/content_scripts#host-page-communication
  */
  window.addEventListener('message', function (event) {
    // We only accept messages from ourselves
    // if (event.source != window) return; // FF doesn't send source
    if (event.data.type && event.data.type === 'FROM_PAGE') {
      var instrumentationName = event.data.instrumentationName;
      _ember['default'].instrument(instrumentationName);
    }
  }, false);

  exports['default'] = _ember['default'].Mixin.create({

    instrumentation: (function () {

      var _this = this;
      /**
      Mousedown events from within iFrame bubble up
      here so we can close any active modal's.
      */
      _ember['default'].subscribe('mousedown', {
        before: function before(name, timestamp, payload) {
          // jshint ignore:line
          _this.send('hideModal', true);
        },
        after: function after() /*name, timestamp, payload*/{}
      });

      /**
        This call is made from the `api`’s `tpl.layout.html` every
        time a legacy-page loads. It’s purpose is to force a reload
        whenever the api version changes.
         This ensures the the front-end will fetch the latest version
        of itself, whenever the api is updated.
         It will become obsolete when the all legacy views
        are rewritten in Ember.
         TODO: Rename call to `updateApiVersions`
        (both here and in back-end)
      */
      _ember['default'].subscribe('updateAppVersion', {
        before: function before(name, timestamp, payload) {
          // jshint ignore:line
          // TODO: Rename payload to `apiVersion`
          if (payload && payload.appVersion) {
            var apiVersion = payload.appVersion;
            _this.send('checkApiVersion', apiVersion);
          }
        },
        after: function after() {}
      });

      _ember['default'].subscribe('modelError', {
        before: function before(name, timestamp, payload) {
          // jshint ignore:line
          _this.transitionTo('legacy.login');
        },
        after: function after() /*name, timestamp, payload*/{}
      });

      _ember['default'].subscribe('standalone', {
        before: function before(name, timestamp, payload) {
          // jshint ignore:line

          if (payload && payload.loginSuccess) {

            if (_this.get('currentUser.model')) {
              //reload the window to reset all emberjs attributes, states enz.
              //only needed when a user model allready exist on login
              window.location.reload(true);
              return;
            }
            _this.set('noSession', null);

            var promises = [];

            // Now that we have a session, retry getting the userdata
            promises.pushObject(_this.store.find('user', 1));
            promises.pushObject(_this.store.find('bank'));

            _ember['default'].RSVP.all(promises).then(function (responses) {
              var user = responses[0],
                  banks = responses[1];

              _this.set('currentUser.model', user);

              // Redirect to or from welcome appropriately
              _this.send('welcomeTransition', user);

              _this.get('sessionManager').initialize();

              // Try to exit full screen after login page 'left' iframe
              // and before new page is loaded. Sigh...
              _ember['default'].run.later(this, function () {
                _ember['default'].$('#legacy-wrap').removeClass('standalone'); // exit fullscreen
                _ember['default'].$('#app').find('main').removeClass('standalone-fullscreen');
              }, 1500);

              // Make sure things are in place before animation is turned back on
              _ember['default'].run.later(this, function () {
                _ember['default'].$('#app').addClass('animate');
              }, 2000);
            });
          }
          /**
          Go into full screen login mode
          */
          else {

              _ember['default'].$('#app').removeClass('animate') // turn off sidebar animation
              .find('main').addClass('standalone-fullscreen');
              _ember['default'].$('#sidebaraccounts-modal').remove();

              _ember['default'].$('#legacy-wrap').addClass('standalone'); // enter fullscreen
              _ember['default'].$('#initializing').removeClass('active');
            }
        },
        after: function after() /*name, timestamp, payload*/{}
      });

      _ember['default'].subscribe('reloadUserModel', {
        before: function before(name, timestamp, payload) {
          // jshint ignore:line
          _this.get('currentUser').send('reloadUser');
        },
        after: function after() /*name, timestamp, payload*/{}
      });

      // Reload accounts in the store,
      // optionally unload them first.
      _ember['default'].subscribe('reloadAccountsModel', {
        before: function before(name, timestamp, payload) {
          // jshint ignore:line
          if (payload.unloadAccountsFirst) {
            // This will cause the UI to glitch since there will be
            // no accounts until the find call below resolves.
            // It'll also make observers on the accounts trigger
            // multiple times. Undesirable.
            _this.store.unloadAll('account');
          }
          // _this.store.find('account');

          // Back to square one, for now.
          _this.get('currentUser').send('reloadUser');
        },
        after: function after() /*name, timestamp, payload*/{}
      });

      // Reload accounts in the store,
      // optionally unload them first.
      _ember['default'].subscribe('reloadCategoriesModel', {
        before: function before(name, timestamp, payload) {
          // Back to square one, for now.
          _this.store.find('category');
          _this.store.find('categorybudget');
        },
        after: function after() /*name, timestamp, payload*/{}
      });

      _ember['default'].subscribe('reloadStateModel', {
        before: function before() /*name, timestamp, payload*/{
          _this.get('currentUser.model.state').rollback();
          _this.get('currentUser.model.state').reload();
        },
        after: function after() /*name, timestamp, payload*/{}
      });

      _ember['default'].subscribe('updateAccountAutomaticImport', {
        before: function before(name, timestamp, payload) {
          if (payload && payload.accountId) {
            _this.transitionTo('automatic-import.update-account', _this.store.find('account', payload.accountId));
          }
        },
        after: function after() /*name, timestamp, payload*/{}
      });

      _ember['default'].subscribe('updateAccount', {
        before: function before(name, timestamp, payload) {
          if (payload && payload.accountId) {
            _this.transitionTo('accountmanagement.update', _this.store.find('account', payload.accountId));
          }
        },
        after: function after() /*name, timestamp, payload*/{}
      });

      _ember['default'].subscribe('unlockAccount', {
        before: function before(name, timestamp, payload) {
          if (payload && payload.accountId) {
            _this.store.find('account', payload.accountId).then(function (account) {
              account.set('locked', false);
              account.save().then(function (account) {
                _ember['default'].$('#legacy').attr('src', function (i, val) {
                  return val;
                });
              });
            });
          }
        },
        after: function after() /*name, timestamp, payload*/{}
      });

      _ember['default'].subscribe('resetSyncType', {
        before: function before(name, timestamp, payload) {

          // Lets argue where to put this, some day ...
          if (payload && payload.accountId) {

            var account = _this.store.getById('account', payload.accountId);

            if (account.get('bankLogin')) {

              var bankLogin = account.get('bankLogin');
              var id = bankLogin.get('id');
              var hasSiblings;

              // Do we have sibling accounts related to this bankLogin?
              _this.store.all('account').forEach(function (acc) {
                if (acc.get('bankLogin.id') === id) {
                  hasSiblings = true;
                }
              });

              // No siblings? destroy the bankLogin
              if (!hasSiblings) {
                bankLogin.destroyRecord();
              }
            }

            // Reset the syncType
            account.setProperties({
              bankLogin: null,
              syncType: null
            });

            account.save().then(function () {
              _this.get('currentUser').send('reloadUser');
            });
          }
        },
        after: function after() /*name, timestamp, payload*/{}
      }, this);

      _ember['default'].subscribe('archiveAccount', {
        before: function before(name, timestamp, payload) {

          // Lets argue where to put this, some day ...
          if (payload && payload.accountId) {

            var account = _this.store.getById('account', payload.accountId);
            if (account.get('archived')) {
              account.set('archived', false);
            } else {
              account.set('archived', true);
            }

            account.save().then(function () {
              _this.get('currentUser').send('reloadUser');
            });
          }
        },
        after: function after() /*name, timestamp, payload*/{}
      }, this);

      _ember['default'].subscribe('openOutsideOfIframe', {
        before: function before(name, timestamp, payload) {
          if (payload.url) window.location = payload.url;
        },
        after: function after() /*name, timestamp, payload*/{}
      });

      _ember['default'].subscribe('startLoading', {
        before: function before(name, timestamp, payload) {
          var loadingTimeEstimate = payload.loadingTimeEstimate || null;
          _this.send('loading', loadingTimeEstimate);
        },
        after: function after(name, timestamp, payload) {}
      });

      _ember['default'].subscribe('stopLoading', {
        before: function before(name, timestamp, payload) {
          _this.send('endLoading');
        },
        after: function after(name, timestamp, payload) {}
      });

      _ember['default'].subscribe('transitionToEmberRoute', {
        before: function before(name, timestamp, payload) {
          if (payload.route) {
            _this.transitionTo(payload.route);
          }
        },
        after: function after(name, timestamp, payload) {}
      });

      _ember['default'].subscribe('bwaInstalled', {
        before: function before(name, timestamp, payload) {
          // Reload accounts, notify user somehow
          _ember['default'].run.next(function () {
            if (/\.chrome/.test(name)) {
              _this.set('currentUser.chromeBwaInstalled', true);
            } else if (/\.firefox/.test(name)) {
              _this.set('currentUser.firefoxBwaInstalled', true);
            }
          });
        },
        after: function after(name, timestamp, payload) {}
      });

      /**
        This instrumentation method is triggered from the
        Chrome or Firefox bwa. And communicates that a setup
        or update was successful.
         `bwaImportSuccess` namespace can contain:
        `bwaImportSuccess.chrome` or `bwaImportSuccess.firefox`
         Reference on namescapeced instrumentation:
        http://emberjs.com/api/classes/Ember.Instrumentation.html#method_subscribe
      */
      _ember['default'].subscribe('bwaImportSuccess', {
        before: function before(name, timestamp, payload) {

          // First transition to the bwaSuccess route
          // with a query param containing the current route
          var currentRouteName = _this.controllerFor('application').get('currentRouteName');
          var params = {},
              targetRoute;

          if (/\.setup/.test(currentRouteName)) {
            /*
              When in the `setup` route we'll reload into
              the `accountmanagement.setup-success` route.
               When the payload contains the `addedMultipleAccounts`
              option, we'll send along a param to trigger
              pluralisation.
            */

            targetRoute = 'accountmanagement.setup-success';
            if (payload.updatedMultipleAccounts) {
              params.updatedMultipleAccounts = payload.updatedMultipleAccounts;
            }
          } else {

            /**
              When in the `updateRoute` we'll reload into timeline.
              When in any other route, we'll reload into the `currentRoute`.
              Both will have a `bwaSuccess` param.
               The param will trigger an action in the
              `applicationRoute` which renders a modal
              (`application/bwa-success` template)
              to indicate the update was successful.
            */
            params.bwaSuccess = true;
            if (payload.updatedMultipleAccounts) {
              params.updatedMultipleAccounts = payload.updatedMultipleAccounts;
            }

            if (/\.update/.test(currentRouteName)) {
              targetRoute = 'timeline';
            } else {
              targetRoute = currentRouteName;
            }
          }

          // Transition into the target route
          _this.transitionTo(targetRoute, { queryParams: params });

          // Force Reload
          _ember['default'].run.next(function () {
            window.location.reload(true);
          });
        },
        after: function after(name, timestamp, payload) {}
      });
    }).on('init')
  });
});