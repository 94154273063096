define('front-end-cli/initializers/store-injector', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(container, application) {
    application.inject('service:store-service', 'store', 'store:main');
  }

  exports['default'] = {
    name: 'store-injector',
    initialize: initialize
  };
});