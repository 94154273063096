define("front-end-cli/pods/components/category-select/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 12
              },
              "end": {
                "line": 25,
                "column": 12
              }
            },
            "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "CategorySelect-costTypeContainer");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "type", "radio");
            dom.setAttribute(el2, "id", "CostType-all");
            dom.setAttribute(el2, "name", "CostType");
            dom.setAttribute(el2, "value", "all");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "for", "CostType-all");
            var el3 = dom.createTextNode("Alles");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "type", "radio");
            dom.setAttribute(el2, "id", "CostType-fixed");
            dom.setAttribute(el2, "name", "CostType");
            dom.setAttribute(el2, "value", "fixed");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "for", "CostType-fixed");
            var el3 = dom.createTextNode("Alleen vaste lasten");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "type", "radio");
            dom.setAttribute(el2, "id", "CostType-variable");
            dom.setAttribute(el2, "name", "CostType");
            dom.setAttribute(el2, "value", "variable");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "for", "CostType-variable");
            var el3 = dom.createTextNode("Alleen variabele uitgaven");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element15 = dom.childAt(fragment, [1]);
            var element16 = dom.childAt(element15, [1]);
            if (this.cachedFragment) {
              dom.repairClonedNode(element16, [], true);
            }
            var element17 = dom.childAt(element15, [4]);
            if (this.cachedFragment) {
              dom.repairClonedNode(element17, [], true);
            }
            var element18 = dom.childAt(element15, [7]);
            if (this.cachedFragment) {
              dom.repairClonedNode(element18, [], true);
            }
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element16, 'onchange');
            morphs[1] = dom.createAttrMorph(element16, 'checked');
            morphs[2] = dom.createAttrMorph(element17, 'onchange');
            morphs[3] = dom.createAttrMorph(element17, 'checked');
            morphs[4] = dom.createAttrMorph(element18, 'onchange');
            morphs[5] = dom.createAttrMorph(element18, 'checked');
            return morphs;
          },
          statements: [["attribute", "onchange", ["subexpr", "action", ["setCostType", "all"], []]], ["attribute", "checked", ["subexpr", "is-equal", [["get", "costType"], "all"], []]], ["attribute", "onchange", ["subexpr", "action", ["setCostType", "fixed"], []]], ["attribute", "checked", ["subexpr", "is-equal", [["get", "costType"], "fixed"], []]], ["attribute", "onchange", ["subexpr", "action", ["setCostType", "variable"], []]], ["attribute", "checked", ["subexpr", "is-equal", [["get", "costType"], "variable"], []]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 16
                },
                "end": {
                  "line": 40,
                  "column": 16
                }
              },
              "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Alle categorieën");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" \n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var element12 = dom.childAt(element11, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element11, 'class');
              morphs[1] = dom.createElementMorph(element11);
              morphs[2] = dom.createAttrMorph(element12, 'class');
              morphs[3] = dom.createElementMorph(element12);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["index ", ["get", "categoryIndexStateClass"]]]], ["element", "action", ["setCategoryIndex"], []], ["attribute", "class", ["concat", ["Category is-index", ["subexpr", "if", [["get", "categoryIndexIsHighlit"], "", " is-gray"], []], ["subexpr", "if", [["get", "category"], "", " is-blue"], []]]]], ["element", "action", ["highlightCategoryIndex"], ["on", "mouseEnter"]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 42,
                    "column": 18
                  },
                  "end": {
                    "line": 52,
                    "column": 18
                  }
                },
                "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
              },
              arity: 3,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element10, 'class');
                morphs[1] = dom.createMorphAt(element10, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["Category ", ["subexpr", "if", [["get", "thisCategory.enabled"], "", "is-disabled"], []], " ", ["get", "subcategoryStateClass"]]]], ["content", "thisCategory.name"]],
              locals: ["isHighlit", "isActive", "subcategoryStateClass"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 16
                },
                "end": {
                  "line": 53,
                  "column": 16
                }
              },
              "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "category-select/category-select-li", [], ["action", "chooseCategory", "leaveTimer", ["subexpr", "@mut", [["get", "leaveTimer"]], []], "category", ["subexpr", "@mut", [["get", "category"]], []], "subcategory", ["subexpr", "@mut", [["get", "subcategory"]], []], "thisCategory", ["subexpr", "@mut", [["get", "thisCategory"]], []], "highlitCategory", ["subexpr", "@mut", [["get", "highlitCategory"]], []], "isSubcategorySelectionOnly", ["subexpr", "@mut", [["get", "isSubcategorySelectionOnly"]], []]], 0, null]],
            locals: ["thisCategory"],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 63,
                      "column": 18
                    },
                    "end": {
                      "line": 67,
                      "column": 18
                    }
                  },
                  "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode("Alle subcategorieën");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" \n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element8 = dom.childAt(fragment, [1]);
                  var element9 = dom.childAt(element8, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createAttrMorph(element8, 'class');
                  morphs[1] = dom.createElementMorph(element8);
                  morphs[2] = dom.createAttrMorph(element9, 'class');
                  morphs[3] = dom.createElementMorph(element9);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["index ", ["get", "subcategoryIndexStateClass"]]]], ["element", "action", ["setSubcategoryIndex", ["get", "highlitCategory"]], []], ["attribute", "class", ["concat", ["Subcategory is-index", ["subexpr", "if", [["get", "subcategoryIndexIsHighlit"], " is-blueFill", " is-gray"], []], ["subexpr", "if", [["get", "activeSubcategoryIndex"], " is-blue"], []]]]], ["element", "action", ["highlightSubcategoryIndex"], ["on", "mouseEnter"]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@1.13.2",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 70,
                          "column": 22
                        },
                        "end": {
                          "line": 78,
                          "column": 22
                        }
                      },
                      "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
                    },
                    arity: 3,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element7 = dom.childAt(fragment, [1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createAttrMorph(element7, 'class');
                      morphs[1] = dom.createMorphAt(element7, 0, 0);
                      return morphs;
                    },
                    statements: [["attribute", "class", ["concat", ["Subcategory ", ["subexpr", "if", [["get", "thisSubcategory.enabled"], "", "is-disabled"], []], " ", ["get", "subcategoryStateClass"]]]], ["content", "thisSubcategory.name"]],
                    locals: ["isHighlit", "isActive", "subcategoryStateClass"],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@1.13.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 69,
                        "column": 20
                      },
                      "end": {
                        "line": 79,
                        "column": 20
                      }
                    },
                    "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "category-select/category-select-li-sub", [], ["action", "chooseSubcategory", "leaveTimer", ["subexpr", "@mut", [["get", "leaveTimer"]], []], "subcategory", ["subexpr", "@mut", [["get", "subcategory"]], []], "thisSubcategory", ["subexpr", "@mut", [["get", "thisSubcategory"]], []], "highlitSubcategory", ["subexpr", "@mut", [["get", "highlitSubcategory"]], []]], 0, null]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 68,
                      "column": 18
                    },
                    "end": {
                      "line": 80,
                      "column": 18
                    }
                  },
                  "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "or-cond", [["get", "thisSubcategory.enabled"], ["get", "showAllCategoriesAsEnabled"]], []]], [], 0, null]],
                locals: ["thisSubcategory"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 18
                  },
                  "end": {
                    "line": 81,
                    "column": 18
                  }
                },
                "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "unless", [["get", "isSubcategorySelectionOnly"]], [], 0, null], ["block", "each", [["get", "highlitCategory.subcategories"]], [], 1, null]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 16
                },
                "end": {
                  "line": 82,
                  "column": 16
                }
              },
              "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "subcategoriesBelongToCurrentFilters"]], [], 0, null]],
            locals: [],
            templates: [child0]
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 88,
                      "column": 20
                    },
                    "end": {
                      "line": 90,
                      "column": 20
                    }
                  },
                  "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "class", "CategorySelect-addSubcategoryShow");
                  var el2 = dom.createTextNode("Subcategorie toevoegen");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element6);
                  return morphs;
                },
                statements: [["element", "action", ["showAddSubcategory"], []]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 92,
                        "column": 22
                      },
                      "end": {
                        "line": 94,
                        "column": 22
                      }
                    },
                    "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    var el2 = dom.createTextNode("Voeg toe");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element4, 'class');
                    morphs[1] = dom.createAttrMorph(element4, 'disabled');
                    morphs[2] = dom.createElementMorph(element4);
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["CategorySelect-addSubcategoryButton btn btn-default btn-sm", ["subexpr", "if", [["get", "validNewSubcategoryName"], "", " btn-disabled"], []]]]], ["attribute", "disabled", ["subexpr", "if", [["get", "validNewSubcategoryName"], false, true], []]], ["element", "action", ["addSubcategory"], []]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 90,
                      "column": 20
                    },
                    "end": {
                      "line": 96,
                      "column": 20
                    }
                  },
                  "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "class", "CategorySelect-addSubcategoryCancel");
                  var el2 = dom.createTextNode("Annuleer");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [5]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  morphs[2] = dom.createElementMorph(element5);
                  return morphs;
                },
                statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "newSubcategoryName"]], []], "class", "CategorySelect-addSubcategoryInput", "autofocus", true, "placeholder", "Nieuwe subcategorie"]], ["block", "if", [["get", "validNewSubcategoryName"]], [], 0, null], ["element", "action", ["hideAddSubcategory"], []]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 86,
                    "column": 16
                  },
                  "end": {
                    "line": 98,
                    "column": 16
                  }
                },
                "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "CategorySelect-addSubcategoryContainer");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "unless", [["get", "showAddSubcategory"]], [], 0, 1]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 85,
                  "column": 14
                },
                "end": {
                  "line": 99,
                  "column": 14
                }
              },
              "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "optionAddSubcats"]], [], 0, null]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 8
              },
              "end": {
                "line": 103,
                "column": 8
              }
            },
            "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "col-sm-6");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "CategorySelect-categoryContainer");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "col-sm-6");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "CategorySelect-subcategoryContainer");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1, 1, 1]);
            var element14 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(element13, 1, 1);
            morphs[1] = dom.createMorphAt(element13, 2, 2);
            morphs[2] = dom.createMorphAt(dom.childAt(element14, [1]), 1, 1);
            morphs[3] = dom.createMorphAt(element14, 3, 3);
            return morphs;
          },
          statements: [["block", "unless", [["get", "isSubcategorySelectionOnly"]], [], 0, null], ["block", "each", [["get", "enabledCategories"]], [], 1, null], ["block", "if", [["get", "highlitCategory"]], [], 2, null], ["block", "if", [["get", "highlitCategory"]], [], 3, null]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 110,
                      "column": 20
                    },
                    "end": {
                      "line": 119,
                      "column": 20
                    }
                  },
                  "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
                },
                arity: 3,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element3, 'class');
                  morphs[1] = dom.createMorphAt(element3, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["Category ", ["get", "subcategoryStateClass"]]]], ["content", "c.name"]],
                locals: ["isHighlit", "isActive", "subcategoryStateClass"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 109,
                    "column": 18
                  },
                  "end": {
                    "line": 120,
                    "column": 18
                  }
                },
                "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "category-select/category-select-li", [], ["action", "chooseCategory", "leaveTimer", ["subexpr", "@mut", [["get", "leaveTimer"]], []], "category", ["subexpr", "@mut", [["get", "category"]], []], "subcategory", ["subexpr", "@mut", [["get", "subcategory"]], []], "thisCategory", ["subexpr", "@mut", [["get", "c"]], []], "highlitCategory", ["subexpr", "@mut", [["get", "highlitCategory"]], []]], 0, null]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 121,
                      "column": 20
                    },
                    "end": {
                      "line": 130,
                      "column": 20
                    }
                  },
                  "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
                },
                arity: 3,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var element2 = dom.childAt(fragment, [3]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createAttrMorph(element1, 'class');
                  morphs[1] = dom.createMorphAt(element1, 0, 0);
                  morphs[2] = dom.createAttrMorph(element2, 'class');
                  morphs[3] = dom.createMorphAt(element2, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["Category ", ["get", "subcategoryStateClass"]]]], ["content", "c.category.name"], ["attribute", "class", ["concat", ["Subcategory ", ["get", "subcategoryStateClass"]]]], ["content", "c.name"]],
                locals: ["isHighlit", "isActive", "subcategoryStateClass"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 120,
                    "column": 18
                  },
                  "end": {
                    "line": 131,
                    "column": 18
                  }
                },
                "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "category-select/category-select-li-sub", [], ["action", "chooseSubcategory", "leaveTimer", ["subexpr", "@mut", [["get", "leaveTimer"]], []], "subcategory", ["subexpr", "@mut", [["get", "subcategory"]], []], "thisSubcategory", ["subexpr", "@mut", [["get", "c"]], []], "highlitSubcategory", ["subexpr", "@mut", [["get", "highlitSubcategory"]], []]], 0, null]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 108,
                  "column": 16
                },
                "end": {
                  "line": 133,
                  "column": 16
                }
              },
              "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "if", [["get", "c.subcategories"]], [], 0, 1]],
            locals: ["c"],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 133,
                  "column": 16
                },
                "end": {
                  "line": 135,
                  "column": 16
                }
              },
              "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "list-group CategorySelect-noSearchResults");
              var el2 = dom.createTextNode("Er zijn geen (sub)categorieën die");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("beginnen met ‘");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("’.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
              return morphs;
            },
            statements: [["content", "categorySearchTerm"]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 103,
                "column": 8
              },
              "end": {
                "line": 140,
                "column": 8
              }
            },
            "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "col-sm-12");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "CategorySelect-categoryContainer");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            dom.setAttribute(el3, "class", "list-group");
            var el4 = dom.createTextNode("\n\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "searchResults"]], [], 0, 1]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 144,
                "column": 2
              },
              "end": {
                "line": 146,
                "column": 2
              }
            },
            "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "CategorySelect-curtain");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["hideSelectIfModal"], []]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 0
            },
            "end": {
              "line": 147,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "CategorySelect-modalOverflow");
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row no-gutters is-gutterless");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-sm-12");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "box");
          var el6 = dom.createTextNode("\n\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6, "class", "CategorySelect-searchContainer");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row no-gutters is-gutterless");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var element20 = dom.childAt(element19, [1]);
          var element21 = dom.childAt(element20, [1, 1, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element19, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element21, [1]), 1, 1);
          morphs[2] = dom.createMorphAt(element21, 3, 3);
          morphs[3] = dom.createMorphAt(dom.childAt(element20, [3]), 1, 1);
          morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["attribute", "class", ["subexpr", "if", [["get", "modal"], "CategorySelect-modal"], []]], ["inline", "input", [], ["class", ["subexpr", "concat", ["CategorySelect-searchInput", " ", "Xform-control", " "], []], "value", ["subexpr", "@mut", [["get", "categorySearchTerm"]], []], "placeholder", "Begin te typen om de categorieën te filteren"]], ["block", "if", [["subexpr", "is-equal", [["get", "type"], "expenses"], []]], [], 0, null], ["block", "unless", [["get", "categorySearchTerm"]], [], 1, 2], ["block", "if", [["get", "modal"]], [], 3, null]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 148,
            "column": 0
          }
        },
        "moduleName": "front-end-cli/pods/components/category-select/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "CategorySelect-title");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element22 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createElementMorph(element22);
        morphs[1] = dom.createMorphAt(element22, 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["element", "action", ["toggleVisibility"], []], ["content", "yield"], ["block", "if", [["get", "showSelect"]], [], 0, null]],
      locals: [],
      templates: [child0]
    };
  })());
});