define('front-end-cli/pods/components/transaction-details/actions/disable-pane/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    /**-------------------------
      External Bindings
    ---------------------------*/

    transaction: null,

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {
      disableTransaction: function disableTransaction() {
        this.get('detailsComponent').send('disableTransaction', true);
      },
      enableTransaction: function enableTransaction() {
        this.get('detailsComponent').send('disableTransaction', false);
      }
    }
  });
});