define('front-end-cli/models/state', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    user: _emberData['default'].belongsTo('user'),
    plus: _emberData['default'].attr('boolean'),
    plusState: _emberData['default'].attr('string'), // pre-trial, during-trial, ending-trial, after-trial, during-payed, ending-payed, after-payed
    lastLogin: _emberData['default'].attr('string'),
    registerDate: _emberData['default'].attr('date'),
    newUser: _emberData['default'].attr('boolean'),
    newUserEmber: _emberData['default'].attr('boolean'),
    isAdvisor: _emberData['default'].attr('boolean'),

    pfpSubscriptionId: _emberData['default'].attr('number'),
    pfpSubscription: _emberData['default'].attr('string'),
    pfpSubscriptionPaymentId: _emberData['default'].attr('number'),
    reports: _emberData['default'].attr('boolean'),
    budgets: _emberData['default'].attr('boolean'),
    budgetsPerYear: _emberData['default'].attr('boolean'),
    contracts: _emberData['default'].attr('boolean'),
    amountOfAccounts: _emberData['default'].attr('number'),
    hasAccessToAllAccounts: _emberData['default'].attr('boolean'),
    hasPlanning: _emberData['default'].attr('boolean'),
    isPfpTrial: _emberData['default'].attr('boolean'),

    pfmUrl: _emberData['default'].attr('string'),

    featureInfo: _emberData['default'].attr(),

    /* TODO : Refactor PSD2 Feature Info */
    psd2HasAccess: _emberData['default'].attr('boolean'),
    psd2Fee: _emberData['default'].attr('string'),
    psd2FeePerMonth: _emberData['default'].attr('string'),
    psd2MonthsNumber: _emberData['default'].attr('number'),
    psd2PayFeeUrl: _emberData['default'].attr('string'),
    psd2StartDate: _emberData['default'].attr('date'),
    psd2EndDate: _emberData['default'].attr('date'),
    psd2NeverHadFeature: _emberData['default'].attr('boolean'),

    pfpUrl: _emberData['default'].attr('string'),

    seenWelcomeMessage: _emberData['default'].attr('date'),
    seenExtendPlusNotice: _emberData['default'].attr('date'),
    isEmployee: _emberData['default'].attr('boolean'),
    isPaycheckOnlyUser: _emberData['default'].attr('boolean'),
    startDayFinancialMonth: _emberData['default'].attr('string'),
    needsToLockAccounts: _emberData['default'].attr('boolean'),
    hasBudgets: _emberData['default'].attr('boolean'),

    /* FEATURE FLAGS */
    featureFlagNibud: _emberData['default'].attr('boolean'),

    plusBeforeTrail: (function () {
      return this.get('plusState') == 'pre-trial';
    }).property('plusState'),
    plusAfterTrial: (function () {
      return this.get('plusState') == 'after-trial';
    }).property('plusState'),
    plusAfterPaid: (function () {
      return this.get('plusState') == 'after-payed';
    }).property('plusState'),
    noPlusTest: (function () {
      return !this.plus && !/pre-trial|after-trial|after-payed/.test(this.get('plusState'));
    }).property('plusState'),
    timeSinceLastLastLogin: (function () {
      var lastLogin = moment(this.get('lastLogin'), 'YYYY-MM-DD'),
          today = moment().format('YYYY-MM-DD');
      return lastLogin.from(today);
    }).property('lastLogin')

  });
});