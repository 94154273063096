define('front-end-cli/views/profiler/text-input', ['exports', 'ember', 'front-end-cli/mixins/profiler/changed-state'], function (exports, _ember, _frontEndCliMixinsProfilerChangedState) {
  exports['default'] = _ember['default'].TextField.extend(_frontEndCliMixinsProfilerChangedState['default'], {
    attributeBindings: ['style', 'placeholder', 'name', 'data-test'],
    style: (function () {
      return ('width:' + this.get('width')).htmlSafe();
    }).property('width'),

    change: function change() {
      // BUG WHERE INPUT IS REPLACE BY SOME JQUERY STRING
      if (/\?\?/.test(this.get('value'))) {
        var clean = this.get('value').replace(/\?\?/, '?');
        this.set('value', clean);
      }
    }
  });
});