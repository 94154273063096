define('front-end-cli/pods/budget/yeartotals/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    queryParams: {
      year: { refreshModel: true }
    },

    beforeModel: function beforeModel(transition) {
      this.set('year', transition.queryParams.year);
    },

    model: function model(params) {
      var year = this.get('year') ? this.get('year') : moment().year();
      return _ember['default'].$.get('/api/v3/trendsbudget?year=' + year);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var budgetController = this.controllerFor('budget');
      controller.setProperties({
        year: budgetController.get('year'),
        month: budgetController.get('month')
      });
    }

  });
});