define('front-end-cli/models/income-later-retirement-employer', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    incomeLater: _emberData['default'].belongsTo('income-later'),

    description: _emberData['default'].attr('string'),
    provider: _emberData['default'].attr('string'),
    registrationNo: _emberData['default'].attr('number'),

    amountByRetirement: _emberData['default'].attr('number'),
    endAge: _emberData['default'].attr('number'),
    endAgeChoice: _emberData['default'].attr('number'),
    growthFactorA: _emberData['default'].attr('number'),
    retirementAge: _emberData['default'].attr('number'),
    survivorPensionChilderen: _emberData['default'].attr('number'),
    survivorPensionPartner: _emberData['default'].attr('number'),
    upoYear: _emberData['default'].attr('string'),
    hasEndAge: (function () {
      return this.get('endAgeChoice') == 2;
    }).property('endAgeChoice')
  });
});