define('front-end-cli/controllers/automatic-import/update-all-accounts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      saveImportSettings: function saveImportSettings() {
        var selectCollection = document.getElementsByClassName('automatic-import-setting');
        for (var i = 0; i < selectCollection.length; i++) {
          var value = selectCollection[i].options[selectCollection[i].selectedIndex].value;
          var accountId = selectCollection[i].children[2].value;
          _ember['default'].$.ajax({
            type: 'GET',
            url: '/api/v3/automaticimport?procedure=saveimportsetting&accountid=' + accountId + '&option=' + value
          }).success(function (response) {
            //Success
          }).error(function (error) {
            //Error
          });
        }
        _ember['default'].$(document).ajaxStop(function () {
          location.reload();
          _ember['default'].$(this).unbind('ajaxStop');
        });
      },

      importTransactions: function importTransactions() {
        var accounts = this.get('currentUser.model.accounts');
        var updateAccountDiv = document.getElementById('update-button');
        var toAccountsDiv = document.getElementById('to-accounts');
        var currentUser = this.get('currentUser');

        updateAccountDiv.style.display = 'none';

        _ember['default'].$.ajax({
          type: 'GET',
          url: '/api/v3/automaticimport?procedure=importall'
        }).success(function (response) {
          accounts.forEach(function (account) {
            if (account.get('bankConsentActive') && !account.get('archived')) {
              var accountDiv = document.getElementById('updating_' + account.get('accountNumber'));
              accountDiv.innerText = 'Rekening is succesvol bijgewerkt';
              accountDiv.style.color = '#3B8F21';
            }
          });
          currentUser.send('reloadUser');
          toAccountsDiv.style.display = 'block';
        }).error(function (error) {
          accounts.forEach(function (account) {
            if (account.get('bankConsentActive') && !account.get('archived')) {
              var accountDiv = document.getElementById('updating_' + account.get('accountNumber'));
              accountDiv.innerText = 'Bijwerken niet gelukt';
              accountDiv.style.color = '#E00000';
            }
          });
          updateAccountDiv.style.display = 'block';
        });

        accounts.forEach(function (account) {
          if (account.get('bankConsentActive') && !account.get('archived')) {
            var accountDiv = document.getElementById('updating_' + account.get('accountNumber'));
            accountDiv.innerText = 'Bezig met bijwerken...';
            accountDiv.style.color = '#083160';
          }
        });
      }
    }
  });
});