define('front-end-cli/views/profiler/input-delete', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({
    tagName: 'span',
    classNames: ['profiler-delete'],
    templateName: 'profiler/mixin/delete',

    click: function click(evt) {
      this.get('controller.parentController').send('deleteRecord', this.get('value'));
      this.get('controller.parentController').send('setChangeStatus');
      // App.ProfileDeleteAnimation.create({view: this});
    },

    touchEnd: function touchEnd(evt) {
      this.get('controller.parentController').send('deleteRecord', this.get('value'));
      this.get('controller.parentController').send('setChangeStatus');
      // App.ProfileDeleteAnimation.create({view: this, touch: true });
    }
  });
});