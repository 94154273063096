define('front-end-cli/pods/trends/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    needs: ['application', 'income-expenses'],
    queryParams: ['plusminus', 'bankrekening'],
    plusminus: '',
    selectedAccounts: [],
    accounts: [],
    trendsnetted: null,
    trendsbalance: null,
    hoverAccount: null,
    toggleAccounts: false,
    resetSettings: false,

    trendFinalMonth: false,
    trendFinalYear: false,

    /**-------------------------
     Calculated Properties
     ---------------------------*/

    showAccounts: (function () {
      return this.get('toggleAccounts');
    }).property('toggleAccounts'),

    hasNoAccounts: (function () {
      return !this.get('currentUser.model.accounts.length');
    }).property('currentUser.model.accounts.[]'),

    hasNoActiveAccounts: (function () {
      return !this.get('currentUser.model.accounts').findBy('activated');
    }).property('currentUser.model.accounts.@each.activated'),

    isFinancialPeriod: (function () {
      return this.get('currentUser.model.state.startDayFinacialMonth') !== '01';
    }).property('currentUser.model.state.startDayFinancialMonth'),

    startOfCurrentMonth: (function () {
      var day = this.get('currentUser.model.state.startDayFinancialMonth'),
          month = moment().format('M'),
          year = moment().format('YYYY');

      return day + '-' + month + '-' + year;
    }).property('currentUser.model.state.startDayFinacialMonth'),

    // Checks if there is at least one account the user has ever set a balance benchmark for.
    userHasBalanceAccounts: (function () {
      return this.get('accounts').find(function (item, index, self) {
        return item.get('balance') !== null;
      }) !== undefined;
    }).property('accounts.@each.balance'),

    endOfCurrentMonth: (function () {
      var day = this.get('currentUser.model.state.startDayFinancialMonth'),
          month = moment().add(1, 'month').format('M'),
          year = moment().add(1, 'month').format('YYYY');

      return day + '-' + month + '-' + year;
    }).property('currentUser.model.state.startDayFinancialMonth'),

    firstTransactionYear: _ember['default'].computed('currentUser.model.accounts.@each.activated', function () {
      var mFirstTransactionDate = undefined;

      this.get('currentUser.model.accounts').forEach(function (account) {
        var md = undefined;
        if (!account.get('firstTransactionDate')) {
          md = moment();
        } else {
          md = moment(account.get('firstTransactionDate'));
        }

        if (mFirstTransactionDate) {
          if (md.isBefore(mFirstTransactionDate)) {
            mFirstTransactionDate = md;
          }
        } else {
          mFirstTransactionDate = md;
        }
      });

      // Returns a moment object!
      return mFirstTransactionDate.year();
    }),

    /**-------------------------
      Observes
    ---------------------------*/

    resetSettingsIncomeExpense: (function () {

      if (this.get('resetSettings')) {
        // reset label and category
        this.set('controllers.income-expenses.category', null);
        this.set('controllers.income-expenses.subcategory', null);
        this.set('controllers.income-expenses.filterLabelsString', null);
        this.set('resetSettings', false);
      } else {
        return;
      }
    }).observes('resetSettings'),

    actions: {
      income: function income() {
        this.transitionToRoute({ queryParams: { plusminus: 'income' } });
      },
      expenses: function expenses() {
        this.transitionToRoute({ queryParams: { plusminus: 'expenses' } });
      },
      both: function both() {
        this.transitionToRoute({ queryParams: { plusminus: '' } });
      },
      setHoverAccount: function setHoverAccount(account) {
        this.set('hoverAccount', account);
      }
    }

  });
});