define('front-end-cli/pods/income-expenses/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    queryParams: {
      periode: {
        refreshModel: true
      },
      filterLabelsString: {
        refreshModel: true
      },
      costType: {
        refreshModel: true
      }
    },

    /**-------------------------
     Properties
     --------------------------*/

    type: null, // income / expense
    startDate: null,
    endDate: null,
    filterLabelsString: null,
    costType: 'all',

    /**-------------------------
     Hooks
     --------------------------*/

    /**
     When trying to enter `/categorieen` directly,
     we'll transition into the `expenses` route.
     */
    beforeModel: function beforeModel(transition) {
      this._redirectWhenTargetIsIndex(transition);
    },

    /**
     Both the income and expenses route have 3 models set on their controller:
     - `categories`, which is loaded via the `model` hook (also fires on queryParam);
     - `transactions`, only related, lazily in the `setupController` hook (_getRelatedTransactions);
     - `trendsnetted`, not a formal model, is loaded via either `setupController` (_refreshTrendsNetted)
     or via the `setCategorySegment` or `setSubcategorySegment` action.
     */
    model: function model(params) {
      var startDate, endDate;

      var controller = this.controllerFor('income-expenses');

      // Define startDate and endDate properties
      this._defineStartEndDate(params.periode);

      // Define the labels to filter on
      this.set('filterLabelsString', params.filterLabelsString);

      var queryParams = {
        startDate: this.get('startDate'),
        endDate: this.get('endDate'),
        costType: controller.get('type') == 'expenses' ? this.get('costType') : 'all'
      };

      if (this.get('filterLabelsString') != null) {
        queryParams['labels'] = this.get('filterLabelsString');
      }

      return this.store.find('category', queryParams);
    },

    setupController: function setupController(controller, model) {

      controller.setProperties({
        categories: model,
        startDate: this.get('startDate'),
        endDate: this.get('endDate'),
        filterLabelsString: this.get('filterLabelsString'),
        periodType: this.get('periodType'),
        costType: controller.get('type') == 'expenses' ? this.get('costType') : 'all'
      });

      this._fetchRelatedTransactions();
      this._fetchTrendsNetted();
      this._fetchReceipts();
      this._fetchLabels();
    },

    resetController: function resetController(controller, isExiting, transition) {

      this.set('lastFetchTrendsNettedUrl', null);

      if (isExiting) {
        controller.setProperties({
          activeRelatedTransactions: false
        });
      }
    },

    /**-------------------------
     Private Methods
     --------------------------*/

    _fetchReceipts: function _fetchReceipts() {

      var controller = this.controllerFor('income-expenses');

      this.store.find('receipt').then(function (receipts) {
        controller.set('receipts', receipts);
      });
    },

    _fetchLabels: function _fetchLabels() {
      var controller = this.controllerFor('income-expenses');
      this.store.find('label').then(function (labels) {
        controller.set('labels', labels);
      });
    },

    /**
     * Fetch transactions in batches of queryParams.limit size (2000) until we fetch an empty set.
     */
    _fetchTransactionsInBatches: function _fetchTransactionsInBatches(queryParams, page, transactionsCollection) {
      var _this = this;

      var controller = this.controllerFor('income-expenses');

      this.store.find('transaction', queryParams).then(function (transactions) {
        /* When a User splits a transaction, they are saved in batch. When batch-saving, Ember data does not recognize the returned child transaction as such,
         * because it now has an ID. Therefore a duplicate is introduced. Here we choose to accept the server-provided transaction, and delete
         * any transaction that has not been posted yet (and therefore has an ID of null). This is performed here, because if it is performed any earlier,
         * the process is visible to the User.
         */
        _this.store.all('transaction').forEach(function (transaction, index, enumerable) {
          if (transaction.get('id') === null) {
            transaction.destroyRecord();
          }
        });

        // The first batch response is copied, the followin batches are inserted using the _internalModel object (Ember 1 limitation)
        if (transactionsCollection == undefined) {
          transactionsCollection = transactions;
        } else {
          transactions.forEach(function (transaction) {
            transactionsCollection.pushObject(transaction._internalModel);
          });
        }

        // If the response has contents, ask for the next page. Can't check against the total page size because of splitted transactions altering the total.
        if (transactions.get('length') > 0) {
          page++;
          queryParams.offset = queryParams.limit * page;
          _this._fetchTransactionsInBatches(queryParams, page, transactionsCollection);
        } else {
          // When the next page is empty, set the controller model data
          controller.set('transactions', transactionsCollection);
        }
      });
    },

    _fetchRelatedTransactions: function _fetchRelatedTransactions() {
      var controller = this.controllerFor('income-expenses');
      var dates = this.getProperties('startDate', 'endDate');

      var queryParams = {
        limit: 2000,
        offset: 0,
        startDate: dates.startDate,
        endDate: dates.endDate,
        costType: controller.get('type') == 'expenses' ? this.get('costType') : 'all'
      };

      if (this.get('filterLabelsString') != null) {
        queryParams['labels'] = this.get('filterLabelsString');
      }

      var transactionsCollection = undefined;

      this._fetchTransactionsInBatches(queryParams, 0, transactionsCollection);
    },

    /**
     Translate the period queryParam
     into a startDate and endDate.
      Possible params:
     Current month:  <empty>
     1 Month:          ?periode=01-2014
     2 Quarter:        ?periode=kwartaal-1-2014
     3 Year:           ?periode=2014
     4 Custom:         ?periode=01-12-2014_17-12-2014
     */
    _defineStartEndDate: function _defineStartEndDate(periodParam) {
      var startDate,
          endDate,
          startDayFinancialMonth = this.get('currentUser.model.state.startDayFinancialMonth') * 1,
          customFinancialMonth = startDayFinancialMonth >= 15,
          year,
          month,
          quarter,
          start,
          end;

      // ------------------------------
      // Define the current period-type

      var periodType;

      // Month.
      if (!periodParam || /^[0-9]{2}-[0-9]{4}$/.test(periodParam)) {
        periodType = 1;

        if (periodParam) {
          month = periodParam.match(/^[0-9]{2}/)[0] * 1 - 1; // meoment months are zero indexed
          year = periodParam.match(/[0-9]{4}$/)[0] * 1;
        } else {
          year = moment().year();
          if (customFinancialMonth) {
            if (moment().date() < startDayFinancialMonth) {
              // When the current-day (0-31) is before
              // the financial-period-day (0-31), we‘ll
              // set the previous month as start.
              month = moment().date(startDayFinancialMonth).month();
            } else {
              // When the current-day (0-31) is or is after
              // the financial-period-day (0-31), we‘ll
              // set the next month as end.

              month = moment().date(startDayFinancialMonth).add(1, 'month').month();
              if (moment().date(startDayFinancialMonth).month() === 11) {
                year++;
              }
            }
          } else {
            month = moment().month();
          }
        }

        startDate = moment().year(year).month(month).startOf('month');
        endDate = moment(startDate, 'YYYY-MM-DD').endOf('month');
      }

      // Quarter.
      else if (/^kwartaal-[0-9]{1}-[0-9]{4}$/.test(periodParam)) {
          periodType = 2;

          year = periodParam.match(/[0-9]{4}/)[0] * 1;
          quarter = periodParam.match(/[0-9]{1}/)[0] * 1;
          startDate = moment().startOf('year').year(year).quarter(quarter);
          endDate = moment(startDate, 'YYYY-MM-DD').add(1, 'quarter').subtract(1, 'days');
        }

        // Year.
        else if (/^[0-9]{4}$/.test(periodParam)) {
            periodType = 3;

            year = periodParam;

            startDate = moment().year(year).startOf('year');
            endDate = moment().year(year).endOf('year');
          }

          // Custom period.
          else if (/^[0-9]{2}-[0-9]{2}-[0-9]{4}_[0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(periodParam)) {
              periodType = 4;

              start = periodParam.match(/^[0-9]{2}-[0-9]{2}-[0-9]{4}/)[0];
              end = periodParam.match(/[0-9]{2}-[0-9]{2}-[0-9]{4}$/)[0];

              startDate = moment(start, 'DD-MM-YYYY');
              endDate = moment(end, 'DD-MM-YYYY');
            }

            // Invalid period.
            else {
                // TODO make sure we don’t break but rather reset the param.
                throw new Error('The given period param (' + periodParam + ') is invalid');
              }

      // Adjust to custom financial period if set.
      if (customFinancialMonth && periodType !== 4) {
        startDate = startDate.subtract(1, 'months').date(startDayFinancialMonth);
        endDate = endDate.date(startDayFinancialMonth - 1);
      }

      // DEBUG
      // Ember.Logger.debug('periodType', periodType, 'startDate', startDate.format('YYYY-MM-DD'), 'period endDate', endDate.format('YYYY-MM-DD'));

      this.setProperties({
        periodType: periodType,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      });
    },

    /**
     Translate the cat/subcat segments into a cat/subcat id
     and use the id to query the `trendsNetted` endpoint.
     */
    _fetchTrendsNetted: function _fetchTrendsNetted() {

      // The runloop was added to prevent fetching data before
      // the type property on the controller was set.

      _ember['default'].run.next(this, function () {

        var controller = this.controllerFor('income-expenses');

        // ----------------------------------------
        // Parse cat/subcat or income/expens params

        var categoryId = controller.get('category.id'),
            subcategoryId = controller.get('subcategory.id');

        var startDate = this.get('startDate'),
            endDate = this.get('endDate');

        var nonCategoryParam = 'plusminus=' + controller.get('type').replace(/s$/, ''),
            categoryParam = subcategoryId || categoryId,
            categoryKey = subcategoryId ? 'subcategoryId=' : 'categoryId=',
            param = categoryParam ? categoryKey + categoryParam : nonCategoryParam;

        // ---------------------------
        // Parse start/end date params

        var startDayFinancialMonth = this.get('currentUser.model.state.startDayFinancialMonth') * 1,
            oldestTransactionDate = controller.get('oldestTransactionDate'),
            customFinancialMonth = startDayFinancialMonth >= 15,
            periodType = this.get('periodType');

        // Get the current filter-labels
        var labelsParam = '';
        if (this.get('filterLabelsString') != null) {
          labelsParam = '&labels=' + this.get('filterLabelsString');
        }

        // Get the current costType filter
        var costType = '';
        if (controller.get('type') == 'expenses' && this.get('costType') != null) {
          costType = '&costType=' + this.get('costType');
        }

        var start = moment().startOf('month').subtract(1, 'years'),
            end = moment().endOf('month');

        if (customFinancialMonth) {
          start = start.date(startDayFinancialMonth);
          end = end.date(startDayFinancialMonth).subtract(1, 'days');
          if (moment().date() >= startDayFinancialMonth) {
            start = start.add(1, 'months');
            end = end.add(1, 'months');
          }
        }

        start = start.format('YYYY-MM-DD');
        end = end.format('YYYY-MM-DD');

        // ---------------------------
        // parse url and query the api

        /**
         Trends based on scope
         */

        // We'll look if user selected scope before
        // Check If the selected period is within previously fetched trendsStartDate and trendsEndDate
        // If not then we will recalculate start and end. When selected period is now or in the future we will
        // take end and subtract 11 periods else we will take selected period 5 periods back for start
        // and add 6 months in future for end

        var scope = 'P1M';
        var periodParam = controller.get('periode'),
            trendsStartDate = controller.get('trendsStartDate'),
            trendsEndDate = controller.get('trendsEndDate'),
            trendsPreviousScope = controller.get('trendsPreviousScope');

        if (periodType === 1) {
          if (periodParam) {
            var selectedMonth = moment(periodParam, 'MM-YYYY');
            scope = 'P1M';

            if (trendsStartDate && trendsEndDate && trendsPreviousScope === 1) {

              if (moment(selectedMonth).isSame(trendsStartDate) || moment(selectedMonth).isSame(trendsEndDate) || moment(selectedMonth).isBetween(trendsStartDate, trendsEndDate)) {

                start = trendsStartDate;
                if (!customFinancialMonth) {
                  end = moment(trendsEndDate).endOf('month').format('YYYY-MM-DD');
                } else {
                  end = moment(trendsEndDate).format('YYYY-MM-DD');
                }
              } else {

                if (moment(selectedMonth, 'YYYY-MM-DD').format('MM-YYYY') >= moment().format('MM-YYYY')) {

                  start = moment(selectedMonth, 'YYYY-MM-DD').subtract(11, 'months').format('YYYY-MM-DD');
                  if (!customFinancialMonth) {
                    end = moment(selectedMonth, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD');
                  } else {
                    end = moment(selectedMonth, 'YYYY-MM-DD').format('YYYY-MM-DD');
                  }
                } else {

                  start = moment(selectedMonth, 'YYYY-MM-DD').subtract(6, 'months').format('YYYY-MM-DD');
                  if (!customFinancialMonth) {
                    end = moment(selectedMonth, 'YYYY-MM-DD').add(5, 'months').endOf('month').format('YYYY-MM-DD');
                  } else {
                    end = moment(selectedMonth, 'YYYY-MM-DD').add(5, 'months').format('YYYY-MM-DD');
                  }
                }
              }
            } else {

              if (moment(selectedMonth).isSame(start) || moment(selectedMonth).isSame(end) || moment(selectedMonth).isBetween(start, end)) {} else {

                if (moment(selectedMonth, 'YYYY-MM-DD').format('MM-YYYY') >= moment().format('MM-YYYY')) {

                  start = moment(selectedMonth, 'YYYY-MM-DD').subtract(11, 'months').format('YYYY-MM-DD');
                  if (!customFinancialMonth) {
                    end = moment(selectedMonth, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD');
                  } else {
                    end = moment(selectedMonth, 'YYYY-MM-DD').format('YYYY-MM-DD');
                  }
                } else {
                  start = moment(selectedMonth, 'YYYY-MM-DD').subtract(5, 'months').format('YYYY-MM-DD');
                  if (!customFinancialMonth) {
                    end = moment(selectedMonth, 'YYYY-MM-DD').add(6, 'months').endOf('month').format('YYYY-MM-DD');
                  } else {
                    end = moment(selectedMonth, 'YYYY-MM-DD').add(6, 'months').format('YYYY-MM-DD');
                  }
                }
              }
            }
          }
        } else if (periodType === 2) {

          // Quarter lookup
          // User selects a quarter in the period select or trends netted chart
          // - We will look if the selected quarter is in the current period of the trends graph (Y/N)
          // - We will look if the selected quarter is between now and 11 quarters back (Y/N)
          // - When both statements above are not true we will move the period of the trends graph to the middle
          //   of the selected quarter (5 quarters before and 6 quarters after)
          //   Check if the new end period is not in the future, else we will adjust

          scope = 'P1Q';

          var currentQuarterOfPeriod = moment(start, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              firstQuarterOfPeriod = moment(end, 'YYYY-MM-DD').subtract(11, 'quarter').format('YYYY-MM-DD'),
              selectedQuarter = moment(periodParam, 'Q-YYYY').format('YYYY-MM-DD');

          if (trendsStartDate && trendsEndDate && trendsPreviousScope === 2) {
            // User has selected Quarter Scope before

            if (moment(selectedQuarter).isBetween(trendsStartDate, trendsEndDate) || moment(selectedQuarter).isSame(trendsStartDate) || moment(selectedQuarter).isSame(trendsEndDate)) {

              start = trendsStartDate;
              end = trendsEndDate;
            } else {

              if (moment(selectedQuarter).isBetween(currentQuarterOfPeriod, firstQuarterOfPeriod) || moment(selectedQuarter).isSame(currentQuarterOfPeriod) || moment(selectedQuarter).isSame(firstQuarterOfPeriod)) {

                start = currentQuarterOfPeriod;
                end = firstQuarterOfPeriod;
              } else {

                if (moment(selectedQuarter, 'YYYY-MM-DD').format('Q-YYYY') >= moment().format('Q-YYYY')) {

                  start = moment(selectedQuarter, 'YYYY-MM-DD').subtract(11, 'quarter').format('YYYY-MM-DD');
                  end = moment(selectedQuarter, 'YYYY-MM-DD').format('YYYY-MM-DD');
                } else {

                  start = moment(selectedQuarter, 'YYYY-MM-DD').subtract(5, 'quarter').format('YYYY-MM-DD');
                  end = moment(selectedQuarter, 'YYYY-MM-DD').add(6, 'quarter').format('YYYY-MM-DD');
                }
              }

              if (customFinancialMonth) {
                start = moment(start).add(startDayFinancialMonth - 1, 'day').format('YYYY-MM-DD');
                end = moment(end).add(2, 'month').add(startDayFinancialMonth - 2, 'day').format('YYYY-MM-DD');
              } else {
                end = moment(end).add(2, 'month').endOf('month').format('YYYY-MM-DD');
              }
            }
          } else {
            if (moment(selectedQuarter, 'YYYY-MM-DD').format('Q-YYYY') >= moment().format('Q-YYYY')) {

              start = moment(selectedQuarter, 'YYYY-MM-DD').subtract(11, 'quarter').format('YYYY-MM-DD');
              end = moment(selectedQuarter, 'YYYY-MM-DD').format('YYYY-MM-DD');
            } else {

              start = moment(selectedQuarter, 'YYYY-MM-DD').subtract(5, 'quarter').format('YYYY-MM-DD');
              end = moment(selectedQuarter, 'YYYY-MM-DD').add(6, 'quarter').format('YYYY-MM-DD');
            }

            if (customFinancialMonth) {
              start = moment(start).add(startDayFinancialMonth - 1, 'day').format('YYYY-MM-DD');
              end = moment(end).add(2, 'month').add(startDayFinancialMonth - 2, 'day').format('YYYY-MM-DD');
            } else {
              end = moment(end).add(2, 'month').endOf('month').format('YYYY-MM-DD');
            }
          }
        } else if (periodType === 3) {
          // Year Scope

          scope = 'P1Y';
          start = moment(start).subtract(11, 'years').startOf('year').format('YYYY-MM-DD');
          end = moment(end).endOf('year').format('YYYY-MM-DD');
        }

        // set properties trendsStartDate and trendsEndDate
        // only when user selects scope month or quarter
        if (periodType === 1 || periodType === 2) {

          controller.set('trendsStartDate', start);
          controller.set('trendsEndDate', end);
        }

        // set the previous scope property
        controller.set('trendsPreviousScope', periodType);

        // ---------------------------
        // parse url and query the api

        var url = '/api/v3/trendsnetted?startDate=' + start + '&endDate=' + end + '&scope=' + scope + '&' + param + labelsParam + costType,
            lastFetchTrendsNettedUrl = this.get('lastFetchTrendsNettedUrl'),
            needsFetch = lastFetchTrendsNettedUrl !== url;

        if (needsFetch) {
          this.set('lastFetchTrendsNettedUrl', url);
          _ember['default'].$.get(url).then(function (trendsnetted) {
            controller.set('trendsnetted', trendsnetted);
          });
        }
      });
    },

    _redirectWhenTargetIsIndex: function _redirectWhenTargetIsIndex(transition) {
      if (transition.targetName === 'income-expenses.index') {
        this.transitionTo('income-expenses.expenses');
      }
    },

    /**
     The period select can optionaly change the
     `startDayFinancialMonth` value.
      When done so, we'll persist it here.
     */
    startDayFinancialMonthObserver: (function () {

      var state = this.get('currentUser.model.state'),
          stateName = state.get('currentState.stateName');

      if (!/^root\.loaded\.saved$/.test(stateName)) {
        this.refresh();
        state.save();
      }
    }).observes('currentUser.model.state.startDayFinancialMonth'),

    /**-------------------------
     Actions
     ---------------------------*/

    actions: {
      queryParamsDidChange: function queryParamsDidChange() {
        if (this.get('ranBefore')) {
          _ember['default'].run.next(this, 'refresh');
        }
      },
      fetchTrendsNetted: function fetchTrendsNetted() {
        this._fetchTrendsNetted();
      },
      forceRefresh: function forceRefresh() {
        this.refresh();
      },

      /**
       After any transition, we notify the
       controller of a `category` property change.
        (This action will also trigger on account
       activation toggling, since this fires an
       application route refresh)
        If the current category happens to switch
       between income and expenses, the controllers
       category observer will trigger the
       appropriate transition.
        `ranBefore` prevents notifying when
       initializing this route, preventing
       a double `_fetchTrendsNetted` call.
       */
      didTransition: function didTransition() {
        if (this.get('ranBefore')) {
          var controller = this.controllerFor('income-expenses');
          controller.notifyPropertyChange('category');
        } else {
          this.set('ranBefore', true);
        }
      },

      /**
       When trying to enter `/categorieen` directly,
       we'll transition into the `expenses` route.
       */
      willTransition: function willTransition(transition) {
        this.set('ranBefore', false);
        this._redirectWhenTargetIsIndex(transition);
        return true;
      },

      /**
       Refresh the model on this route and any child routes,
       firing the beforeModel, model, and afterModel hooks
       in a similar fashion to how routes are entered when
       transitioning in from other route.
        Used for updating all components after changes in
       the transactions occured.
       */
      refreshRoute: function refreshRoute() {
        // Force fetching trends data.
        this.set('lastFetchTrendsNettedUrl', null);

        this.refresh();
      },

      addSubcategory: function addSubcategory(newSubcategoryName, category) {

        var newSubRgx = new RegExp('^' + newSubcategoryName + '$', 'i'),
            valid = true;

        // A name and category is required
        if (!newSubcategoryName || !category) return false;

        // Check if subcategory name is already
        // added in selected category.. again.
        this.get('store').all('category').forEach(function (category) {
          category.get('subcategories').forEach(function (subcategory) {
            if (newSubRgx.test(subcategory.get('name'))) {
              valid = false;
            }
          });
        });

        if (valid) {

          var cat = this.get('store').createRecord('subcategory', {
            name: newSubcategoryName,
            category: category,
            enabled: true
          });

          cat.save();
        } else {
          // No valid newSubcategoryName
        }
      },
      setCostTypeAction: function setCostTypeAction(type) {
        this.set('costType', type);
        this.refresh();
      }
    }
  });
});