define('front-end-cli/models/employed', ['exports', 'ember-data', 'front-end-cli/models/income'], function (exports, _emberData, _frontEndCliModelsIncome) {
  exports['default'] = _frontEndCliModelsIncome['default'].extend({
    labor: _emberData['default'].belongsTo('labor'),
    hasMiscchoice: (function () {
      var value = this.get('miscchoice');
      return value == 1;
    }).property('miscchoice'),
    isOneTimeMisc: (function () {
      var value = this.get('onetimemiscchoice');
      return value == 1;
    }).property('onetimemiscchoice'),
    isRegularMisc: (function () {
      var value = this.get('onetimemiscchoice');
      return value == 2;
    }).property('onetimemiscchoice')
  });
});