define('front-end-cli/views/accountmanagement/edit/step', ['exports', 'ember'], function (exports, _ember) {

  //App.WindowResizeMixin
  exports['default'] = _ember['default'].View.extend({

    classNames: ['accountmanagement-step animate'],
    classNameBindings: ['goingBackward:left:right'],
    willInsertElement: function willInsertElement() {

      var currentStep = this.get('controller.previousSteps.lastObject'),
          nextStep = this.get('controller.step');

      this.set('goingBackward', currentStep >= nextStep);

      /*
        DEBUG
        var animateClass = currentStep >= nextStep? 'left' : 'right';
        console.log('willInsertElement', 'currentStep: '+currentStep +' >= nextStep: '+ nextStep + ' (class: '+animateClass+')');
      */
    },

    animateIn: (function () {

      /**
        Needs to be fully inserted before
        triggering the css animation.
         The goingBackward condition fixes an issue with going
        back from 'choose-accounts', but should be obsolete.
      */

      var $view = this.$();
      if (this.get('goingBackward')) {
        _ember['default'].run.later(function () {
          $view.removeClass('left right');
        }, 200);
      } else {
        _ember['default'].run.next(function () {
          $view.removeClass('left right');
        });
        window.setTimeout(function () {
          $view.removeClass('left right');
        }, 100);
      }

      /*
        Focus the element with an .autofocus class
        –if any– after the css-transition is ended.
         This is done because real autofocus attributes
        will disrupt the css transition.
         Includes a fallback for IE9 which doesn’t
        support the `transitionend` event.
      */
      if (Modernizr.csstransitions) {
        this.$().one('transitionend', (function () {
          if (this.$('.autofocus').length) this.$('.autofocus:first').focus();
        }).bind(this));
      } else {
        var $autofocusEl = this.$('.autofocus');
        if ($autofocusEl.length) {
          _ember['default'].run.later(function () {
            $autofocusEl.focus();
          }, 800);
        }
      }

      if (!Modernizr.input.placeholder) {
        this.$('input, textarea').placeholder();
      }
    }).on('didInsertElement')
  });
});