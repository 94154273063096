define('front-end-cli/helpers/bank-sync-error', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(function (value /*, options*/) {
    var errorMessage;
    switch (value) {
      case 1010:
        // BANKWEBSITEDOWN
        errorMessage = 'De ' + this.get('bankChoice.bank.title') + ' is momenteel niet bereikbaar. Probeer het later nog een keer.';
        break;
      case 1020:
        // LOGINFAILED
        errorMessage = 'Het inloggen is mislukt, controleer jouw inloggegevens en probeer het opnieuw.';
        break;
      case 1030:
        // FILEDOWNLOADFAILED
        errorMessage = 'Het ophalen van de gegevens is niet gelukt. Probeer het later nog een keer.';
        break;
      case 2010:
        // HTMLERROR
        errorMessage = 'De koppeling is tijdelijk niet beschikbaar, probeer het later nog een keer.';
        break;
      case 2020:
        // IMPORTFAILED
        errorMessage = 'De koppeling is tijdelijk niet beschikbaar, probeer het later nog een keer.';
        break;
      case 1040:
        errorMessage = 'De koppeling is tijdelijk niet beschikbaar, probeer het later nog een keer.';
        break;
      case 1050:
        errorMessage = 'Login in op je bank omgeving, de bank heeft een informatie bericht klaar staan, waardoor wij nu niet kunnen updaten.';
        break;
      default:
        errorMessage = 'De koppeling is tijdelijk niet beschikbaar, probeer het later nog een keer.';
        break;
    }

    return errorMessage;
  });
});