define('front-end-cli/pods/components/trends-chart-netted/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['TrendsNetted'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    chartType: null,
    data: null,
    preferences: null,
    startDate: null,
    endDate: null,
    periodType: null,
    startDayFinancialMonth: null,
    height: 180, // Default

    periode: null,

    // actions
    transitionAction: null,

    /**-------------------------
      Properties
    ---------------------------*/

    periodDetails: null,
    budgetDetails: null,
    animateLine: false,

    toggleAnimateLine: (function () {
      this.set('animateLine', true);
    }).observes('comparedTo'),

    typeIsResults: (function () {
      return this.get('chartType') === 'results';
    }).property('chartType'),

    comparedTo: _ember['default'].computed.alias('preferences.trendsChartNettedCompareTo'),

    comparedToBudget: (function () {
      return this.get('comparedTo') === 'budget';
    }).property('comparedTo'),

    compareToBudgetUnavailable: (function () {
      return !this.get('data.lastObject.expected');
    }).property('data.firstObject.expected'),

    comparedToAverage: (function () {
      return this.get('comparedTo') === 'average';
    }).property('comparedTo'),

    compareToAverageUnavailable: (function () {
      return !this.get('data.firstObject.averageNetted');
    }).property('data.firstObject.averageNetted'),

    /**-------------------------
      Methods
    ---------------------------*/

    didInsertElement: function didInsertElement() {
      // The view is rendered before the async
      // data is resolved. But we still need to
      // redraw when the element gets re-inserted
      // after a relatedTransaction close.

      if (this.get('data')) {
        this._updateChart();
      }
      this.send('insertDetails');
      this.send('insertBudgetDetails');
    },

    willDestroyElement: function willDestroyElement() {
      // Make sure detail-DOM is removed
      // when this componenent is destroyed.
      this.get('periodDetails').remove();
      this.get('budgetDetails').remove();
    },

    /**
      Draw the chart as soon as we have data.
    */
    updateChart: (function () {
      _ember['default'].run.debounce(this, function () {
        this._updateChart();
      }, 50);
    }).observes('data', 'chartType', 'comparedTo', 'startDate', 'endDate'),

    _updateChart: function _updateChart() {

      var _this = this,
          svg,
          data = this.get('data'),
          dataItems = this.get('data.length') - 1,
          width = this.$().width(),
          chartType = this.get('chartType'),
          height = this.get('height'),
          margin = {
        left: 60,
        right: 60,
        top: 35,
        bottom: 35
      },
          widthNoMargin = width - margin.left - margin.right,
          heightNoMargin = height - margin.top - margin.bottom,
          parseDate = d3.time.format('%Y-%m-%d').parse;

      var startDate = moment(_this.get('startDate'), 'YYYY-MM-DD'),
          endDate = moment(_this.get('endDate'), 'YYYY-MM-DD');

      _ember['default'].assert('We’ll need data in order to draw a chart', data);

      // Flush the conponents contents.
      this.$('svg').remove();

      var averageNetted = this.get('data.lastObject.averageNetted'),
          expectedAmount = this.get('data.lastObject.expected');

      /**---------------------------------
        The graphs y-axix domain determines
        what the extremes of our graph are.
         (We could use d3.extend but we'll need
        to correct min or max when 0 is outside
        the domain)
      */

      var min = d3.min(data, function (d) {

        /**--------------------------------------
          Calculate Math.min and Math.max with all
          possible lines when in income or expense route.
           Trends we'll just use the netted.
        */

        if (chartType !== 'results') {
          return Math.min(d.netted, d.averageNetted, d.expected);
        } else {
          return d.netted;
        }
      });
      var max = d3.max(data, function (d) {

        if (chartType !== 'results') {
          return Math.max(d.netted, d.averageNetted, d.expected);
        } else {
          return d.netted;
        }
      });

      // Make sure the 0 line is always withinin
      // our domain

      if (max < 0) max = 0;else if (min > 0) min = 0;

      var y,
          x = d3.scale.ordinal().rangeRoundBands([0, widthNoMargin], 0.45).domain(data.map(function (d) {
        return d.period;
      }));

      if (chartType === 'expenses') {

        if (min === 0 && max === 0) {

          // When no data in selected category we want
          // the x line below the chart

          y = d3.scale.linear().range([heightNoMargin, 0]).domain([min, max]).nice();
        } else {
          y = d3.scale.linear().range([0, heightNoMargin]).domain([min, max]).nice();
        }
      } else {

        y = d3.scale.linear().range([heightNoMargin, 0]).domain([min, max]).nice();
      }

      /**--------------------------
        Create X and Y axes helpers
      */

      var xAxis = d3.svg.axis().scale(x).tickFormat(function (d, i) {

        if (_this.get('periodType') === 3) {
          // Year scope
          return d;
        } else if (_this.get('periodType') === 2) {
          // Quarter scope
          return 'Q' + moment(d, 'YYYY-MM').format('M') + '-' + moment(d, 'YYYY-MM').format('YY');
        } else {
          // Month or custom scope
          return moment(d, 'YYYY-MM').format('MMM').replace(/\./, '');
        }
      });

      var yAxis = d3.svg.axis().scale(y).orient('left').tickFormat(function (d, i) {
        return accounting.formatMoney(d, { precision: 0 });
      }).ticks(3);

      /**---------------------
        Draw the svg container
      */

      if (bowser.msie) {

        // SVG viewBox in IE needs a fixed height

        svg = d3.select('#' + this.elementId).append('svg').classed('TrendsChartNetted', true).attr('viewBox', '0 0 ' + ' ' + width + ' ' + height).attr('height', height).attr('preserveAspectRatio', 'none meet').append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
      } else {

        svg = d3.select('#' + this.elementId).append('svg').classed('TrendsChartNetted', true).attr('viewBox', '0 0 ' + ' ' + width + ' ' + height).attr('preserveAspectRatio', 'none meet').append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
      }

      /**---------------------------------
        Create a diagonal line mask which
        we can set in css like so:
        .bar {
          mask: url(mask-stripe);
          fill: pink;
        }
      */

      var defs = svg.append('defs');
      defs.append('pattern').attr('id', 'pattern-stripe').attr('patternUnits', 'userSpaceOnUse').attr('patternTransform', 'rotate(50)').attr('width', 8).attr('height', 8).append('rect').attr('width', 5).attr('height', 8).attr('transform', 'translate(0,0)').attr('fill', 'white');
      defs.append('mask').attr('id', 'mask-stripe').append('rect').attr('x', '0').attr('y', '0').attr('width', '100%').attr('height', '100%').attr('fill', 'url(#pattern-stripe)');

      /**--------------------
        Draw the X and Y axes
      */

      // Draw the X-axis tick backgrounds
      svg.selectAll('.TrendsChartNetted-rectXTickBg').data(data).enter().append('rect').attr('class', function (d, i) {

        var className = 'TrendsChartNetted-rectXTickBg',
            periodType = _this.get('periodType');

        if (chartType !== 'results') {

          if (periodType === 1) {

            if (moment(d.period, 'YYYY-MM').date(1).add(1, 'day').isBetween(startDate, endDate)) {
              className += ' is-withinPeriod';
            }

            var startDayFinancialMonth = _this.get('startDayFinancialMonth') * 1,
                customFinancialMonth = startDayFinancialMonth >= 15;

            if (customFinancialMonth) {
              if (startDayFinancialMonth > moment().format('DD')) {
                if (d.period == moment().date(startDayFinancialMonth).format('YYYY-MM')) {
                  className += ' is-current';
                }
              } else {
                if (d.period == moment().date(startDayFinancialMonth).add(1, 'month').format('YYYY-MM')) {
                  className += ' is-current';
                }
              }
            } else {
              if (d.period == moment().format('YYYY-MM')) {
                className += ' is-current';
              }
            }
          } else if (periodType === 2) {

            if (moment(_this.get('periode'), 'Q-YYYY').format('YYYY-0Q') === d.period) {
              className += ' is-current is-withinPeriod';
            }
          } else if (periodType === 3) {
            if (moment(d.period, 'YYYY-MM').date(1).add(1, 'day').isBetween(startDate, endDate)) {
              className += ' is-withinPeriod';
            }
          }
        }

        return className;
      }).attr('x', function (d) {
        return x(d.period);
      }).attr('y', y(0) + 5).attr('rx', 2).attr('height', 15).attr('width', x.rangeBand()).on('click', function (d, i) {
        _this.send('transitionFromRect', d.period);
      }).on('mouseover', function (d) {
        if (Modernizr.touch) {
          _this.send('transitionFromRect', d.period);
        } else {
          _this.send('showDetails', d, d3.event);
        }
      }).on('mouseout', function (d) {
        if (!Modernizr.touch) {
          _this.send('hideDetails');
        }
      });

      // Draw the X-axis.
      svg.append('g').attr('class', 'x axis TrendsChartNetted-xAxis').attr('transform', function (d, i) {
        // Position the x-axis on the zero line
        return 'translate(' + 0 + ',' + (y(0) - 2) + ')';
      }).call(xAxis);

      // Add a class to xAxix ticks
      d3.selectAll('g.x.axis g.tick text').data(data).attr('class', function (d, i) {

        var className = 'TrendsChartNetted-xTick',
            periodType = _this.get('periodType');

        if (chartType !== 'results') {

          if (periodType === 1) {

            if (moment(d.period, 'YYYY-MM').date(1).add(1, 'day').isBetween(startDate, endDate)) {
              className += ' is-withinPeriod';
            }

            var startDayFinancialMonth = _this.get('startDayFinancialMonth') * 1,
                customFinancialMonth = startDayFinancialMonth >= 15;

            if (customFinancialMonth) {
              if (startDayFinancialMonth > moment().format('DD')) {
                if (d.period == moment().date(startDayFinancialMonth).format('YYYY-MM')) {
                  className += ' is-current';
                }
              } else {
                if (d.period == moment().date(startDayFinancialMonth).add(1, 'month').format('YYYY-MM')) {
                  className += ' is-current';
                }
              }
            } else {
              if (d.period == moment().format('YYYY-MM')) {
                className += ' is-current';
              }
            }
          } else if (periodType === 2) {

            if (moment(_this.get('periode'), 'Q-YYYY').format('YYYY-0Q') === d.period) {
              className += ' is-current is-withinPeriod';
            }
          } else if (periodType === 3) {

            if (moment(d.period, 'YYYY-MM').date(1).add(1, 'day').isBetween(startDate, endDate)) {
              className += ' is-withinPeriod';
            }
          }
        }

        return className;
      }).on('click', function (d, i) {
        _this.send('transitionFromRect', d.period);
      }).on('mouseover', function (d) {
        if (Modernizr.touch) {
          _this.send('transitionFromRect', d.period);
        } else {
          _this.send('showDetails', d, d3.event);
        }
      }).on('mouseout', function (d) {
        if (!Modernizr.touch) {
          _this.send('hideDetails');
        }
      });

      // Draw the Y-axis
      svg.append('g').attr('class', 'y axis TrendsChartNetted-yAxis').attr('transform', 'translate(20,0)').call(yAxis);

      // Reposition ‘opposite’ Y-ticks, to compensate
      // for the thickened null-line.
      d3.selectAll('g.y.axis g.tick').attr('transform', function (d, i) {
        var push = 0;
        if (chartType === 'expenses') {
          if (d === 0) push = 13;else if (d > 0) push = 26;
        } else {
          if (d === 0) push = 13;else if (d < 0) push = 26;
        }

        return 'translate(0, ' + (y(d) + push) + ')';
      });

      // Add a class to yAxix ticks
      d3.selectAll('g.y.axis g.tick text').attr('class', 'TrendsChartNetted-yTick');

      /** ----------------------------
        Draw the expected-netted bars.
      */

      if (chartType !== 'results') {}

      // svg.selectAll('.TrendsChartNetted-rectExpected')
      // .data(data)
      // .enter().append('rect')
      //   .attr('class', 'TrendsChartNetted-rectExpected')
      //   .attr('rx', 2)
      //   .attr('width', x.rangeBand())
      //   .attr('height', function(d) {
      //     return heightNoMargin - y(d.expected);
      //   })
      //   .attr('x', function(d) {
      //     return x(d.period);
      //   })
      //   .attr('y', function(d) {
      //     if (chartType === 'expenses') {
      //       // Place negative values below the zero ticks
      //       var isNegative = d.expected < 0;
      //       return isNegative? y(d.expected) : y(0) + 15 + 5 + 5;
      //     } else {
      //       var isPositive = d.expected > 0;
      //       return isPositive? y(d.expected) : y(0) + 15 + 5 + 5;
      //       // return y(Math.max(0, d.netted));
      //     }
      //   })
      //   .attr('height', function(d) {
      //     if (chartType === 'expenses') {
      //       return Math.abs(y(0) - y(d.expected));
      //     } else {
      //       return Math.abs(y(d.expected) - y(0));
      //     }
      //   })
      //   .on('mouseover', function(d) {
      //     if (Modernizr.touch) {
      //       _this.send('transitionFromRect', d.period);
      //     }
      //     else {
      //       _this.send('showDetails', d, d3.event);
      //     }
      //   })
      //   .on('mouseout', function(d) {
      //     if (!Modernizr.touch) {
      //       _this.send('hideDetails');
      //     }
      //   })
      //   .on('click', function(d,i) {
      //     _this.send('transitionFromRect', d.period);
      //   });

      /**--------------------
        Draw the netted bars.
      */

      svg.selectAll('.TrendsChartNetted-rectBackground').data(data).enter().append('rect').attr('class', 'TrendsChartNetted-rectBackground').attr('rx', 2).attr('width', x.rangeBand()).attr('height', function (d) {
        if (chartType === 'expenses') {
          return Math.abs(y(0) - y(min));
        } else {
          return Math.abs(y(max) - y(0));
        }
      }).attr('x', function (d) {
        return x(d.period);
      }).attr('y', function (d) {
        // Place negative values below the zero ticks

        if (chartType === 'expenses') {
          var isNegative = d.netted <= 0;
          return isNegative ? y(min) : y(0) + 15 + 5 + 5;
        } else {
          var isPositive = d.netted >= 0;
          return isPositive ? y(max) : y(0) + 15 + 5 + 5;
        }
      }).on('mouseover', function (d) {
        if (Modernizr.touch) {
          _this.send('transitionFromRect', d.period);
        } else {
          _this.send('showDetails', d, d3.event);
        }
      }).on('mouseout', function (d) {
        if (!Modernizr.touch) {
          _this.send('hideDetails');
        }
      }).on('click', function (d, i) {
        _this.send('transitionFromRect', d.period);
      });

      svg.selectAll('.TrendsChartNetted-rect').data(data).enter().append('rect').attr('class', function (d, i) {

        var className;

        if (chartType === 'results') {
          return 'TrendsChartNetted-rectResults';
        } else {

          var periodType = _this.get('periodType');
          className = 'TrendsChartNetted-rect';

          if (periodType === 1) {

            if (moment(d.period, 'YYYY-MM').date(1).add(1, 'day').isBetween(startDate, endDate)) {
              className += ' is-withinPeriod';
            }

            var startDayFinancialMonth = _this.get('startDayFinancialMonth') * 1,
                customFinancialMonth = startDayFinancialMonth >= 15;

            if (customFinancialMonth) {
              if (startDayFinancialMonth > moment().format('DD')) {
                if (d.period == moment().date(startDayFinancialMonth).format('YYYY-MM')) {
                  className += ' is-current';
                }
              } else {
                if (d.period == moment().date(startDayFinancialMonth).add(1, 'month').format('YYYY-MM')) {
                  className += ' is-current';
                }
              }
            } else {
              if (d.period == moment().format('YYYY-MM')) {
                className += ' is-current';
              }
            }
          } else if (periodType === 2) {

            if (moment(_this.get('periode'), 'Q-YYYY').format('YYYY-0Q') === d.period) {
              className += ' is-withinPeriod';
            }
          } else if (periodType === 3) {
            if (moment(d.period, 'YYYY-MM').date(1).add(1, 'day').isBetween(startDate, endDate)) {
              className += ' is-withinPeriod';
            }
          }
          return className;
        }
      }).attr('rx', 2).attr('x', function (d) {
        return x(d.period);
      }).attr('width', x.rangeBand()).attr('y', function (d) {
        if (chartType === 'expenses') {
          var isNegative = d.netted < 0;
          return isNegative ? y(d.netted) : y(0) + 15 + 5 + 5;
        } else {
          var isPositive = d.netted > 0;
          return isPositive ? y(d.netted) : y(0) + 15 + 5 + 5;
        }
      }).attr('height', function (d) {
        if (chartType === 'expenses') {
          return Math.abs(y(0) - y(d.netted));
        } else {
          return Math.abs(y(d.netted) - y(0));
        }
      }).on('mouseover', function (d) {
        if (Modernizr.touch) {
          _this.send('transitionFromRect', d.period);
        } else {
          d3.select(this).classed('TrendsChartNetted-rectHover', true);
          _this.send('showDetails', d, d3.event);
        }
      }).on('mouseout', function (d) {
        if (!Modernizr.touch) {
          _this.send('hideDetails');
          d3.select(this).classed('TrendsChartNetted-rectHover', false);
        }
      }).on('click', function (d, i) {
        _this.send('transitionFromRect', d.period);
      });

      if (chartType !== 'results') {

        /**------------------------------------
          Draw Expected line when user selects
          Expected in the Legend, however, hide
          the line when expectedAmount = 0
        */

        if (this.get('comparedToBudget') && expectedAmount !== 0) {

          var lineFunction = d3.svg.line().x(function (d) {
            return x(d.period) + x.rangeBand() / 2; // middle of the rectangle
          }).y(function (d) {
            if (d.expected < 0 && chartType === 'income') {
              return y(d.expected) + 15 + 5 + 5;
            } else if (d.expected > 0 && chartType === 'expenses') {
              return y(d.expected) + 15 + 5 + 5;
            } else {
              return y(d.expected);
            }
          });

          var lineGraph = svg.append('path').datum(data).attr('d', lineFunction).attr('class', 'TrendsChartNetted-budgetLine');

          if (this.get('animateLine')) {

            var totalLength = lineGraph.node().getTotalLength();

            lineGraph.attr('stroke-dasharray', totalLength + ' ' + totalLength).attr('stroke-dashoffset', totalLength).transition().duration(1000).ease('linear').attr('stroke-dashoffset', 0);

            this.set('animateLine', false);
          }

          // svg.append('line')
          //
          // .data(data)
          // .attr('x1', 22)
          // .attr('y1', function(d) {
          //
          //   if (expectedAmount < 0  && chartType === 'income') {
          //     return y(expectedAmount) + 15 + 5 + 5;
          //   } else if (expectedAmount > 0 && chartType === 'expenses') {
          //     return y(expectedAmount) + 15 + 5 + 5;
          //   } else {
          //     return y(expectedAmount);
          //   }
          //
          // })
          // .attr('x2', widthNoMargin - 22)
          // .attr('y2', function(d) {
          //
          //   if (expectedAmount < 0  && chartType === 'income') {
          //     return y(expectedAmount) + 15 + 5 + 5;
          //   } else if (expectedAmount > 0 && chartType === 'expenses') {
          //     return y(expectedAmount) + 15 + 5 + 5;
          //   } else {
          //     return y(expectedAmount);
          //   }
          //
          // })
          // .attr('class', 'TrendsChartNetted-budgetLine');

          svg.append('text').data(data).attr('transform', function (d) {

            if (expectedAmount < 0 && chartType === 'income') {
              return 'translate(' + (widthNoMargin - 11) + ',' + (y(expectedAmount) + 15 + 5 + 5) + ')';
            } else if (expectedAmount > 0 && chartType === 'expenses') {
              return 'translate(' + (widthNoMargin - 11) + ',' + (y(expectedAmount) + 15 + 5 + 5) + ')';
            } else {
              return 'translate(' + (widthNoMargin - 11) + ',' + y(expectedAmount) + ')';
            }
          }).attr('dy', '.35em').attr('text-anchor', 'start').attr('class', 'TrendsChartNetted-expectedAmount')
          // Use <tspan> to allow line-wrapping
          .each(function (d) {
            d3.select(this).append('tspan').text('Budget').attr('x', 0).attr('dy', 0).append('tspan').text(function (d, i) {
              return accounting.formatMoney(expectedAmount, { precision: 0 });
            }).attr('x', 0).attr('dy', '1.2em');
          }).on('click', function (d, i) {
            _this.send('hideBudgetDetails', d, d3.event);
            _this.sendAction('transitionAction', 'budget');
          }).on('mouseover', function (d, i) {
            _this.send('showBudgetDetails', d, d3.event);
          }).on('mouseleave', function (d, i) {
            _this.send('hideBudgetDetails', d, d3.event);
          });
        }

        /**------------------------------------
          Draw Average line when user selects Average
          in the Legend, hide the line however when
          averageNetted = 0
        */

        if (this.get('comparedToAverage') && averageNetted !== 0) {

          var lineFunctionAverage = d3.svg.line().x(function (d) {
            return x(d.period) + x.rangeBand() / 2; // middle of the rectangle
          }).y(function (d) {

            if (d.averageNetted < 0 && chartType === 'income') {
              return y(d.averageNetted) + 15 + 5 + 5;
            } else if (d.averageNetted > 0 && chartType === 'expenses') {
              return y(d.averageNetted) + 15 + 5 + 5;
            } else {
              return y(d.averageNetted);
            }
          });

          var lineGraphAverage = svg.append('path').datum(data).attr('d', lineFunctionAverage).attr('class', 'TrendsChartNetted-budgetLine');

          if (this.get('animateLine')) {

            var totalLengthAverage = lineGraphAverage.node().getTotalLength();

            lineGraphAverage.attr('stroke-dasharray', totalLengthAverage + ' ' + totalLengthAverage).attr('stroke-dashoffset', totalLengthAverage).transition().duration(1000).ease('linear').attr('stroke-dashoffset', 0);

            this.set('animateLine', false);
          }

          // svg.append('line')
          // .data(data)
          // .attr('x1', -15)
          // .attr('y1', function(d) {
          //
          //   if (averageNetted < 0  && chartType === 'income') {
          //     return y(averageNetted) + 15 + 5 + 5;
          //   } else if (averageNetted > 0 && chartType === 'expenses') {
          //     return y(averageNetted) + 15 + 5 + 5;
          //   } else {
          //     return y(averageNetted);
          //   }
          //
          // })
          // .attr('x2', widthNoMargin - 15)
          // .attr('y2', function(d) {
          //
          //   if (averageNetted < 0  && chartType === 'income') {
          //     return y(averageNetted) + 15 + 5 + 5;
          //   } else if (averageNetted > 0 && chartType === 'expenses') {
          //     return y(averageNetted) + 15 + 5 + 5;
          //   } else {
          //     return y(averageNetted);
          //   }
          //
          // })
          // .attr('class', 'TrendsChartNetted-budgetLine');

          svg.append('text').data(data).attr('transform', 'translate(' + (widthNoMargin - 11) + ',' + y(averageNetted) + ')').attr('dy', '.35em').attr('text-anchor', 'start').classed('TrendsChartNetted-expectedAverage', true).each(function (d) {
            d3.select(this).append('tspan').text('gem.').attr('dy', 0).attr('x', 0).append('tspan').text(function (d) {
              if (d.averageNetted < 0) {
                return accounting.formatMoney(-Math.abs(d.averageNetted), { precision: 0 });
              } else {
                return accounting.formatMoney(d.averageNetted, { precision: 0 });
              }
            }).attr('dy', '1.2em').attr('x', 0);
          });
        }
      }
    },

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {

      /**
        Transition to the given period via our `periode` param,
        after translating the period format to dutch (MM-YYYY).
         When the given period is the current financial month,
        we'll set the `periode` param to null.
      */
      transitionFromRect: function transitionFromRect(period) {

        var startDate = moment(this.get('startDate'), 'YYYY-MM-DD'),
            endDate = moment(this.get('endDate'), 'YYYY-MM-DD'),
            financialMonthStartDay = startDate.date(),
            isCustomFinancialMonth = financialMonthStartDay >= 15,
            periodMonth = moment(period, 'YYYY-MM'),
            currentMonth = moment().endOf('month'),
            periodType = this.get('periodType');

        if (isCustomFinancialMonth) {
          var currentDay = moment().date();
          if (currentDay >= financialMonthStartDay) {
            currentMonth = currentMonth.add(1, 'months');
          }
        }

        // When current financial month, remove period param
        if (currentMonth.format('YYYY-MM') === periodMonth.format('YYYY-MM') && periodType == 1) {
          this.sendAction('transitionAction', { queryParams: { periode: null } });
        } else {

          if (periodType === 3) {
            this.sendAction('transitionAction', { queryParams: { periode: periodMonth.format('YYYY') } });
          } else if (periodType === 2) {
            this.sendAction('transitionAction', { queryParams: { periode: 'kwartaal-' + periodMonth.format('M') + '-' + periodMonth.format('YYYY') } });
          } else {
            this.sendAction('transitionAction', { queryParams: { periode: periodMonth.format('MM-YYYY') } });
          }
        }
      },

      insertDetails: function insertDetails() {
        var periodDetails = _ember['default'].$('<div class="TrendsChartNettedDetails"></div>');
        this.set('periodDetails', periodDetails);
        _ember['default'].$('body').append(periodDetails);
      },

      showDetails: function showDetails(d, event) {
        var m = moment(d.period, 'YYYY-MM'),
            month = m.format('MMMM'),
            year = m.format('YYYY'),
            netted = d.netted,
            income = d.income,
            expense = d.expense,
            target = _ember['default'].$(event.target),
            periodType = this.get('periodType'),
            amountType;

        if (this.get('chartType') === 'results') {
          amountType = 'Resultaat';
        } else if (this.get('chartType') === 'income') {
          if (netted < 0) {
            amountType = 'Uitgaven';
          } else {
            amountType = 'Inkomsten';
          }
        } else {

          if (netted > 0) {
            amountType = 'Inkomsten';
          } else {
            amountType = 'Uitgaven';
          }
        }

        netted = accounting.formatMoney(netted, { precision: 0 });

        var periodDetails = this.get('periodDetails');
        var htmlString;

        if (this.get('chartType') === 'results') {

          income = accounting.formatMoney(income, { precision: 0 });
          expense = accounting.formatMoney(expense, { precision: 0 });

          htmlString = '\n        <div class="TrendsChartNettedDetails-date">\n          <span class="TrendsChartNettedDetails-month">' + month + '</span>\n          <span class="TrendsChartNettedDetails-year">' + year + '</span>\n        </div>\n        <div class="TrendsChartNettedDetails-title">' + amountType + '</div>\n        <div class="TrendsChartNettedDetails-result">' + netted + '</div>\n\n        <div class="TrendsChartNettedDetails-sub">\n          <div class="TrendsChartNettedDetails-subTitle">Inkomsten</div>\n          <div class="TrendsChartNettedDetails-subSum">' + income + '</div>\n        </div>\n        <span class="TrendsChartNettedDetails-subOperator">-</span>\n        <div class="TrendsChartNettedDetails-sub">\n          <div class="TrendsChartNettedDetails-subTitle">Uitgaven</div>\n          <div class="TrendsChartNettedDetails-subSum">' + expense + '</div>\n        </div>';
        } else {

          if (periodType === 3) {

            htmlString = '\n          <div class="TrendsChartNettedDetails-date">\n          <span class="TrendsChartNettedDetails-year">Jaar ' + year + '</span>\n          </div>\n          <div class="TrendsChartNettedDetails-title">' + amountType + '</div>\n          <div class="TrendsChartNettedDetails-result">' + netted + '</div>';
          } else if (periodType === 2) {

            htmlString = '\n          <div class="TrendsChartNettedDetails-date">\n          <span class="TrendsChartNettedDetails-year">Kwartaal ' + m.format('M') + ' ' + year + '</span>\n          </div>\n          <div class="TrendsChartNettedDetails-title">' + amountType + '</div>\n          <div class="TrendsChartNettedDetails-result">' + netted + '</div>';
          } else {

            htmlString = '\n          <div class="TrendsChartNettedDetails-date">\n          <span class="TrendsChartNettedDetails-month">' + month + '</span>\n          <span class="TrendsChartNettedDetails-year">' + year + '</span>\n          </div>\n          <div class="TrendsChartNettedDetails-title">' + amountType + '</div>\n          <div class="TrendsChartNettedDetails-result">' + netted + '</div>';
          }
        }

        // Make sure amounts in tooltip are negative
        // when in expense route
        var _top = target.offset().top - 80 - 20;

        if (this.get('chartType') === 'results') {
          periodDetails.addClass('is-resultsChart');
          _top = target.offset().top - 140;
        }

        periodDetails.css({
          top: _top,
          left: target.offset().left - periodDetails.width() / 2 + target.attr('width') / 2
        }).html(htmlString).addClass('is-active');
      },

      hideDetails: function hideDetails() {
        this.get('periodDetails').removeClass('is-active').empty();
      },

      insertBudgetDetails: function insertBudgetDetails() {
        var budgetDetails = _ember['default'].$('<div class="TrendsChartNettedBudget"></div>');
        this.set('budgetDetails', budgetDetails);
        _ember['default'].$('body').append(budgetDetails);
      },

      showBudgetDetails: function showBudgetDetails(d, event) {

        var expectedAmount = this.get('data.lastObject.expected');

        this.get('budgetDetails').css({
          top: event.pageY + 10,
          left: event.pageX - 200
        }).html('<h4>Jouw budget</h4>Wil je de hoogte van jouw budget (' + accounting.formatMoney(expectedAmount, { precision: 0 }) + ') aanpassen. Ga dan naar budgetteren.').addClass('is-active');
      },

      hideBudgetDetails: function hideBudgetDetails() {
        this.get('budgetDetails').removeClass('is-active').empty();
      },

      compareTo: function compareTo(target) {

        // Ignore unavailable targets
        var Target = target.capitalize();
        if (this.get('compareTo' + Target + 'Unavailable')) return;

        if (this.get('comparedTo') === target) {
          this.set('comparedTo', null);
        } else {
          this.set('comparedTo', target);
        }
      }
    }
  });
});