define("front-end-cli/templates/accountmanagement/edit/guide-auto", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 6
              },
              "end": {
                "line": 11,
                "column": 6
              }
            },
            "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        Je gegevens worden opgehaald\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 6
              },
              "end": {
                "line": 14,
                "column": 6
              }
            },
            "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        Je gegevens worden bijgewerkt\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 16,
              "column": 2
            }
          },
          "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "syncloader");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "glyphicons glyphicons-refresh syncloader-icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "status-text");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element5, 1, 1);
          morphs[1] = dom.createMorphAt(element5, 2, 2);
          return morphs;
        },
        statements: [["block", "if", [["get", "parentController.isSetupController"]], [], 0, null], ["block", "if", [["get", "parentController.isUpdateController"]], [], 1, null]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 12
                },
                "end": {
                  "line": 23,
                  "column": 12
                }
              },
              "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "bank-sync-error", [["get", "error.code"]], []]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 12
                },
                "end": {
                  "line": 25,
                  "column": 12
                }
              },
              "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              Er is een onbekende fout opgetreden tijdens het synchroniseren met de ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" server. Neem contact op met ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "href", "https://help.mijnhuishoudboekje.mijngeldzaken.nl");
              dom.setAttribute(el1, "class", "alert-link");
              var el2 = dom.createTextNode("support");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(".\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "bankChoice.bank.title"]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 6
              },
              "end": {
                "line": 28,
                "column": 6
              }
            },
            "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "alert alert-danger");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h4");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "error.code"]], [], 0, 1]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 12
                  },
                  "end": {
                    "line": 36,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "account-nr");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("Je kan je rekeningnummer vinden op je Rabobank pas.");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 37,
                    "column": 12
                  },
                  "end": {
                    "line": 40,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "pass-nr");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("Je kan je pasnummer vinden op je Rabobank pas.");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 41,
                    "column": 12
                  },
                  "end": {
                    "line": 55,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "reader");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                dom.setAttribute(el1, "class", "step-by-step reader-steps");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createTextNode("Plaats je bankpas in de Random Reader");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createTextNode("Druk op I (Inloggen)");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createTextNode("Toets je pincode in en druk op OK");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dic");
                dom.setAttribute(el1, "class", "clearfix");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("Vul hiernaast de toegangscode in die op je Random Reader verschijnt.");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "i-key");
                var el2 = dom.createTextNode("\n                Log alleen in met de I-toets.");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("br");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                (de S-toets gebruik je alleen voor betalingen)\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 8
                },
                "end": {
                  "line": 58,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "rabobank");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element3, 1, 1);
              morphs[1] = dom.createMorphAt(element3, 2, 2);
              morphs[2] = dom.createMorphAt(element3, 3, 3);
              return morphs;
            },
            statements: [["block", "if", [["get", "guide1"]], [], 0, null], ["block", "if", [["get", "guide2"]], [], 1, null], ["block", "if", [["get", "guide3"]], [], 2, null]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 63,
                    "column": 12
                  },
                  "end": {
                    "line": 69,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createTextNode("Vul de gegevens in waarmee je ook inlogt bij de ING.");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createTextNode("Je wachtwoord wordt nooit opgeslagen.");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "username-password");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 70,
                      "column": 14
                    },
                    "end": {
                      "line": 77,
                      "column": 14
                    }
                  },
                  "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("ul");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("De ING heeft via SMS een PAC-code naar je verstuurd.");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Vul de PAC-code uit de SMS hiernaast in om in te loggen.");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Een PAC-code (Persoonlijke Authenticatie Code) wordt af en toe verstuurd door de bank om zeker te weten dat jij het bent die inlogt.");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createElement("a");
                  dom.setAttribute(el3, "href", "https://www.ing.nl/de-ing/veilig-bankieren/veilig-internetbankieren/extra-veiligheid-met-de-PAC-code/index.aspx?first_visit=true");
                  dom.setAttribute(el3, "target", "blank");
                  var el4 = dom.createTextNode("lees meer");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 77,
                      "column": 14
                    },
                    "end": {
                      "line": 82,
                      "column": 14
                    }
                  },
                  "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("ul");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("De verificatie van de PAC-code is helaas mislukt.");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("button");
                  dom.setAttribute(el2, "class", "btn btn-warning");
                  var el3 = dom.createTextNode("Probeer het opnieuw");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1, 3]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element2);
                  return morphs;
                },
                statements: [["element", "action", ["endFailedPacVerification"], []]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 69,
                    "column": 12
                  },
                  "end": {
                    "line": 83,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "unless", [["get", "bankChoice.showPacFailedNotice"]], [], 0, 1]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 8
                },
                "end": {
                  "line": 86,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "ing");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "unless", [["get", "guide3"]], [], 0, 1]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 92,
                    "column": 12
                  },
                  "end": {
                    "line": 97,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createTextNode("Neem je rekeningnummer over zoals hieronder aangegeven. Staat er geen IBAN op je pas maar een rekeningnummer van 9 cijfers, dan neem je dit rekeningnummer in zijn geheel over.");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "pass");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 98,
                    "column": 12
                  },
                  "end": {
                    "line": 103,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createTextNode("\n                Neem je ‘pasnummer’ over van je pas.\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "pass");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 105,
                      "column": 14
                    },
                    "end": {
                      "line": 110,
                      "column": 14
                    }
                  },
                  "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("ul");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Toes je 5-cijferige identificatiecode in. Gebruikt je al mobiel bankieren? Dan is dit dezelfde code.");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Je identificatiecode wordt nooit opgeslagen.");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 110,
                      "column": 14
                    },
                    "end": {
                      "line": 120,
                      "column": 14
                    }
                  },
                  "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("ul");
                  dom.setAttribute(el1, "class", "step-by-step");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Pak je e.dentifier");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Voer je pas in");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Druk op ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  var el4 = dom.createTextNode("1");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" inloggen");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Toets je pincode in");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Druk op ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  var el4 = dom.createTextNode("ok");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Vul de getoonde response in.");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("p");
                  var el2 = dom.createTextNode("Vul de toegangscode in die op je e.dentifier verschijnt");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 104,
                    "column": 12
                  },
                  "end": {
                    "line": 121,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "bankChoice.isAbnLogincode"]], [], 0, 1]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 8
                },
                "end": {
                  "line": 123,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "abn");
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element1, 1, 1);
              morphs[1] = dom.createMorphAt(element1, 2, 2);
              morphs[2] = dom.createMorphAt(element1, 3, 3);
              return morphs;
            },
            statements: [["block", "if", [["get", "guide1"]], [], 0, null], ["block", "if", [["get", "guide2"]], [], 1, null], ["block", "if", [["get", "guide3"]], [], 2, null]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 128,
                    "column": 12
                  },
                  "end": {
                    "line": 134,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createTextNode("Vul de gegevens in waarmee je ook inlogt bij de SNS Internetbankieren");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                var el3 = dom.createTextNode("Je wachtwoord wordt nooit opgeslagen.");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("li");
                dom.setAttribute(el2, "class", "error");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 136,
                      "column": 14
                    },
                    "end": {
                      "line": 141,
                      "column": 14
                    }
                  },
                  "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("ul");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Het serienummer van je digipas vind je op de achterkant van de digipas. Voer het serienummer van je digipas in als één getal, zonder spaties of streepjes.");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  dom.setAttribute(el2, "class", "error");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 141,
                      "column": 14
                    },
                    "end": {
                      "line": 149,
                      "column": 14
                    }
                  },
                  "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("ul");
                  dom.setAttribute(el1, "class", "step-by-step");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Druk op de pijltjestoets van je digipas");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Toets je pincode in op je digipas");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Toets het nummer wat je hiernaast ziet staan in op je digipas");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  var el3 = dom.createTextNode("Er verschijnt een nummer op je digipas. Vul dit hiernaast in.");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("li");
                  dom.setAttribute(el2, "class", "error");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 135,
                    "column": 12
                  },
                  "end": {
                    "line": 150,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "unless", [["get", "bankChoice.challenge"]], [], 0, 1]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 126,
                  "column": 8
                },
                "end": {
                  "line": 152,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "sns");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element0, 1, 1);
              morphs[1] = dom.createMorphAt(element0, 2, 2);
              return morphs;
            },
            statements: [["block", "if", [["get", "bankChoice.isSnsDigicode"]], [], 0, null], ["block", "if", [["get", "bankChoice.isSnsDigipass"]], [], 1, null]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 6
              },
              "end": {
                "line": 154,
                "column": 6
              }
            },
            "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "bankChoice.isRabobank"]], [], 0, null], ["block", "if", [["get", "bankChoice.isIng"]], [], 1, null], ["block", "if", [["get", "bankChoice.isAbn"]], [], 2, null], ["block", "if", [["get", "bankChoice.isSns"]], [], 3, null]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 2
            },
            "end": {
              "line": 156,
              "column": 2
            }
          },
          "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element4, 'class');
          morphs[1] = dom.createMorphAt(element4, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "unbound", [["get", "bankChoice.bank.name"]], []]]]], ["block", "if", [["get", "error"]], [], 0, 1]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 159,
            "column": 0
          }
        },
        "moduleName": "front-end-cli/templates/accountmanagement/edit/guide-auto.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-sm-5");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "guide");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "parentController.syncing"]], [], 0, 1]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});