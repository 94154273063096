define('front-end-cli/helpers/whatif-retirement-format-detail-number', ['exports', 'ember'], function (exports, _ember) {
    exports.whatifRetirementFormatDetailNumber = whatifRetirementFormatDetailNumber;

    function whatifRetirementFormatDetailNumber(value, options) {

        // CHECK IF WE NEED TO DEVIDE (PER-YEAR INTO PER-MONTH)
        var v,
            divide = options && options.hash['divide'] ? 12 : 1,
            round = options && options.hash['round'] ? true : false,
            negative = options && options.hash['negative'] ? true : false;

        // DEVIDE OR NORMALIZE
        v = value ? value / divide : 0;

        // ROUND THE VALUE TO TEN'S (111 into 110)
        v = round ? Math.round(v / 50) * 50 : v;

        // MAKE NEGATIVE
        v = negative ? v * -1 : v;

        // RETURN THOUSAND SEPERATED
        return accounting.formatMoney(v, '', 0, '.', '');
    }

    exports['default'] = _ember['default'].Handlebars.makeBoundHelper(whatifRetirementFormatDetailNumber);
});