define('front-end-cli/views/application', ['exports', 'ember', 'front-end-cli/mixins/window-resize'], function (exports, _ember, _frontEndCliMixinsWindowResize) {
  exports['default'] = _ember['default'].View.extend(_frontEndCliMixinsWindowResize['default'], {
    sessionManager: _ember['default'].inject.service('session-manager'),
    elementId: 'app',
    classNameBindings: ['controller.hasSidebar:sidebar', ':initializing'],
    triangleTimeout: null,
    fullScreenMode: false,

    initializeSidebar: (function () {

      this.set('fullScreenMode', !this.get('controller.hasSidebar'));

      var force = true;

      // Add mobile `orientationChanged` event listener
      _ember['default'].$(window).on('orientationchange', (function () {
        _ember['default'].run.next(this, function () {
          this.send('watchSidebar', force);
        });
      }).bind(this));

      // Init sidebar visibility
      this.send('watchSidebar', force);
    }).on('didInsertElement'),

    /**
      Every time an app window receives a focus event,
      we request the server to reset the session-timeout
      and we will reload the app when there is none.
    */
    listenForFocusEvents: _ember['default'].on('didInsertElement', function () {
      var _this = this;

      window.addEventListener('focus', function () {
        _this.get('sessionManager').keepAlive();
      });
    }),

    click: function click(e) {
      this.get('controller').send('hideModal', e);
    },

    //touchEnd: function(e) {
    // this.get('controller').send('hideModal', e);
    //},

    currentPathObserver: (function () {
      this.send('positionNavTriangle');
    }).observes('controller.currentPath'),

    accountsLengthObserver: (function () {

      // Only re-position sidebarTriangle on accounts change
      // when in an accountmanagement route
      if (!/^accountmanagement|legacy\.accounts/.test(this.get('controller.currentPath'))) {
        return;
      }

      // TODO: When account deletion is done from ember,
      // we should get rid of this deletions triggering
      // observer multiple time to glitch thing up.
      // We'll then use `Ember.run.scheduleOnce('afterRender'...`
      _ember['default'].run.cancel(this.get('triangleTimeout'));
      this.set('triangleTimeout', _ember['default'].run.later(this, function () {
        this.send('positionNavTriangle');
      }, 100));
    }).observes('controller.currentUser.model.accounts.length'),

    resizeObserver: (function () {
      _ember['default'].run.debounce(this, function () {
        this.send('watchSidebar');
      }, 100);
    }).on('windowResize'),

    actions: {

      // Re-architect: This is a view thing, but we have
      // to be able to trigger it from outside the controller
      // (sidebaraccountsView:setHeight)
      positionNavTriangle: function positionNavTriangle() {
        var ms = 100,
            self = this,
            $sidebarScrollContainer,
            $sidebarList,
            $sidebarTriangle,
            $active;

        /* `currentPath` is changed before active states are set,
          thus the delay (maybe use `beforeModel` in future).*/
        _ember['default'].run.later(this, function () {
          $sidebarScrollContainer = _ember['default'].$('#sidebar-scroll-container');
          $sidebarList = _ember['default'].$('#sidebar-list');
          $sidebarTriangle = _ember['default'].$('#sidebar-triangle');

          $active = _ember['default'].$('#app > nav').find('.active');
          if (!$active.length || /accountmanagement.setup|accountmanagement.update.*/.test(this.get('controller.currentPath'))) {
            $sidebarTriangle.addClass('unloaded');
            return;
          }

          var navTop = _ember['default'].$('#app > nav').offset().top,
              activeTop = $active.offset().top + $active.outerHeight() / 2,
              scrollTop = $sidebarScrollContainer.scrollTop();

          // TO FIXED
          $sidebarTriangle.css({
            position: 'fixed',
            top: $sidebarTriangle.offset().top - navTop,
            left: $sidebarScrollContainer.width(),
            right: '',
            bottom: ''
          });

          // TRANSITION
          $sidebarTriangle.animate({
            top: activeTop + scrollTop - navTop
          }, ms, function () {
            // BACK TO RELATIVE
            if (!self.get('controller.currentPath').match(/legacy.accounts.*|accountmanagement.setup|accountmanagement.update.*/)) {
              $sidebarTriangle.css({
                top: activeTop + scrollTop - navTop,
                position: '',
                left: '',
                right: 0,
                bottom: ''
              });
            }
            // TO BOTTOM FIXED
            else {
                $sidebarTriangle.css({
                  bottom: _ember['default'].$(window).height() - activeTop - navTop,
                  left: _ember['default'].$('nav').width(),
                  top: ''
                });
              }
          });

          // FIRST RUN SHOW TRIANGLE
          if ($sidebarTriangle.hasClass('unloaded')) {
            _ember['default'].run.later(this, function () {
              $sidebarTriangle.removeClass('unloaded');
            }, 1000);
          }
        }, 10);
      },

      /*
      Responsible for toggling the menu on window resize.
      We don't override possible user initiated toggles by checking these conditions:
      If narrow and sidebar on resize start, skip toggling;
      If wide and no sidebar on resize start, skip toggling;
      */
      watchSidebar: function watchSidebar(force) {

        var width = _ember['default'].$(window).width(),
            $app = _ember['default'].$('#app'),
            fullScreenMode = this.get('fullScreenMode'),
            self = this,
            breakpoint = 1024;

        // Handy!
        /*console.group('watchSidebar');
        console.log('firstRun', firstRun);
        console.log('breakpoint', breakpoint);
        console.log('width', width);
        console.log('$app.hasClass(sidebar)', $app.hasClass('sidebar'));
        console.log('resizing', this.resizing);
        console.groupEnd();*/

        if (fullScreenMode) return;

        // NARROW, REMOVE SIDEBAR
        if (width < breakpoint) {
          // IF WIDE AND NO SIDEBAR ON RESIZE START, SKIP TOGGLE
          if ($app.hasClass('sidebar') && !this.resizing) {
            $app.addClass('narrow-keep-sidebar');
          }
          if (force || !$app.hasClass('narrow-keep-sidebar')) {
            this.get('controller').send('hideSidebar');
          }
        }
        // WIDE, ADD SIDEBAR
        else {
            // IF NARROW AND SIDEBAR ON RESIZE START, SKIP TOGGLE
            if (!$app.hasClass('sidebar') && !this.resizing) {
              $app.addClass('wide-keep-sidebar');
            }
            if (force || !$app.hasClass('wide-keep-sidebar')) {
              this.get('controller').send('showSidebar');
            }
          }

        // NOW WE'RE RESIZING
        this.resizing = true;

        // KEEP STATES WHILE RESIZING
        if (this.timer) clearTimeout(this.timer);

        // CLEAR STATES WHEN DONE
        this.timer = setTimeout(function () {

          // RESET
          self.resizing = false;
          $app.removeClass('narrow-keep-sidebar wide-keep-sidebar');

          // ADD ANIMATECLASS AFTER FIRST RUN
          // if(firstRun) $app.addClass('animate');
          $app.addClass('animate');
        }, 500);
      }
    }
  });

  // Add autofocus binding to `TextField` views.
  // TODO: find a more suitable place for this
  _ember['default'].TextField.reopen({
    attributeBindings: ['autofocus']
  });
});