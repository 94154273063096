define('front-end-cli/views/application/sidebaraccounts-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({

    sidebaraccountsController: _ember['default'].computed.alias('controller.controllers.application/sidebaraccounts'),

    elementId: 'sidebaraccounts-modal',
    classNames: ['modal-container'],

    addArrow: (function () {
      // Add an arrow to point out our trigger
      var arrow = _ember['default'].$('<div/>', {
        'class': 'accountsmodalarrow'
      });
      this.set('arrow', arrow);
      this.$().append(arrow);

      // Postion arrow and modal
      this.position();
    }).on('didInsertElement'),

    position: (function () {

      _ember['default'].run.scheduleOnce('afterRender', this, function () {

        if (!this.$()) {
          return;
        }

        var modal = this.$();

        // Reset
        modal.css({
          top: '',
          bottom: ''
        });

        var windowHeight = _ember['default'].$(window).height(),
            scrollTop = _ember['default'].$(document).scrollTop(),
            arrowHeight = 32;

        var modalTop = modal.offset().top - scrollTop,
            modalHeight = modal.height(),
            modalBottom = windowHeight - modalTop - modalHeight;

        var activeTrigger = this.get('sidebaraccountsController.activeTrigger'),
            activeTriggerTop = activeTrigger.offset().top - scrollTop + activeTrigger.outerHeight() / 2,
            activeTriggerBottom = _ember['default'].$(window).height() - activeTriggerTop;

        // Fix modal position when its trigger to high up.
        if (activeTriggerBottom > modalHeight + modalBottom - arrowHeight) {

          modalTop = windowHeight - activeTriggerBottom - modalBottom - arrowHeight;

          modal.css({
            top: modalTop,
            bottom: 'inherit'
          });
        }

        this.get('arrow').css({
          top: activeTriggerTop - modalTop - arrowHeight / 2
        });
      });
    }).observes('controller.model')
  });
});