define('front-end-cli/controllers/legacy', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    needs: ['application'],
    lastLegacyPath: null,
    /**
      Observer fires when we are in a legacy route
      and `currentPath` is changed. It is used to
      update the iframe with desired `src`.
    */
    currentPathObserver: (function () {
      /**
        `currentPath` fires before Embers' `window.location`
        is set, thus the `hashchange` listener.
      */
      var pathname = window.location.hash.replace(/#\//, '').replace(/app\/account\//, 'app/');

      // Only run on legacy routes
      if (!/^legacy\./.test(this.get('controllers.application.currentPath'))) {
        return;
      }

      // console.log('currentPathObserver: '+this.get('lastLegacyPath')+' != '+pathname);

      // Link-to's fire currentpath before url change, so we wait for hashchange
      if (this.get('lastLegacyPath') == pathname) {
        var self = this;
        _ember['default'].$(window).one('hashchange', function () {
          self.send('setLegacyIframeLocation');
        });
      }
      // or we got here via `handleIframeChange`
      else {
          this.send('setLegacyIframeLocation');
        }
    }).observes('controllers.application.currentPath'),

    actions: {
      setLegacyIframeLocation: function setLegacyIframeLocation(init) {

        var pathname = window.location.hash.replace(/#\//, '') // clear hash
        .replace(/app\/account\//, 'app/'); // rewrite account paths

        // Tests don't have a pathname and
        // shouldn't load legacy pages.
        if (!pathname) return;

        // console.log('setLegacyIframeLocation('+init+'): '+this.get('lastLegacyPath')+' != '+pathname);

        if (init || this.get('lastLegacyPath') && this.get('lastLegacyPath') != pathname) {
          this.set('lastLegacyPath', pathname);

          var origin = window.location.origin || window.location.protocol + '//' + window.location.hostname;
          var src = origin + '/' + pathname;

          // When ran from ember-cli's express server
          // we need to remove the port from the url
          // so the iframe will point to the APP.
          if (window.location.port) {
            var r = new RegExp(':' + window.location.port);
            src = src.replace(r, '');
          }

          // Show line-loader
          // TODO: Skip loadier when not plus and on a plus route
          this.send('loading', 600);
          _ember['default'].$('#legacy').prop('src', src);
        }
      },

      /**
        When the legacy-iframe `src` changes, we will update
        `window.location` so ember reflects the iframe location.
        This will trigger a transition, which will update the
        UI-state. Since we'll stay within the legacy-route,
        the iframe will be left in place.
      */
      handleIframeChange: function handleIframeChange(iframePathname) {

        // console.log('handleIframeChange: '+this.get('lastLegacyPath')+' != '+iframePathname);

        // HACK The legacy app redirects to overview
        // on quite some occasions, while we want this
        // to be timeline instead.
        // Glitchy but error proof.
        if (iframePathname == 'app/overzicht') {
          this.transitionToRoute('timeline');
          return;
        }
        if (this.get('lastLegacyPath') != iframePathname) {
          // This prevents `setLegacyIframeLocation` from setting the src again
          this.set('lastLegacyPath', iframePathname);

          if (bowser.firefox) {
            //this is too prevent an extra reload on the transaction page when searching
            //firefox preforms a decodeURIComponent on the hash, this will be fixed in mozilla41 coming september 2015, after that this code can be removed
            //https://bugzilla.mozilla.org/show_bug.cgi?id=1093611
            iframePathname = encodeURI(iframePathname);
          }

          // This will trigger `setLegacyIframeLocation` via currentPath
          window.location.hash = '#/' + iframePathname;
        }

        // End loading
        this.send('endLoading');
      }
    }
  });
});