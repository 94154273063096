define('front-end-cli/views/accountmanagement/edit/manual-steps', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({

    setGuideHeight: (function () {
      var minimum = 100,
          substepsHeight = this.$('.manual-substeps').height(),
          height = substepsHeight < minimum ? minimum : substepsHeight;

      this.$('.guide').parent().css({
        height: height + 'px'
      });
    }).on('didInsertElement')
  });
});