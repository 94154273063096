define('front-end-cli/models/income', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    grossannual: _emberData['default'].attr('number'),
    monthlyPensionPremium: _emberData['default'].attr('number'),
    thirteenth: _emberData['default'].attr('string'),
    holiday: _emberData['default'].attr('string'),

    businessCarChoice: _emberData['default'].attr('number'),
    taxLiabilityAmount: _emberData['default'].attr('number'),
    miscchoice: _emberData['default'].attr('number'),
    onetimemiscchoice: _emberData['default'].attr('number'),
    miscgross: _emberData['default'].attr('number'),
    miscgross1: _emberData['default'].attr('number'), //year 1
    miscgross2: _emberData['default'].attr('number'), //year 2
    miscgross3: _emberData['default'].attr('number'), //year 3

    hasBusinessCar: (function () {
      var value = this.get('businessCarChoice');
      return value == 1;
    }).property('businessCarChoice')
  });
});