define('front-end-cli/controllers/whatif/retirement/events', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    // NATIVE
    needs: 'whatif/retirement',
    /**
      When true, the event template will be rendered
    */
    hasEvents: (function () {
      return this.get('model.events');
    }).property(),
    /**
      Return true when event.year is `selectedYear`.
      Used for classNameBinding of `WhatifRetirementEventView`
    */
    isActive: (function () {
      return this.get('model.year') == this.get('controllers.whatif/retirement.selectedYear');
    }).property('controllers.whatif/retirement.selectedYear'),

    actions: {
      setSelectedYear: function setSelectedYear() {
        this.set('controllers.whatif/retirement.selectedYear', this.get('model.year'));
      }
    }
  });
});