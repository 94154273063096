define("front-end-cli/pods/components/budget/yeartotals-table/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 8
              },
              "end": {
                "line": 27,
                "column": 8
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.resultBudget"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
          locals: ["item", "index"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 8
              },
              "end": {
                "line": 33,
                "column": 8
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.resultSpent"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
          locals: ["item", "index"],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 8
              },
              "end": {
                "line": 39,
                "column": 8
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.resultDifference"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
          locals: ["item", "index"],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 8
              },
              "end": {
                "line": 45,
                "column": 8
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.resultCumulativeDifference"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
          locals: ["item", "index"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 0
            },
            "end": {
              "line": 58,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tbody");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3, "class", "is-left");
          var el4 = dom.createTextNode("volgens budget");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          dom.setAttribute(el2, "class", "is-mainRow");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3, "class", "is-left is-bold");
          var el4 = dom.createTextNode("werkelijk");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3, "class", "is-left");
          var el4 = dom.createTextNode("verschil");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3, "class", "is-left");
          var el4 = dom.createTextNode("cumulatief");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 3, 3);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]), 3, 3);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [7]), 3, 3);
          return morphs;
        },
        statements: [["block", "each", [["get", "model"]], [], 0, null], ["block", "each", [["get", "model"]], [], 1, null], ["block", "each", [["get", "model"]], [], 2, null], ["block", "each", [["get", "model"]], [], 3, null]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 8
                },
                "end": {
                  "line": 66,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.incomeBudget"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 8
                },
                "end": {
                  "line": 69,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.expensesBudget"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 6
              },
              "end": {
                "line": 70,
                "column": 6
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isIncome"]], [], 0, null], ["block", "if", [["get", "isExpenses"]], [], 1, null]],
          locals: ["item", "index"],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 73,
                "column": 45
              },
              "end": {
                "line": 73,
                "column": 70
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("inkomsten");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 73,
                "column": 70
              },
              "end": {
                "line": 73,
                "column": 86
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("uitgaven");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 75,
                  "column": 8
                },
                "end": {
                  "line": 77,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.incomeSpent"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 78,
                  "column": 8
                },
                "end": {
                  "line": 80,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.expensesSpent"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 74,
                "column": 6
              },
              "end": {
                "line": 81,
                "column": 6
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isIncome"]], [], 0, null], ["block", "if", [["get", "isExpenses"]], [], 1, null]],
          locals: ["item", "index"],
          templates: [child0, child1]
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 86,
                  "column": 8
                },
                "end": {
                  "line": 88,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.incomeDifference"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 8
                },
                "end": {
                  "line": 91,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.expensesDifference"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 6
              },
              "end": {
                "line": 92,
                "column": 6
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isIncome"]], [], 0, null], ["block", "if", [["get", "isExpenses"]], [], 1, null]],
          locals: ["item", "index"],
          templates: [child0, child1]
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 97,
                  "column": 8
                },
                "end": {
                  "line": 99,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.incomeCumulativeDifference"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 100,
                  "column": 8
                },
                "end": {
                  "line": 102,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.expensesCumulativeDifference"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 96,
                "column": 6
              },
              "end": {
                "line": 103,
                "column": 6
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isIncome"]], [], 0, null], ["block", "if", [["get", "isExpenses"]], [], 1, null]],
          locals: ["item", "index"],
          templates: [child0, child1]
        };
      })();
      var child6 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 112,
                      "column": 10
                    },
                    "end": {
                      "line": 114,
                      "column": 10
                    }
                  },
                  "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.incomeUnknown"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 111,
                    "column": 8
                  },
                  "end": {
                    "line": 115,
                    "column": 8
                  }
                },
                "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
              },
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "isIncome"]], [], 0, null]],
              locals: ["item", "index"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 108,
                  "column": 6
                },
                "end": {
                  "line": 117,
                  "column": 6
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "is-left");
              var el3 = dom.createTextNode("werkelijke inkomsten niet in budget");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
              return morphs;
            },
            statements: [["block", "each", [["get", "model"]], [], 0, null]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 4
              },
              "end": {
                "line": 118,
                "column": 4
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isIncomeUnknown"]], [], 0, null]],
          locals: [],
          templates: [child0]
        };
      })();
      var child7 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 125,
                      "column": 10
                    },
                    "end": {
                      "line": 127,
                      "column": 10
                    }
                  },
                  "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "budget/yeartotals-tablecell", [], ["value", ["subexpr", "@mut", [["get", "item.expensesUnknown"]], []], "month", ["subexpr", "@mut", [["get", "index"]], []], "year", ["subexpr", "@mut", [["get", "year"]], []]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 124,
                    "column": 8
                  },
                  "end": {
                    "line": 128,
                    "column": 8
                  }
                },
                "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
              },
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "isExpenses"]], [], 0, null]],
              locals: ["item", "index"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 121,
                  "column": 6
                },
                "end": {
                  "line": 130,
                  "column": 6
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "is-left");
              var el3 = dom.createTextNode("werkelijke uitgaven niet in budget");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
              return morphs;
            },
            statements: [["block", "each", [["get", "model"]], [], 0, null]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 120,
                "column": 4
              },
              "end": {
                "line": 131,
                "column": 4
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isExpensesUnknown"]], [], 0, null]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 0
            },
            "end": {
              "line": 135,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tbody");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          dom.setAttribute(el2, "style", "color: #808080;");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3, "class", "is-left");
          var el4 = dom.createTextNode("ingesteld budget");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          dom.setAttribute(el2, "class", "is-mainRow");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3, "class", "is-left is-bold");
          var el4 = dom.createTextNode("werkelijke ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" in budget");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3, "class", "is-left");
          var el4 = dom.createTextNode("verschil t.o.v. budget");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3, "class", "is-left");
          var el4 = dom.createTextNode("cumulatief verschil t.o.v. budget");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 3, 3);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
          morphs[2] = dom.createMorphAt(element1, 3, 3);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [5]), 3, 3);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [7]), 3, 3);
          morphs[5] = dom.createMorphAt(element0, 9, 9);
          morphs[6] = dom.createMorphAt(element0, 11, 11);
          return morphs;
        },
        statements: [["block", "each", [["get", "model"]], [], 0, null], ["block", "if", [["get", "isIncome"]], [], 1, 2], ["block", "each", [["get", "model"]], [], 3, null], ["block", "each", [["get", "model"]], [], 4, null], ["block", "each", [["get", "model"]], [], 5, null], ["block", "if", [["get", "isIncome"]], [], 6, null], ["block", "if", [["get", "isExpenses"]], [], 7, null]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 136,
            "column": 0
          }
        },
        "moduleName": "front-end-cli/pods/components/budget/yeartotals-table/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("thead");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("tr");
        dom.setAttribute(el2, "class", "is-headerRow");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("td");
        dom.setAttribute(el3, "class", "is-left");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("td");
        var el4 = dom.createTextNode("totaal");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(13);
        morphs[0] = dom.createMorphAt(element3, 3, 3);
        morphs[1] = dom.createMorphAt(element3, 5, 5);
        morphs[2] = dom.createMorphAt(element3, 7, 7);
        morphs[3] = dom.createMorphAt(element3, 9, 9);
        morphs[4] = dom.createMorphAt(element3, 11, 11);
        morphs[5] = dom.createMorphAt(element3, 13, 13);
        morphs[6] = dom.createMorphAt(element3, 15, 15);
        morphs[7] = dom.createMorphAt(element3, 17, 17);
        morphs[8] = dom.createMorphAt(element3, 19, 19);
        morphs[9] = dom.createMorphAt(element3, 21, 21);
        morphs[10] = dom.createMorphAt(element3, 23, 23);
        morphs[11] = dom.createMorphAt(element3, 25, 25);
        morphs[12] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "budget/yeartotals-tablecell", [], ["value", "jan", "month", 0, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["inline", "budget/yeartotals-tablecell", [], ["value", "feb", "month", 1, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["inline", "budget/yeartotals-tablecell", [], ["value", "mrt", "month", 2, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["inline", "budget/yeartotals-tablecell", [], ["value", "apr", "month", 3, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["inline", "budget/yeartotals-tablecell", [], ["value", "mei", "month", 4, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["inline", "budget/yeartotals-tablecell", [], ["value", "jun", "month", 5, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["inline", "budget/yeartotals-tablecell", [], ["value", "jul", "month", 6, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["inline", "budget/yeartotals-tablecell", [], ["value", "aug", "month", 7, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["inline", "budget/yeartotals-tablecell", [], ["value", "sep", "month", 8, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["inline", "budget/yeartotals-tablecell", [], ["value", "okt", "month", 9, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["inline", "budget/yeartotals-tablecell", [], ["value", "nov", "month", 10, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["inline", "budget/yeartotals-tablecell", [], ["value", "dec", "month", 11, "year", ["subexpr", "@mut", [["get", "year"]], []]]], ["block", "if", [["get", "isResults"]], [], 0, 1]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});