define('front-end-cli/pods/components/transaction-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['isActive', 'transaction.enabled:is-enabled:is-disabled', 'transaction.parentTransaction:is-child', 'transaction.willDisappear:will-disappear'],
    classNames: ['TransactionRow'],

    /**-------------------------
      External bindings
    ---------------------------*/

    transaction: null,
    editedTransaction: null,

    /**-------------------------
      Properties
    ---------------------------*/

    isActive: (function () {
      var hasEditingTransaction = this.get('editingTransaction'),
          isEditingTransaction;

      if (this.get('transaction.id') !== null) {
        isEditingTransaction = this.get('transaction.id') === this.get('editingTransaction.id');
      } else {
        isEditingTransaction = this.get('transaction') === this.get('editingTransaction');
      }

      return hasEditingTransaction && isEditingTransaction;
    }).property('editingTransaction'),

    hasGlyphicon: (function () {
      return this.get('transaction.receipt') || this.get('transaction.comment');
    }).property('transaction.receipt', 'transaction.comment'),

    /**-------------------------
      Events
    ---------------------------*/

    click: function click() {
      if (!this.get('transaction.parentTransaction')) {
        this.set('editingTransaction', this.get('transaction'));
      }
    },

    touchEnd: function touchEnd() {
      //this.set('editingTransaction', this.get('transaction'));
    }
  });
});