define('front-end-cli/helpers/profiler-row-help', ['exports', 'ember'], function (exports, _ember) {
  exports.profilerRowHelp = profilerRowHelp;

  var translations = {
    'pensioenen.aowbuitenland': 'Vul ‘ja’ in als je één of meer jaren in het buitenland hebt gewoond en gewerkt in de 50 jaar voordat je AOW ingaat. Voor ieder jaar dat je in het buitenland hebt gewoond, wordt de AOW-uitkering met 2% gekort. Heb je in het buitenland gewoond en toch AOW-rechten opgebouwd, laat die jaren dan buiten beschouwing.',
    'employed.grossannual': 'Vul hier het brutosalaris per jaar in, incl. vakantiegeld, eindejaarsuitkering en eventuele bonussen. Je kunt het jaarsalaris vinden op de laatste jaaropgaaf; vaak ook op de loonstrook. Als ‘ondernemer’ vul je hier de fiscale winst uit onderneming in. Zie hiervoor bijv. de belastingaangifte of het belastingaanslagformulier.',
    'personal.monthlyExpectedExpenses': 'Geef hier aan hoeveel je denkt dat je huishouden gemiddeld per maand uit gaat geven na pensionering. Denk hierbij aan woonlasten, kosten voor huishouden, medische kosten, verzekeringen, telecom, vervoer, kleding en vrije tijd. Het gaat om alle uitgavenposten samen. Kijk voor een indicatie van je huidige uitgaven bij het tabblad Transacties en/of Overzicht in je account. Bedenk welke posten van nu er straks misschien niet meer zijn, of dat er straks extra kosten zullen zijn die je nu niet hebt, bijvoorbeeld voor zorg. ',
    'retirementAge': 'Vul de leeftijd in waarop je het werknemers- of beroepspensioen voor het eerst gaat ontvangen.',
    'endAgeChoice': 'Geef aan of je het aanvullende pensioen tot je overlijden blijft ontvangen (=levenslang) of voor een beperkt aantal jaren (=tijdelijk). Als het tijdelijk is, vul dan ook in tot welke leeftijd je het pensioen blijft ontvangen.',
    'amountByRetirement': 'Dit is het bedrag dat je bruto per jaar gaat ontvangen van je (beroeps)pensioenfonds of -verzekeraar. Je kunt dit bedrag vinden op het Uniform Pensioenoverzicht (UPO), dat je regelmatig van je pensioenuitvoerder ontvangt. Of kijk voor een totaaloverzicht op www.mijnpensioenoverzicht.nl. Klik op het tabblad ‘Per uitvoerder’. Heb je bij meerdere (beroeps)pensioenfondsen of -verzekeraars een aanvullend pensioen opgebouwd? Dan kun je elk aanvullend pensioen apart toevoegen. Neem de te bereiken bedragen over achter ‘Pensioen’ en/of ‘Indicatief Pensioen’. Neem het bedrag van de AOW niet over! Daar houden wij in onze berekeningen al apart rekening mee.  ',
    // 'later.amountByRetirement': 'Dit is het bedrag dat je bruto per jaar gaat ontvangen van je (beroeps)pensioenfonds of -verzekeraar. Je kunt dit bedrag vinden op het Uniform Pensioenoverzicht (UPO), dat je regelmatig van je pensioenuitvoerder ontvangt. Of kijk voor een totaaloverzicht op <a href="http://www.mijnpensioenoverzicht.nl>mijnpensioenoverzicht</a>. Klik op het tabblad ‘Per uitvoerder’. Heb je bij meerdere (beroeps)pensioenfondsen of -verzekeraars een aanvullend pensioen opgebouwd? Dan kun je elk aanvullend pensioen apart toevoegen. Neem de te bereiken bedragen over achter ‘Pensioen’ en/of ‘Indicatief Pensioen’. Neem het bedrag van de AOW niet over! Daar houden wij in onze berekeningen al apart rekening mee.  ',
    'amountPrognosed': 'Vul hier het bedrag in dat op de aangegeven einddatum beschikbaar komt van je lijfrenteverzekering of lijfrentebankspaarrekening. Je vind dit op je polisblad of aanvullende informatie van de verzekeraar of op het overzicht of aanvullende informatie van de bank. Let op: Het gaat dus niet om het bedrag dat nu in je lijfrenteverzekering zit of op je lijfrentebankspaarrekening staat, maar wat op de einddatum beschikbaar komt.',
    'bankaccount.type': 'Geef aan om wat voor soort rekening het gaat: een betaalrekening, spaarrekening, beleggingsrekening of deposito. ',
    'bankaccount.balance': 'Vul hier in hoeveel euro momenteel op de rekening staat. Heb je meerdere bank- en/of spaarrekeningen? Vul dan voor iedere rekening afzonderlijk het saldo in.',
    'capital.yearAmount': 'Als je verwacht dat je meer vermogen (meer spaargeld) hebt als je met pensioen gaat dan dat je nu hebt, vul dan hier in hoeveel je extra denkt te hebben op dat moment. Extra vermogen zou kunnen komen uit andere bronnen zoals erfenissen, overwaarde bij verkoop van een woning, e.d. Vul dat hier ook in.',
    'later.labor': 'Als je na je pensionering nog van plan bent om te gaan werken, vul dan hier in hoeveel je daarmee verwacht te verdienen en tot welke leeftijd je van plan bent te werken. Ben je niet van plan te gaan werken na je pensionering, vul dan niets in.',
    'labor.employed.grossannual': 'Vul hier het brutobedrag per jaar in dat je verwacht te gaan verdienen na je pensionering.'
  };

  function profilerRowHelp(value, options) {
    var className = 'row-help';

    if (options && options.hash['class']) {
      className = _ember['default'].Handlebars.Utils.escapeExpression(className + ' ' + options.hash['class']);
    }

    var text = translations[value];
    if (!text) text = 'Missing translation: ' + value;

    var escaped = _ember['default'].Handlebars.Utils.escapeExpression(text);

    return new _ember['default'].Handlebars.SafeString('<span class="' + className + '"><i class="glyphicons glyphicons-circle-info" title="' + escaped + '">&nbsp;</i></span>');
  }

  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(profilerRowHelp);
});