define('front-end-cli/mixins/profiler/parse-report', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    renderProfiles: false,

    actions: {
      renderRapport: function renderRapport() {

        var profileHTML = '';
        var html = '';

        var $ = _ember['default'].$;

        this.set('renderProfiles', true);

        function generateRapport(HTML) {
          _ember['default'].$.ajax({
            type: 'POST',
            url: '/app/async/report.generate',
            data: {
              type: 'profile',
              email: 0,
              data: HTML
            }
          }).done(function (returndata) {
            // This link should resolve to http://mijn.afaspersonal.nl/app/download-report/xxxx
            window.location = '/app/download-report/' + returndata.data.id;
          });
        }

        function cleanProfileHTML(profileContent) {

          profileContent.find('section').each(function () {

            /**
              Remove empty sections
            */
            var filledInputCount = 0;
            _ember['default'].$(this).find('input').each(function () {
              if (_ember['default'].$(this).val()) filledInputCount++;
            });

            if (filledInputCount === 0) {
              _ember['default'].$(this).remove();
            }
          });

          profileContent.find('.plus-row').each(function () {
            // remove add buttons
            _ember['default'].$(this).parent().remove();
          });
          profileContent.find('select').remove();
          profileContent.find('script').remove(); // let's first throw away all those scripts

          profileContent.find('input').each(function () {
            // replace all inputs by their values
            if (_ember['default'].$(this).hasClass('ember-text-field')) {
              var value = _ember['default'].$(this).val();
              if (!value) {
                value = ' ';
              }
              _ember['default'].$(this).replaceWith(value);
            }
          });

          profileContent.find('.money').each(function () {
            var value = _ember['default'].$(this).find('.display').val();
            if (!value) value = '€ 0,00';
            _ember['default'].$(this).replaceWith(value);
          });

          profileContent.find('.custom-select').each(function () {
            // if(Ember.$(this).hasClass('date')) TODO: put in single span for better spacing
            var li = _ember['default'].$(this).parents('li');
            var content = !_ember['default'].$(this).hasClass('dim') ? _ember['default'].$(this).find('i').remove().end().text() : '';
            li.append(_ember['default'].$('<span>' + content + '</span>'));
            _ember['default'].$(this).parent('.select').remove();
          });

          profileContent.find('.custom-toggle').each(function () {
            var toggle = false;
            _ember['default'].$(this).find('span').each(function () {
              if (!_ember['default'].$(this).hasClass('dim')) {
                var value = _ember['default'].$(this).text();
                _ember['default'].$(this).parents('.toggle').replaceWith(value);
                toggle = true;
              }
            });
            // REMOVE INPUT WHEN UNSELECTED, should we remove the whole row?
            if (!toggle) _ember['default'].$(this).parent('.toggle').remove();
          });

          profileContent.find('div.ember-view, option.ember-view').each(function () {
            _ember['default'].$(this).find(':first-child').unwrap();
          });

          // remove some more!
          profileContent.find('div.ember-view, option.ember-view').remove();
          profileContent.find('button').remove();
          profileContent.find('.profiler-delete').remove();
          profileContent.find('i').remove();

          //For nice printing on separte pages
          profileContent.find('ul').each(function () {
            _ember['default'].$(this).css('page-break-inside', 'avoid');
          });

          profileContent.find('div').each(function () {
            // replace divs with spans
            _ember['default'].$(this).wrapInner('<span></span>');
            _ember['default'].$(this).children('span').unwrap();
          });

          profileContent.find('section').each(function () {
            var $diver = _ember['default'].$('<div></div>').css('page-break-inside', 'avoid');
            _ember['default'].$(this).wrapInner($diver);
            _ember['default'].$(this).children('div').unwrap();
          });

          profileContent.find('time').each(function () {
            _ember['default'].$(this).wrapInner('<span></span>');
            _ember['default'].$(this).children('span').unwrap();
          });

          return profileContent;
        }

        // next run loop
        _ember['default'].run.schedule('afterRender', this, function () {
          _ember['default'].$('#profile-pdf > .profiler').each(function () {
            html = cleanProfileHTML(_ember['default'].$(this).clone());
            if (html.html().trim().length > 0) {
              if (profileHTML.length > 0) profileHTML += '<br/><br/><br/>';
              profileHTML += html.html().trim();
            }
          });
          generateRapport(profileHTML);
          this.set('renderProfiles', false);
        });
      }
    }
  });
});