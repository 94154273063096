define("front-end-cli/templates/application/sidebaraccounts", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 8,
                "column": 4
              }
            },
            "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      Rekeningen\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 2
            }
          },
          "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "link-to", ["automatic-import.new-user-setup"], ["tagName", "h6"], 0, null]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 2
              },
              "end": {
                "line": 14,
                "column": 2
              }
            },
            "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    Rekeningen\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 2
            },
            "end": {
              "line": 15,
              "column": 2
            }
          },
          "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "link-to", ["accounts"], ["tagName", "h6"], 0, null]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 4
              },
              "end": {
                "line": 19,
                "column": 4
              }
            },
            "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fas fa-exclamation");
            dom.setAttribute(el2, "style", "color: #dc3545 !important; margin-left: -20px; margin-top: 10px;");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 2
            },
            "end": {
              "line": 20,
              "column": 2
            }
          },
          "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "currentUser.model.state.psd2NeverHadFeature"]], [], 0, null]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 45,
                      "column": 18
                    },
                    "end": {
                      "line": 47,
                      "column": 18
                    }
                  },
                  "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "glyphicons glyphicons-lock sidebaraccounts-lock");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("  \n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 44,
                    "column": 16
                  },
                  "end": {
                    "line": 48,
                    "column": 16
                  }
                },
                "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "unless", [["get", "currentUser.model.state.hasAccessToAllAccounts"]], [], 0, null]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 49,
                    "column": 16
                  },
                  "end": {
                    "line": 51,
                    "column": 16
                  }
                },
                "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "glyphicons glyphicons-refresh sidebaraccounts-lock");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 52,
                      "column": 18
                    },
                    "end": {
                      "line": 54,
                      "column": 18
                    }
                  },
                  "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "fas fa-exclamation text-danger sidebaraccounts-lock");
                  dom.setAttribute(el1, "style", "color: #dc3545 !important;");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 54,
                      "column": 18
                    },
                    "end": {
                      "line": 56,
                      "column": 18
                    }
                  },
                  "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "far fa-hand-paper");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 16
                  },
                  "end": {
                    "line": 57,
                    "column": 16
                  }
                },
                "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "bankaccount.bankConsentExpired"]], [], 0, 1]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 8
                },
                "end": {
                  "line": 63,
                  "column": 8
                }
              },
              "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "auto-account");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "name");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "balance");
              var el4 = dom.createElement("span");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1, 1]);
              var element4 = dom.childAt(element3, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element3, 'class');
              morphs[1] = dom.createMorphAt(element4, 1, 1);
              morphs[2] = dom.createMorphAt(element4, 2, 2);
              morphs[3] = dom.createMorphAt(element4, 4, 4);
              morphs[4] = dom.createMorphAt(dom.childAt(element3, [3, 0]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "bankaccount.activated"], "", "inactive"], []]]]], ["block", "if", [["get", "bankaccount.locked"]], [], 0, null], ["block", "if", [["get", "bankaccount.bankConsentActive"]], [], 1, 2], ["inline", "cap-account-name", [], ["bankaccount", ["subexpr", "@mut", [["get", "bankaccount"]], []], "cap", 16]], ["inline", "money-format", [["get", "bankaccount.balance"]], []]],
            locals: ["bankaccount"],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 4
              },
              "end": {
                "line": 65,
                "column": 4
              }
            },
            "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            var el2 = dom.createTextNode("\n         \n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            dom.setAttribute(el2, "class", "title");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element5, 3, 3);
            return morphs;
          },
          statements: [["content", "bankLoginTitle"], ["block", "each", [["get", "bankLogin.accounts"]], [], 0, null]],
          locals: ["bankLoginTitle"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 2
            },
            "end": {
              "line": 66,
              "column": 2
            }
          },
          "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "sidebaraccounts-modal-trigger", [], ["bankaccount", ["subexpr", "@mut", [["get", "bankLogin"]], []], "activeTrigger", ["subexpr", "@mut", [["get", "controller.activeTrigger"]], []], "currentPath", ["subexpr", "@mut", [["get", "controller.controllers.application.currentPath"]], []], "showModal", "showModal", "swapModal", "swapModal", "stopModal", "stopModal"], 0, null]],
        locals: ["bankLogin"],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 83,
                    "column": 12
                  },
                  "end": {
                    "line": 85,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "glyphicons glyphicons-lock sidebaraccounts-lock");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("  \n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 10
                },
                "end": {
                  "line": 86,
                  "column": 10
                }
              },
              "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "unless", [["get", "currentUser.model.state.hasAccessToAllAccounts"]], [], 0, null]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 10
                },
                "end": {
                  "line": 89,
                  "column": 10
                }
              },
              "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "glyphicons glyphicons-refresh sidebaraccounts-lock");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 90,
                    "column": 12
                  },
                  "end": {
                    "line": 92,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "fas fa-exclamation text-danger sidebaraccounts-lock");
                dom.setAttribute(el1, "style", "color: #dc3545 !important;");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 92,
                    "column": 12
                  },
                  "end": {
                    "line": 94,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "far fa-hand-paper");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 10
                },
                "end": {
                  "line": 95,
                  "column": 10
                }
              },
              "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "bankaccount.bankConsentExpired"]], [], 0, 1]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 98,
                  "column": 73
                },
                "end": {
                  "line": 98,
                  "column": 108
                }
              },
              "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("*");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 4
              },
              "end": {
                "line": 100,
                "column": 4
              }
            },
            "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "title");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "name");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "balance");
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [3]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element0, [3, 0]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createAttrMorph(element0, 'class');
            morphs[2] = dom.createMorphAt(element1, 1, 1);
            morphs[3] = dom.createMorphAt(element1, 2, 2);
            morphs[4] = dom.createMorphAt(element1, 4, 4);
            morphs[5] = dom.createMorphAt(element2, 0, 0);
            morphs[6] = dom.createMorphAt(element2, 2, 2);
            return morphs;
          },
          statements: [["content", "bankTitle"], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "bankaccount.activated"], "", "inactive"], []]]]], ["block", "if", [["get", "bankaccount.locked"]], [], 0, null], ["block", "if", [["get", "bankaccount.bankConsentActive"]], [], 1, 2], ["inline", "cap-account-name", [], ["bankaccount", ["subexpr", "@mut", [["get", "bankaccount"]], []], "cap", 16]], ["inline", "money-format", [["get", "bankaccount.balance"]], []], ["block", "if", [["get", "bankaccount.noBalanceAlert"]], [], 3, null]],
          locals: ["bankTitle"],
          templates: [child0, child1, child2, child3]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 68,
              "column": 2
            },
            "end": {
              "line": 102,
              "column": 2
            }
          },
          "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "sidebaraccounts-modal-trigger", [], ["bankaccount", ["subexpr", "@mut", [["get", "bankaccount"]], []], "activeTrigger", ["subexpr", "@mut", [["get", "activeTrigger"]], []], "currentPath", ["subexpr", "@mut", [["get", "controllers.application.currentPath"]], []], "currentAccountId", ["subexpr", "@mut", [["get", "currentAccountId"]], []], "showModal", "showModal", "swapModal", "swapModal", "stopModal", "stopModal"], 0, null]],
        locals: ["bankaccount"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 108,
            "column": 0
          }
        },
        "moduleName": "front-end-cli/templates/application/sidebaraccounts.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("header");
        dom.setAttribute(el1, "id", "accountsLink");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "glyphicons glyphicons-cogwheels");
        dom.setAttribute(el3, "style", "color: white !important");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("ul");
        dom.setAttribute(el1, "class", "account-list");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("footer");
        var el2 = dom.createTextNode("\n  Totaal ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "balanceSum");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [1]);
        var element7 = dom.childAt(fragment, [3]);
        var element8 = dom.childAt(fragment, [5, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element6, 1, 1);
        morphs[1] = dom.createMorphAt(element6, 2, 2);
        morphs[2] = dom.createMorphAt(element7, 1, 1);
        morphs[3] = dom.createMorphAt(element7, 3, 3);
        morphs[4] = dom.createElementMorph(element8);
        morphs[5] = dom.createMorphAt(element8, 0, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "currentUser.model.hasNoAccounts"]], [], 0, 1], ["block", "unless", [["get", "currentUser.model.state.psd2HasAccess"]], [], 2, null], ["block", "each", [["get", "bankLogins"]], [], 3, null], ["block", "each", [["get", "manualAccounts"]], [], 4, null], ["element", "action", ["transitionToTrends"], []], ["inline", "money-format", [["get", "balanceSum"]], []]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});