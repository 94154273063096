define('front-end-cli/pods/budget/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    queryParams: {
      month: { refreshModel: true },
      year: { refreshModel: true }
    },

    setupController: function setupController(controller, model) {

      _ember['default'].run.next(this, function () {
        this.get('container').lookup('controller:application').set('topBarHeightAdjustment', 72);
      });

      if (!controller.get('year')) {
        controller.set('year', moment().year());
      }
      if (!controller.get('month')) {
        controller.set('month', moment().format('MM'));
      }

      this._super(controller, model);
    },

    actions: {

      queryParamsDidChange: function queryParamsDidChange() {
        if (this.get('ranBefore')) {
          _ember['default'].run.next(this, 'refresh');
        }
      },

      didTransition: function didTransition() {
        if (!this.get('ranBefore')) {
          this.set('ranBefore', true);
        }
      },

      willTransition: function willTransition(transition) {
        if (!/budget\./.test(transition.targetName)) {
          _ember['default'].run.later(this, function () {
            // Wait to sync with rerender
            this.get('container').lookup('controller:application').set('topBarHeightAdjustment', null);
          }, 100);
        }
        this.set('ranBefore', false);
      },

      saveDirtyBudgets: function saveDirtyBudgets() {
        // Send save actions from the BudgetsHeader
        // to the matrix controller (which in turn will
        // pass them on to the matrixRoute).
        this.controller.get('budgetMatrix').send('saveDirtyBudgets');
      },

      restoreDirtyBudgets: function restoreDirtyBudgets() {
        // Send restore actions from the BudgetsHeader
        // to the matrix controller (which in turn will
        // pass them on to the matrixRoute).
        this.controller.get('budgetMatrix').send('restoreDirtyBudgets');
      }
    }
  });
});