define('front-end-cli/mixins/window-resize', ['exports', 'ember'], function (exports, _ember) {

  /**
    This will make the `window.resize` event trigger
    `windowResize` in the target view.
    usage:
    `windowResize: function() {}`
    or:
    `.on('windowResize')`
  */
  exports['default'] = _ember['default'].Mixin.create({

    addResizeObserver: (function () {
      var fn = (function () {
        this.trigger('windowResize');
      }).bind(this);
      _ember['default'].$(window).bind('resize', fn);
      // For unbinding the listener later
      this.set('_recomputeStyle', fn);
    }).on('init'),

    willDestroyElement: function willDestroyElement() {
      _ember['default'].$(window).unbind('resize', this.get('_recomputeStyle'));
    }
  });
});