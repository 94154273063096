define("front-end-cli/templates/accountmanagement/edit/recommended-svg", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 0
          }
        },
        "moduleName": "front-end-cli/templates/accountmanagement/edit/recommended-svg.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "id", "recommended-emblem");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "width", "85.666px");
        dom.setAttribute(el1, "height", "84.333px");
        dom.setAttribute(el1, "viewBox", "557.502 458.167 85.666 84.333");
        dom.setAttribute(el1, "enable-background", "new 557.502 458.167 85.666 84.333");
        dom.setAttributeNS(el1, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("polygon");
        dom.setAttribute(el2, "id", "emblem-shape");
        dom.setAttribute(el2, "fill", "#3E83C7");
        dom.setAttribute(el2, "points", "600.32,464.876 604.408,461.177 607.64,465.646 612.408,462.878 614.641,467.919\n  619.879,466.204 621.014,471.599 626.496,471.011 626.484,476.524 631.969,477.089 630.811,482.479 636.057,484.171\n  633.805,489.204 638.584,491.95 635.334,496.403 639.439,500.083 635.334,503.763 638.584,508.216 633.805,510.964 636.057,515.995\n  630.811,517.688 631.969,523.077 626.484,523.642 626.496,529.155 621.014,528.567 619.879,533.962 614.641,532.247\n  612.408,537.288 607.64,534.521 604.408,538.989 600.32,535.29 596.23,538.989 593,534.521 588.231,537.288 586,532.247\n  580.76,533.962 579.625,528.567 574.143,529.155 574.155,523.642 568.671,523.077 569.829,517.688 564.582,515.995 566.835,510.964\n  562.055,508.216 565.305,503.763 561.2,500.083 565.305,496.403 562.055,491.95 566.835,489.204 564.582,484.171 569.829,482.479\n  568.671,477.089 574.155,476.524 574.143,471.011 579.625,471.599 580.76,466.204 586,467.919 588.231,462.878 593,465.646\n  596.23,461.177 ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("text");
        dom.setAttribute(el2, "id", "emblem-text");
        dom.setAttribute(el2, "transform", "matrix(0.9472 -0.3207 0.3207 0.9472 571.8477 512.9922)");
        dom.setAttribute(el2, "fill", "#FFFFFF");
        dom.setAttribute(el2, "font-family", "'DINNextW01-Regular'");
        dom.setAttribute(el2, "font-size", "11.925");
        var el3 = dom.createTextNode("Aanbevolen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});