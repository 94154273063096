define('front-end-cli/controllers/whatif/retirement/wizard', ['exports', 'ember', 'front-end-cli/controllers/profiler'], function (exports, _ember, _frontEndCliControllersProfiler) {
  exports['default'] = _frontEndCliControllersProfiler['default'].extend({

    // NATIVE
    needs: ['application', 'whatif', 'whatif/retirement'],
    user: _ember['default'].computed.alias('controllers.application.model'),

    // PROPERTIES
    userOrPartner: 'user',
    step: 1,
    prevStep: null,
    stepLength: 6,
    updateModelTimer: null,

    stepOne: (function () {
      return this.get('step') === 1;
    }).property('step'),

    stepTwo: (function () {
      return this.get('step') === 2;
    }).property('step'),

    stepThree: (function () {
      return this.get('step') === 3;
    }).property('step'),

    stepFour: (function () {
      return this.get('step') === 4;
    }).property('step'),

    stepFive: (function () {
      return this.get('step') === 5;
    }).property('step'),

    stepSix: (function () {
      return this.get('step') === 6;
    }).property('step'),

    firstStep: (function () {
      return this.get('step') === 1;
    }).property('step'),

    lastStep: (function () {
      return this.get('step') === this.get('stepLength');
    }).property('step'),

    // METHODS
    actions: {

      showStep: function showStep(step) {
        this.set('prevStep', this.get('step') || 1);
        this.set('step', step);
      },

      nextStep: function nextStep() {

        // prevent going to nextstep when no required data, this prevents a flicker
        var expenses = this.get('currentUser.model.profile.personal.monthlyExpectedExpenses');
        if (!expenses || expenses <= 0) return false;

        var cur = this.get('step');
        var next = cur < this.get('stepLength') ? cur + 1 : cur;
        this.send('showStep', next);
      },

      previousStep: function previousStep() {
        var cur = this.get('step');
        var previous = cur > 1 ? cur - 1 : cur;
        this.send('showStep', previous);
      }
    },

    // profile: function() {
    //   if(this.get('userOrPartner') == 'user') {
    //     return this.get('model.profile');
    //   }
    //   else return this.get('model.lastObject');
    // }.property('userOrPartner'),

    // WE'LL USE MODAL LATER
    // bindTooltips: function() {
    //     setTimeout(function() {
    //         jQuery('.row-help > i')
    //         .tooltip({
    //             html:true,
    //             container: '.wizard',
    //             placement: 'right',
    //             // trigger: 'click'
    //         });
    //     }, 200);
    // }.observes('step', 'userOrPartner'),

    backToStepOne: (function () {
      if (!this.get('controllers.whatif/retirement.requiredData')) {
        this.send('showStep', 1);
      }
    }).observes('controllers.whatif/retirementrequiredData'),

    // `runLater` can be used to indicate the retirement
    // chart is being refreshed.
    isRefreshing: null,
    changedObserver: (function () {

      // UPDATE MODEL (timeout is for performance of the '+ animation')
      _ember['default'].run.cancel(this.get('isRefreshing'));

      var isRefreshing = _ember['default'].run.later(this, function () {
        this.get('controllers.whatif/retirement').send('updateModel');
        this.notifyPropertyChange('didChanged');
        this.set('isRefreshing', null);
      }, 1000);

      this.set('isRefreshing', isRefreshing);
    }).observes('didChange')
  });
});