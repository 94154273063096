define('front-end-cli/utils/accountmanagement/bank-choice', ['exports', 'ember'], function (exports, _ember) {

  /**
    Default bankdata object with attributes
  */
  var _BankData = _ember['default'].Object.extend({
    username: null,
    passNr: null,
    password: null,

    clearSpaces: (function () {
      // Loop over all keys in this object and replace spaces within their values
      _ember['default'].keys(this).forEach(function (key) {
        if (this.get(key)) {

          if (/^App\.(IngData|SnsData)$/.test(this.constructor) && key === 'password') {
            // Don't replace spaces in ing & sns passwords
            // (TODO: limit sns non-replacement to digicode only)
          } else {
              // Setters only trigger on an actual change,
              // therefore we don't need to first test for the existence of
              // spaces to prevent the 'triggering' from replacing the value
              // and putting the cursor at the end.
              this.set(key, this.get(key).replace(/ /g, ''));
            }
        }
      }, this);
    }).observes('username', 'passNr', 'password'),

    isUsername: (function () {
      return this.get('username') && /.{4,99}/.test(this.get('username'));
    }).property('username'),

    isPassNr: (function () {
      return this.get('passNr') && /.{3,99}/.test(this.get('passNr'));
    }).property('passNr'),

    isPassword: (function () {
      return this.get('password') && /.{4,99}/.test(this.get('password'));
    }).property('password'),

    isComplete: (function () {
      return this.get('isUsername') && this.get('isPassNr') && this.get('isPassword');
    }).property('isUsername', 'isPassNr', 'isPassword')

  });

  var _RaboData = _BankData.extend({
    isUsername: (function () {
      // IBAN
      var input = this.get('username') || '';
      var nr = input.toString().replace(/\ /g, '');
      if (input && /^NL\d{2}[A-Z]{4}\d{10}$|^BE\d{14}$/i.test(nr)) {
        return true;
      } else {
        return (/^\d{3,11}$/.test(nr.replace(/\./g, ''))
        );
      }
    }).property('username')

  });

  // Test
  /*username: '342417320',
  passNr: '3987',
  password: '',*/
  var _IngData = _BankData.extend({

    pacCodePart1: null,
    pacCodePart2: null,

    isComplete: (function () {
      return this.get('isUsername') && this.get('isPassword');
    }).property('isUsername', 'isPassword'),

    isPacCodePart1: (function () {
      return (/^[a-z]{3}$/i.test(this.get('pacCodePart1'))
      );
    }).property('pacCodePart1'),

    isPacCodePart2: (function () {
      return (/^[0-9]{3}$/.test(this.get('pacCodePart2'))
      );
    }).property('pacCodePart2'),

    isPacCode: (function () {
      return this.get('isPacCodePart1') && this.get('isPacCodePart2');
    }).property('isPacCodePart1', 'isPacCodePart2')
  });

  var _AbnData = _BankData.extend({
    challenge: null,

    isPassNr: (function () {
      return (/\d{3}/.test(this.get('passNr'))
      );
    }).property('passNr'),

    isUsername: (function () {
      // IBAN
      var input = this.get('username') || '';
      var nr = input.toString().replace(/\ /g, '');
      if (/^NL\d{2}[A-Z]{4}\d{10}$|^BE\d{14}$/.test(nr)) {
        return true;
      } else {
        return (/^\d{3,11}$/.test(nr.replace(/\./g, ''))
        );
      }
    }).property('username'),

    isTwoFactorComplete: (function () {
      return this.get('isUsername') && this.get('isPassNr');
    }).property('isUsername', 'isPassNr')

  });

  // Test loginCode
  /*username: '598904034',
  passNr: '441',*/
  var _SnsData = _BankData.extend({
    challenge: null,

    isComplete: (function () {
      return this.get('isUsername') && this.get('isPassword');
    }).property('isUsername', 'isPassword'),

    isChanllengeComplete: (function () {
      return this.get('isUsername');
    }).property('isUsername', 'isPassword')

  });

  // Test digicode
  /*username: '1529239056'*/
  exports['default'] = _ember['default'].Object.extend({

    bankSearchTerm: null,
    showPlusTrailConfirmation: null,
    showPlusTrailDenial: null,
    createdAccountsLength: null,
    banks: null,
    name: null,
    auto: null,
    loginMethod: null, //edentifier2

    syncType: (function () {
      if (this.get('auto') === true) return 'auto';else if (this.get('auto') === false) return 'manual';else return null;
    }).property('auto'),

    bank: (function () {
      // TODO!!!
      return this.get('account.bank');
    }).property('account'),

    // Reset loginMethod when previous bank choice is overwritten
    resetLoginMethod: (function () {
      this.set('loginMethod', '');
    }).observes('name'),

    // Bank specific credentials
    data: (function () {
      switch (this.get('name')) {
        case 'rabobank':
          return _RaboData.create();
        case 'ing':
          return _IngData.create();
        case 'abn':
          return _AbnData.create();
        case 'sns':
          return _SnsData.create();
        default:
          return null;
      }
    }).property('name'),

    isTwoFactor: (function () {
      return this.get('isAbnEdentifier1') || this.get('isSnsDigipass');
    }).property('isAbnEdentifier1', 'isSnsDigipass'),

    // TODO Generate the following properties using `Ember.defineProperty()`
    // (see `steps.js` for an example)

    // LARGE FOUR BANKS
    isAbn: _ember['default'].computed.match('name', /^abn$/),
    isAbnLogincode: _ember['default'].computed.equal('loginMethod', 3),
    isAbnEdentifier2: _ember['default'].computed.equal('loginMethod', 4),

    isIng: _ember['default'].computed.match('name', /^ing$/),
    isAbnEdentifier1: _ember['default'].computed.equal('loginMethod', 5),

    isRabobank: _ember['default'].computed.match('name', /^rabobank$/),

    isSns: _ember['default'].computed.match('name', /^sns$/),
    isSnsDigicode: _ember['default'].computed.equal('loginMethod', 6),
    isSnsDigipass: _ember['default'].computed.equal('loginMethod', 7),

    // MANUALLY SUPPORTED BANKS
    isAegon: _ember['default'].computed.match('name', /^aegon$/),
    isArgenta: _ember['default'].computed.match('name', /^argenta$/),
    isAsn: _ember['default'].computed.match('name', /^asn$/),
    isAxa: _ember['default'].computed.match('name', /^axa$/),
    isLloydsBank: _ember['default'].computed.match('name', /^lloydsbank$/),
    isBizner: _ember['default'].computed.match('name', /^bizner$/),
    isBelfius: _ember['default'].computed.match('name', /^belfius$/),
    isCrelan: _ember['default'].computed.match('name', /^crelan$/),
    isDeltalloyd: _ember['default'].computed.match('name', /^deltalloyd$/),
    isDhb: _ember['default'].computed.match('name', /^dhb$/),
    isFrieslandbank: _ember['default'].computed.match('name', /^friesland$/),
    isKbc: _ember['default'].computed.match('name', /^kbc$/),
    isKeytrade: _ember['default'].computed.match('name', /^keytrade$/),
    isKnab: _ember['default'].computed.match('name', /^knab$/),
    isLeaseplan: _ember['default'].computed.match('name', /^leaseplan$/),
    isMoneyou: _ember['default'].computed.match('name', /^moneyou$/),
    isNibc: _ember['default'].computed.match('name', /^nibc$/),
    isNs: _ember['default'].computed.match('name', /^ns$/),
    isOvchipkaart: _ember['default'].computed.match('name', /^ovchipkaart$/),
    isParibas: _ember['default'].computed.match('name', /^paribas$/),
    isPaypal: _ember['default'].computed.match('name', /^paypal$/),
    isPaysquare: _ember['default'].computed.match('name', /^paysquare$/),
    isRegiobank: _ember['default'].computed.match('name', /^regiobank$/),
    isTriodos: _ember['default'].computed.match('name', /^triodos$/),
    isVanlanschot: _ember['default'].computed.match('name', /^vanlanschot$/),

    // Match everything except "csvgeneriek"
    noManualBankGuide: _ember['default'].computed.match('name', /^((?!csvgeneriek).)*$/),

    isCSVGeneriek: _ember['default'].computed.match('name', /^csvgeneriek$/),

    // LEGACY FORMATS
    isDexia: _ember['default'].computed.equal('name', 'dexia'),
    isFortis: _ember['default'].computed.equal('name', 'fortis'),
    isDsb: _ember['default'].computed.equal('name', 'dsb')
  });
});