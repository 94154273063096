define('front-end-cli/controllers/whatif/retirement/event', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    isCapitalEvent: (function () {
      return this.get('model.type') == 1;
    }).property('model.type'),

    isBalanceEvent: (function () {
      return this.get('model.type') == 2;
    }).property('model.type'),

    isBrokeEvent: (function () {
      return this.get('model.type') == 3;
    }).property('model.type'),

    isDeficit: (function () {
      return this.get('model.description') == 'Tekort';
    }).property('model.description')
  });
});