define('front-end-cli/pods/components/nibud/search-filter/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'section',

    /* MidasCloud Api Variables */
    title: null,
    filters: null,
    calculate: null,
    beforeResult: null,

    /* Page Variables */
    budgetIsSelected: false,

    netIncomeValidation: (function () {
      var _this = this;

      var netIncomeInput = document.getElementById('NetIncome');
      if (netIncomeInput) {
        (function () {
          var min = parseInt(netIncomeInput.attributes.min.nodeValue, 10),
              max = parseInt(netIncomeInput.attributes.max.nodeValue, 10);
          netIncomeInput.addEventListener('keyup', function (e) {
            netIncomeInput.value = netIncomeInput.value.replace(/[^\d]/g, '');
            if (parseInt(netIncomeInput.value, 10) < min) {
              netIncomeInput.value = min;
            } else if (parseInt(netIncomeInput.value, 10) > max) {
              netIncomeInput.value = max;
            }
          });
          netIncomeInput.addEventListener('blur', function (e) {
            var netIncomeValue = parseInt(netIncomeInput.value, 10);
            if (isNaN(netIncomeValue) || netIncomeValue < 0) {
              netIncomeInput.value = _this.get('filters').netIncome.value;
            }
          });
        })();
      }
    }).on('didInsertElement'),

    actions: {
      sendSearchFilters: function sendSearchFilters() {
        this.sendAction('action');
      },
      displayOrHideYearDisplay: function displayOrHideYearDisplay() {
        var budgetInput = document.getElementById('Budget');

        if (budgetInput) {
          if (budgetInput.checked) {
            this.set('budgetIsSelected', true);
          } else {
            this.set('budgetIsSelected', false);
          }
        }
      }
    }
  });
});