define('front-end-cli/models/misc-life-insurance', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    miscInsurance: _emberData['default'].belongsTo('misc-insurance'),

    insurerName: _emberData['default'].attr('string'),
    insuranceNumber: _emberData['default'].attr('string'),
    contractor: _emberData['default'].attr('string'),
    insured: _emberData['default'].attr('string'),
    beneficiary: _emberData['default'].attr('string'),
    beginDate: _emberData['default'].attr('string'),
    endDate: _emberData['default'].attr('string'),
    singleOrPeriodic: _emberData['default'].attr('number'), //Eenmalig (koopsom) of Periodieke premie
    periodicAmount: _emberData['default'].attr('number'),
    periodicInterval: _emberData['default'].attr('number'),
    deposit: _emberData['default'].attr('number'), //Eenmalige inleg
    lifeInsurancePaymentType: _emberData['default'].attr('number'), //   Uitkering gelijkblijvend of dalend?
    descendingLifeInsurancePaymentType: _emberData['default'].attr('number'), //   Annuiteit of lineair
    paymentOnDecease: _emberData['default'].attr('number'),
    premiumExcemption: _emberData['default'].attr('number'),
    pledge: _emberData['default'].attr('number'),
    pledgee: _emberData['default'].attr('string'),

    isDescendingPayment: (function () {
      var type = this.get('lifeInsurancePaymentType');
      return type == 2;
    }).property('lifeInsurancePaymentType'),

    isPledged: (function () {
      var type = this.get('pledge');
      return type == 1;
    }).property('pledge'),

    isPeriodic: (function () {
      var type = this.get('singleOrPeriodic');
      return type == 2;
    }).property('singleOrPeriodic')
  });
});