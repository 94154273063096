define('front-end-cli/views/profiler/label-select-input', ['exports', 'ember', 'front-end-cli/mixins/profiler/changed-state'], function (exports, _ember, _frontEndCliMixinsProfilerChangedState) {
  exports['default'] = _ember['default'].View.extend(_frontEndCliMixinsProfilerChangedState['default'], {
    tagName: 'div',
    classNames: ['select'],
    templateName: 'profiler/mixin/select',
    selectedLabel: '',
    value: null,
    prompt: 'kies',
    attributeBindings: ['style', 'data-test'],

    style: (function () {
      return ('width:' + this.get('width')).htmlSafe();
    }).property('width'),

    valueObserver: (function () {
      this.updateLabel();
    }).observes('value'),

    setFocusClass: (function () {
      this.$().addClass('focus');
    }).on('focusIn'),

    unFocus: (function () {
      this.$().removeClass('focus');
    }).on('focusOut'),

    storePrompt: (function () {
      this.set('originalPrompt', this.get('prompt'));
    }).on('init'),

    didInsertElement: function didInsertElement() {

      // MAKE SURE CHANGE FIRES WHEN USING KEYBOARD NAVIGATION (firefox)
      this.$().find('select').on('keydown', function () {
        _ember['default'].$(this).change();
      });

      // SET RIGHT VALUE ON LOAD
      this.updateLabel();
    },

    updateLabel: (function () {

      var nr = this.get('value') * 1;

      this.$().find('option').prop('disabled', false);
      // UNDEFINED SELECTS USE PROMPT FOR PLACEHOLDING
      if (!nr) {

        // DEFAULT PROMPT MESSAGE
        if (!this.get('originalPrompt')) {
          this.set('prompt', 'kies');
        }
        // DEFINED PROMPT MESSAGE
        else {
            this.set('prompt', this.get('originalPrompt'));
          }

        setTimeout((function () {

          if (!this.$()) return;

          this.$().find('option').prop('selected', false).eq(0).prop('disabled', true).prop('selected', true);
        }).bind(this), 50);

        // SET CUSTOM LABEL
        this.set('selectedLabel', this.get('prompt'));

        // ADD DIM CLASS
        this.$().find('.custom-select').addClass('dim');
      } else {

        // REMOVE PROMPT OPTION IF ANY
        this.set('prompt', '');

        var labelPath = this.get('optionLabelPath');
        labelPath = labelPath.replace('content.', '');

        // SET CUSTOM LABEL
        var selectedObject = this.get('content').objectAt(nr - 1);
        if (typeof selectedObject != 'undefined') {
          var selectedLabel = selectedObject.get(labelPath);
          this.set('selectedLabel', selectedLabel);
        }

        // ENSURE DIM CLASS IS REMOVED
        this.$().find('.custom-select').removeClass('dim');
      }
    }).observes('value').on('change')
  });
});