define('front-end-cli/pods/components/budget/index-subcat/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['BudgetIndexSubcat'],

    // External Bindings
    subcategory: null,
    selectedCategory: null,

    // Properties
    isActive: _ember['default'].computed('selectedCategory', function () {
      return this.get('selectedCategory') == this.get('subcategory');
    }),

    isWarning: _ember['default'].computed('subcategory.progress', 'subcategory.categoryType', function () {
      var prog = this.get('subcategory.progress'),
          isExpenses = this.get('subcategory.categoryType') === 'expenses';

      return isExpenses && prog > 100 && prog <= 115;
    }),

    isDanger: _ember['default'].computed('subcategory.progress', 'subcategory.categoryType', function () {
      var prog = this.get('subcategory.progress'),
          isExpenses = this.get('subcategory.categoryType') === 'expenses';

      return isExpenses && prog > 115;
    }),

    // Events
    click: function click() {
      this.set('selectedCategory', this.get('subcategory'));
    }
  });
});