define('front-end-cli/pods/components/nibud/action-renderer/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    /* MidasCloud Api Variables */
    item: null,
    isNibudCategory: false,
    updateCategory: null,
    icons: {},
    bindBlurEvent: true,
    actions: {
      toggleEditing: function toggleEditing() {
        var _this2 = this;

        var element = this.get('item'),
            selectorId = 'Nibud-CategorySelector_' + element.id,
            selectorElement = document.getElementById(selectorId);

        // Allow toggle off
        if (!selectorElement.classList.contains('selecting')) {
          this._hideAllSelectors();
          selectorElement.classList.add('selecting');
          selectorElement.focus();
          // Bind only once
          if (this.get('bindBlurEvent')) {
            (function () {
              var _this = _this2;
              _ember['default'].$(selectorElement).on('focusout', function () {
                _this.set('bindBlurEvent', false);
                _ember['default'].$(this).removeClass('selecting');
              });
            })();
          }
        } else {
          this._hideAllSelectors();
        }
      },
      categorySelected: function categorySelected(item, subcategoryId, nibudCategoryId) {
        if (item.action.status == "OpenForEdit") {
          this._hideAllSelectors();
          if (this.get('isNibudCategory')) {
            // invert parameter order
            this.sendAction('updateCategory', nibudCategoryId, subcategoryId);
          } else {
            this.sendAction('updateCategory', subcategoryId, nibudCategoryId);
          }
        }
      },
      closeSelector: function closeSelector() {
        this._hideAllSelectors();
      },
      unlinkElement: function unlinkElement(item, nibudCategoryId) {
        if (item['delete'].status == "OpenForEdit") {
          this.sendAction('updateCategory', null, nibudCategoryId);
        }
      }
    },
    _hideAllSelectors: function _hideAllSelectors() {
      var selectorCollection = document.getElementsByClassName('Nibud-CategorySelector');
      for (var i = 0; i < selectorCollection.length; i++) {
        selectorCollection[i].classList.remove('selecting');
      }
    }

  });
});