define('front-end-cli/models/categorybudget', ['exports', 'ember-data', 'ember', 'front-end-cli/models/base-category'], function (exports, _emberData, _ember, _frontEndCliModelsBaseCategory) {
  exports['default'] = _frontEndCliModelsBaseCategory['default'].extend({
    subcategorybudgets: _emberData['default'].hasMany('subcategorybudget'),
    budgets: _emberData['default'].attr('pojo'),
    isGenerated: _emberData['default'].attr('boolean'),
    year: _emberData['default'].attr('number'),
    enabled: _emberData['default'].attr('boolean'),

    /**
      1. if no cache, create  cache
      2. else if budgets differ from cache, return dirty
       CLEAR CACHE ON SAVE!
    */
    budgetsAreDirty: _ember['default'].computed('budgets.@each.amount', 'budgetsCache', function () {

      var isDirty = false;

      var budgetsCache = this.get('budgetsCache');
      var budgets = this.get('budgets');

      var invalidateBudgetCache = this.get('invalidateBudgetCache');

      if (invalidateBudgetCache) {
        budgetsCache = false;
        this.set('invalidateBudgetCache', null);
      }

      if (!budgetsCache) {
        budgetsCache = _ember['default'].$.extend(true, [], budgets);
        this.set('budgetsCache', budgetsCache);
      } else {
        budgets.forEach(function (b, i) {
          var cache = budgetsCache[i];

          if (b.amount != cache.amount) {
            b.isDirty = true;
            isDirty = true;
          } else {
            b.isDirty = false;
          }
        });
      }
      return isDirty;
    }),

    hasDirtySubBudgets: _ember['default'].computed('subcategorybudgets.@each.budgetsAreDirty', function () {
      var _this = this;

      var hasDirtySubBudgets = false,
          hasBeenReverted = false;

      this.get('subcategorybudgets').forEach(function (bud) {

        if (bud.get('budgetsAreDirty')) hasDirtySubBudgets = true;

        // Whenever subcategory-budgets changed
        // and has budgets, we’ll revert any changes
        // to the category-budgets.
        if (_this.get('budgetsAreDirty') && !hasBeenReverted && hasDirtySubBudgets && bud.get('budgetsSum')) {

          if (_this.get('budgets') && _this.get('budgetsCache')) {
            _this.set('budgets', _this.get('budgetsCache'));
          }
          hasBeenReverted = true;
        }
      });
      return hasDirtySubBudgets;
    }),

    budgetsSum: _ember['default'].computed('budgets.@each.amount', 'subcategorybudgets.@each.budgetsSum', function () {
      var total = 0;
      if (this.get('hasSubBudgets')) {
        this.get('calculatedSubBudgets').forEach(function (budget) {
          total += budget.amount * 1;
        });
      }

      //No longer possible to set budgets on categories so we can remove it being calculated
      // else {
      //   this.get('budgets').forEach(budget => {
      //     total += budget.amount * 1;
      //   });
      // }
      //

      return total;
    }),

    calculatedSubBudgets: _ember['default'].computed('subcategorybudgets.@each.budgetsSum', function () {

      var totals = [];

      this.get('subcategorybudgets').forEach(function (scb) {
        scb.get('budgets').forEach(function (b, i) {
          if (!totals[i]) totals[i] = { amount: 0 };
          totals[i].amount += b.amount * 1;
        });
      });

      return totals;
    }),

    /**
      Whenever a category has subcategory-budgets,
      we’ll use a computed value in stead of the `budget.amount`.
       This makes sure the shown value is always the sum of
      subcategorybudget amounts.
    */
    hasSubBudgets: _ember['default'].computed('subcategorybudgets.@each.budgetsSum', function () {

      var hasSubBudgets = false;

      this.get('subcategorybudgets').forEach(function (scb) {
        if (!hasSubBudgets && scb.get('budgetsSum')) hasSubBudgets = true;
      });

      // If the category had subBudgets, but not anymore,
      // we’ll clear all its budgets.
      var hadSubBudgets = this.get('hadSubBudgets');
      if (hadSubBudgets && !hasSubBudgets) {
        this.get('budgets').forEach(function (b) {
          // Somehow setting amount here threw
          // a mandatory setter error, as did `.set()`.
          // To overcome this we’ve set the
          // `'mandatory-setter': false` FEATURE FLAG
          b.amount = 0;
        });
      }

      if (hadSubBudgets != hasSubBudgets) {
        this.set('hadSubBudgets', hasSubBudgets);
      }

      return hasSubBudgets;
    })
  });
});