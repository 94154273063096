define('front-end-cli/routes/legacy', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect(model, transition) {
      // Part of the overview eradication
      if (/overview|legacy\.index/.test(transition.targetName)) {
        this.transitionTo('timeline');
      }
    },

    renderTemplate: function renderTemplate() {
      this.render('legacy');
    }
  });

  // App.LegacyRoute = Ember.Route.extend({
  //   renderTemplate: function() {
  //     this.render('application-legacy');
  //   }
  // });

  // App.AccountRoute = Ember.Route.extend({
  //   beforeModel: function(transition) {

  //     // Hide modal on any account transition
  //     transition.send('hideModal', true);
  //   }
  // });

  // App.SettingsRoute = App.AccountRoute.extend();
  // App.ControlRoute = App.AccountRoute.extend();
  // App.PlusRoute = App.AccountRoute.extend();
  // App.ProfileRoute = App.AccountRoute.extend();
});