define('front-end-cli/helpers/cap-account-name', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.helper(function (value, hash) {
    // TODO: Measure text width

    // var maxPixelWidth;
    // var textSpan = Ember.$('<span/>', { "class": 'sidebar-account-name', text: value });
    // Ember.$('body').append(
    //   textSpan
    // });

    if (!hash.bankaccount) {
      return '';
    }

    var account = hash.bankaccount,
        name = account.get('description') || account.get('accountNumber'),
        amount = account.get('balance'),
        maxLength = hash.cap || 20,
        formattedName,
        amountLength;
    /*availableAmount,
    spacesLeft = name.length - account.balance;*/

    if (amount != null) {
      amountLength = (amount * 1).toFixed(2).toString().length;
    } else {
      amountLength = 3;
    }

    if (name && name.length + amountLength > maxLength) {

      // available space minus balance
      maxLength = maxLength - amountLength;

      if (/^(NL|BE).*\d{8}$/i.test(name)) {
        // Since numbers are generally wider then letters
        // BE91ABNA0417164300 into BE91...164300
        var correction = 4;
        var rgx = new RegExp('^(NL|BE)(\\d{2}).*(\\d{' + (maxLength - correction) + '})$', 'i');
        //formattedName = name.substring(name.length - maxLength);
        formattedName = name.replace(/^(NL|BE)(\d{2}).*(\d{8})$/i, '$1$2...$3');
        formattedName = name.replace(rgx, '$1$2...$3');
      } else {
        if (/^\d+$/.test(name)) // check if name has only digits, if so cut left else cut right
          {
            formattedName = '..' + name.substring(name.length - maxLength);
          } else {
          formattedName = name.substring(0, maxLength + 2) + '..';
        }
      }
    } else {
      formattedName = name;
    }
    return formattedName;
  });
});