define('front-end-cli/services/session-manager', ['exports', 'ember'], function (exports, _ember) {

  /**
    A service which manages the server's session timeout,
    so it play’s nice with the client's user experience.
  */
  exports['default'] = _ember['default'].Service.extend({
    timer: null,
    isKeepAlive: null,
    appRoute: null,
    // When there is no active session, `isRunning` is
    // false and we'll ignore any keepAlive calls.
    isRunning: null,

    /**
      Go to the server to check if there
      still is an active session.
       If there is an active session, it's timeout can
      optionally be reset via the `keepAlive` param.
       The server responds with either:
      - A hash with a `session_expires` property
        containing the expiration timestamp.
          - A new timer is set which will check the session
            right after the server said it would time out.
      - A 401 error with an emptymessage body.
          - The app wil be reloaded.
      @private
    */
    _checkSession: function _checkSession() {
      var _this = this;

      var data = {};
      if (this.get('isKeepAlive')) {
        data.keepAlive = true;
      }

      _ember['default'].$.ajax({
        url: '/api/v3/session',
        type: 'get',
        data: data
      }).then(function (response) {
        if (response['session_expires']) {
          _this.set('isKeepAlive', false);
          var msLeft = moment(response['session_expires']).diff(moment());
          _this._setCheckSessionTimer(msLeft + 1100);
        } else {
          _this.set('isRunning', false);
          window.location.replace('https://www.mijngeldzaken.nl/account/logout');
          // let appRoute = this.get('appRoute');
          // appRoute.transitionTo('legacy.login');
        }
      }, function (response) {

        _this.set('isRunning', false);

        if (response.status === 401) {
          var appRoute = _this.get('appRoute');
          var currentPath = appRoute.controllerFor('application').get('currentPath');

          if (currentPath !== 'lecagy.login') {
            window.location.replace('https://www.mijngeldzaken.nl/account/logout');
          }
        }
      });
    },

    /**
      Set a new timer for checking
      the session's livelyhood.
      @private
    */
    _setCheckSessionTimer: function _setCheckSessionTimer(ms) {
      this._cancelCheckSessionTimer();

      var timeout = ms || 1;
      this.set('timer', _ember['default'].run.later(this, this._checkSession, ms));
    },

    /**
      Cancel any existing timers
      @private
    */
    _cancelCheckSessionTimer: function _cancelCheckSessionTimer() {
      if (this.get('timer')) {
        _ember['default'].run.cancel(this.get('timer'));
      }
      if (this.get('keepAliveTimer')) {
        _ember['default'].run.cancel(this.get('keepAliveTimer'));
      }
    },

    /**
      Start or re-start watching the session timeout.
      @public
    */
    initialize: function initialize() {
      this.set('isRunning', true);
      this._setCheckSessionTimer();
    },

    /**
      Ask the server to reset the session-timeout
      for the current session.
       Subsequent calls within the throttle-time
      will be ignored for performance reasons.
       @public
    */
    keepAlive: function keepAlive() {
      if (this.get('isRunning')) {
        this.set('isKeepAlive', true);
        var thottleTime = 1000 * 60 * 1; // 1 mins
        this.set('keepAliveTimer', _ember['default'].run.throttle(this, this._setCheckSessionTimer, thottleTime));
      }
    }
  });
});