define('front-end-cli/pods/components/transaction-details/categories/categories-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'transaction-detail-row',
    classNames: ['TransactionDetails-row', 'CategoriesRow'],
    classNameBindings: ['isEditing'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    isEditing: null,
    transaction: null,

    // Actions
    toEditDetail: null,

    /**-------------------------
      Properties
    ---------------------------*/

    /**-------------------------
      Events
    ---------------------------*/

    click: function click() {
      if (this.get('isEditing')) {
        this.sendAction('toEditDetail', 'categories/categories-pane');
      }
    }

  });
});