define('front-end-cli/mixins/steps', ['exports', 'ember'], function (exports, _ember) {
  /**
    Mixing to add step functionality to a controller,
    Combine with ...
  */
  exports['default'] = _ember['default'].Mixin.create({

    // Properties which are set in receiving class
    // step:, previousSteps: []

    // Nééds to be defined in receiving class
    // stepLength:

    previousSteps: [],

    /**
      Generate a certain amount of step-properties on init.
      These properties are used in templates to control the
      rendering of steps within step-based interaction.
       `count` is the maximum number steps for all instances
      of this mixin.
    */

    defineSteps: (function () {
      var count = 20;
      while (count > 0) {
        /* jshint ignore:start */
        _ember['default'].defineProperty(this, 'step' + count, (function (name) {
          return 'step' + this.get('step') === name;
        }).property('step'));
        count--;
        /* jshint ignore:end */
      }
    }).on('init'),

    /**
      Array containing steps for handlebars each helper
    */
    steps: (function () {
      var steps = [],
          stepLength = this.get('stepLength'),
          i = 1;
      while (i < stepLength + 1) {
        steps.push(i);i++;
      }
      return steps;
    }).property('stepLength'),

    lastStep: (function () {
      return this.get('step') * 1 === this.get('stepLength');
    }).property('step'),

    actions: {
      /**
        All step-setting should be done through this action,
        in order to keep track of previous steps.
      */
      showStep: function showStep(step) {
        // are we going back?
        if (step < this.get('step')) {
          var length = this.get('previousSteps.length') - 1;

          /**
            Wait for `didInsertElement` hook in steps_view to fire
            before slicing the previousSteps Array.
          */
          _ember['default'].run.later(this, function () {
            if (this.get('step')) {
              this.set('previousSteps', this.get('previousSteps').slice(0, length));
            }
          }, 100);
        } else if (this.get('step') && step > this.get('step')) {
          this.get('previousSteps').pushObject(this.get('step'));
        }

        this.set('step', step);
      },
      showNextStep: function showNextStep() {
        var cur = this.get('step');
        var next = cur < this.get('stepLength') ? cur + 1 : cur;
        this.send('showStep', next);
      },
      showPreviousStep: function showPreviousStep(absolute) {
        if (absolute) {
          var cur = this.get('step');
          var previous = cur > 1 ? cur - 1 : cur;
          this.send('showStep', previous);
        } else if (this.get('previousSteps.length')) {
          var p = this.get('previousSteps.lastObject');
          this.send('showStep', p);
        }
      }
    }
  });
});