define('front-end-cli/models/financial-means-money', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {

  _ember['default'].Inflector.inflector.irregular('financialMeansMoney', 'financialMeansMoneys');
  _ember['default'].Inflector.inflector.rules.uncountable['money'] = false;

  exports['default'] = _emberData['default'].Model.extend({
    profile: _emberData['default'].belongsTo('profile'),

    type: _emberData['default'].attr('number'),
    provider: _emberData['default'].attr('string'), //bankname
    accountnumber: _emberData['default'].attr('string'),
    balance: _emberData['default'].attr('number'),
    interest: _emberData['default'].attr('number'),
    endDate: _emberData['default'].attr('string'),

    showInterestAttribute: (function () {
      var type = this.get('type');
      return type == 2 || type == 4;
    }).property('type'),

    showEndDateAttribute: (function () {
      return this.get('type') == 4;
    }).property('type')
  });
});