define('front-end-cli/controllers/accountmanagement/edit/manual', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    needs: ['application'],
    user: _ember['default'].computed.alias('controllers.application.model'),
    bankChoice: _ember['default'].computed.alias('parentController.bankChoice'),
    error: _ember['default'].computed.alias('parentController.error'),

    noSelectedAccounts: (function () {
      return !this.get('parentController.hasSelectedAccounts');
    }).property('parentController.hasSelectedAccounts'),

    actions: {

      /**
        When we've got pending balance-benchmarks,
        and new transactions, we'll go to step 6,
        else we'll go to step 7.
      */
      advanceToNextStep: function advanceToNextStep() {

        var requestBalance = false;

        _ember['default'].run.next(this, function () {

          this.get('bankChoice.availableAccounts').forEach(function (a) {

            if (a.get('transcount') !== 0) {

              var accountId = a.get('accountNumber').toString(),
                  account = this.get('currentUser.model.accounts').findBy('accountNumber', accountId);

              _ember['default'].assert('The account (' + a.get('accountNumber') + ') was not found in the store', account);

              if (!account.get('isCashAccount') && account.get('requestBalanceBenchmark')) {
                requestBalance = true;
              }
            }
          }, this);

          this.send('showStep', requestBalance ? 6 : 7);
        });
      }
    }
  });
});