define('front-end-cli/pods/components/budget/index-cat/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['BudgetIndexCat'],

    // External Bindings
    category: null, // Can be cat or subcat!
    selectedCategory: null,
    isDisabled: null,

    // Properties
    showSubcategories: null,

    isActive: _ember['default'].computed('selectedCategory', function () {
      return this.get('selectedCategory') == this.get('category');
    }),

    isWarning: _ember['default'].computed('category.progress', 'category.categoryType', function () {
      var prog = this.get('category.progress'),
          isExpenses = this.get('category.categoryType') === 'expenses';

      return isExpenses && prog > 100 && prog <= 115;
    }),

    isDanger: _ember['default'].computed('category.progress', 'category.categoryType', function () {
      var prog = this.get('category.progress'),
          isExpenses = this.get('category.categoryType') === 'expenses';

      return isExpenses && prog > 115;
    }),

    sortedSubcategories: _ember['default'].computed('category.subcategories', function () {
      var subcategories = this.get('category.subcategories').filter(function (sub) {
        if (sub.get('expectedNetted')) {
          return sub;
        }
      });

      var sorted = subcategories.sortBy('progress'),
          sortedsubs = undefined;

      if (this.get('category.expectedNetted') < 0) {
        sortedsubs = sorted.reverse();
      } else {
        sortedsubs = sorted;
      }

      return sortedsubs;
    }),

    sortedSubcategoriesNoBudget: _ember['default'].computed('category.subcategories', function () {

      var subcategories = this.get('category.subcategories').filter(function (sub) {
        if (!sub.get('expectedNetted') && sub.get('currentNetted') !== null) {
          return sub;
        }
      });

      var sorted = subcategories.sortBy('progress');
      return sorted;
    }),

    actions: {

      selectCategory: function selectCategory() {
        this.set('selectedCategory', this.get('category'));
      },

      toggleSubCategories: function toggleSubCategories() {
        if (this.get('category.subcategories')) {
          this.toggleProperty('showSubcategories');
        }
      }
    }
  });
});