define('front-end-cli/models/account', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    user: _emberData['default'].belongsTo('user'),
    bank: _emberData['default'].belongsTo('bank', { async: true }),
    // name                       : DS.attr('string'),
    description: _emberData['default'].attr('string'),
    image: _emberData['default'].attr('string'), // kill
    balance: _emberData['default'].attr('number'),
    lastupdate: _emberData['default'].attr('string'),
    archived: _emberData['default'].attr('boolean', { defaultValue: false }),
    locked: _emberData['default'].attr(),
    activated: _emberData['default'].attr('boolean', { defaultValue: true }),
    mobileWallet: _emberData['default'].attr('boolean', { defaultValue: false }),
    orderid: _emberData['default'].attr('number'),
    guestMode: _emberData['default'].attr('boolean'),

    bankLogin: _emberData['default'].belongsTo('bank-login'),
    accountNumber: _emberData['default'].attr('string'),
    holder: _emberData['default'].attr('string'),
    syncType: _emberData['default'].attr('string'),

    requestBalanceBenchmark: _emberData['default'].attr('boolean'),
    latestTransactions: _emberData['default'].attr(),
    requestBalanceBenchmarkType: _emberData['default'].attr('string'),
    noBalanceAlert: _emberData['default'].attr('boolean'),
    showInChart: _emberData['default'].attr('boolean', { defaultValue: true }),

    consentStatus: _emberData['default'].attr('string'),
    importSetting: _emberData['default'].attr('number'),
    hasNoTransactions: _emberData['default'].attr('boolean'),
    isFirstTimePsd2Import: _emberData['default'].attr('boolean'),

    firstTransactionDate: _emberData['default'].attr('date'),
    firstBudgetDate: _emberData['default'].attr('date'),

    isCashAccount: (function () {
      return this.get('bank.id') == 22;
    }).property('bank'),

    //Bank consent booleans

    bankConsentActive: (function () {
      return this.get('consentStatus') === 'Active';
    }).property('account'),

    bankConsentExpired: (function () {
      return this.get('consentStatus') === 'Expired';
    }).property('account'),

    bankConsentManual: (function () {
      return this.get('consentStatus') === 'Inactive';
    }).property('account'),

    bankConsentPreFeature: (function () {
      return this.get('consentStatus') === null;
    }).property('account'),

    //Import setting boolean

    hasImportSetting: (function () {
      return this.get('importSetting') !== null;
    }).property('account'),

    needsToUpdateImportSettings: (function () {
      return !this.get('hasNoTransactions') && this.get('importSetting') === null && this.get('isFirstTimePsd2Import');
    }).property('account')

  });
});