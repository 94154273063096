define('front-end-cli/initializers/fastclick', ['exports', 'ember-cli-fastclick/initializers/fastclick'], function (exports, _emberCliFastclickInitializersFastclick) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliFastclickInitializersFastclick['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberCliFastclickInitializersFastclick.initialize;
    }
  });
});