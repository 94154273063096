define('front-end-cli/pods/budget/matrix/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    budget: _ember['default'].inject.controller(),
    sessionManager: _ember['default'].inject.service('session-manager'),

    plusIncentiveIsShown: null,

    pastStartDayFinancialMonth: _ember['default'].computed('currentUser.model.state.startDayFinancialMonth', function () {
      var currentDay = moment().date();
      var startDayFinancialMonth = this.get('currentUser.model.state.startDayFinancialMonth') * 1;
      if (currentDay > startDayFinancialMonth) {
        return true;
      } else {
        return false;
      }
    }),

    sortedIncomeCategories: null,
    incomeCategories: _ember['default'].computed('matrixHasDirtyBudgets', 'year', 'model', function () {

      var matrixHasDirtyBudgets = this.get('matrixHasDirtyBudgets');
      var sortedIncomeCategories = this.get('sortedIncomeCategories');

      if (!sortedIncomeCategories || !matrixHasDirtyBudgets) {
        sortedIncomeCategories = this.get('model').sortBy('budgetsSum').reverse().filter(function (category) {
          if (category.get('budgetsSum') > 0 || category.get('budgetsSum') === 0 && /inkomsten/i.test(category.get('name'))) {
            var sortedSubs = category.get('subcategorybudgets').sortBy('budgetsSum').reverse();
            category.set('sortedSubcategoryBudgets', sortedSubs);
            return true;
          }
        });
        this.set('sortedIncomeCategories', sortedIncomeCategories);
      }

      return sortedIncomeCategories;
    }),

    sortedExpensesCategories: null,
    expensesCategories: _ember['default'].computed('matrixHasDirtyBudgets', 'year', 'model', function () {

      var matrixHasDirtyBudgets = this.get('matrixHasDirtyBudgets');
      var sortedExpensesCategories = this.get('sortedExpensesCategories');

      if (!sortedExpensesCategories || !matrixHasDirtyBudgets) {
        sortedExpensesCategories = this.get('model').sortBy('budgetsSum').filter(function (category) {
          if (category.get('budgetsSum') <= 0 && !(category.get('budgetsSum') === 0 && /inkomsten/i.test(category.get('name')))) {
            var sortedSubs = category.get('subcategorybudgets').sortBy('budgetsSum');
            category.set('sortedSubcategoryBudgets', sortedSubs);
            return true;
          }
        });
        this.set('sortedExpensesCategories', sortedExpensesCategories);
      }

      return sortedExpensesCategories;
    }),

    dirtyBudgetObserver: _ember['default'].observer('model.@each.budgetsAreDirty', 'model.@each.hasDirtySubBudgets', function () {

      var isDirty = false;

      this.get('model').forEach(function (cat) {
        if (cat.get('budgetsAreDirty') || cat.get('hasDirtySubBudgets')) {
          isDirty = true;
        }
      });

      this.set('matrixHasDirtyBudgets', isDirty);
      this.get('budget').set('matrixHasDirtyBudgets', isDirty);

      this.get('sessionManager').keepAlive();
    }),

    /**
      Currently, non-actived accounts are not ignored.
    */
    firstBudgetYear: _ember['default'].computed('currentUser.model.accounts.@each.activated', function () {
      var mFirstBudgetDate = undefined;

      this.get('currentUser.model.accounts').forEach(function (account) {
        var md = moment(account.get('firstBudgetDate'));
        if (mFirstBudgetDate) {
          if (md.isBefore(mFirstBudgetDate)) {
            mFirstBudgetDate = md;
          }
        } else {
          mFirstBudgetDate = md;
        }
      });

      // Returns a moment object!
      return mFirstBudgetDate.year();
    }),

    actions: {
      showPlusIncentive: function showPlusIncentive() {
        this.set('plusIncentiveIsShown', true);
      },
      hidePlusIncentive: function hidePlusIncentive() {
        this.set('plusIncentiveIsShown', false);
      }
    }
  });
});