define('front-end-cli/initializers/bowser', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(container, application) {
    application.register('util:bowser', bowser, { instantiate: false });
    application.inject('view', 'bowser', 'util:bowser');
  }

  exports['default'] = {
    name: 'bowser',
    initialize: initialize
  };
});