define('front-end-cli/pods/budget/matrix/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    queryParams: {
      year: { refreshModel: true }
    },

    paramYear: null,
    saving: false,

    /**
      Before loading the model, invalidate any
      existing budgetsCache (used for dirty-tracking)
    */
    beforeModel: function beforeModel(transition) {

      this.set('year', transition.queryParams.year);

      this.store.all('categorybudget').forEach(function (cat) {
        cat.set('invalidateBudgetCache', true);
        cat.get('subcategorybudgets').forEach(function (sub) {
          sub.set('invalidateBudgetCache', true);
        });
      });

      this._super(transition);
    },

    model: function model(params) {
      return this.store.find('categorybudget', { year: this.get('currentYear') });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var budgetController = this.controllerFor('budget');

      controller.setProperties({
        year: budgetController.get('year'),
        month: budgetController.get('month')
      });

      // Resets
      controller.set('plusIncentiveIsShown', false);
    },

    pastStartDayFinancialMonth: _ember['default'].computed('currentUser.model.state.startDayFinancialMonth', function () {
      var currentDay = moment().date();
      var startDayFinancialMonth = this.get('currentUser.model.state.startDayFinancialMonth') * 1;
      if (currentDay > startDayFinancialMonth) {
        return true;
      } else {
        return false;
      }
    }),

    /**
      When `year` param is not set use currentYear
      and add 1 when we are beyond december
      startDayFinancialMonth.
    */
    currentYear: _ember['default'].computed('year', function () {

      var currentYear = undefined;
      var year = this.get('year');

      if (year) {
        currentYear = year;
      } else {
        var currentMonth = moment().month() + 1;
        if (currentMonth === 12 && this.get('pastStartDayFinancialMonth')) {
          currentYear = moment().year() + 1;
        } else {
          currentYear = moment().year();
        }
      }
      return currentYear;
    }),

    actions: {

      willTransition: function willTransition(transition) {

        if (this.controller.get('matrixHasDirtyBudgets')) {

          this.controller.set('preventedTransition', transition);

          transition.abort();

          var template = 'application/dialog',
              controller = null,
              // this,
          model = {
            header: 'Wil je de wijzigingen in je budgetten opslaan?',
            // content: 'Lorem.',
            buttons: [{ title: 'Bewaar niet', action: 'restoreBeforeTransition', close: true }, { title: 'Bewaar', action: 'saveBeforeTransition', close: true }]
          };

          // Render the bwa-success template in the modal-route
          _ember['default'].run.next(this, function () {
            this.send('toggleModal', template, controller, model);
          });
        } else {
          // Bubble the `willTransition` action so that
          // parent routes can decide whether or not to abort.
          return true;
        }
        //
        //   // if (window.confirm('Je hebt aanpassingen gedaan aan je budgetten...')) {
        //   //   // nothing
        //   // } else {
        //   //   transition.abort();
        //   // }
        // }
      },

      saveBeforeTransition: function saveBeforeTransition() {
        var preventedTransition = this.controller.get('preventedTransition'),
            waiting = true;
        var route = this,
            i = 0;

        this.controller.set('preventedTransition', null);
        this.send('saveDirtyBudgets');

        if (preventedTransition) {
          var interval = setInterval(function () {
            if (!route.get('saving') || i > 5) {
              clearInterval(interval);
              preventedTransition.retry();
            }
            i++;
          }, 1000);
        }
      },

      restoreBeforeTransition: function restoreBeforeTransition() {

        var preventedTransition = this.controller.get('preventedTransition');
        this.controller.set('preventedTransition', null);

        // We'll only reload the model (restoreDirtyBudgets)
        // if the targetRoute is the `budget.matrix`.
        if (/budget\.matrix/.test(preventedTransition.targetName)) {}
        this.send('restoreDirtyBudgets');

        if (preventedTransition) {
          _ember['default'].run.later(function () {
            preventedTransition.retry();
          }, 2000);
        }
      },

      invalidateBudgetsCache: function invalidateBudgetsCache() {
        this.store.all('categorybudget').forEach(function (cat) {
          cat.set('invalidateBudgetCache', true);
          cat.get('subcategorybudgets').forEach(function (sub) {
            sub.set('invalidateBudgetCache', true);
          });
        });
      },

      saveDirtyBudgets: function saveDirtyBudgets() {
        var catBudgetsToBeSaved = [],
            subcategoryBudgetsSavePromises = [],
            route = this;

        route.set('saving', true);

        this.store.all('categorybudget').forEach(function (cat) {
          //We no longer check if categories are dirty, because we cannot set them anymore.
          //Instead, the model has been adjusted to always display 0 when being updated while
          //subBudgets are still calculated based on their set budgets

          // if (cat.get('budgetsAreDirty')) {
          //   catBudgetsToBeSaved.pushObject(cat);
          // }
          catBudgetsToBeSaved.pushObject(cat);

          cat.get('subcategorybudgets').forEach(function (sub) {
            if (sub.get('budgetsAreDirty')) {
              subcategoryBudgetsSavePromises.push(sub.save().then(function (s) {
                sub.set('budgetsCache', null);
              }));
            }
          });
        });

        _ember['default'].RSVP.all(subcategoryBudgetsSavePromises).then(function (subcategoryBudgets) {
          var categoryBudgetsSavePromises = [];
          catBudgetsToBeSaved.forEach(function (item, index, enumerable) {
            categoryBudgetsSavePromises.push(item.save().then(function (c) {
              item.set('budgetsCache', null);
            }));
          });

          _ember['default'].RSVP.all(categoryBudgetsSavePromises).then(function (categoryBudgets) {
            route.set('saving', false);
            route.set('currentUser.model.state.hasBudgets', true);
          });

          _ember['default'].RSVP.on('error', function (reason, label) {

            var response = reason.responseJSON.error.message;
            if (response !== null) {

              var template = 'application/dialog',
                  controller = null,
                  model = {
                header: response,
                buttons: [{ title: 'Herstel', action: 'restoreDirtyBudgets', close: true }]
              };
              _ember['default'].run.next(route, function () {
                this.send('toggleModal', template, controller, model);
              });
            }
          });
        });
      },

      restoreDirtyBudgets: function restoreDirtyBudgets() {

        // invalidate cache
        this.send('invalidateBudgetsCache');

        // Get year param
        var year = this.get('currentYear');

        this.store.find('categorybudget', { year: year });
      },

      /**
        Action send from the budget/matrix-options
        menu get send to the api here.
      */
      budgetAction: function budgetAction(action) {
        var _this = this;

        if (!action) return;

        var year = this.get('currentYear');

        _ember['default'].$.ajax({
          type: 'GET',
          url: '/api/v3/probudgets',
          data: {
            'procedure': action,
            year: year
          }
        }).success(function (response) {

          if (/remove-all/.test(action)) {
            _this.store.find('state', 1);
          } else {
            _this.set('currentUser.model.state.hasBudgets', true);
          }

          _this.refresh();
        }).error(function (error) {
          // hmm
        });
      }
    }
  });
});