define('front-end-cli/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'front-end-cli/config/environment'], function (exports, _ember, _emberResolver, _emberLoadInitializers, _frontEndCliConfigEnvironment) {

  var App;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _frontEndCliConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _frontEndCliConfigEnvironment['default'].podModulePrefix,
    Resolver: _emberResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _frontEndCliConfigEnvironment['default'].modulePrefix);

  /**
    Enable cross-frame access (The ember-cli test server's
    port number would otherwise disallow this)
  */
  document.domain = 'mijngeldzaken.nl';

  /**
    Log errors
    TODO: enable server logging!
  */
  _ember['default'].onerror = function (error) {
    /** TURNED OFF FOR NOW
    // Throttle prevents potentially DDOSing our own server.
    Ember.run.throttle(this, function() {
       // var currentPath = FrontEndCli.__container__.lookup("controller:application").get("currentPath");
       Ember.$.ajax({
        url  : '/app/error/',
        data : error.stack,
        type : 'post',
        dataType: 'text',
      });
    }, 150);
     */
    _ember['default'].Logger.error(error.stack, error);
  };

  /**
    Log RSVP errors
  */
  _ember['default'].RSVP.on('error', function (error) {

    // TransitionAborted when performing a full route transition with changed query params
    // Read more : https://github.com/emberjs/ember.js/issues/5566
    // TODO: MHO is monitoring this issue and will remove this when issue is resolved
    if (error && error.message === 'TransitionAborted') {
      return;
    }

    if (error && error.status === 401) {
      // window.location.reload();
    }

    _ember['default'].Logger.assert(false, error);
  });

  exports['default'] = App;
});