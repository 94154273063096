define('front-end-cli/pods/components/nibud/comparison-page/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    /* MidasCloud Api Variables */
    table: null,
    categoryRows: null,
    total: null,

    /* Page Variables */
    tableIsRendered: false,
    isCollapsed: true,

    actions: {
      collapse: function collapse() {
        this.set('isCollapsed', !this.get('isCollapsed'));
      }
    }

  });
});