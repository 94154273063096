define('front-end-cli/views/application/help-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({
    tagName: 'button',
    elementId: 'helpButton',
    classNames: ['modal-trigger'],
    classNameBindings: ['isActive:active'],
    click: function click() {
      this.get('controller').send('toggleModal', 'application/help-modal');
    },
    isActive: (function () {
      return this.get('controller.modal') === 'application/help-modal';
    }).property('controller.modal')
  });
});