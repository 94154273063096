define('front-end-cli/models/income-now-retirement-annuity', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    incomeNow: _emberData['default'].belongsTo('income-now'),

    description: _emberData['default'].attr('string'),
    provider: _emberData['default'].attr('string'),
    yearAmount: _emberData['default'].attr('string'),
    endDate: _emberData['default'].attr('string'),
    policyNr: _emberData['default'].attr('string'),
    temporaryChoice: _emberData['default'].attr('number'),

    isTemporary: (function () {
      return this.get('temporaryChoice') == 2;
    }).property('temporaryChoice')
  });
});