define('front-end-cli/pods/components/transaction-details/description/description-row/component', ['exports', 'ember'], function (exports, _ember) {
  var _this = this;

  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['contenteditable'],

    tagName: 'transaction-detail-row',
    classNames: ['TransactionDetails-row', 'DescriptionRow'],
    classNameBindings: ['isEditing', 'transaction.manDescription:is-manual', 'transaction.childTransactions.length:is-splitted'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    isEditing: null,
    transaction: null,

    /**-------------------------
      Properties
    ---------------------------*/

    // Transaction description related
    descriptionProxy: null,
    descriptionOriginal: null,

    isEditable: null,

    isNotEditing: (function () {
      return !_this.get('isEditing');
    }).property('isEditing'),

    contenteditable: (function () {
      return _this.get('isEditable');
    }).property('isEditable'),

    /**-------------------------
      Methods
    ---------------------------*/

    /**
      We do not let the user edit manDescription directly
      but `descriptionProxy` instead.
       When `manDescription` is empty we'll
      use `name`+', '+`description`.
    */
    descriptionInitialize: (function () {

      var descriptionProxy = '';

      var manDescription = this.get('transaction.manDescription'),
          descriptionOriginal = this.get('transaction.descriptionOriginal');

      if (manDescription) {
        descriptionProxy = manDescription;
      } else {
        descriptionProxy = descriptionOriginal;
      }

      this.set('descriptionProxy', descriptionProxy);

      if (manDescription && manDescription === descriptionOriginal) {
        this.set('transaction.manDescription', manDescription);
      }

      if (this.$()) {
        this.$().text(this.get('descriptionProxy'));
      }
    }).on('init').observes('transaction', 'isEditing'),

    /**
      When loading and when changing transaction,
      we’ll update the content of this element.
    */
    setInitialContent: (function () {
      this.$().text(this.get('descriptionProxy'));
    }).on('didInsertElement').observes('transaction'),

    /**
      When `descriptionProxy` changes, we’ll use it
      to set the transactions `manDescription`.
       When the `descriptionProxy` is empty or equal to
      `name`+', '+`description`, we'll unset manDescription.
    */
    descriptionProxyObserver: (function () {

      if (!this.get('transaction')) {
        return;
      }
      var descriptionProxy = this.get('descriptionProxy'),
          description = this.get('transaction.description'),
          name = this.get('transaction.name'),
          descriptionOriginal = this.get('transaction.descriptionOriginal');

      if (!descriptionProxy || descriptionProxy === descriptionOriginal) {
        this.set('transaction.manDescription', '');
      } else {
        this.set('transaction.manDescription', descriptionProxy);
      }
    }).observes('descriptionProxy'),

    /**-------------------------
      Events
    ---------------------------*/

    touchEnd: function touchEnd() {
      this.element.focus();
    },

    focusIn: function focusIn() {
      if (this.get('transaction.manDescription') === '') {
        this.set('descriptionProxy', '');
      }
    },

    focusOut: function focusOut() {

      // `Next` run loop to ensure any
      // `descriptionInitialize` call,
      // tiggered by a rollback, runs
      // first. Preventing a glitch.
      _ember['default'].run.next(this, function () {

        if (this._state === 'destroying') return;

        var descriptionProxy = this.get('descriptionProxy'),
            descriptionOriginal = this.get('transaction.descriptionOriginal');

        if (!descriptionProxy) {

          this.set('transaction.manDescription', '');
          this.set('descriptionProxy', descriptionOriginal);

          // Revert to original description
          this.$().text(descriptionOriginal);
        }
      });
    },

    keyPress: function keyPress(e) {
      // Prevent new lines (enter key)
      return e.which != 13;
    },

    keyUp: function keyUp(event) {
      this.set('descriptionProxy', this.element.innerText);
    }
  });
});