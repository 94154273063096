define('front-end-cli/pods/components/balance-benchmark/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    /**-------------------------
    External Bindings
    ---------------------------*/

    accounts: null,
    bankChoice: null,

    /**-------------------------
      Properties
    ---------------------------*/

    promises: null,
    onlyConfirmations: null,

    /**-------------------------
    Calculated Properties
    ---------------------------*/

    balanceAccounts: (function () {
      return this.get('accounts').filterBy('requestBalanceBenchmark', true)
      // We'll create a wrapper object to be able to pass
      // account specific properties to and from the nested
      // `balance-benchmark-account` component.
      .map(function (item) {
        return {
          account: item,
          benchmark: null
        };
      });
    }).property('accounts'),

    benchmarkBalanceAccounts: (function () {

      var selectedAccountNumbers = [];
      this.get('bankChoice.availableAccounts').forEach(function (newAccountData, index) {
        selectedAccountNumbers.push(String(newAccountData.get('accountNumber'))); //type casting to string removes the unwanted characters
      }, this);

      return this.get('balanceAccounts').filter(function (item) {
        return !item.account.get('balance') && selectedAccountNumbers.contains(item.account.get('accountNumber'));
      });
    }).property('balanceAccounts'),

    unfinishedBusiness: (function () {
      // We'll set this to false when we encounter benchmarks
      // It's used to change the button text.
      this.set('onlyConfirmations', true);

      var containsEmptyBenchmarks = false;

      this.get('benchmarkBalanceAccounts').forEach(function (a) {
        if (!a.benchmark) {
          containsEmptyBenchmarks = true;
        } else this.set('onlyConfirmations', false);
      }, this);
      return containsEmptyBenchmarks;
    }).property('benchmarkBalanceAccounts.@each.benchmark'),

    hasMultipleBenchmarkBalanceAccounts: (function () {
      return this.get('benchmarkBalanceAccounts.length') > 1;
    }).property('benchmarkBalanceAccounts'),

    /**-------------------------
    Actions
    ---------------------------*/

    actions: {

      persistBenchmarks: function persistBenchmarks() {
        // Array which will holds all promises
        this.set('promises', []);

        // Save each filteredaccounts with a new benchmark
        this.get('balanceAccounts').forEach(function (wrappedAccount) {
          if (wrappedAccount.benchmark) {
            this.send('saveBenchmark', wrappedAccount);
          }
        }, this);

        // When all promises are resolved
        _ember['default'].RSVP.all(this.get('promises')).then((function () {
          var promises = [];

          this.get('balanceAccounts').forEach((function (wrappedAccount) {
            if (wrappedAccount.benchmark) {
              var promise = wrappedAccount.account.save();
              this.get('promises').pushObject(promise);
            }
          }).bind(this));
          // Advance
          this.sendAction('showStep', 7);
        }).bind(this), (function (reason) {
          // Error
          console.error(reason);
          this.set('error', reason);
        }).bind(this));
      },

      ignoreAllBenchmarks: function ignoreAllBenchmarks() {
        // User ignores benchmark option, and will be asked
        // the next time he updates his account(s).
        // action send to update controller in setup and update state
        this.sendAction('showStep', 7);
      },

      saveBenchmark: function saveBenchmark(wrappedAccount) {

        // Convert to floaty
        var benchmark = accounting.unformat(wrappedAccount.benchmark.replace(/,/g, '.'));

        // XHR
        var promise = _ember['default'].$.post('/app/async/accounts.balance.save', {
          'account-balance': wrappedAccount.account.get('balance'),
          'account-id': wrappedAccount.account.get('id'),
          'requestBalanceBenchmarkType': wrappedAccount.account.get('requestBalanceBenchmarkType')
        }, function (data) {
          if (!data.error.message) {
            wrappedAccount.account.set('noBalanceAlert', false);
          }
        });

        // Add to promisses array
        this.get('promises').pushObject(promise);
      }
    }

  });
});