define('front-end-cli/pods/components/category-dissect-chart/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'svg',
    classNames: ['CategoryDissectChart'],
    classNameBindings: ['collapsed:is-collapsed', 'hasCategory', 'hasSubcategory', 'lastUpdateIsBeforeStartDate', 'isClickable'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    type: null,
    filteredCategories: null,
    category: null,
    subcategory: null,
    preview: null,
    showDetails: null,
    showDetailsToggle: null,
    lastUpdateIsBeforeStartDate: null,

    /**-------------------------
      Properties
    ---------------------------*/

    width: 200,
    height: 200,

    hasCategory: (function () {
      return this.get('category');
    }).property('category'),

    hasSubcategory: (function () {
      return this.get('subcategory');
    }).property('subcategory'),

    isClickable: (function () {
      return this.get('category') !== null || this.get('subcategory') !== null;
    }).property('category', 'subcategory'),

    /**
      Translates the category records into plain json
    */
    categoriesData: (function () {

      if (!this.get('filteredCategories')) {
        return;
      }
      var categories = this.get('filteredCategories').map(function (category) {
        return category;
      });

      // Sort the categories.
      categories.sortBy('currentNetted');
      if (this.get('type') === 'income') {}
      //   categories.reverse();

      // Turn enumerable into plain JSON array for d3.
      var json = null,
          currentNettedSum;
      if (categories.get('length')) {

        json = [];
        categories.forEach(function (category) {
          var catJson = category.toJSON();
          catJson.id = category.id;
          json.push(catJson);
        });

        // When the sum is empty, we can’t draw a graph
        // and we’ll return a placeholder object.
        currentNettedSum = json.reduce(function (previousValue, cat) {
          return previousValue + cat.currentNetted;
        }, 0);
      }

      return currentNettedSum ? json : [{ currentNetted: 1 }];
    }).property('filteredCategories.[]'),

    categoryData: (function () {

      if (!this.get('category')) {
        return;
      }

      var subcategories = this.get('category.subcategories')
      // Remove negative values when income and
      // positve values when expenses since we
      // can’t chart both in the same graph.
      .filter((function (subcategory) {
        if (subcategory.get('enabled')) {
          if (this.get('type') == 'income') {
            if (subcategory.get('currentNetted') > 0) {
              return true;
            }
          } else if (subcategory.get('currentNetted') < 0) {
            return true;
          }
        }
      }).bind(this))
      // Clone the array so sorting doesn’t
      // affect other components.
      .map(function (subcategory) {
        var subcatJson = subcategory.toJSON();
        subcatJson.id = subcategory.id;
        return subcatJson;
      })
      // Sort the subcategories.
      .sortBy('currentNetted').toArray();

      // Reverse sorting if type is `income`
      if (this.get('type') == 'income') {
        subcategories.reverse();
      }

      // When the sum is empty, we can’t draw a graph
      // and we’ll return a placeholder object.
      var currentNettedSum = subcategories.reduce(function (previousValue, cat) {
        return previousValue + cat.currentNetted;
      }, 0);

      return currentNettedSum ? subcategories : [{ currentNetted: 1 }];
    }).property('category', 'filteredCategories.[]'),

    /**-------------------------
      Observers
    ---------------------------*/

    showDetailsObserver: (function () {
      var showDetailsClass = this.get('showDetails') ? ' is-collapsed' : '',
          componentClass = 'CategoryDissectChart' + showDetailsClass;
      this.$().attr('class', componentClass);
    }).observes('showDetails'),

    /**
      `preview` is bound across the category-dissect
      subcomponents and manages highlighting list
      and chart items.
    */
    previewObserver: (function () {
      var _this = this;
      var id = this.$().attr('id');

      // Skip when there are no values in the categoryData
      var dataLengthIsOne = this.get('categoriesData.length') === 1,
          dataValueIsOne = this.get('categoriesData.firstObject.currentNetted') === 1;

      if (dataLengthIsOne && dataValueIsOne) {
        // Ignore
      } else {
          var activeCat = this.get('category');
          d3.select('#' + id + ' > .CategoryDissectChart-categories').selectAll('path').attr('class', function (d, i) {
            return _this.parseClassName(d.data, activeCat);
          });
        }

      // Skip when there are no values in the categoryData
      var subDataLengthIsOne = this.get('categoryData.length') === 1,
          subDataValueIsOne = this.get('categoryData.firstObject.currentNetted') === 1;

      if (subDataLengthIsOne && subDataValueIsOne) {
        // Ignore
      } else {
          var activeSubcat = this.get('subcategory');
          d3.select('#' + id + ' > .CategoryDissectChart-category').selectAll('path').attr('class', function (d, i) {
            return _this.parseClassName(d.data, activeSubcat);
          });
        }
    }).observes('preview'),

    /**-------------------------
      Methods
    ---------------------------*/

    initChart: (function () {
      if (this.get('category')) {
        this.drawCategoriesChart();
        this.drawCategoryChart();
      } else {
        this.drawCategoriesChart();
        if (this.get('categoryChart')) {
          // Remove categoryChart
          _ember['default'].$(this.get('categoryChart')[0]).remove();
        }
      }
    }).on('didInsertElement').observes('category', 'subcategory', 'filteredCategories', 'lastUpdateIsBeforeStartDate'),
    /**
      jQuery‘s .className() method doens‘t work
      on <svg> elements so we parse className
      change manually.
    */
    parseClassName: function parseClassName(cat, activeCat) {
      var previewClass = cat.id == this.get('preview.id') ? ' is-previewed' : '',
          activeClass = activeCat && cat.id == activeCat.get('id') ? ' is-active' : '',
          beyondActiveClass = activeClass && this.get('subcategory') ? ' is-beyond-active' : '';
      return 'CategoryDissectChart-arc' + previewClass + activeClass + beyondActiveClass;
    },

    drawCategoriesChart: function drawCategoriesChart() {

      if (this.get('categoriesChart')) {
        _ember['default'].$(this.get('categoriesChart')[0]).remove();
      }

      var _this = this;
      var id = this.$().attr('id');
      var width = this.get('width');
      var height = this.get('height');
      var radius = Math.min(width, height) / 2;

      var data = this.get('categoriesData');

      var color = d3.scale.ordinal().range(['#397FC4', '#397FC4', '#397FC4', '#397FC4', '#397FC4']);

      var arc = d3.svg.arc().outerRadius(radius - 10).innerRadius(radius - 40);

      var pie = d3.layout.pie().sort(null).value(function (d) {
        return Math.abs(d.currentNetted);
      });

      var svg = d3.select('#' + id).attr('width', width).attr('height', height).append('g').attr('class', function () {
        var hasCategoryClass = _this.get('category') ? ' has-category' : '';
        return 'CategoryDissectChart-categories' + hasCategoryClass;
      }).attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')').attr('fill', '#CCCCCCC');

      var path = svg.selectAll('path').data(pie(data)).enter().append('path').attr('class', function (d, i) {
        var catname = _this.get('category.name');
        var activeClass = catname && d.data.name === catname ? ' is-active' : '';
        var beyondActiveClass = activeClass && _this.get('subcategory') ? ' is-beyond-active' : '';
        return 'CategoryDissectChart-arc' + activeClass + beyondActiveClass;
      }).style('stroke', 'white').style('stroke-width', 2).on('mouseenter', function (d, i) {
        // var $path = Ember.$(this);
        // var classnames = $path.attr('class').replace(/ ?is-previewed/, '');
        // $path.attr('class', classnames + ' is-previewed');
        _this.set('preview', d.data);
      }).on('mouseleave', function (d, i) {
        // var $path = Ember.$(this);
        // var classnames = $path.attr('class').replace(/ ?is-previewed/, '');
        // $path.attr('class', classnames);
        _this.set('preview', false);
      }).on('click', function (d, i) {
        var cat = _this.get('filteredCategories').findBy('name', d.data.name);
        _this.set('subcategory', null);
        _this.set('category', cat);
      });

      path.transition().duration(0).style('opacity', function (d, i) {
        return _this.returnOpacity(d, i, _this.get('category.name'));
      })
      // .attr('fill', function(d, i) {
      //   return _this.returnColor(d, i, _this.get('category.name'));
      // }.bind(this))
      .attr('d', arc).each(function (d) {
        this._current = d;
      }); // Store the initial angles

      this.set('categoriesChart', svg);
      this.set('pie', pie);
      this.set('arc', arc);
      this.set('path', path);
    },

    drawCategoryChart: function drawCategoryChart() {

      if (this.get('categoryChart')) {
        _ember['default'].$(this.get('categoryChart')[0]).remove();
      }

      var _this = this;
      var id = this.$().attr('id');
      var width = this.get('width');
      var height = this.get('height');
      var radius = Math.min(width, height) / 2;

      var data = this.get('categoryData');

      var color = d3.scale.ordinal().range(['#397FC4', '#397FC4', '#397FC4', '#397FC4', '#397FC4']);

      var arc = d3.svg.arc().outerRadius(radius - 40).innerRadius(radius - 70);

      var pie = d3.layout.pie().sort(null).value(function (d) {
        return Math.abs(d.currentNetted);
      });

      var svg = d3.select('#' + id).attr('width', width).attr('height', height).append('g').attr('class', function () {
        var hasSubcategoryClass = _this.get('subcategory') ? ' has-subcategory' : '';
        return 'CategoryDissectChart-category' + hasSubcategoryClass;
      }).attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

      this.set('categoryChart', svg);

      var path = svg.selectAll('path').data(pie(data)).enter().append('path').attr('fill', '#FCFCFC').style('stroke', 'white').style('stroke-width', 2).attr('class', function (d, i) {
        // This gets overruled by previewobserver
        var catname = _this.get('subcategory.name');
        var activeClass = catname && d.data.name === catname ? ' is-active' : '';
        return 'CategoryDissectChart-arc' + activeClass;
      }).on('mouseenter', function (d, i) {
        // var $path = Ember.$(this);
        // var classnames = $path.attr('class').replace(/ ?is-previewed/, '');
        // $path.attr('class', classnames + ' is-previewed');
        _this.set('preview', d.data);
      }).on('mouseleave', function (d, i) {
        // var $path = Ember.$(this);
        // var classnames = $path.attr('class').replace(/ ?is-previewed/, '');
        // $path.attr('class', classnames);
        _this.set('preview', false);
      }).on('click', function (d, i) {
        var subcat = _this.get('category.subcategories').findBy('name', d.data.name);
        _this.set('subcategory', subcat);
      });

      path.transition().duration(0).style('opacity', function (d, i) {
        return _this.returnOpacity(d, i, _this.get('subcategory.name'));
      })
      // .attr('fill', function(d, i) {
      //   return _this.returnColor(d, i, _this.get('subcategory.name'));
      // })
      .attr('d', arc).each(function (d) {
        this._current = d;
      }); // Store the initial angles
    },

    returnOpacity: function returnOpacity(d, i, catname) {
      if (catname && d.data.name == catname) {
        return 1;
      } else {
        var c = i === 0 ? 0.2 : 0;

        return 1 / (i + 1) - c; // - 0.2;
      }
    },

    returnColor: function returnColor(d, i, catname) {
      if (catname && d.data.name != catname) {
        return '#CCCCCC'; // grey
      } else {
          return '#397FC4'; // blue
        }
    },

    /*
    refreshChart: function() {
       var categories = this.get('categoriesData');
      var category = this.get('categoryData');
      var subcategory = this.get('subcategoryData');
      var subcategories = category? category.get('subcategories') : null;
      var data;
       if (!category) {
        // Index
        data = categories;
      } else if (!subcategory) {
      // Category
        data = subcategories;
      } else {
        // Subcategory
        data = subcategories;
      }
      var path = this.get('path');
      var arc = this.get('arc');
      var pie = this.get('pie');
       // Store the displayed angles in _current.
      // Then, interpolate from _current to the new angles.
      // During the transition, _current is updated in-place by d3.interpolate.
      function arcTween(a) {
        var i = d3.interpolate(this._current, a);
        this._current = i(0);
        return function(t) {
          return arc(i(t));
        };
      }
       path.data(pie(categories));
      path.transition().duration(1250).attrTween('d', arcTween); // redraw the arcs
     }.observes('categoriesData', 'categoryData')
    */

    click: function click(e) {
      if (!this.get('subcategory')) {
        this.set('category', null);
      }
      this.set('subcategory', null);
    }
  });
});