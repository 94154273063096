define('front-end-cli/initializers/viewport-units-buggyfill', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() /* container, application */{
    window.viewportUnitsBuggyfill.init();
  }

  exports['default'] = {
    name: 'viewport-units-buggyfill',
    initialize: initialize
  };
});