define('front-end-cli/controllers/accountmanagement/edit/base', ['exports', 'ember', 'front-end-cli/mixins/steps'], function (exports, _ember, _frontEndCliMixinsSteps) {
  exports['default'] = _ember['default'].Controller.extend(_frontEndCliMixinsSteps['default'], {

    needs: ['application'],
    queryParams: ['stepTo'],
    stepTo: null,

    /**
      When firefox restarts after the bwa is installed,
      we need to come back to step 4. To make this possible
      we added a stepTo queryParam.
    */
    stepToObserver: (function () {
      _ember['default'].run.next(this, function () {
        if (this.get('stepTo') == 'bwa-4') {
          this.set('bankChoice.bwa', true);
          this.send('showStep', 4);
        }
      });
    }).observes('stepTo'),

    canNotAddMoreAccounts: (function () {
      var createdAccountsLength = this.get('bankChoice.createdAccountsLength'),
          hasTwoAccounts = this.get('currentUser.model.accounts').filterBy('isCashAccount', false).filter(function (item, index, enumerable) {
        return item.get('locked') !== true;
      }).get('length') - createdAccountsLength >= parseInt(this.get('currentUser.model.state.amountOfAccounts'));

      return hasTwoAccounts;
    }).property('currentUser.model.accounts.length', 'bankChoice.createdAccountsLength'),

    preventSyncMore: (function () {
      if (this.get('isUpdateController')) return false;
      var accountsLength = this.get('currentUser.model.accounts').filterBy('isCashAccount', false).filter(function (item, index, enumerable) {
        return item.get('locked') !== true;
      }).get('length'),
          selectedAccounts = this.get('bankChoice.availableAccounts').filterBy('sync', true).get('length');

      return accountsLength + selectedAccounts >= parseInt(this.get('currentUser.model.state.amountOfAccounts'));
    }).property('currentUser.model.accounts.length', 'bankChoice.availableAccounts.@each.sync'),

    showMaxAccountWarning: (function () {
      if (this.get('isUpdateController')) return false;
      var accountsLength = this.get('currentUser.model.accounts').filterBy('isCashAccount', false).filter(function (item, index, enumerable) {
        return item.get('locked') !== true;
      }).get('length'),
          availableAccounts = this.get('bankChoice.availableAccounts.length'),
          createdAccountsLength = this.get('bankChoice.createdAccountsLength');

      return accountsLength + availableAccounts - createdAccountsLength > parseInt(this.get('currentUser.model.state.amountOfAccounts'));
    }).property('currentUser.model.accounts.length', 'bankChoice.availableAccounts'),

    addedMultipleAccounts: (function () {
      var accounts = this.get('bankChoice.availableAccounts'),
          length = this.get('bankChoice.availableAccounts.length');
      return length && accounts.filterBy('sync', true).get('length') > 1;
    }).property('bankChoice.availableAccounts.@each.sync'),

    updatedMultipleAccounts: (function () {
      var accounts = this.get('bankChoice.availableAccounts'),
          length = this.get('bankChoice.availableAccounts.length');
      return length && accounts.filterBy('update', true).get('length') > 1;
    }).property('bankChoice.availableAccounts.@each.update'),

    // Created on route’s `setupController` hook
    bankChoice: {},

    isTourVisible: (function () {
      return false;
    }).property('showNameEdit'),

    _getChallenge: function _getChallenge() {

      var data = {};

      if (this.get('bankChoice.data.isAbnEdentifier1')) {
        data.username = this.get('bankChoice.data.username');
        data.passNr = this.get('bankChoice.data.passNr');
      } else if (this.get('bankChoice.isDigipass')) {
        data.username = this.get('bankChoice.data.username');
      }

      var promise = _ember['default'].$.ajax({
        url: 'api/v3/banklogins/getchallenge/' + this.get('bankChoice.bankLogin.id'),
        type: 'get',
        context: this,
        data: data
      }).done(function (response) {
        /**
          This show password field in UI, which enables
          the `syncBankLogin` button when valid.
        */
        this.set('bankChoice.challenge', response.challenge);
      }).fail(function (jqXHR, textStatus, errorThrown) {

        var error = jqXHR.responseJSON ? jqXHR.responseJSON.error : {};
        this.set('error', error);
      });

      return promise;
    },

    _createBankLogin: function _createBankLogin() {

      // Rabo doesn't allow IBAN for username
      if (/rabobank|abn/.test(this.get('bankChoice.name'))) {
        if (/^NL\d{2}[A-Z]{4}\d{10}$|^BE\d{14}$/i.test(this.get('bankChoice.data.username'))) {
          this.set('bankChoice.data.username', this.get('bankChoice.data.username').substring(8));
        }
      }

      var bankLogin = this.store.createRecord('bankLogin', {
        bank: this.get('bankChoice.bank'),
        username: this.get('bankChoice.data.username'),
        passNr: this.get('bankChoice.data.passNr'),
        loginMethod: this.get('bankChoice.loginMethod')
      });

      var promise = bankLogin.save()['catch'](function () {
        throw 'Creation of App.bankLogin REST recource failed';
      });

      return promise;

      /*var promise2 = promise.then(function() {
         self.set('bankChoice.bankLogin', bankLogin);
         // When two factor, the user will manualy call `syncBankLogin`
        // if(bankLogin.get('isSnsDigiPass') || bankLogin.get('isAbnEdentifier2')) {
          self.send('getChallenge');
        // }
        // else self.send('syncBankLogin');
      }, function() {
       });*/
    },

    _syncBankLogin: function _syncBankLogin() {

      var blid = this.get('bankChoice.bankLogin.id'),
          blpass = this.get('bankChoice.data.password');

      if (!blid || !blpass) {
        throw 'missing required data for doing an account sync call';
      }

      var promise = _ember['default'].$.ajax({
        url: 'api/v3/banklogins/sync/' + blid,
        type: 'PUT',
        dataType: 'json',
        data: {
          password: '--' + Base64.encode(Base64.encode(blpass))
        },
        context: this
      }).always(function () {
        // Flush password
        this.set('bankChoice.data.password', null);
      });

      return promise;
    },

    hasSelectedAccounts: (function () {

      var accounts = this.get('bankChoice.availableAccounts'),
          length = this.get('bankChoice.availableAccounts.length'),
          syncing = this.get('syncing');

      if (length && !this.get('syncing')) {
        if (this.get('isSetupController')) {
          return accounts.filterBy('sync', true).filterBy('update', false).get('length') > 0;
        } else {
          return accounts.filterBy('sync', true).get('length') > 0;
        }
      } else {
        return false;
      }
    }).property('bankChoice.availableAccounts.@each.sync', 'syncing'),

    actions: {

      dismissError: function dismissError() {
        this.set('error', null);
      },

      reloadAccounts: function reloadAccounts() {
        // Refresh `account.lastupdate`
        this.store.find('account');
      },

      saveChangesBankLoginAndGetChallenge: function saveChangesBankLoginAndGetChallenge() {
        /* Function only used in combination with ABN autosync, which is disabled.
         */
        var bankLogin = {
          bank: this.get('bankChoice.bank'),
          username: this.get('bankChoice.data.username'),
          passNr: this.get('bankChoice.data.passNr'),
          loginMethod: this.get('bankChoice.loginMethod')
        };

        this.get('bankChoice.bankLogin').setProperties(bankLogin);
        this.get('bankChoice.bankLogin').save();
        this._getChallenge();
      },

      createBankLoginAndGetChallenge: function createBankLoginAndGetChallenge() {
        this.send('dismissError');
        this.set('syncing', true);
        var promise = this._createBankLogin();

        promise.then((function (bankLogin) {
          this.set('bankChoice.bankLogin', bankLogin);
          var challengePromise = this._getChallenge();

          challengePromise.always(function () {
            this.set('syncing', false);
          });
        }).bind(this));
      },

      updateAccounts: function updateAccounts() {
        var bankLogin = this.get('bankChoice.bankLogin') || null;
        var account = this.get('bankChoice.account');
        account.set('bankLogin', bankLogin);
        account.save();
      },

      saveChangesAndSyncBankLogin: function saveChangesAndSyncBankLogin() {

        this.send('dismissError');

        var bankLogin = {
          bank: this.get('bankChoice.bank'),
          username: this.get('bankChoice.data.username'),
          passNr: this.get('bankChoice.data.passNr'),
          loginMethod: this.get('bankChoice.loginMethod')
        };

        this.get('bankChoice.bankLogin').setProperties(bankLogin);
        if (this.get('bankChoice.bankLogin.isDirty')) {
          this.get('bankChoice.bankLogin').save().then((function () {
            this.send('syncBankLogin');
          }).bind(this));
        } else {
          this.send('syncBankLogin');
        }

        /**
          When the user gets here by using `showPreviousStep`
          we need to clear any `availableAccounts` so that when
          he changes his `bankLogin`, he won’t be presented any
          accounts of his previous `bankLogin`.
        */
        if (this.get('bankChoice.availableAccounts.length')) {
          this.set('bankChoice.availableAccounts', null);
        }
      },

      createAndSyncBankLogin: function createAndSyncBankLogin() {

        this.send('dismissError');

        // Create `bankLogin` when it’s blank
        if (_ember['default'].isBlank(this.get('bankChoice.bankLogin'))) {
          var promise = this._createBankLogin().then((function (bankLogin) {
            this.set('bankChoice.bankLogin', bankLogin);
            this.send('syncBankLogin');
          }).bind(this));
        } else {
          // Update `bankLogin` when present (previous sync failed)
          this.send('saveChangesAndSyncBankLogin');
        }
      },

      syncBankLogin: function syncBankLogin() {

        this.set('syncing', true);

        var promise = this._syncBankLogin();

        promise.done((function (response) {
          this.send('syncSuccess', response);
        }).bind(this));

        promise.fail(function (jqXHR, textStatus, errorThrown) {

          var error = jqXHR.responseJSON ? jqXHR.responseJSON.error : {};

          /*const BANKWEBSITEDOWN = 1010;
          const LOGINFAILED = 1020;
          const FILEDOWNLOADFAILED = 1030;
           const HTMLERROR = 2010; //report these error's to HQ
          const IMPORTFAILED = 2020; //something went wrong in the doImport function*/

          if (this.get('bankChoice.isSnsDigipass')) {
            //Get a new challenge code if the request failed
            this._getChallenge();
          }

          if (!this.get('bankChoice.requestIngPacCode')) this.set('error', error);
        });

        promise.always((function () {
          this.set('syncing', false);
        }).bind(this));
      },

      syncSuccess: function syncSuccess(response) {

        this.send('setAvailableAccounts', response.accounts);

        if (this.get('isSetupController')) {
          this.send('showStep', 5);
        } else if (this.get('isUpdateController')) {
          this.send('updateAccounts');
          this.send('reloadAccounts');
          this.send('showStep', 7);
        }
      },

      setAvailableAccounts: function setAvailableAccounts(accounts) {

        if (!_ember['default'].$.isArray(accounts)) {
          this.set('error', { message: 'PAC response doesn’t contain any accounts.' });
          // throw 'PAC response doesn’t contain any accounts.';
          return;
        }

        var availableAccounts = [],
            accountsLength = this.get('currentUser.model.accounts').filterBy('isCashAccount', false).get('length'),
            plus = false; //This has been set to false because of new subscription model PFP

        accounts.forEach(function (account, index) {
          // Turn everything on by default unless no-plus and maxAccounts
          if (this.isUpdateController) {
            account.sync = true;
          } else {
            if (plus || accountsLength + (index + 1) <= this.get('currentUser.model.state.amountOfAccounts')) {
              if (this.isSetupController && account.update) {
                account.setupDuplicate = true;
              }
              if (!this.isSetupController || !account.update) account.sync = true;
            }
          }
          // Add
          availableAccounts.addObject(_ember['default'].Object.create(account));
        }, this);

        this.set('bankChoice.availableAccounts', availableAccounts);
      },

      endAccountSetup: function endAccountSetup() {

        // // Full screen success action
        // if (this.get('controllers.application.sidebarlessed')) {
        //
        //   if (this.set('currentUser.model.state.newUser')) {
        //     this.set('currentUser.model.state.newUser', false);
        //     this.get('currentUser.model.state').save();
        //   }
        //
        //   this.set('controllers.application.sidebarlessed', false);
        //   this.get('controllers.application').send('showSidebar');
        //
        //   this.send('showTour');
        // } else {
        //   // Regular setup/update success action
        //   // `setup` go’s back to accountmanagement,
        //   // `update` to whatever route it was in before.
        //   this.send('goToPreviousRoute');
        // }
        if (this.get('currentUser.model.hasNoAccounts')) {
          this.set('currentUser.model.hasNoAccounts', false);
          this.get('currentUser.model.state').save();
        }

        if (this.get('currentUser.model.state.newUser')) {

          this.get('controllers.application').send('showSidebar');
          // this.set('controllers.application.sidebarlessed', false);

          this.set('isTourVisible', true);

          this.set('currentUser.model.state.newUser', false);
          this.get('currentUser.model.state').save();
        }
        this.transitionToRoute('accounts');
      },

      uploadInvalid: function uploadInvalid(error) {
        this.set('error', error);
      },

      uploadStart: function uploadStart() {
        this.set('error', null);
        this.set('syncing', true);
      },

      uploadFail: function uploadFail(error) {
        this.set('error', error);
      },

      toggleSync: function toggleSync(account) {
        if (this.get('isUpdateController')) return;
        if (!this.get('preventSyncMore')) {
          if (!account.get('update')) account.toggleProperty('sync');
        } else if (account.get('sync')) {
          account.set('sync', false);
        }
      },

      orderPlus: function orderPlus() {
        this.transitionToRoute('plus');
      },

      orderFreePlusTrail: function orderFreePlusTrail() {

        _ember['default'].$.ajax('/app/async/plus.order-free').done((function (response) {
          if (response.error.type !== 0 && response.error.type !== 2) {
            this.set('bankChoice.showPlusTrialDenial', true);
          } else {
            this.set('bankChoice.showPlusTrialConfirmation', true);
            // refresh state
            this.get('currentUser.model.state').rollback();
            this.store.find('state', 1);
          }
        }).bind(this)).fail(function () {});
      },

      showPreviousStep: function showPreviousStep() {
        // Clear password
        if (this.get('bankChoice.data')) this.set('bankChoice.data.password', null);

        // Reset error object
        this.set('error', null);

        // Reset PAC properties (in stead of showing previous step,
        // since the PAC-code view doesn’t have its own step)
        if (this.get('bankChoice.requestIngPacCode')) {
          this.set('bankChoice.requestIngPacCode', null);
          this.set('bankChoice.data.pacCodePart1', null);
          this.set('bankChoice.data.pacCodePart2', null);
          return false;
        }

        this._super();
      },

      showStep: function showStep(step) {

        var stepName = '';
        switch (step) {
          case 1:
            stepName = 'choose bank';
            break;
          case 2:
            stepName = 'choose auto/manual';
            break;
          case 3:
            stepName = 'choose login method';
            break;
          case 4:
            if (this.get('bankChoice.auto') === true) {
              stepName = 'fillin credentials';
              this.set('bankChoice.availableAccounts', null); // Reset `availableAccounts`
            } else {
                stepName = 'manual information';
              }
            break;
          case 5:
            if (this.get('bankChoice.auto') === true) {
              stepName = 'select accounts';
            } else {
              stepName = 'manual upload en select accounts';
              this.set('bankChoice.availableAccounts', null); // Reset `availableAccounts`
            }
            break;
          case 6:
            stepName = 'set balance';
            break;
          case 7:
            stepName = 'import successful';
            break;
        }

        _ember['default'].$.ajax({
          url: 'api/v3/analytics/track/accountwizard-steps',
          type: 'POST',
          dataType: 'json',
          data: {
            event: step,
            bank: this.get('bankChoice.bank.id'),
            auto: this.get('bankChoice.auto'),
            loginMethod: this.get('bankChoice.loginMethod')
          }
        });

        this._super(step);
      }
    }
  });
});

/**
  This controller is not used directly, but extended from
  by the `setup-` and `update` controller.
*/