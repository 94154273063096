define('front-end-cli/models/labor', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    type: _emberData['default'].attr('number'),
    endAge: _emberData['default'].attr('number'),

    // EMBEDDED
    employed: _emberData['default'].belongsTo('employed'),
    selfEmployed: _emberData['default'].belongsTo('self-employed'),
    shareholder: _emberData['default'].belongsTo('shareholder'),

    // RELATIONSHIPS
    incomeNow: _emberData['default'].belongsTo('income-now'),
    incomeLater: _emberData['default'].belongsTo('income-later'),

    isEmployed: (function () {
      return this.get('type') == 1;
    }).property('type'),
    isSelfemployed: (function () {
      return this.get('type') == 2;
    }).property('type'),
    isShareholder: (function () {
      return this.get('type') == 3;
    }).property('type')
  });
});