define('front-end-cli/pods/components/category-select/category-select-li-sub/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'li',
    classNameBindings: ['isHighlit', 'isActive'],

    // Bound outside component
    subcategory: null,
    thisSubcategory: null,
    highlitSubcategory: null,
    leaveTimer: null,

    isHighlit: (function () {
      var state = false;
      var hasHighlitcat = this.get('highlitSubcategory');
      var hasNameMatch = this.get('thisSubcategory.name') == this.get('highlitSubcategory.name');
      var hasParentMatch = this.get('thisSubcategory.category.name') == this.get('highlitSubcategory.category.name');
      if (hasHighlitcat && hasNameMatch && hasParentMatch) {
        state = true;
      }
      return state;
    }).property('highlitSubcategory'),

    // resetSelected: function() {
    // this.set('selected', null);
    // }.observes('highlitSubcategory'),

    isActive: (function () {
      return this.get('thisSubcategory.name') == this.get('subcategory.name');
    }).property('subcategory'),

    // {{if isHighlit " is-blueFill" " is-gray"}}{{if isActive " is-blue"}}
    subcategoryStateClass: _ember['default'].computed('isHighlit', 'isActive', function () {
      var clss = '';
      if (this.get('isHighlit')) clss += 'is-blueFill';else clss += 'is-gray';
      if (this.get('isActive')) clss += clss ? ' is-blue' : 'is-blue';
      return clss;
    }),

    mouseEnter: function mouseEnter(e) {
      this.set('highlitSubcategory', this.get('thisSubcategory'));

      _ember['default'].run.cancel(this.get('leaveTimer'));
      this.set('leaveTimer', null);
    },

    click: function click() {
      // chooseSubcategory
      if (Modernizr.touch) {
        this.set('highlitSubcategory', this.get('thisSubcategory'));
      }
      this.sendAction();
    }
  });
});