define('front-end-cli/controllers/accountmanagement/edit/choose-bank', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    bankChoice: _ember['default'].computed.alias('parentController.bankChoice'),
    banks: _ember['default'].computed.alias('parentController.banks'),

    filteredContent: (function () {

      var searchTerm = this.get('bankChoice.bankSearchTerm').toLowerCase().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

      if (!searchTerm) return;
      var matches = [],
          searchableBanks = this.get('banks').rejectBy('id', '22') // kasboek
      .rejectBy('autosyncReady').rejectBy('major');

      var startingWithResults = searchableBanks.filter(function (bank) {
        var title = bank.get('title'),
            rgx = new RegExp('^' + searchTerm, 'i');
        return rgx.test(title);
      });

      var middleResults = searchableBanks.filter(function (bank) {
        var title = bank.get('title'),
            rgx = new RegExp(searchTerm, 'i'),
            inStartingWithResults = startingWithResults.findBy('title', title);

        return !inStartingWithResults && rgx.test(title);
      });

      return startingWithResults.concat(middleResults).slice(0, 15);
    }).property('bankChoice.bankSearchTerm'),

    actions: {

      //Since PSD2, we only allow manual updates and BWA is no longer supported

      chooseBank: function chooseBank(bank) {

        this.set('bankChoice.bank', bank);
        this.set('bankChoice.name', bank.get('name'));
        this.set('bankChoice.auto', null);
        this.set('bankChoice.bwa', null);

        // var chromeBwaCompatible = ['abn', 'asn', 'ing', 'rabobank'],
        //     firefoxBwaCompatible = ['abn', 'ing', 'rabobank'],
        //     isChromeBwaCompatible = bowser.chrome && chromeBwaCompatible.contains(bank.get('name')),
        //     isFirefoxBwaCompatible = bowser.firefox && firefoxBwaCompatible.contains(bank.get('name'));
        var enableLegacyImport = false;
        if (this.get('bankChoice.bank.legacy') && enableLegacyImport) {
          // Reset the importwizard when adding a new legacy (e.g. Bizzner) bank account. This is done in sync, because otherwise the (session-based) reset is overwritten.
          var defer = _ember['default'].RSVP.defer();
          this.send('resetImportWizard', defer);

          defer.promise.then((function () {
            this.send('showStep', 5);
          }).bind(this));
        }
        // else if(this.get('bankChoice.bank.autosyncReady') || isChromeBwaCompatible) { //TODO place ` || isFirefoxBwaCompatible` back when FF bwa is fixed!
        //   this.send('showStep', 2);
        // }
        else {
            this.send('showStep', 4); // Manual
          }
      },

      createCashAccount: function createCashAccount() {
        this.transitionToRoute('accountmanagement.cashbook');
      }
    }
  });
});