define('front-end-cli/pods/components/profiler-delete/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['ProfilerDelete'],
    classNameBindings: ['isRequestingConfirmation'],

    // External bindings
    title: null,
    model: null,

    // Properties
    isRequestingConfirmation: null,

    // Private method
    _unconfirmListener: function _unconfirmListener(e) {
      var target = _ember['default'].$(e.target);
      if (!target.hasClass('ProfilerDelete-confirmation')) {
        this.set('isRequestingConfirmation', false);
        _ember['default'].$(window).off('click', this._unconfirmListener);
      }
    },

    // Actions
    actions: {
      showConfirmation: function showConfirmation() {
        this.set('isRequestingConfirmation', true);
        // Bind a unconfirm handler in the next run loop.
        _ember['default'].run.next(this, function () {
          _ember['default'].$(window).on('click', _ember['default'].$.proxy(this._unconfirmListener, this));
        });
      },
      confirmDeletion: function confirmDeletion() {
        this.sendAction('action', this.get('model'));
        this.set('isRequestingConfirmation', false);
        _ember['default'].$(window).off('click', this._unconfirmListener);
      }
    }
  });
});