define('front-end-cli/pods/components/no-plus/component', ['exports', 'ember'], function (exports, _ember) {

  var _noplus = {
    account: {
      profile: {
        title: 'Maak je eigen financieel profiel compleet',
        body: 'Je financiële situatie houdt niet op bij je bankrekeningen. De meeste mensen hebben allerlei bezittingen, schulden, verzekeringen en andere financiële producten. Het is belangrijk om deze in kaart te brengen, voor bijv. een financieel adviesgesprek. Het rapport van je Financieel Profiel in Huishoudboekje PLUS kun je meenemen naar elke financieel adviseur. Omdat financieel advies geld kost, kun je door de inventarisatie zelf te doen geld besparen en een beter advies krijgen.',
        functionality: 'Financieel Profiel'
      }
    },
    whatif: {
      retirement: {
        title: 'De inkomsten en uitgaven <br class="visible-md visible-sm" />van je huishouden na pensionering',
        body: 'Na je pensionering verandert je financiële plaatje. Hoe verhouden je toekomstige inkomsten zich tot je verwachte uitgaven? Misschien kom je straks makkelijk rond, maar er kunnen ook fasen aanbreken dat je geld tekort komt. Door alvast inzicht te krijgen in je situatie voor straks, kun je nu al eventuele voorzorgsmaatregelen treffen. Wil je ook je pensioensituatie berekenen? Neem dan een abonnement op Huishoudboekje PLUS om deze functie te blijven gebruiken.',
        functionality: 'Pensioen'
      }
    },
    accountmanagement: {
      setup: {
        title: 'Meer dan 1 rekening <br>in MijnGeldzaken Huishoudboekje',
        body: 'Om meer dan 1 rekening aan MijnGeldzaken Huishoudboekje toe <br>te kunnen voegen heb je Huishoudboekje PLUS nodig.',
        functionality: 'Meer dan 1 rekening'
      },
      cashbook: {
        title: 'Meer dan 1 kasboek<br />in MijnGeldzaken Huishoudboekje',
        body: 'Om meer dan 1 kasboek aan MijnGeldzaken Huishoudboekje toe <br>te kunnen voegen heb je Huishoudboekje PLUS nodig.',
        functionality: 'Meer dan 1 kasboek'
      },
      update: {
        title: 'Rekening staat op slot!',
        body: 'Om meer dan 1 rekening bij te kunnen <br>werken heb je Huishoudboekje PLUS nodig.',
        functionality: 'Meer dan 1 rekening'
      }
    },
    report: {
      title: 'Maak een rapport van je financiële situatie',
      body: 'Met de rapport functie van MijnGeldzaken Huishoudboekje genereer je PDF rapporten voor jezelf of bijvoorbeeld voor je belastingadviseur.',
      functionality: 'Rapporten'
    },
    budget: {
      title: 'Start met budgetteren in de PLUS-versie.',
      body: 'Je hebt geen toegang (meer) tot budgetteren. Neem één van de betaalde versies en krijg toegang tot alle mogelijkheden van het huishoudboekje.',
      functionality: 'Budgetteren',
      yeartotals: {
        title: 'Hoe verhoudt je budget zich tot je werkelijke inkomsten en uitgaven?',
        body: 'Zie met ‘Jaar Totalen’ in één oogopslag hoe je hebt gepresteerd ten opzichte van je budget.',
        functionality: 'Jaar Totalen'
      },
      matrix: {
        title: 'Start met budgetteren in de PLUS-versie.',
        body: 'Je hebt geen toegang (meer) tot budgetteren. Neem één van de betaalde versies en krijg toegang tot alle mogelijkheden van het huishoudboekje.',
        funtionality: 'Budgetteren'
      }
    }
  };

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['u-vertical-centered-container NoPlus'],

    // External Bindings
    application: null,
    featurePreviewImage: null,

    // Aliases
    currentUser: _ember['default'].computed.alias('application.currentUser'),
    state: _ember['default'].computed.alias('currentUser.model.state'),
    hasSidebar: _ember['default'].computed.alias('application.hasSidebar'),

    lang: (function () {

      if (!this.get('application')) {
        throw new Error('No application controller defined in NoPlusComponent');
      }

      var keys = this.get('application.currentPath').replace(/\.(index|catch)/g, '').split('.');
      var obj = _noplus;
      var current = {};
      keys.forEach(function (key) {
        obj = obj[key];
      });
      return obj;
    }).property('application.currentPath'),

    title: (function () {
      return this.get('lang').title.htmlSafe();
    }).property('lang'),

    body: (function () {
      return this.get('lang').body.htmlSafe();
    }).property('lang'),

    functionality: (function () {
      return this.get('lang').functionality.htmlSafe();
    }).property('lang')
  });
});