define('front-end-cli/pods/components/transaction-details/splits/splits-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'transaction-detail-row',
    classNames: ['TransactionDetails-row', 'TransactionDetails-rowOptional', 'SplitsRow'],
    classNameBindings: ['isFilledOutOrEditing', 'isEditing'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    isEditing: null,
    transaction: null,

    /**-------------------------
      Properties
    ---------------------------*/

    transactionHolder: null,
    editingTransactionFilter: (function () {
      if (!this.get('isEditing')) {
        this.set('transaction', this.get('transactionHolder'));
      } else {
        this.set('transactionHolder', null);
      }
    }).observes('transactionHolder.id'),

    hasChildTransactions: (function () {
      return this.get('transaction.childTransactions.length') > 0;
    }).property('transaction.childTransactions.length'),

    isFilledOutOrEditing: (function () {
      return this.get('transaction.childTransactions.length') > 0 || this.get('transaction.parentTransaction') !== null || this.get('isEditing');
    }).property('transaction.childTransactions.length', 'isEditing'),

    /**-------------------------
      Events
    ---------------------------*/

    click: function click() {
      if (this.get('isEditing')) {
        this.sendAction('toEditDetail', 'splits/splits-pane');
      }
    }
  });
});