define('front-end-cli/pods/components/money-input/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    /**-------------------------
      External Binding
    ---------------------------*/

    value: null,

    /**-------------------------
      Properties
    ---------------------------*/

    displayValue: null,

    /**-------------------------
      Methods
    ---------------------------*/

    initialize: _ember['default'].on('init', function () {
      this._setDisplayValue();
    }),

    /**
      We handle reformatting on focus-out, but when
      a new value comes in via normalization, we do
      want to updtae the displayValue.
    */
    valueObserver: _ember['default'].observer('value', function () {
      var value = this.get('value'),
          displayValue = this.get('displayValue').replace(/,/g, '.') || '',
          displayValueFloat = accounting.toFixed(displayValue, 2);

      // Disregarding the formatting,
      // are the values the same?
      if (displayValueFloat !== value) {
        this._setDisplayValue();
      }
    }),

    /**
      Whenever the displayValue changes,
      strip disallowed characters if needed
      and update the value.
    */
    displayValueObserver: _ember['default'].observer('displayValue', function () {
      var _this = this;

      var displayValue = this.get('displayValue') || '';

      // Remove disallowed input
      if (/[^\d,-]/.test(displayValue)) {
        _ember['default'].run.next(function () {
          _this.set('displayValue', displayValue.replace(/\./g, ',').replace(/[^\d,-]/g, ''));
        });
      } else {
        displayValue = displayValue.toString().replace(/,/, '.');
        var floatValue = accounting.toFixed(displayValue, 2),
            value = this.get('value');

        if (floatValue !== accounting.toFixed(value, 2)) {
          this.set('value', floatValue);
        }
      }
    }),

    _setDisplayValue: function _setDisplayValue() {

      var value = this.get('value'),
          formattedValue = accounting.formatMoney(value, { symbol: '', decimal: ',', thousand: '', format: '%v' }),
          displayValue = this.get('displayValue');

      if (formattedValue !== displayValue) {
        this.set('displayValue', formattedValue);
      }
    },

    actions: {
      focusOutInput: function focusOutInput() {
        this._setDisplayValue();
      }
    }
  });
});