define('front-end-cli/models/debt', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    profile: _emberData['default'].belongsTo('profile'),

    type: _emberData['default'].attr('number'),

    debtMortgage: _emberData['default'].belongsTo('debt-mortgage'),
    debtStudentLoan: _emberData['default'].belongsTo('debt-student-loan'),
    debtConsumerCredit: _emberData['default'].belongsTo('debt-consumer-credit'),

    isMortgage: (function () {
      return this.get('type') == 1;
    }).property('type'),

    isStudentLoan: (function () {
      return this.get('type') == 2;
    }).property('type'),

    isConsumerCredit: (function () {
      return this.get('type') == 3;
    }).property('type')
  });
});