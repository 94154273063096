define('front-end-cli/views/application/accountmenu-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({
    elementId: 'accountMenuButton',
    classNameBindings: [':modal-trigger', 'isOpen:open', 'isActive:active'],
    click: function click() {
      this.get('controller').send('toggleModal', 'application/accountmenu-modal');
    },
    isActive: (function () {
      return this.get('controller.isAccountRoute');
    }).property('controller.isAccountRoute'),
    isOpen: (function () {
      return this.get('controller.modal') === 'application/accountmenu-modal';
    }).property('controller.modal')
  });
});