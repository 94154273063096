define('front-end-cli/pods/components/help-modal/component', ['exports', 'ember'], function (exports, _ember) {
  var _help = {
    'timeline': {
      title: 'Deze Maand',
      body: 'Hoe sta je er deze maand voor. Wat staat je nog te wachten en wat is er tot nu toe gebeurd.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl'
    },
    'trends': {
      title: 'Trends',
      body: 'De trends pagina toont je het verloop van je resultaat en saldo over een langere periode.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl'
    },
    'legacy.overview': {
      title: 'Overzicht',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Man_Report_IO.htm',
      body: 'Inzicht krijgen door een uitgebreide analyse van je inkomsten en uitgaven.'
    },
    'legacy.transactions': {
      title: 'Transacties',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Man_Trans.htm',
      body: 'Alle mutaties van je toegevoegde bankrekening(en) inzien en bewerken.'
    },
    'legacy.compare': {
      title: 'Vergelijken',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Man_Report_Compare.htm',
      body: 'Vergelijk jezelf anoniem met andere gebruikers van MijnGeldzaken Huishoudboekje. Om hiervan gebruik te kunnen maken moet je eerst jouw profiel invullen.'
    },
    'legacy.contracts': {
      title: 'Contracten',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Man_Archive_Contract.htm',
      body: 'In de loop der tijd sluit je heel wat contracten af. Maar bijna niemand heeft een goed overzicht van alle contracten, opzegtermijnen en looptijden. Met contractbeheer voeg je gemakkelijk al je contracten toe, inclusief documenten. MijnGeldzaken Huishoudboekje herinnert je automatisch wanneer je contracten aflopen.'
    },
    'legacy.receipts': {
      title: 'Bonnetjes',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Man_Archive_Tickets.htm',
      body: 'Met Bonnetjes in MijnGeldzaken Huishoudboekje voeg je makkelijk al je bonnetjes toe aan je eigen archief. Zo heb je altijd een bewijs voor de garantie bij de hand. Bonnen vervagen niet in een schoenendoos onder het bed. Je voegt bonnen toe via MijnGeldzaken Huishoudboekje of via de gratis mobile app.',
      listItems: [{
        title: 'Meer over de mobiele app',
        targetBlank: true,
        href: 'https://www.mijngeldzaken.nl/watishet'
      }]
    },
    'legacy.paycheck': {
      title: 'Loonstroken',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_SW_Algemeen.htm',
      body: 'Met de salariswijzer van MijnGeldzaken Huishoudboekje zijn jouw loonstroken altijd veilig online beschikbaar en inzichtelijk gemaakt. Hier zie je een korte samenvatting met de belangrijkste informatie en kan je altijd je originele loonstrook pdf raadplegen.'
    },
    'whatif.retirement': {
      title: 'Pensioen',
      width: 300,
      body: 'Na je pensionering verandert je financiële plaatje. Hoe verhouden je toekomstige inkomsten zich tot je verwachte uitgaven? Misschien kom je straks makkelijk rond, maar er kunnen ook fasen aanbreken dat je geld tekort komt. Met deze functionaliteit heb je de mogelijkheid om alvast inzicht te krijgen in je situatie voor straks zodat je nu al eventuele voorzorgsmaatregelen kan treffen indien nodig.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=Pensioen.htm'
    },
    'legacy.settings': {
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Account.htm'
    },
    'legacy.settings.plus': {
      title: 'MijnGeldzaken Huishoudboekje Plus',
      body: 'MijnGeldzaken Huishoudboekje Plus is een uitgebreidere versie van MijnGeldzaken Huishoudboekje. In tegenstelling tot de gratis MijnGeldzaken Huishoudboekje betaal je voor MijnGeldzaken Huishoudboekje Plus een klein bedrag per maand.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_AFASPP.htm'
    },
    'legacy.settings.balance': {
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Man_Report_Saldo_Start.htm'
    },
    'legacy.settings.categories': {
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Man_Group_Cat.htm'
    },
    'legacy.settings.labels': {
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Man_Group_Label.htm'
    },
    'legacy.settings.importrules': {
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_TT_How_Step2.htm'
    },
    'legacy.settings.export': {
      title: 'Data exporteren',
      body: 'Wil je het volledige inzicht van MijnGeldzaken Huishoudboekje op je eigen computer hebben, voor je eigen administratie? Met MijnGeldzaken Huishoudboekje Plus sla je de gehele administratie op, zodat je een back-up hebt of het kunt gebruiken voor andere programma\'s.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_AM_Export.htm'
    },
    'legacy.accounts': {
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_AccUpd.htm'
    },
    'legacy.importrules': {
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Importeer.htm'
    },
    'legacy.export': {
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_AM_Export.htm'
    },
    'legacy.categories': {
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Man_Group_Cat.htm'
    },
    'legacy.labels': {
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Man_Group_Label.htm'
    },
    'legacy.plugin': {
      // title: 'De Bijwerk Assistent',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_BWA_Start.htm'
    },
    'legacy.plus': {
      // title: 'De Bijwerk Assistent',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_AFASPP.htm'
    },
    'accountmanagement.firsttimesetup': {
      title: 'Een rekening toevoegen aan MijnGeldzaken Huishoudboekje',
      body: 'Voor je met MijnGeldzaken Huishoudboekje aan de slag kan moet je eerst een rekening toevoegen. Indien je geen bankrekening wilt koppelen, kan je geen gebruik maken van MijnGeldzaken Huishoudboekje.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_AccAdd.htm',
      listItems: [{
        title: 'Verwijder je account',
        href: '#/app/instellingen'
      }]
    },
    'accountmanagement.setup': {
      title: 'Een rekening toevoegen aan MijnGeldzaken Huishoudboekje',
      body: 'Voeg hier een extra rekening toe aan je MijnGeldzaken Huishoudboekje account',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_AccAdd.htm'
    },
    'accountmanagement.cashbook': {
      title: 'Kasboek',
      body: 'Met behulp van een kasboek kun je jouw contante transacties bijhouden. Op deze pagina leggen we uit hoe je een kasboek aanmaakt.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Man_Accnt_Cash.htm'
    },
    'report': {
      title: 'Rapport maken',
      body: 'Maak een PDF-rapport van financiële gegevens.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/?url=AP_Nibud_Report.htm'
    },
    'income-expenses.income': {
      title: 'Je Inkomsten',
      body: 'Een nauwkeurig overzicht van je inkomsten binnen de geselecteerde periode.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl'
    },
    'income-expenses.expenses': {
      title: 'Je Uitgaven',
      body: 'Zie waar je je geld binnen de geselecteerde periode aan uit hebt gegeven.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl'
    },
    'budget.index': {
      title: 'Budgetten',
      body: 'Met budgetten geef je aan hoeveel je, per categorie, uit wilt geven of verwacht te onvangen.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl'
    },
    'budget.matrix': {
      title: 'Budgetten instellen',
      body: 'Met budgetten geef je aan hoeveel je, per categorie, uit wilt geven of verwacht te onvangen.',
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl'
    },
    'default': {
      title: null,
      body: null,
      width: 260,
      href: 'https://help.mijnhuishoudboekje.mijngeldzaken.nl'
    }
  };

  /**
    Usage:
    - When a route is not listed above, it'll use the
      default helpModal content.
    - The HelpModal's width can be adjusted per route
    - An array with additional listMenuItem's can be set
      per route, these listmenuItems can trigger either
      actions (via `action` key) or location changes
      (via `href` key).
  
    ```
    {
      title: <string>,
      body: <string>,
      href: <url>,
      width: <integer>,
      listItems: [
        {
          title: <string>,
          targetBlank: <boolean>, // optional
          href: <url>
        },
        {
          title: <string>,
          action: <string>
        }
      ]
    }
    ```
  */

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['HelpModal modal-container'],

    // External bindings
    applicationController: null,

    // Properties
    content: null,
    isEmpty: false,
    searchResults: [],
    searchTerm: null,
    searchSuggestion: null,

    widthStyle: (function () {
      var width = this.get('content.width');
      return ('width:' + width + 'px;').htmlSafe();
    }).property('content'),

    searchTermObserver: (function () {
      // What the User actually typed
      var searchTerm = this.get('searchTerm');

      if (searchTerm.length < 3) {
        this.set('searchSuggestion', null);
        this.set('searchResults', []);
      } else {
        var suggestionUrl = 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/suggest.php?personal=true&query=' + _ember['default'].String.htmlSafe(searchTerm);

        // Remove custom header
        if (_ember['default'].$.ajaxSettings.headers['X-AFAS-PERSONAL-CSRF']) {
          delete _ember['default'].$.ajaxSettings.headers['X-AFAS-PERSONAL-CSRF'];
        }

        _ember['default'].$.get(suggestionUrl).then((function (searchSuggestions) {
          // searchSuggestions: what the KB suggests the user is trying to find. If the best suggestion is changed since the last KB query...
          if (searchSuggestions[0]) {
            if (this.get('searchSuggestion') !== searchSuggestions[0].value) {
              this.set('searchSuggestion', searchSuggestions[0].value);

              // If set again, remove custom header.
              if (_ember['default'].$.ajaxSettings.headers['X-AFAS-PERSONAL-CSRF']) {
                delete _ember['default'].$.ajaxSettings.headers['X-AFAS-PERSONAL-CSRF'];
              }

              var resultsUrl = 'https://help.mijnhuishoudboekje.mijngeldzaken.nl/search.php?personal=true&query=' + this.get('searchSuggestion');
              _ember['default'].$.get(resultsUrl).then((function (searchResults) {
                if (searchResults.results[0]) {
                  searchResults = searchResults.results;

                  // Search results can contain quite a large body of text. This text is in snapshots, divided by ... . Just pick the first snapshot.
                  searchResults.forEach(function (item, index, self) {
                    var splitText = item.text.split('...');
                    item.text = splitText[0] + '...';
                  });

                  /* Try to prioritize returned search results based on their title: if the title contains the entire searchSuggestion, it is placed at the top.
                   * If it doesn't, but does match the search term the User provided, it is recorded as a partial match and placed at the top as well.
                   */
                  var prioritySearchResults = [],
                      partialPrioritySearchResults = [];

                  searchResults.forEach((function (item, index, self) {
                    if (item.title[0].toLowerCase().indexOf(this.get('searchSuggestion').toLowerCase()) > -1) {
                      prioritySearchResults.pushObject(item);
                    } else if (item.title[0].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                      partialPrioritySearchResults.pushObject(item);
                    }
                  }).bind(this));

                  partialPrioritySearchResults.forEach(function (item, index, self) {
                    searchResults.removeObject(item);
                    searchResults.unshift(item);
                  });

                  prioritySearchResults.forEach(function (item, index, self) {
                    searchResults.removeObject(item);
                    searchResults.unshift(item);
                  });

                  this.set('searchResults', searchResults);
                } else {
                  this.set('searchResults', []);
                }
              }).bind(this));

              // If header not set, set custom header
              if (!_ember['default'].$.ajaxSettings.headers['X-AFAS-PERSONAL-CSRF']) {
                _ember['default'].$.ajaxSetup({
                  headers: {
                    'X-AFAS-PERSONAL-CSRF': encodeURIComponent(this.get('applicationController.currentUser.model.csrf'))
                  }
                });
              }
            }
          } else {
            this.set('searchSuggestion', null);
            this.set('searchResults', []);
          }
        }).bind(this));

        // If header not set, set custom header
        if (!_ember['default'].$.ajaxSettings.headers['X-AFAS-PERSONAL-CSRF']) {
          _ember['default'].$.ajaxSetup({
            headers: {
              'X-AFAS-PERSONAL-CSRF': encodeURIComponent(this.get('applicationController.currentUser.model.csrf'))
            }
          });
        }
      }
    }).observes('searchTerm'),

    currentPath: (function () {
      return this.get('applicationController.currentPath');
    }).property('applicationController.currentPath'),

    isBudget: (function () {
      // Get route
      var route = this.get('applicationController.currentPath');

      if (route) route = route.replace(/\.index/, '').replace(/\.catch/, '');

      // Deal with special cases
      if (this.get('applicationController.sidebarlessed')) {
        if (!this.get('applicationController.currentUser.model.accounts.length')) {
          route = 'accountmanagement.firsttimesetup';
        }
      }
      if (route === 'index') {
        if (!this.get('applicationController.currentUser.model.accounts.length')) {
          route = 'index.no-accounts';
        }
      }

      if (route === 'budget' || route === 'budget.matrix') {
        return true;
      } else {
        return false;
      }
    }).on('init').observes('currentPath'),

    setContent: (function () {

      // Get route
      var route = this.get('applicationController.currentPath');

      if (route) route = route.replace(/\.index/, '').replace(/\.catch/, '');

      // Deal with special cases
      if (this.get('applicationController.sidebarlessed')) {
        if (!this.get('applicationController.currentUser.model.accounts.length')) {
          route = 'accountmanagement.firsttimesetup';
        }
      }
      if (route === 'index') {
        if (!this.get('applicationController.currentUser.model.accounts.length')) {
          route = 'index.no-accounts';
        }
      }

      // Set title, width, body, href, extend from default
      var h = _help[route] ? _ember['default'].$.extend({}, _help['default'], _help[route]) : _help['default'];

      if (!h.title && !h.body) {
        this.set('isEmpty', true);
      }

      this.set('content', h);

      /* Autofocus the search field whenever the HelpModal is opened (even after opening it a second time).
       * This is put in Ember.run.later because otherwise the field might not be rendered yet.
       */
      _ember['default'].run.later(function () {
        var searchField = _ember['default'].$('input.HelpModal-searchField');
        if (searchField !== undefined) {
          searchField.focus();
        }
      });
    }).on('init').observes('currentPath'),

    actions: {
      logout: function logout() {
        this.sendAction('logout');
      }
    }
  });
});