define('front-end-cli/controllers/profiler/misc-insurance', ['exports', 'ember', 'front-end-cli/mixins/profiler/sub-model-switch'], function (exports, _ember, _frontEndCliMixinsProfilerSubModelSwitch) {
  exports['default'] = _ember['default'].Controller.extend(_frontEndCliMixinsProfilerSubModelSwitch['default'], {
    needs: ['profiler'],
    selectObjects: _ember['default'].computed.alias('controllers.profiler.selectObjects'),
    /**
      Create and delete the appropriate
      type-records when the type changes.
       miscLifeInsurance
      miscBurialInsurance
      miscSavingsInsurance
      miscDamageInsurance
    */
    typeObserver: (function () {
      var type = this.get('model.type'),
          recordName,
          record;

      if (type == 1) {
        recordName = 'miscLifeInsurance';
        record = this.deleteRecords(['miscBurialInsurance', 'miscSavingsInsurance', 'miscDamageInsurance']);
      } else if (type == 2) {
        recordName = 'miscBurialInsurance';
        record = this.deleteRecords(['miscLifeInsurance', 'miscSavingsInsurance', 'miscDamageInsurance']);
      } else if (type == 3) {
        recordName = 'miscSavingsInsurance';
        record = this.deleteRecords(['miscLifeInsurance', 'miscBurialInsurance', 'miscDamageInsurance']);
      } else if (type == 4) {
        recordName = 'miscDamageInsurance';
        record = this.deleteRecords(['miscLifeInsurance', 'miscBurialInsurance', 'miscSavingsInsurance']);
      }

      record = this.store.createRecord(recordName, {
        miscInsurance: this.get('model')
      });

      /**
        Parent model needs to be saved to be able to the declare relation
      */
      if (!this.get('model.id')) {

        this.storeRelationlessRecord({
          recordName: recordName,
          record: record,
          parentName: 'miscInsurance',
          parent: this.get('model')
        });
      }
    }).observes('model.type')
  });
});