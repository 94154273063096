define('front-end-cli/pods/components/trends-balance-chart/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['TrendsBalanceChart'],

    width: 940,
    height: 250,
    initialBuild: false,
    transitionTimer: 0,
    xAxis: null,
    yAxis: null,
    xScale: null,
    yScale: null,
    area: null,
    periodDetails: null,

    /**-------------------------
     External Bindings
     ---------------------------*/

    allAccounts: [],
    selectedAccounts: [],
    hoverAccount: null,
    allAccountsBalance: null,

    /**-------------------------
      Methods
    ---------------------------*/

    didInsertElement: function didInsertElement() {
      this.send('insertDetails');
    },

    willDestroyElement: function willDestroyElement() {
      this.send('hideDetails');
    },

    /**-------------------------
     Calculated Properties
     ---------------------------*/

    // Filters the balance date points of all accounts returned by the API by the accounts the user has actually selected.
    selectedAccountsBalance: (function () {
      var selectedAccounts = this.get('selectedAccounts');

      // If the user has selected no accounts, we might as well return an empty array.
      if (selectedAccounts.length === 0) {
        return [];
      }

      var selectedAccountsBalance = this.get('allAccountsBalance').filter(function (accountsBalance, index, self) {
        if (selectedAccounts.findBy('id', accountsBalance.account) !== undefined && accountsBalance.balance !== null /* The API returns null for accounts that have no balance set */) {
            return true;
          }
      });

      return selectedAccountsBalance;
    }).property('selectedAccounts.[]'),

    // Sums selectedAccountsBalance to one balance per period.
    totalSelectedAccountsBalance: (function () {
      var totalBalance = [];

      if (this.get('selectedAccounts.length') > 0) {
        this.get('selectedAccountsBalance').forEach(function (accountsBalance, index, self) {
          var totalBalancePoint = totalBalance.find(function (item, index, self) {
            return item.period.toString() === accountsBalance.period.toString();
          });
          if (totalBalancePoint === undefined) {
            totalBalance.addObject({
              period: accountsBalance.period,
              balance: accountsBalance.balance
            });
          } else {
            if (accountsBalance.balance !== null) {
              totalBalancePoint.balance = totalBalancePoint.balance + accountsBalance.balance;
            }
          }
        });
      }

      return totalBalance;
    }).property('selectedAccounts.[]', 'selectedAccountsBalance.[]'),

    /* Calculates the balance to show the user for the account he is hovering on. Basically what is shown is the influence of flipping the switch of this account. This has two scenario's:
     * 1) The hovered account is in the selected accounts, in which case we show what part of the balance 'belongs' to the hovered account, or
     * 2) The hovered account is not in the selected accounts, in which case we show what the total balance would be if we add the hovered account's balance to it.
     */
    hoverAccountBalance: (function () {
      var hoverAccount = this.get('hoverAccount');
      var hoverAccountBalance = [];

      // If no account is hovered on, return an empty array.
      if (hoverAccount === null) {
        return hoverAccountBalance;
      }

      // Get the balance of the hovered account.
      var hoverAccountId = hoverAccount.get('id');
      var selectedAccounts = this.get('selectedAccounts');
      this.get('allAccountsBalance').forEach(function (accountBalance, index, self) {
        if (accountBalance.account === hoverAccountId) {
          hoverAccountBalance.addObject({
            period: accountBalance.period,
            balance: accountBalance.balance
          });
        }
      });

      // If the hovered account is not in the selected accounts, then add the total balance of the selected accounts to it.
      if (selectedAccounts.length > 0 && selectedAccounts.findBy('id', hoverAccountId) === undefined) {
        var totalSelectedAccountsBalance = this.get('totalSelectedAccountsBalance');

        // It could be that the only selectedAccount remaining is one that never had any balance information to begin with.
        if (totalSelectedAccountsBalance.length > 0) {
          hoverAccountBalance.forEach(function (hoverBalance, index, self) {
            var totalBalancePoint = totalSelectedAccountsBalance.find(function (item, index, self) {
              return item.period.toString() === hoverBalance.period.toString();
            });

            hoverBalance.balance += totalBalancePoint.balance;
          });
        }
      }

      return hoverAccountBalance;
    }).property('hoverAccount', 'allAccountsBalance.[]', 'totalSelectedAccountsBalance.[]'),

    // Calculates the domain of the graph scales. (i.e. the from to where the axis should be)
    maxGraphBoundaries: (function () {
      var totalSelectedAccountsBalance = this.get('totalSelectedAccountsBalance');

      if (totalSelectedAccountsBalance.length === 0) {
        totalSelectedAccountsBalance = this.get('allAccountsBalance').filter(function (item, index, self) {
          return item.balance !== null;
        });
      }

      var minBalance = d3.min(totalSelectedAccountsBalance, function (d) {
        return d.balance;
      }),
          maxBalance = d3.max(totalSelectedAccountsBalance, function (d) {
        return d.balance;
      }),
          minPeriod = d3.min(totalSelectedAccountsBalance, function (d) {
        return d.period;
      }),
          maxPeriod = d3.max(totalSelectedAccountsBalance, function (d) {
        return d.period;
      });

      // Make sure there always is a scale to show.
      if (minBalance === maxBalance) {
        minBalance -= 25;
        maxBalance += 25;
      } else {
        minBalance -= Math.abs(minBalance) * 0.1;
        maxBalance += Math.abs(maxBalance) * 0.1;
      }

      return {
        'minBalance': minBalance,
        'maxBalance': maxBalance,
        'minPeriod': minPeriod,
        'maxPeriod': maxPeriod
      };
    }).property('totalSelectedAccountsBalance.[]'),

    // Creates the initial chart
    createChart: (function () {
      var _this = this,
          svg,
          margin = { top: 25, right: 95, bottom: 20, left: 75 },
          width = this.get('width') - margin.left - margin.right,
          height = this.get('height') - margin.top - margin.bottom;

      // Sets the type of the scale and how much pixels on the screen the actual graph (minus the legends) may consume.
      var xScale = d3.time.scale().range([0, width]),
          yScale = d3.scale.linear().range([height, 0]);

      // Creates the axis (legends) to the graph.
      var xAxis = d3.svg.axis().scale(xScale).orient('bottom').tickFormat(function (d, i) {
        return '' + moment(d).format('MMM').replace(/\./, '');
      });
      var yAxis = d3.svg.axis().scale(yScale).orient('left').tickFormat(function (d, i) {
        return accounting.formatMoney(d, '€', 0);
      });

      /* Specific to the area graph: tells D3 what area to draw in (X and Y coördinates on the screen).
       * To do this, we convert data point values (d) to screen coordinates using the scale. For every period (x), we basically want to fill the area between 0 (y) and the actual balance (y0).
       * However, 0 may be out of bounds for the graph area. Therefore, if the coordinate for 0 > height, we draw to the max of the graph instead.
       */
      var area = d3.svg.area().x(function (d) {
        return xScale(d.period);
      }).y(function (d) {
        if (yScale(0) > height) {
          return height;
        } else {
          return yScale(0);
        }
      }).y0(function (d) {
        if (yScale(d.balance) > height) {
          return height;
        } else {
          return yScale(d.balance);
        }
      });

      // Set the properties we need to update the graph later on.
      this.set('xScale', xScale);
      this.set('yScale', yScale);
      this.set('xAxis', xAxis);
      this.set('yAxis', yAxis);
      this.set('area', area);

      // Draw the graph area

      // SVG viewBox in IE needs a fixed height
      if (bowser.msie) {

        svg = d3.select('#' + this.elementId).append('svg').classed('BalanceChart', true).attr('viewBox', '0 0 ' + ' ' + (width + margin.left + margin.right) + ' ' + (height + margin.top + margin.bottom)).attr({
          height: height + margin.top + margin.bottom
        }).append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')'); // Leave space for the legends
      } else {

          svg = d3.select('#' + this.elementId).append('svg').classed('BalanceChart', true).attr('viewBox', '0 0 ' + ' ' + (width + margin.left + margin.right) + ' ' + (height + margin.top + margin.bottom)).attr('preserveAspectRatio', 'none meet').append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')'); // Leave space for the legends
        }

      // Add legends (axis)

      svg.append('g').attr('class', 'x-axis-balance axis').attr('transform', 'translate(0,' + height + ')').call(xAxis);

      svg.append('g').attr('class', 'y-axis-balance axis').call(yAxis).append('text').attr('transform', 'rotate(-90)').attr('y', 6).attr('dy', '.71em').style('text-anchor', 'end');

      // Draw area graphs
      svg.append('g').attr('class', 'balance-total').append('path').attr('class', 'area');

      // svg.append('g')
      //   .attr('class', 'balance-hoveraccount')
      //   .append('path')
      //   .attr('fill', '#D8D8D8')
      //   .style('stroke-width', '2px');

      this.set('initialBuild', true);
      _ember['default'].run.once(this, 'updateChart');
    }).on('didInsertElement'),

    // Updates the initial graph when the user changes the selection of accounts.
    updateChart: (function () {

      var _this = this,
          xScale = this.get('xScale'),
          yScale = this.get('yScale'),
          xAxis = this.get('xAxis'),
          yAxis = this.get('yAxis'),
          area = this.get('area'),
          svg = d3.select('#' + this.elementId).select('g'),
          initialBuild = this.get('initialBuild'),
          maxGraphBoundaries = this.get('maxGraphBoundaries'),
          totalSelectedAccountsBalance = this.get('totalSelectedAccountsBalance'),
          hoverAccountBalance = this.get('hoverAccountBalance'),
          transitionTimer = initialBuild ? 0 : 750,

      // If it is the initial build and all accounts are disabled (or for other weird reasons there is no totalSelectedAccountsBalance (yet) axis do need to be drawn according to possible data, so the following variable is added:
      axisBalance = initialBuild && totalSelectedAccountsBalance.length <= 0 ? this.get('allAccountsBalance') : totalSelectedAccountsBalance;

      /* Rescale axis, so that the remaining data makes sense (there could, for instance, be one account that has 10.000 balance, while another only has 100.
       * Don't rescale axis if all accounts are deselected.
       */
      if (axisBalance.length > 0) {
        xScale.domain([maxGraphBoundaries.minPeriod, maxGraphBoundaries.maxPeriod]);
        yScale.domain([maxGraphBoundaries.minBalance, maxGraphBoundaries.maxBalance]);

        /* Calculate which values to show on the axis, such that the value labels can be completely shown.
         * - For the X-axis this means: every 15th of the month the shorthand of a month is shown, unless the 15th is less than 3 data points 'away' from the limit of the graph,
         *   in which case the 20th is chosen for the beginning of the graph, or the 10th for the end of the graph.
         * - For the Y-axis this means: A value will be shown at the 10% and 90% range of the axis. A third value will be shown at 0
         *   if the user's balance ever goes below 0 and if it won't overlap with another axis value (further away than 6% of the total range takes into account an axis font size of 12px),
         *   or at the 45% mark of the range of the axis otherwise.
         */
        var yScaleValueRange = maxGraphBoundaries.maxBalance - maxGraphBoundaries.minBalance,
            firstYScalePoint = Math.round(maxGraphBoundaries.maxBalance - yScaleValueRange * 0.1),
            lastYScalePoint = Math.round(maxGraphBoundaries.minBalance + yScaleValueRange * 0.1),
            yScaleValues = [firstYScalePoint, lastYScalePoint < 0 - yScaleValueRange * 0.06 && firstYScalePoint > yScaleValueRange * 0.06 ? 0 : Math.round(firstYScalePoint - (firstYScalePoint - lastYScalePoint) / 2), lastYScalePoint],
            xScaleValues = [],
            xScaleDateValue = 15,
            firstDataPoint = axisBalance.get('firstObject'),
            firstXScaleDateValue = firstDataPoint.period.getDate() > 12 && firstDataPoint.period.getDate() < 18 ? 20 : 15,
            firstXScaleMonthValue = firstDataPoint.period.getMonth(),
            lastDataPoint = axisBalance.get('lastObject'),
            lastXScaleDateValue = lastDataPoint.period.getDate() > 12 && lastDataPoint.period.getDate() < 18 ? 10 : 15,
            lastXScaleMonthValue = lastDataPoint.period.getMonth();

        axisBalance.forEach(function (item, index, enumberable) {
          var month = item.period.getMonth();
          if (month === firstXScaleMonthValue || month !== lastXScaleMonthValue && month !== firstXScaleMonthValue || month === lastXScaleMonthValue) {
            xScaleValues.push(item.period);
          }
        });

        // Set the scales, and the values to show on them.
        xAxis.tickValues(xScaleValues);
        yAxis.tickValues(yScaleValues);
        xAxis.scale(xScale);
        yAxis.scale(yScale);
      }

      // Update the rescaling of axis to the graph.
      svg.select('.x-axis-balance').transition().duration(transitionTimer).ease('elastic', 1, 0.7).call(xAxis);
      svg.select('.y-axis-balance').transition().duration(transitionTimer).ease('elastic', 1, 0.7).call(yAxis);

      // Remove the hovered account SVG (the one the user just added to the selection of accounts) for a while, as the chart is transitioning to this state and having the hover will only confuse.
      svg.select('.balance-hoveraccount').select('path').datum([]).attr('d', area);

      // Transition the data in the chart to the new data.
      svg.select('.balance-total').select('path').datum(totalSelectedAccountsBalance).transition().duration(transitionTimer).ease('elastic', 1, 0.7).attr('d', area).each('end', function () {
        /* .. And re-add the effect of clicking on the account the user is currently hovering on (if he is still hovering, as he might have moved his mouse to the chart for instance).
          * This is performed without a transition, because if the user is no longer hovering an account when the transition ends, the hovered account is not removed from the chart.
         * While this could be 'patched', removing the hovered account would at least have to wait for the transition effect to finish, which beats the purpose of quickly hovering over accounts to see the effect.
         * I know, I know, transitioning looks very pretty, but this is for the best :-(.
         */

        var hoverAccountBalance = _this.get('hoverAccountBalance');
        svg.select('.balance-hoveraccount').select('path').datum(hoverAccountBalance).attr('d', area);

        svg.selectAll('#circles1').remove();

        svg.selectAll('circle').data(totalSelectedAccountsBalance).enter().append('circle').attr({
          id: 'circles1',
          cx: function cx(d) {
            return xScale(d.period);
          },
          cy: function cy(d) {
            return yScale(d.balance);
          },
          r: 3,
          'class': 'BalanceChart-circle',
          fill: 'black'
        });

        svg.selectAll('#rectBack1').remove();

        svg.selectAll('#rectBack1').data(totalSelectedAccountsBalance).enter().append('rect').attr({
          id: 'rectBack1',
          width: '50px',
          height: '50px',
          fill: 'transparent',
          x: function x(d) {
            return xScale(d.period) - 25;
          },
          y: function y(d) {
            return yScale(d.balance) - 15;
          }
        }).on('mouseover', function (d) {
          if (!Modernizr.touch) {
            _this.send('showDetails', d, d3.event);
          }
        }).on('mouseleave', function (d) {
          if (!Modernizr.touch) {
            _this.send('hideDetails');
          }
        }).on('touchstart', function (d) {
          if (Modernizr.touch) {
            _this.send('showDetails', d, d3.event);
          }
        }).on('touchend', function (d) {
          if (Modernizr.touch) {
            _this.send('hideDetails');
          }
        });

        svg.select('#subtext1').remove();
        svg.append('text').data(totalSelectedAccountsBalance).attr({
          id: 'subtext1',
          x: function x(d) {
            return xScale(d.period) + 10;
          },
          y: function y(d) {
            return yScale(d.balance);
          }
        }).text(function (d) {
          return moment(firstDataPoint.period).endOf('month').format('DD MMM YY');
        });

        svg.select('#subtext2').remove();
        svg.append('text').data(totalSelectedAccountsBalance).attr({
          id: 'subtext2',
          x: function x(d) {
            return xScale(d.period) + 10;
          },
          y: function y(d) {
            return yScale(d.balance) + 15;
          }
        }).text(function (d) {
          return accounting.formatMoney(d.balance, { precision: 0 });
        });
      });

      /* Draw the overlay required for tracking mouse movement
       * Credits for idea: http://bl.ocks.org/Frozenfire92/80ba8c6f844cf48708c4
       *
       * Remove the current rectangle tracking mouse move actions to show a tooltip on X and Y coordinates and add a new one using the new (updated) scales.
       * Simply updating the mousemove function does not work.
       */
      svg.select('rect').remove();
      if (totalSelectedAccountsBalance.length > 0) {
        svg.append('rect').attr('class', 'balance-overlay').attr('width', this.get('width')).attr('height', this.get('height'));

        // .on('mouseout', function () {
        //   _this.hidePeriodDetails();
        // })
        // .on('mousemove', function () {
        //   var dateTime = xScale.invert(d3.mouse(this)[0]),
        //     date = d3.time.day.floor(dateTime).toString(),
        //     d = totalSelectedAccountsBalance.find(function (item, index, self) {
        //       if (date === item.period.toString()) {
        //         return item;
        //       }
        //     });
        //
        //   if (d) {
        //     _this.showPeriodDetails(d, xScale(d.period), yScale(d.balance));
        //   }
        // });
      }

      this.set('initialBuild', false);
    }).observes('selectedAccounts.[]'),

    actions: {

      // Update the graph to show the effect of clicking on the account hovered on.
      // updateHoverAccount: function(){
      //   var area = this.get('area'),
      //       hoverAccountBalance = this.get('hoverAccountBalance');
      //
      //   d3.select('.balance-hoveraccount').select('path').datum(hoverAccountBalance).attr('d', area);
      // }.observes('hoverAccount'),

      insertDetails: function insertDetails() {
        var periodDetails = _ember['default'].$('<div class="BalanceChartDetails"></div>');
        this.set('periodDetails', periodDetails);
        _ember['default'].$('body').append(periodDetails);
      },

      showDetails: function showDetails(d, event) {
        var m = moment(d.period),
            month = m.format('MMMM'),
            year = m.format('YYYY'),
            balance = d.balance;

        var target = _ember['default'].$(event.target);

        balance = accounting.formatMoney(balance, { precision: 0 });

        var periodDetails = this.get('periodDetails');

        var htmlString = '\n      <div class="BalanceChartDetails-date">\n      <span class="BalanceChartDetails-month">' + month + '</span>\n      <span class="BalanceChartDetails-year">' + year + '</span>\n      </div>\n      <div class="BalanceChartDetails-title">Saldo</div>\n      <div class="BalanceChartDetails-result">' + balance + '</div>';

        var _top = target.offset().top - 80 - 10;

        periodDetails.addClass('is-resultsChart');
        _top = target.offset().top - 88;

        periodDetails.css({
          top: _top,
          left: target.offset().left - (periodDetails.width() / 2 - 25)
        }).html(htmlString).addClass('is-active');
      },

      hideDetails: function hideDetails() {
        this.get('periodDetails').removeClass('is-active').empty();
      }

    }

  });
});