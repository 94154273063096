define('front-end-cli/initializers/modernizr', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(container, application) {
    application.register('util:modernizr', Modernizr, { instantiate: false });
    application.inject('view', 'modernizr', 'util:modernizr');
  }

  exports['default'] = {
    name: 'modernizr',
    initialize: initialize
  };
});