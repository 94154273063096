define('front-end-cli/controllers/accountmanagement/edit/step4', ['exports', 'ember', 'front-end-cli/mixins/steps'], function (exports, _ember, _frontEndCliMixinsSteps) {
  exports['default'] = _ember['default'].Controller.extend(_frontEndCliMixinsSteps['default'], {

    needs: ['accountmanagementSetup'],
    bankChoice: _ember['default'].computed.alias('parentController.bankChoice'),
    error: _ember['default'].computed.alias('parentController.error'),

    stepLength: 8,
    step: 1,

    currentGuide: (function () {
      return 1;
    }).property(),

    guide1: _ember['default'].computed.equal('currentGuide', 1),
    guide2: _ember['default'].computed.equal('currentGuide', 2),
    guide3: _ember['default'].computed.equal('currentGuide', 3),
    guide4: _ember['default'].computed.equal('currentGuide', 4),
    guide5: _ember['default'].computed.equal('currentGuide', 5),
    guide6: _ember['default'].computed.equal('currentGuide', 6),
    guide7: _ember['default'].computed.equal('currentGuide', 7),
    guide8: _ember['default'].computed.equal('currentGuide', 8),
    guide9: _ember['default'].computed.equal('currentGuide', 9),
    guide10: _ember['default'].computed.equal('currentGuide', 10),

    actions: {

      setCurrentGuide: function setCurrentGuide(guide) {
        this.set('currentGuide', guide);
      },

      // EventedForm view sends triggers action on enter
      pressedEnter: function pressedEnter() {
        if (this.get('bankChoice.data.isComplete')) {
          this.send('createAndSyncBankLogin');
        }
      }

    }
  });
});