define('front-end-cli/pods/components/label-select/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['LabelSelect'],
    selectizePlugins: [{ name: 'remove_button', options: { title: 'Verwijderen' } }],

    /**-------------------------
     External bindings
     ---------------------------*/

    labels: null, // All labels this User has ever used.
    categories: null,
    transactions: null,
    subcategory: null,
    category: null,
    type: null, // 'income' or 'expense'
    filterLabelsString: null, // Labels that currently is filtered on.

    /**-------------------------
     Properties
     ---------------------------*/

    isUpdating: false, // When this is true, a 'curtain' is shown (because the page is loading)

    /**-------------------------
     Calculated Properties
     ---------------------------*/

    // Gets all the transactions in the current overview.
    filteredTransactions: (function () {
      var transactions = this.get('transactions'),
          result = null;

      if (transactions === null) {
        return [];
      }

      if (this.get('subcategory')) {
        result = transactions.filter(function (transaction) {
          return transaction.get('subcategory.id') === this.get('subcategory.id');
        }, this);
      } else {
        var allowedSubCategories = [];

        if (this.get('category')) {
          allowedSubCategories = this.get('category.subcategories').filterBy('enabled', true);
        } else {
          var type = this.get('type');
          var allowedCategories = this.get('categories').filter(function (category) {
            if (category.get('enabled')) {
              if (type === 'income') {
                return category.get('currentNetted') > 0;
              } else {
                return category.get('currentNetted') < 0;
              }
            }
          });
          allowedCategories.forEach(function (category, index, self) {
            category.get('subcategories').filterBy('enabled', true).forEach(function (subcategory, index, self) {
              allowedSubCategories.pushObject(subcategory);
            });
          });
        }

        result = transactions.filter(function (transaction) {
          return allowedSubCategories.findBy('id', transaction.get('subcategory.id')) !== undefined;
        });
      }

      return result;
    }).property('transactions.@each', 'transactions.@each.subcategory', 'subcategory', 'category', 'categories', 'type', 'transactions.@each.labels.length'),

    // Labels the overview is currently filtered on
    currentFilterLabels: (function () {
      var filterLabels = [];

      if (this.get('filterLabelsString') != null) {
        var availableFilterLabels = this.get('availableFilterLabels'),
            allLabels = this.get('labels'),
            filterLabelStrings = this.get('filterLabelsString').split('~');

        filterLabelStrings.forEach(function (labelString, index, self) {
          if (allLabels !== null && availableFilterLabels !== null) {
            var foundLabel = allLabels.findBy('name', labelString);

            if (foundLabel !== undefined) {
              var notInOverview = availableFilterLabels.findBy('id', foundLabel.get('id')) === undefined;
              filterLabels.pushObject({
                name: foundLabel.get('name'),
                notInOverview: notInOverview,
                sortProperty: notInOverview ? 'b' : 'a'
              });
            } else {
              // Label most likely does not exist at all.
              filterLabels.pushObject({
                name: labelString,
                notInOverview: true,
                sortProperty: 'b'
              });
            }
          } else {
            // Label most likely does not exist at all.
            filterLabels.pushObject({
              name: labelString,
              notInOverview: true,
              sortProperty: 'b'
            });
          }
        });
      }

      this.set('isUpdating', false); // Overview is done loading, so unset the 'curtain'.
      return filterLabels;
    }).property('filterLabelsString', 'availableFilterLabels.length', 'labels.length'),

    // The labels used in the current set of transactions the overview is created from. Separate objects are created, because we need to add an attribute based on if it is in use in the current overview.
    availableFilterLabels: (function () {
      var labels = [];
      this.get('filteredTransactions').forEach(function (transaction, index, self) {
        if (transaction.get('enabled')) {
          var transactionLabels = transaction.get('labels');

          if (transactionLabels.get('length') > 0) {
            transactionLabels.forEach(function (label, index, self) {
              if (labels.findBy('id', label.get('id')) === undefined) {
                labels.pushObject(label);
              }
            });
          }
        }
      });
      return labels;
    }).property('filteredTransactions.length'),

    // The labels shown in the selectize component. Separate objects are created, because we need to add an attribute based on if it is in use in the current overview.
    selectizeFilterLabels: (function () {
      var selectizeFilterLabels = [],
          availableFilterLabels = this.get('availableFilterLabels'),
          labels = this.get('labels'),
          currentFilterLabels = this.get('currentFilterLabels');

      // Check currentFilterLabels first, because the user might have edited the URL, causing the label not to be shown in the input field.
      currentFilterLabels.forEach(function (item, index, enumerable) {
        selectizeFilterLabels.pushObject(item);
      });

      if (labels !== null) {
        labels.forEach(function (item, index, enumerable) {
          if (selectizeFilterLabels.findBy('name', item.get('name')) === undefined) {
            var notInOverview = availableFilterLabels.findBy('id', item.get('id')) === undefined;
            selectizeFilterLabels.pushObject({
              name: item.get('name'),
              notInOverview: notInOverview,
              sortProperty: notInOverview ? 'b' : 'a'
            });
          }
        });
      }

      return selectizeFilterLabels;
    }).property('labels.length', 'availableFilterLabels.length', 'currentFilterLabels.length'),

    selectizeItemDiv: function selectizeItemDiv(data, escape) {
      return '<div data-value="' + data.label + '" class="Label">' + data.label + '</div>';
    },

    customOptionDiv: function customOptionDiv(data, escape) {
      return '<div data-value="' + escape(data.id) + '" data-selectable="" class="option ' + (data.data.notInOverview ? 'notInOverview' : '') + '">' + escape(data.label) + '</div>';
    },

    /**-------------------------
     Actions
     ---------------------------*/

    actions: {
      addLabel: function addLabel(label) {
        this.send('updateQueryParams', label.name, 'add');
      },

      removeLabel: function removeLabel(label) {
        this.send('updateQueryParams', label.name, 'remove');
      },

      updateQueryParams: function updateQueryParams(labelName, action) {
        this.set('isUpdating', true); // Overview will need to load new data, so set a curtain.
        var newQueryString = null;

        // Create the new query param: all filter labels connected with joined with ~ (a non-reserved safe url character)
        this.get('currentFilterLabels').forEach(function (label, index, self) {
          if (action !== 'remove' || label.name !== labelName) {
            if (newQueryString === null) {
              newQueryString = label.name;
            } else {
              newQueryString += '~' + label.name;
            }
          }
        });

        this.sendAction('transitionAction', { queryParams: { filterLabelsString: newQueryString } });
      }
    }
  });
});