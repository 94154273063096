define('front-end-cli/transforms/safestring', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return String(serialized).replace('&amp;', '&');
      }
      return serialized;
    },

    serialize: function serialize(deserialized) {
      if (deserialized) {
        return String(deserialized).replace('&amp;', '&');
      }
      return deserialized;
    }
  });
});