define('front-end-cli/routes/application', ['exports', 'ember', 'front-end-cli/config/environment', 'front-end-cli/mixins/application-instrumentation', 'ember-keyboard-shortcuts/mixins/route'], function (exports, _ember, _frontEndCliConfigEnvironment, _frontEndCliMixinsApplicationInstrumentation, _emberKeyboardShortcutsMixinsRoute) {
  exports['default'] = _ember['default'].Route.extend(_frontEndCliMixinsApplicationInstrumentation['default'], _emberKeyboardShortcutsMixinsRoute['default'], {
    sessionManager: _ember['default'].inject.service('session-manager'),
    user: null,
    runOnce: null,
    noSession: null,

    /**
      `adapters/application` triggers the `checkApiVersion`
      action after each API call. When a model hook makes multiple
      API calls it will trigger the action before the route
      finished transitioning. This `routeDidResolve` property
      tells if the initial transition was completed.
    */
    initialTransitionComplete: null,
    apiVersion: null,
    apiVersionHasChanged: false,

    /**
      Redirect `http` connections to `https` in case
      users enter via a manually entered `http` url.
    */
    beforeModel: function beforeModel() {

      var wl = window.location;
      if (wl.protocol != 'https:' && !/local|test/.test(wl.hostname)) {
        window.location.href = 'https:' + wl.href.substring(wl.protocol.length);
      }

      if (Modernizr.localstorage) {
        var currentVersion = _frontEndCliConfigEnvironment['default'].APP.version || '',
            userLastVersion = localStorage['version'] || '';

        if (currentVersion && currentVersion !== userLastVersion) {

          var currentParts = currentVersion.split('.'),
              userParts = userLastVersion.split('.');

          var mayorDifference = currentParts[0] != userParts[0],
              minorDifference = currentParts[1] != userParts[1],
              patchDifference = currentParts[2] != userParts[2];

          localStorage['version'] = currentVersion;

          if (mayorDifference || minorDifference || patchDifference) {
            //reload on any change in versionnumber
            window.location.reload(true);
          }
        } else {
          localStorage['version'] = currentVersion;
        }
      }
    },

    /**
      Load the user model, if it fails we'll transition to login
      via the error substate.
    */
    model: function model() {

      if (this.get('noSession')) {
        /**
          'noSession' means we're in the login route without session.
          The error substate sets 'noSession' when getting a 403.
        */
        return '';
      } else {

        var promises = {
          // Attempt to fetch all user data
          user: this.store.find('user', 1),
          // Here we make sure banks are loaded
          banks: this.store.find('bank')
        };

        return _ember['default'].RSVP.hash(promises).then(function (hash) {
          return hash.user;
        });
      }
    },

    afterModel: function afterModel(model, transition) {

      transition.then((function () {
        // Done transitioning
        this.set('initialTransitionComplete', true);
      }).bind(this));

      var user = model;
      var targetRoute = transition.targetName;

      this.get('sessionManager').set('appRoute', this);

      // Set currenUser model if there is a session
      if (user) {
        this.get('currentUser').set('model', user);
        this.get('sessionManager').initialize();
      }

      if (targetRoute !== 'legacy.login') {
        transition.send('welcomeTransition', user, targetRoute);
      }
    },

    actions: {

      /**
        Redirect to the welcome route (`indexRoute`), if not already, when new or back after a while.
        When no welcome is needed, we'll redirect users to the `timelineRoute`, if not already.
         @param  {object} user        An optional user model, if undefind we'll fetch currentUser
        @param  {string} targetRoute An optional string, if undefind we'll fetch currentPath
      */
      welcomeTransition: function welcomeTransition(user, targetRoute) {

        // AfterModel hook runs every redirect, we only want the 1st one
        if (this.get('runOnce')) return;
        this.set('runOnce', true);

        if (!user) user = this.get('currentUser.model');
        if (!targetRoute) targetRoute = this.controllerFor('application').get('currentPath');

        var seenWelcomeMessageDate = moment(user.get('state.seenWelcomeMessage')),
            startOfDay = moment().startOf('day'),
            showWelcomeMessage = seenWelcomeMessageDate.diff(startOfDay) < 0 || !seenWelcomeMessageDate.isValid(),
            isTimeline = targetRoute === 'timeline';

        // Ensure direct links –from email notification–
        // go straight to the paycheck route.
        if (/^paycheck/.test(targetRoute) && !user.get('state.newUser')) {
          return;
        }

        // When a user chose to use AP for just his paychecks,
        // we'll skip welcoming.
        var isPayCheckOnlyUser = this.get('currentUser.model.state.isPaycheckOnlyUser') === true,
            isNoPlusUser = this.get('currentUser.model.state.plus') === false,
            hasNoAccounts = !this.get('currentUser.model.accounts.length');

        if (isPayCheckOnlyUser && isNoPlusUser && hasNoAccounts) {

          this.transitionTo('paycheck');
        } else {

          // When `showWelcomeMessage` is true, `newUserEmber` is also caught.
          if (user.get('state.newUser') || showWelcomeMessage) {
            if (!isTimeline) {
              this.transitionTo('timeline');
            }
          } else if (isTimeline) {
            this.transitionTo('timeline');
          } else {
            if (/legacy\.(overview|login)/.test(targetRoute)) {
              this.transitionTo('timeline');
            }
          }
        }
      },

      loading: function loading(duration) {

        var controller = this.controllerFor('application');
        var loadingTimeEstimate = duration || true;
        controller.set('loading', loadingTimeEstimate);
        this.router.one('didTransition', function () {
          controller.set('loading', false);
        });
      },

      endLoading: function endLoading() {
        this.controllerFor('application').set('loading', false);
      },

      /**
        When user model fails to resolve, we come here and we'll
        transition to login route if not already.
      */
      error: function error(reason, transition) {

        if (reason.status && /^401$/.test(reason.status)) {
          //!/^2[0-9]{2}$/.test(reason.status) 200range
          this.set('noSession', true);
          if (transition.targetName != 'legacy.login') {
            this.transitionTo('legacy.login');
          } else {
            transition.retry();
          }
        } else {
          _ember['default'].Logger.error('ApplicationRoute::error', reason);
          return true; // Propogate error. Default handler renders error.hbs
        }
      },

      willTransition: function willTransition(transition) {

        /**
          Every API response checks if the `app-version` has changed.
          If so, `appVersionHasChanged` is set so we can reload here
          (when a transition is trigered and the user –hopefully–
          persited his data and states).
        */
        if (this.get('apiVersionHasChanged')) {
          // Finish the transition then force-reload
          this.transitionTo(transition.targetName);
          window.location.reload(true);
        }

        // When there ain't no session go straight to the login route.
        if (this.get('noSession')) this.transitionTo('legacy.login');

        var currentRouteName = this.controllerFor('application').get('currentRouteName');
        this.set('previousRouteName', currentRouteName);

        /**
          Legacy pages renew the user's session when loaded and
          will serve the login page when the session is @enum {[type]}xpired.
          Non legacy routes will renew their session via ping
          action which is triggered here, via willTransition.
           (Ping will also take care of transitioning
          to the login route)
        */
        if (/account.profile|whatif.retirement/.test(transition.targetName)) {
          this.send('ping');
        }
      },

      /**
        Ajax ping, keeps session alive without
        making exces calls to the server.
      */
      ping: function ping() {

        var currentTime = Math.round(new Date().getTime() / 1000),
            offsetTime = 5 * 60; // time between pings

        var nextPingTime = !this.get('nextPingTime') ? currentTime + offsetTime : '';
        this.set('nextPingTime', nextPingTime);

        if (currentTime > nextPingTime) {
          var _this = this;
          _ember['default'].$.ajax({
            'url': '/app/ping',
            'success': function success(response) {
              if (response.error.message == 'Logged out') {
                _this.transitionTo('legacy.login');
                return false;
              }
            }
          });
          this.set('nextPingTime', currentTime + offsetTime);
        }
      },

      hideModal: function hideModal(e) {

        var modal = this.controllerFor('application').get('modal'),
            target = e !== true ? e.target : '',
            force = e === true;

        if (modal) {
          if (target || force) {

            var closeselectors = '.modal-close';
            if (!force && (_ember['default'].$(target).is(closeselectors) || _ember['default'].$(target).parents().is(closeselectors))) {
              force = true;
            }

            /*
            Check if any of the modal selectors match the target or its
            parent elements. If they don't, and force isn't true, we'll hide the modal.
            */
            var skipselectors = '.modal-container, .modal-trigger';
            if (force || !_ember['default'].$(target).is(skipselectors) && !_ember['default'].$(target).parents().is(skipselectors)) {

              this.disconnectOutlet({
                outlet: 'modal',
                parentView: 'application'
              });

              this.controllerFor('application').set('modal', null);

              // HACK Remove the view from the container’s cache
              // If this isn’t done, 1.13.0 will fail by trying
              // re-registering the same view.
              var normalized = modal.replace(/.*\//, '');
              delete this.container.cache['-view-registry:main'][normalized];
            }
          }
        }
      },

      toggleModal: function toggleModal(modal, controller, model) {

        // HIDE ANY OTHER PREVIOUS MODAL
        var applicationController = this.controllerFor('application');

        if (modal && applicationController.get('modal')) {
          if (applicationController.get('modal') != modal) {

            // When a modal is already open and
            // is not the requested modal, we’ll
            // first close the old one.
            this.send('hideModal', true);
          } else if (controller && model) {

            // `controller` can be either string or object.
            // When its a string we’ll look it up.
            if (controller && _ember['default'].typeOf(controller) === 'string') {
              controller = this.get('container').lookup('controller:' + controller);
            }

            // When modal is already open, we'll switch the model
            // of the current modal-controller if needed.
            controller.set('model', model);
          }
        }

        // RENDER MODAL WHEN NOT DEFINED
        if (!applicationController.get('modal') || applicationController.get('modal') == modal) {

          this.render(modal, {
            into: 'application',
            outlet: 'modal',
            controller: controller || null,
            model: model || null
          });

          applicationController.set('modal', modal);
        }
        // HIDE WHEN DEFINED
        else {
            this.send('hideModal', true);
            // console.log('hideModal', modal);
          }
      },

      /**
        currentUser actions proxy
      */
      cUlogOut: function cUlogOut() {
        this.get('currentUser').send('logOut');
      },

      /**
       * Reset the importwizzard class at the backend
       */
      resetImportWizard: function resetImportWizard(defer) {
        _ember['default'].$.get('api/v3/accounts?procedure=resetImportWizard').then(function () {
          defer.resolve();
        });
      },

      goToPreviousRoute: function goToPreviousRoute() {
        var currentRouteName = this.controllerFor('application').get('currentRouteName');
        if (this.get('previousRouteName') && this.get('previousRouteName') != currentRouteName) {
          this.transitionTo(this.get('previousRouteName'));
        } else {
          this.transitionTo('timeline');
        }
      },

      /**
        Called from either the instrumentation call or the application adapter.
        When the version is different from the applicationRoute’s `apiVersion`
        property, `apiVersionHasChanged` is set to `true`, causing a
        force-reload on the next transition (willTransition).
      */
      checkApiVersion: function checkApiVersion(apiVersion) {
        var currentApiVersion = this.get('apiVersion');
        if (currentApiVersion && apiVersion && currentApiVersion != apiVersion) {
          this.set('apiVersionHasChanged', true);
        }
        this.set('apiVersion', apiVersion);
      },

      /**
        Used to force a reload on the User. When
        reloading the browser will ignore its cache. (
        This action is triggered when the User
        clicks the logo.
      */
      resetCache: function resetCache() {
        this.transitionTo('timeline');
        window.location.reload(true);
      },

      refreshApplicationRoute: function refreshApplicationRoute() {
        this.refresh();
      },

      /**
        This action is used for handling transitions
        triggered by components. This way a single
        action can deal with any transition action
        from any component.
         For available arguments, check:
        http://emberjs.com/api/classes/Ember.Route.html#method_transitionTo
      */
      transitionAction: function transitionAction() {
        this.transitionTo.apply(this, arguments);
      }
    }
  });
});