define('front-end-cli/controllers/accountmanagement/edit/choose-sync-type', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    bankChoice: _ember['default'].computed.alias('parentController.bankChoice'),

    /**
      Banks from which the auto-sync is (temporarily)
      taken offline due to legal issues.
    */
    isBankDisabled: (function () {
      return this.get('bankChoice.isIng') || this.get('bankChoice.isRabobank') || this.get('bankChoice.isAbn');
    }).property('bankChoice.name'),

    actions: {
      chooseMethod: function chooseMethod(method) {

        /**
          When the user gets here by using `showPreviousStep`
          we need to clear any `availableAccounts`
        */
        if (this.get('bankChoice.availableAccounts.length')) {
          this.set('bankChoice.availableAccounts', null);
        }

        this.set('bankChoice.auto', method === 'auto');

        if (method === 'auto') {
          this.send('showStep', 3);
        } else if (method === 'bwa') {
          this.set('bankChoice.bwa', true);
          this.send('showStep', 4);
        } else {
          // delete bankLogin if any
          // console.log(this.get('bankChoice.account.bankLogin'));
          // if(this.get('bankChoice.account.bankLogin')) {
          //   this.get('bankChoice.account.bankLogin').deleteRecord().then(function() {
          //     this.get('bankChoice.account').save();
          //   });
          // }
          this.set('bankChoice.bwa', false);
          this.send('showStep', 4);
        }
      }
    }
  });
});