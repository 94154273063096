define('front-end-cli/pods/components/budget/yeartotals-tablecell/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: ['td'],
    classNameBindings: ['currentMonth:is-currentMonth'],
    month: null,
    year: null,
    currentMonth: _ember['default'].computed('month', 'year', function () {
      var yearIsCurrentYear = this.get('year') * 1 === moment().year() * 1;
      var monthIsCurrentMonth = this.get('month') * 1 === moment().month() * 1;
      return yearIsCurrentYear && monthIsCurrentMonth;
    })
  });
});