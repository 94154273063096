define('front-end-cli/helpers/if-route', ['exports'], function (exports) {
  exports['default'] = function (value, options) {

    var applicationController = this.get('container').lookup('controller:application'),
        currentPath = applicationController.get('currentPath');

    if (value === currentPath) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  };
});