define('front-end-cli/pods/components/budget/matrix-options/component', ['exports', 'ember', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _ember, _emberKeyboardShortcutsMixinsComponent) {
  exports['default'] = _ember['default'].Component.extend(_emberKeyboardShortcutsMixinsComponent['default'], {
    classNames: ['MatrixOptions'],

    // External bindings
    year: null,
    firstBudgetYear: null,

    // Actions
    budgetAction: null,

    currentYear: _ember['default'].computed('year', function () {
      return this.get('year') || moment().year();
    }),

    previousYear: _ember['default'].computed('currentYear', function () {
      return this.get('currentYear') - 1;
    }),

    hasBudgetsInPreviousYear: _ember['default'].computed('year', 'firstBudgetYear', function () {
      var year = this.get('year') || moment().year(),
          firstBudgetYear = this.get('firstBudgetYear');

      return firstBudgetYear && firstBudgetYear < year;
    }),

    /**
      Hide and reset period-select when the
      event-target was outside the component.
    */
    _mouseDownResponder: function _mouseDownResponder(e) {
      if (this.get('isActive')) {

        var notWithinMatrixOptions = !_ember['default'].$(e.target).parents('.MatrixOptions').length,
            onCurtain = _ember['default'].$(e.target).hasClass('MatrixOptions-curtain');

        if (onCurtain || notWithinMatrixOptions) {
          this.setProperties({
            isActive: false
          });
        }
      }
    },

    setMouseDownListeners: (function () {
      this.mdr = this._mouseDownResponder.bind(this);
      _ember['default'].$(document).on('mousedown touchend', this.mdr);
    }).on('didInsertElement'),

    tearDown: (function () {
      _ember['default'].$(document).off('mousedown', this.mdr);
    }).on('willDestroyElement'),

    detailNr: 1,
    detail1: _ember['default'].computed('detailNr', function () {
      return this.get('detailNr') == 1;
    }),
    detail2: _ember['default'].computed('detailNr', function () {
      return this.get('detailNr') == 2;
    }),
    detail3: _ember['default'].computed('detailNr', function () {
      return this.get('detailNr') == 3;
    }),
    detail4: _ember['default'].computed('detailNr', function () {
      return this.get('detailNr') == 4;
    }),

    detail2or3: _ember['default'].computed('detailNr', function () {
      var nr = this.get('detailNr'),
          prv = this.get('hasBudgetsInPreviousYear');

      return nr == 2 && prv || nr == 3 && !prv;
    }),

    isActiveProcedure: null,

    actions: {
      toggleActiveState: function toggleActiveState() {
        this.toggleProperty('isActive');
      },
      showDetail: function showDetail(nr) {
        this.set('detailNr', nr);
      },
      startProcedure: function startProcedure() {
        this.set('isActiveProcedure', true);

        _ember['default'].run.later(this, function () {
          this.set('isActive', false);
          this.set('isActiveProcedure', false);
        }, 8000);
      },
      action1: function action1() {
        this.send('startProcedure');
        this.sendAction('budgetAction', 'generate-all');
      },
      action2: function action2() {
        this.send('startProcedure');
        this.sendAction('budgetAction', 'copy');
      },
      action3: function action3() {
        this.send('startProcedure');
        this.sendAction('budgetAction', 'generate');
      },
      action4: function action4() {
        this.send('startProcedure');
        this.sendAction('budgetAction', 'remove-all');
      }
    },

    keyboardShortcuts: {

      'esc': function esc() {
        this.set('isActive', false);
      },

      'down': function down() {
        var isActive = this.get('isActive');
        if (isActive) {
          var _isActive = this.get('isActive');
          var detailNr = this.get('detailNr');
          var nr = detailNr < 4 ? detailNr + 1 : 1;
          if (!this.get('hasBudgetsInPreviousYear') && nr == 2) {
            nr = 3;
          }
          this.set('detailNr', nr);
        }
      },

      'up': function up() {
        var isActive = this.get('isActive');
        if (isActive) {
          var _isActive2 = this.get('isActive');
          var detailNr = this.get('detailNr');
          var nr = detailNr > 1 ? detailNr - 1 : 4;
          if (!this.get('hasBudgetsInPreviousYear') && nr == 2) {
            nr = 1;
          }
          this.set('detailNr', nr);
        }
      }
    }
  });
});