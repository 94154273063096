define("front-end-cli/templates/whatif/retirement", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 10
              },
              "end": {
                "line": 22,
                "column": 10
              }
            },
            "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "intro");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "placeholder-image");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "intro-text");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            var el4 = dom.createTextNode("\n                  Om je iets te kunnen laten zien, hebben we minimaal\n                  je ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("em");
            var el5 = dom.createTextNode("geboortedatum");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" en je ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("em");
            var el5 = dom.createTextNode("uitgaven per maand");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" nodig.\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            var el4 = dom.createTextNode("\n                  Als je een partner hebt, hebben we ook zijn of haar\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("em");
            var el5 = dom.createTextNode("geboortedatum");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" nodig.\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 40,
                      "column": 22
                    },
                    "end": {
                      "line": 42,
                      "column": 22
                    }
                  },
                  "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["content", "model.partnerAge"]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 46,
                      "column": 22
                    },
                    "end": {
                      "line": 48,
                      "column": 22
                    }
                  },
                  "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  dom.setAttribute(el1, "class", "compensation");
                  var el2 = dom.createTextNode("Uit vermogen");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "whatif-retirement-format-detail-number", [["get", "model.details.capital_compensation"]], []]],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 49,
                      "column": 22
                    },
                    "end": {
                      "line": 59,
                      "column": 22
                    }
                  },
                  "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  dom.setAttribute(el1, "class", "detailed");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("ul");
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("li");
                  var el4 = dom.createTextNode("aow ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("span");
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("li");
                  var el4 = dom.createTextNode("pensioen ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("span");
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("li");
                  var el4 = dom.createTextNode("lijfrente ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("span");
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("li");
                  var el4 = dom.createTextNode("arbeidsinkomen");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("span");
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                          ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("li");
                  var el4 = dom.createTextNode("belasting");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("span");
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1, 1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 1]), 0, 0);
                  morphs[1] = dom.createMorphAt(dom.childAt(element1, [3, 1]), 0, 0);
                  morphs[2] = dom.createMorphAt(dom.childAt(element1, [5, 1]), 0, 0);
                  morphs[3] = dom.createMorphAt(dom.childAt(element1, [7, 1]), 0, 0);
                  morphs[4] = dom.createMorphAt(dom.childAt(element1, [9, 1]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "whatif-retirement-format-detail-number", [["get", "model.details.aow"]], ["divide", "true"]], ["inline", "whatif-retirement-format-detail-number", [["get", "model.details.pension"]], ["divide", "true"]], ["inline", "whatif-retirement-format-detail-number", [["get", "model.details.annuity"]], ["divide", "true"]], ["inline", "whatif-retirement-format-detail-number", [["get", "model.details.labor_income"]], ["divide", "true"]], ["inline", "whatif-retirement-format-detail-number", [["get", "model.details.tax"]], ["divide", "true", "negative", "true"]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 16
                  },
                  "end": {
                    "line": 70,
                    "column": 16
                  }
                },
                "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("time");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("ul");
                dom.setAttribute(el2, "class", "age");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                var el4 = dom.createElement("i");
                dom.setAttribute(el4, "class", "glyphicons glyphicons-user");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "monthly");
                var el3 = dom.createTextNode("maandelijks");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("ul");
                dom.setAttribute(el2, "class", "numbers");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                dom.setAttribute(el3, "class", "income");
                var el4 = dom.createTextNode("Inkomsten ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                dom.setAttribute(el3, "class", "expense");
                var el4 = dom.createTextNode("Uitgaven ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                var el4 = dom.createTextNode("Saldo ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("ul");
                dom.setAttribute(el2, "class", "numbers");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                dom.setAttribute(el3, "class", "capital");
                var el4 = dom.createTextNode("Vermogen");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("nav");
                var el2 = dom.createElement("button");
                var el3 = dom.createTextNode("details ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("i");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var element3 = dom.childAt(element2, [1]);
                var element4 = dom.childAt(element2, [3]);
                var element5 = dom.childAt(element2, [7]);
                var element6 = dom.childAt(element5, [8]);
                var element7 = dom.childAt(fragment, [3, 0]);
                var element8 = dom.childAt(element7, [1]);
                var morphs = new Array(13);
                morphs[0] = dom.createAttrMorph(element3, 'class');
                morphs[1] = dom.createMorphAt(element3, 0, 0);
                morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
                morphs[3] = dom.createMorphAt(element4, 3, 3);
                morphs[4] = dom.createMorphAt(element5, 1, 1);
                morphs[5] = dom.createMorphAt(element5, 2, 2);
                morphs[6] = dom.createMorphAt(dom.childAt(element5, [4, 1]), 0, 0);
                morphs[7] = dom.createMorphAt(dom.childAt(element5, [6, 1]), 0, 0);
                morphs[8] = dom.createAttrMorph(element6, 'class');
                morphs[9] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
                morphs[10] = dom.createMorphAt(dom.childAt(element2, [9, 1, 1]), 0, 0);
                morphs[11] = dom.createElementMorph(element7);
                morphs[12] = dom.createAttrMorph(element8, 'class');
                return morphs;
              },
              statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "events"], "has-events"], []]]]], ["content", "model.year"], ["content", "model.userAge"], ["block", "if", [["get", "showPartnerAge"]], [], 0, null], ["block", "if", [["get", "model.details.capital_compensation"]], [], 1, null], ["block", "if", [["get", "isDetailed"]], [], 2, null], ["inline", "whatif-retirement-format-detail-number", [["get", "model.income"]], ["round", "true"]], ["inline", "whatif-retirement-format-detail-number", [["get", "model.expense"]], ["round", "true"]], ["attribute", "class", ["concat", ["balance ", ["subexpr", "if", [["get", "model.isNegative"], " negative"], []]]]], ["inline", "whatif-retirement-format-detail-number", [["get", "model.balance"]], ["round", "true"]], ["inline", "whatif-retirement-format-detail-number", [["get", "model.details.capital_balance"]], ["round", "true"]], ["element", "action", ["toggleDetailed"], []], ["attribute", "class", ["concat", ["glyphicons ", ["subexpr", "if", [["get", "isDetailed"], "glyphicons-show-big-thumbnails", "glyphicons-show-thumbnails"], []]]]]],
              locals: [],
              templates: [child0, child1, child2]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 14
                },
                "end": {
                  "line": 71,
                  "column": 14
                }
              },
              "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "render", ["whatif/retirement/details", ["get", "selectedYearObject"]], [], 0, null]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 10
              },
              "end": {
                "line": 75,
                "column": 10
              }
            },
            "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "prev");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "whatif_ylabels");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("Uitgaven      Inkomen");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "whatif_selection_arrow");
            var el2 = dom.createElement("div");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "whatif_chart");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "details");
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "next");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var element10 = dom.childAt(fragment, [11]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element9);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [9]), 1, 1);
            morphs[2] = dom.createElementMorph(element10);
            return morphs;
          },
          statements: [["element", "action", ["previousEvent", true], ["on", "mouseDown"]], ["block", "if", [["get", "selectedYearObject"]], [], 0, null], ["element", "action", ["nextEvent", true], ["on", "mouseDown"]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 84,
                  "column": 14
                },
                "end": {
                  "line": 86,
                  "column": 14
                }
              },
              "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              Klaar met wijzigen\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 86,
                  "column": 14
                },
                "end": {
                  "line": 88,
                  "column": 14
                }
              },
              "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              Wijzig je gegevens\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 82,
                "column": 10
              },
              "end": {
                "line": 91,
                "column": 10
              }
            },
            "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "glyphicons glyphicons-retweet");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(element0, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "activeWizard"], "active-wizard"], []]]]], ["element", "action", ["toggleProfilerWizard"], []], ["block", "if", [["get", "hasActiveWizard"]], [], 0, 1]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 100,
                    "column": 12
                  },
                  "end": {
                    "line": 102,
                    "column": 12
                  }
                },
                "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "render", ["whatif/retirement/events", ["get", "year"]], []]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 99,
                  "column": 10
                },
                "end": {
                  "line": 103,
                  "column": 10
                }
              },
              "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "year.events"]], [], 0, null]],
            locals: ["year"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 95,
                "column": 4
              },
              "end": {
                "line": 107,
                "column": 4
              }
            },
            "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-12");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            dom.setAttribute(el3, "id", "eventlist");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "years"]], [], 0, null]],
          locals: [],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 110,
                "column": 2
              },
              "end": {
                "line": 112,
                "column": 2
              }
            },
            "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "render", ["whatif/retirement/wizard"], []]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 114,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "container vert-offset-md-40");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-sm-12");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "id", "top-section");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-sm-12");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "id", "editoggle");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var element12 = dom.childAt(element11, [3, 1, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element11, [1, 1, 1]), 1, 1);
          morphs[1] = dom.createAttrMorph(element12, 'class');
          morphs[2] = dom.createMorphAt(element12, 1, 1);
          morphs[3] = dom.createMorphAt(element11, 5, 5);
          morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasActiveIntro"]], [], 0, 1], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "requiredData"], "required-data"], []]]]], ["block", "if", [["get", "hasRequiredData"]], [], 2, null], ["block", "if", [["get", "hasActiveEventlist"]], [], 3, null], ["block", "if", [["get", "hasActiveWizard"]], [], 4, null]],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 114,
              "column": 0
            },
            "end": {
              "line": 120,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "no-plus", [], ["application", ["subexpr", "@mut", [["get", "application"]], []], "featurePreviewImage", "images/no-plus/whatif-retirement.png"]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 121,
            "column": 0
          }
        },
        "moduleName": "front-end-cli/templates/whatif/retirement.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "currentUser.model.state.plus"]], [], 0, 1]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});