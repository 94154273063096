define('front-end-cli/models/transaction', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    debcred: _emberData['default'].attr('string'),
    amount: _emberData['default'].attr('number'),
    counteraccount: _emberData['default'].attr('string'),
    name: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    transactioncode: _emberData['default'].attr('string'),
    manDescription: _emberData['default'].attr('string'),
    descriptionOriginal: (function () {

      var descriptionOriginal = '',
          name = this.get('name'),
          description = this.get('description');

      if (name) {
        descriptionOriginal += name;
      }
      if (description) {
        if (name) {
          descriptionOriginal += ', ';
        }
        descriptionOriginal += description;
      }

      return descriptionOriginal;
    }).property('name', 'description'),
    amountReceived: _ember['default'].computed('amount', function () {
      return this.get('amount') > 0;
    }),
    descriptionDisplay: _ember['default'].computed('manDescription', function () {
      return this.get('manDescription') || this.get('descriptionOriginal');
    }),
    date: _emberData['default'].attr('iso8601date'),
    userDate: _emberData['default'].attr('iso8601date'),
    displayDate: _ember['default'].computed('date', 'userDate', {
      get: function get() {
        return this.get('userDate') || this.get('date');
      },
      set: function set(key, newDate) {
        if (!moment(newDate).isSame(this.get('date'))) {
          this.set('userDate', newDate);
        } else {
          this.set('userDate', null);
        }
        return newDate;
      }
    }),
    account: _emberData['default'].belongsTo('account', { inverse: null }),
    enabled: _emberData['default'].attr('boolean'),
    comment: _emberData['default'].attr('string'),
    receipt: _emberData['default'].belongsTo('receipt'),
    labels: _emberData['default'].hasMany('label', { inverse: 'transactions' }),
    subcategory: _emberData['default'].belongsTo('subcategory', { inverse: null }),
    parentTransaction: _emberData['default'].belongsTo('transaction', { inverse: 'childTransactions' }),
    childTransactions: _emberData['default'].hasMany('transaction', { inverse: 'parentTransaction' }),
    relatedTransactions: _emberData['default'].hasMany('transaction', { async: true }),
    restore: _emberData['default'].attr('boolean', { defaultValue: false })
  });
});