define('front-end-cli/pods/income-expenses/controller', ['exports', 'ember', 'front-end-cli/models/preferences'], function (exports, _ember, _frontEndCliModelsPreferences) {
  exports['default'] = _ember['default'].Controller.extend({

    incomeExpenses: _ember['default'].inject.service('income-expenses'),
    needs: ['application'],

    queryParams: ['periode', { filterLabelsString: { as: 'labels' } }],
    periode: null,
    filterLabelsString: null,

    startDate: null,
    endDate: null,

    trendsStartDate: null,
    trendsEndDate: null,
    trendsPreviousScope: null,

    periodType: null,

    /**-------------------------
      Models
    ---------------------------*/

    categories: null,
    category: null,
    subcategory: null,
    transactions: null,
    labels: null,
    trendsnetted: null,

    // localstorage preference model
    preferences: _frontEndCliModelsPreferences['default'],

    /**-------------------------
      Properties
    ---------------------------*/

    currentPath: _ember['default'].computed.alias('controllers.application.currentPath'),
    hasSidebar: _ember['default'].computed.alias('controllers.application.hasSidebar'),

    activeRelatedTransactions: null,

    /**-------------------------
      Calculated Properties
    ---------------------------*/

    lastUpdateIsBeforeStartDate: _ember['default'].computed('startDate', 'currentUser.model.accounts.@each.lastupdate', function () {

      var lastupdate = this.get('currentUser.model.accounts').sortBy('lastupdate').reverse().get('firstObject.lastupdate'),
          startDate = this.get('startDate');

      var mLastUpdate = moment(lastupdate, 'YYYY-MM-DD'),
          mStartDate = moment(startDate, 'YYYY-MM-DD');

      return mLastUpdate.isBefore(mStartDate);
    }),

    // Set by the related-transactions component.
    hasNoTransactions: null,
    hasNoEnabledTransactions: null,

    updateService: _ember['default'].observer('categories', 'category', 'subcategory', function () {
      this.get('incomeExpenses').setProperties({
        categories: this.get('categories'),
        category: this.get('category'),
        subcategory: this.get('subcategory')
      });
    }),

    trendsPeriodHeader: (function () {
      var _header,
          periodType = this.get('periodType'),
          trendsPreviousScope = this.get('trendsPreviousScope');

      if (trendsPreviousScope === 1) {
        _header = 'per maand van ' + moment(this.get('trendsStartDate')).format('DD MMMM YYYY') + ' t/m ' + moment(this.get('trendsEndDate')).format('DD MMMM YYYY') + '.';
      } else if (periodType === 2) {
        _header = 'per kwartaal van Q' + moment(this.get('trendsStartDate')).format('Q YYYY') + '\n      t/m Q' + moment(this.get('trendsEndDate')).format('Q YYYY') + '.';
      } else if (periodType === 3) {
        _header = 'per jaar van ' + moment().subtract(11, 'years').format('YYYY') + ' t/m ' + moment().format('YYYY') + '.';
      } else {
        _header = 'per maand van het afgelopen jaar.';
      }
      return _header;
    }).property('periodType', 'trendsPreviousScope', 'trendsStartDate', 'trendsEndDate'),

    hasNoAccounts: (function () {
      return !this.get('currentUser.model.accounts.length');
    }).property('currentUser.model.accounts.[]'),

    hasNoActiveAccounts: (function () {
      return !this.get('currentUser.model.accounts').findBy('activated');
    }).property('currentUser.model.accounts.@each.activated'),

    type: (function () {

      var currentPath = this.get('currentPath'),
          type;

      if (currentPath && /income-expenses\./.test(currentPath)) {
        type = currentPath.replace(/income-expenses\./, '');
      } else {
        type = 'expenses';
      }
      return type;
    }).property('currentPath'),

    isIncome: (function () {
      return this.get('type') === 'income';
    }).property('type'),

    hasCustomFinancialMonth: (function () {
      return this.get('startDayFinancialMonth') !== 1;
    }).property('startDayFinancialMonth'),

    startDayFinancialMonth: (function () {
      return this.get('currentUser.model.state.startDayFinancialMonth') * 1;
    }).property('currentUser.model.state.startDayFinancialMonth'),

    /**
      In order to let the UI only offer date
      choices which actually exist, we fetch the
      oldest transaction date from `meta`.
    */
    oldestTransactionDate: (function () {
      var meta = this.get('transactions.meta');
      return meta ? meta.oldestTransactionDate : null;
    }).property('transactions.length'),

    transactionsDidLoad: function transactionsDidLoad() {},

    /**-------------------------
      Observers
    ---------------------------*/

    /*
      When the `category` property is changed,
      make the route follow suit, if needed.
    */

    categoryObserver: (function () {
      var type = this.get('type'),
          category = this.get('category'),
          categoryType = category ? this._incomeOrExpenses(category) : null;

      if (categoryType && categoryType != type) {
        this.transitionToRoute('income-expenses.' + categoryType);
      }
    }).observes('category'),

    /*
      When the `subcategory` property is changed,
      make the route follow suit, we'll make sure
      that the category still matches
      the subcategory.
    */
    subcategoryObserver: (function () {
      if (this.get('subcategory')) {
        if (this.get('category') != this.get('subcategory.category')) {
          this.set('category', this.get('subcategory.category'));
        }
      }
    }).observes('subcategory'),

    /**
      When the `type` doesn’t match current `category`,
      which happens when it is changed via the sidebar or url,
      we'll reset the category & subcategory.
    */
    typeObserver: (function () {

      var type = this.get('type'),
          category = this.get('category'),
          categoryType = category ? this._incomeOrExpenses(category) : null;

      if (categoryType && categoryType != type) {
        this.setProperties({
          category: null,
          subcategory: null
        });
      }
    }).observes('type'),

    /**
      When either category or subcategory changes
      we'll trigger the `fetchTrendsNetted` action
      on our route.
       This observer is also triggered by the
      didTransition action on the IncomeExpensesRoute,
      via a notifyPropertyChange.
      06-01-2020:
     Since moving to the new environment, we've had some strange behaviour where
     a double categories call was made when transitioning for or to the income-expense
     pages. This had to be adjusted in the router by checking for 'ranbefore'.
     Side effect of this was that 'fetchTrensNetted' wouldn't be called when going from
     income to the expense page, so now we're checking this as an observer.
    */
    catAndSubcatObserver: (function () {
      if (/income-expenses/.test(this.get('currentPath'))) {
        this.send('fetchTrendsNetted');
      }
      // Also refresh categories when visiting income, as expenses may filter them
      if (/income/.test(this.get('currentPath'))) {
        this.send('forceRefresh');
      }
    }).observes('category', 'subcategory', 'currentPath'),

    /**-------------------------
      Methods
    ---------------------------*/

    /**
      Figure out whether the given category
      belongs to 'income' or 'expenses'.
    */
    _incomeOrExpenses: function _incomeOrExpenses(category) {

      _ember['default'].assert('We need a category to be able to decide if its `income` or `expenses`', category);

      var expectedNetted = category.get('expectedNetted'),
          currentNetted = category.get('currentNetted'),
          averageNetted = category.get('averageNetted');

      // if (expectedNetted != null) {
      //   if (expectedNetted > 0) {
      //     return 'income';
      //   } else if (expectedNetted < 0) {
      //     return 'expenses';
      //   }
      // } else {
      if (currentNetted > 0) {
        return 'income';
      } else if (currentNetted < 0) {
        return 'expenses';
      } else {
        if (currentNetted === 0) {
          if (averageNetted < 0) {
            return 'expenses';
          } else if (averageNetted > 0) {
            return 'income';
          } else {
            if (expectedNetted > 0) {
              return 'income';
            } else if (expectedNetted < 0) {
              return 'expenses';
            } else {
              return 'expenses';
            }
          }
        }
      }
      // }
    }

  });
});