define('front-end-cli/mixins/profiler/sub-model-switch', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    deleteRecords: function deleteRecords(keys) {

      var self = this,
          relationlessRecords = this.get('controllers.profiler.relationlessRecords');

      keys.forEach(function (key) {
        // If there is an ID, the record has been
        // persisted before, so we'll delete it.
        var id = self.get(key + '.id');
        if (id) {
          self.store.find(key, id).then(function (record) {
            record.deleteRecord();
          });
        }
        // If there is no ID, the record could still be
        // in the `relationlessRecords` array, so we'll
        // track it and remove it when found.
        else {
            relationlessRecords.forEach(function (item, index) {
              if (item.record == self.get(key)) {
                // console.log('remove `' + key + '` from relationlessRecords.');
                relationlessRecords.removeAt(index);
              }
            });
          }
      });
    },

    storeRelationlessRecord: function storeRelationlessRecord(recordData) {

      var rd = recordData,
          relationlessRecords = this.get('controllers.profiler.relationlessRecords');

      /**
        Store the unrelated record information we need to
        establish a relation after the next saveProfile call
        recordData = {
          recordName
          record
          parentName
          parent
        }
      */
      relationlessRecords.pushObject(rd);
    }
  });
});
/**
 Mixin for controllers with embedded type models.
  Adds `delete` method for switching the subModel
 and `storeRelationlessRecord` for persiting it when
 parent-model isn’t saved yet.
  import SubModelSwitchMixin from 'front-end-cli/mixins/profiler/sub-model-switch';
*/