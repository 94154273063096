define('front-end-cli/models/misc-insurance', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    profile: _emberData['default'].belongsTo('profile'),

    type: _emberData['default'].attr('number'),

    miscLifeInsurance: _emberData['default'].belongsTo('misc-life-insurance'),
    miscBurialInsurance: _emberData['default'].belongsTo('misc-burial-insurance'),
    miscSavingsInsurance: _emberData['default'].belongsTo('misc-savings-insurance'),
    miscDamageInsurance: _emberData['default'].belongsTo('misc-damage-insurance'),

    isLifeInsurance: (function () {
      var type = this.get('type');
      return type == 1;
    }).property('type'),
    isBurialInsurance: (function () {
      var type = this.get('type');
      return type == 2;
    }).property('type'),
    isSavingsInsurance: (function () {
      var type = this.get('type');
      return type == 3;
    }).property('type'),
    isDamageInsurance: (function () {
      var type = this.get('type');
      return type == 4;
    }).property('type')
  });
});