define('front-end-cli/controllers/application/sidebaraccounts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend(_ember['default'].Evented, {
    needs: ['application', 'accountmanagement/update'],

    application: _ember['default'].computed.alias('controllers.application'),
    activeTrigger: null,
    accountmanagementUpdateController: _ember['default'].computed.alias('controllers.accountmanagement/update'),
    currentAccountId: _ember['default'].computed('accountmanagementUpdateController.content.id', function () {
      return this.get('accountmanagementUpdateController.content.id');
    }),
    /**
      Returns an array of bankLogin’s with an added array
      containing all related accounts (called `accounts`).
       When powered by a decent Rest API (dis), we could simply itterate
      over the account relations, right in the template, but we don't,
      so we'll have to construct our own related accounts array and
      hack it onto the banklogin model. For now.
    */
    bankLogins: (function () {

      // Create array with bankLogins and extend each with
      // an accounts array containing all related accounts.
      var sidebarBankLogins = [];

      if (this.get('currentUser.model.accounts')) {

        // Filter account down to only accounts with a banklogin relation.
        var bankLoginAccounts = this.get('currentUser.model.accounts').filterBy('bankLogin');

        bankLoginAccounts.forEach(function (account) {
          if (!account.get('archived')) {
            var bankLogin = account.get('bankLogin');

            // If the banklogin is not yet in the
            // `sidebarBankLogins` array... add it.
            if (!sidebarBankLogins.contains(account.get('bankLogin'))) {

              sidebarBankLogins.push(bankLogin);

              // Whenever hitting a new bankLogin, declare/clear the
              // `accounts` array. This ensures deleted accounts
              // are cleared properly
              bankLogin.set('accounts', []);
            }
            // Add this account to our custom array
            bankLogin.accounts.push(account);

            if (account.get('locked') !== true) {
              bankLogin.set('fullyLocked', false);
            }
          }
        });
      }
      return sidebarBankLogins;
    }).property('currentUser.model.accounts.[]'),

    /**
      Returns an array with non-banklogin-accounts
    */
    manualAccounts: (function () {
      var accounts = [];
      if (this.get('currentUser.model.accounts')) {

        var manualAccounts = this.get('currentUser.model.accounts').filter(function (item, index, enumerable) {
          return !(item.get('bankLogin') || item.get('archived'));
        });

        manualAccounts.forEach(function (account) {
          accounts.push(account);
        }, this);
      }
      return accounts.sortBy('orderid');
    }).property('currentUser.model.accounts.[]'),

    /**
      Returns the balance-total of all accounts.
    */
    balanceSum: (function () {
      var sum = 0;
      if (this.get('currentUser.model.accounts.length')) {
        this.get('currentUser.model.accounts').forEach(function (account) {
          if (!account.get('archived')) {
            sum += account.get('balance') * 1;
          }
        });
        return sum;
      }
    }).property('currentUser.model.accounts.length', 'currentUser.model.accounts.@each.balance'),

    actions: {

      showModal: function showModal(model) {
        // Render the sidebaraccountsmodal template with the given account as its model
        this.send('toggleModal', 'application/sidebaraccounts-modal', 'application/sidebaraccountsModal', model);
        this.trigger('showModalMist');
      },

      stopModal: function stopModal() {
        this.send('hideModal', true);
        this.trigger('hideModalMist');
      },

      swapModal: function swapModal(model) {
        // Render the sidebaraccountsmodal template with the given account as its model
        this.send('toggleModal', 'application/sidebaraccounts-modal', 'application/sidebaraccountsModal', model);
        this.trigger('showModalMist');
      },

      transitionToTrends: function transitionToTrends() {
        this.transitionToRoute('trends');
      }
    }
  });
});