define('front-end-cli/pods/timeline/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    /**-------------------------
      Hooks
    ---------------------------*/

    model: function model() {
      // Without params, /categories will
      // return the current financial period.

      //TODO: make this a little more eloquent

      if (this.get('currentUser.model.accounts') === null) {
        window.location.reload(true);
        //this.send('refreshApplicationRoute');
      }

      return this.store.find('category');
    },

    afterModel: function afterModel() {

      var user = this.get('currentUser.model'),
          showWelcomeMessage = undefined;

      if (user.get('state.seenWelcomeMessage')) {
        var seenWelcomeMessageDate = user.get('state.seenWelcomeMessage') ? moment(user.get('state.seenWelcomeMessage')) : null,
            startOfDay = moment().startOf('day');

        showWelcomeMessage = seenWelcomeMessageDate.diff(startOfDay) < 0 || !seenWelcomeMessageDate.isValid();
      } else {
        showWelcomeMessage = true;
      }

      var controller = this.controllerFor('timeline');
      controller.set('showWelcomeMessage', showWelcomeMessage);
    },

    actions: {

      willTransition: function willTransition() {

        var controller = this.controllerFor('timeline');
        if (controller.get('showWelcomeMessage')) {

          _ember['default'].run.later(this, function () {
            controller.set('showWelcomeMessage', false);
          }, 1000);

          var state = this.get('currentUser.model.state');
          state.set('seenWelcomeMessage', new Date());
          state.save();
        }
        return true;
      }
    }
  });
});