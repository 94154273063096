define('front-end-cli/controllers/profiler', ['exports', 'ember', 'front-end-cli/mixins/profiler/parse-report', 'front-end-cli/utils/profiler/select-objects'], function (exports, _ember, _frontEndCliMixinsProfilerParseReport, _frontEndCliUtilsProfilerSelectObjects) {
  exports['default'] = _ember['default'].Controller.extend(_frontEndCliMixinsProfilerParseReport['default'], {
    /**
      Returns an object containing the timestamps for the currently
      selected profile. Needs this controller to be included and can
      be called this.get('timestamps.personal.date')
    */

    isPartnerProfile: false,
    isUserProfile: true,
    dirtyModels: [],
    deletedRecords: [],
    relationlessRecords: [],
    didChange: false,
    selectObjects: _frontEndCliUtilsProfilerSelectObjects['default'].create(),

    activeProfile: (function () {
      // Default to user profile
      return this.get('currentUser.model.profile');
    }).property(),

    userHasPartner: (function () {
      return this.get('currentUser.model.profile.personal.civilState') > 1;
    }).property('currentUser.model.profile.personal.civilState'),

    profileIsDirty: (function () {
      return this.get('dirtyModels.length') || this.get('deletedRecords.length');
    }).property('dirtyModels.length', 'deletedRecords.length'),

    didChangeObserver: (function () {
      // Itterate over profile after this run loop
      _ember['default'].run.next(this, function () {
        this.iterateProfile();
      });
    }).observes('didChange'),

    /**
      Recurse into profile model
      @param {String} job Optional job, leaving it undefined
      will just check for dirty records.
      @param {Object} model Optional, leaving it blank will
      recurse over full profile model.
    */
    iterateProfile: function iterateProfile(job, scope) {

      var promises = [],
          parentKey = 'user',
          dirtyModels = [];

      var self = this;

      function walker3(model, parentKey) {

        if (!model) return false;

        var modelKey = model.constructor.typeKey; //type.parentType.typeKey

        // Check for dirty state and run optional job
        if (model.get('isDirty')) {
          if (job) {
            var relationless = false;
            self.get('relationlessRecords').forEach(function (ur) {
              if (model === ur.record) relationless = true;
            });
            // When the model is not in relationless records,
            // run job and push the job's promise.
            if (!relationless) promises.push(model[job]());
          } else dirtyModels.push(model);
        }

        var newParentKey = model.constructor.typeKey; //type.parentType.typeKey

        model.eachRelationship(function (name, type) {
          if (type.kind === 'hasMany') {
            // console.log('hasMany:', name+' parent:('+modelKey+')');
            var records = model.get(name);
            records.forEach(function (record) {
              walker3(record, modelKey);
            });
          } else if (type.kind === 'belongsTo') {
            if (name !== parentKey) {
              // ignore up-tree relations
              // console.log('belongsTo:', name+' parent:('+modelKey+')');
              var record = model.get(name);
              walker3(record, modelKey);
            }
          }
        });
      }

      var model;
      if (scope) {
        model = scope;
        walker3(model, parentKey);
      } else {
        model = this.get('currentUser.model.profile');
        walker3(model, parentKey);

        model = this.get('currentUser.model.partnerProfile');
        walker3(model, parentKey);
      }

      this.set('dirtyModels', dirtyModels);

      return promises;
    },

    actions: {

      activatePartnerProfile: function activatePartnerProfile() {
        this.setProperties({
          isPartnerProfile: true,
          isUserProfile: false,
          activeProfile: this.get('currentUser.model.partnerProfile')
        });
        parent.scrollTo(0, 0);
      },

      activateUserProfile: function activateUserProfile() {
        this.setProperties({
          isPartnerProfile: false,
          isUserProfile: true,
          activeProfile: this.get('currentUser.model.profile')
        });
        parent.scrollTo(0, 0);
      },

      /**
        Called from view, didChange is updated whenever a
        change is made in contrary of isDirty which is only
        updated when going from true to false and vice versa.
         wizard_controller uses it to update graph e.g.
      */
      setChangeStatus: function setChangeStatus() {
        this.notifyPropertyChange('didChange');
      },

      saveProfile: function saveProfile() {
        _ember['default'].$.ajaxSetup({
          async: false
        });
        // All save-promises are returned in an array
        var promises = this.iterateProfile('save');

        // Delete record
        this.get('deletedRecords').forEach(function (record) {
          // Only if persisted in first place
          if (record.get('isDirty')) record.save();
        });
        this.get('deletedRecords').clear();

        /**
          Declare the relation and save sub-records after
          the parentRecord has been saved.
        */
        var self = this;
        _ember['default'].RSVP.all(promises).then(function () {
          self.get('relationlessRecords').forEach(function (ur) {
            ur.record.set(ur.parentName, ur.parent);
            ur.record.save();
            ur.parent.set(ur.recordName, ur.record);
          });
          self.get('relationlessRecords').clear();
          _ember['default'].$.ajaxSetup({
            async: true
          });
        });
      },

      rollbackProfile: function rollbackProfile() {

        this.iterateProfile('rollback');

        if (this.get('deletedRecords.length')) {
          // Broken in beta 7! (works in beta 5)
          this.get('deletedRecords').forEach(function (record) {
            record.rollback();
          });
          this.send('reloadProfile');
          this.get('deletedRecords').clear();
        }
      },

      reloadProfile: function reloadProfile() {
        this.iterateProfile('rollback');
        this.get('currentUser.model').reload();
      }
    }
  });
});