define('front-end-cli/pods/budget/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    queryParams: {
      month: { refreshModel: true },
      year: { refreshModel: true }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var budgetController = this.controllerFor('budget');
      controller.setProperties({
        year: budgetController.get('year'),
        month: budgetController.get('month')
      });
    },

    model: function model(params) {
      params = {};

      var budgetController = this.controllerFor('budget');
      params.year = budgetController.get('year');
      params.month = budgetController.get('month');

      var period = undefined;
      if (params.year) {
        if (params.month) {
          period = params.year + '-' + params.month;
        } else {
          period = params.year;
          this.set('year', params.year);
        }
      } else {
        var startDayFinancialMonth = this.get('currentUser.model.state.startDayFinancialMonth') * 1,
            customFinancialMonth = startDayFinancialMonth >= 15;

        if (customFinancialMonth && moment().date() >= startDayFinancialMonth) {
          period = moment().add(1, 'month').format('YYYY-MM');
        } else {
          period = moment().format('YYYY-MM');
        }
      }

      var periode = period;

      return this.store.find('category', { period: periode });
    },

    actions: {
      startBudgetting: function startBudgetting() {
        var _this = this;

        var year = moment().year();

        _ember['default'].$.ajax({
          type: 'GET',
          url: '/api/v3/probudgets',
          data: {
            'procedure': 'generate-all',
            year: year
          }
        }).success(function (response) {
          _this.store.find('user', 1).then((function (user) {
            user.reload().then((function () {
              this.refresh();
              // note: state.hasBudgets property is read only,
              // it’s value is determined by back end logic.
              this.set('currentUser.model.state.hasBudgets', true);
            }).bind(this));
          }).bind(_this));
        }).error(function (error) {
          // hmm
        });
      }
    }

  });
});