define('front-end-cli/models/bank-login', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    // user                      : DS.belongsTo('user'),
    bank: _emberData['default'].belongsTo('bank', { async: true }),
    // bank                      : DS.belongsTo('bank'),
    // accounts                  : DS.hasMany('account'),

    // rabo                   : accountnumber, abn: accountnumber, ing: username, sns: username or digipassserial
    username: _emberData['default'].attr('string'),
    // password, rabobank     : reader response, abn:edentifier2 response
    // password                  : DS.attr('string'),
    // rabobank, abn
    passNr: _emberData['default'].attr('string'),
    // abn, sns
    // twoFactorChallengeCode    : DS.attr('string'),
    // null, abn              : edentifier1, abn:edentifier2, abn:logincode, sns:digicode, sns:digipas
    loginMethod: _emberData['default'].attr('number'),
    fullyLocked: _emberData['default'].attr('boolean', { defaultValue: true }),
    isRaboSync: _ember['default'].computed.equal('loginMethod', 1),
    isIngSync: _ember['default'].computed.equal('loginMethod', 2),
    isAbnLoginCode: _ember['default'].computed.equal('loginMethod', 3),
    isAbnEdentifier2: _ember['default'].computed.equal('loginMethod', 4),
    isAbnEdentifier1: _ember['default'].computed.equal('loginMethod', 5),
    isSnsDigiCode: _ember['default'].computed.equal('loginMethod', 6),
    isSnsDigiPas: _ember['default'].computed.equal('loginMethod', 7)
  });
});