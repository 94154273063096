define("front-end-cli/pods/components/transaction-details/labels/labels-wrapper/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 2
          }
        },
        "moduleName": "front-end-cli/pods/components/transaction-details/labels/labels-wrapper/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "labels"]], []], "optionValuePath", "content.name", "optionLabelPath", "content.name", "selection", ["subexpr", "@mut", [["get", "transaction.labels"]], []], "disabled", ["subexpr", "@mut", [["get", "disabled"]], []], "multiple", true, "placeholder", "Type hier om een label toe te voegen", "maxOptions", 10, "selectOnTab", true, "create-item", "createLabel", "optionCreateFunction", ["subexpr", "@mut", [["get", "createNewLabelDiv"]], []], "itemFunction", ["subexpr", "@mut", [["get", "selectizeItemDiv"]], []], "createFilter", "^[a-zA-Z0-9\\-\\_]+$", "plugins", ["subexpr", "@mut", [["get", "selectizePlugins"]], []]]]],
      locals: [],
      templates: []
    };
  })());
});