define('front-end-cli/controllers/automatic-import/update-account-wizard', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    startImport: function startImport() {
      var accounts = this.get('currentUser.model.accounts');
      var amountOfPsd2Account = 0;
      var currentUser = this.get('currentUser');
      accounts.forEach(function (account) {
        if (account.get('bankConsentActive') && !account.get('archived')) {
          amountOfPsd2Account++;
        }
      });
      if (amountOfPsd2Account < 1) {
        this.transitionToRoute('accounts');
      } else {
        _ember['default'].$.ajax({
          type: 'GET',
          url: '/api/v3/automaticimport?procedure=importall'
        }).success(function (response) {
          accounts.forEach(function (account) {
            if (account.get('bankConsentActive') && !account.get('archived')) {
              var accountDiv = document.getElementById('updating_' + account.get('accountNumber'));
              accountDiv.innerText = 'Rekening is succesvol bijgewerkt';
              accountDiv.style.color = '#3B8F21';
            }
          });
          currentUser.send('reloadUser');
          var toAccountsDiv = document.getElementById('to-accounts-btn');
          toAccountsDiv.style.display = 'block';
        }).error(function (error) {
          accounts.forEach(function (account) {
            if (account.get('bankConsentActive') && !account.get('archived')) {
              var accountDiv = document.getElementById('updating_' + account.get('accountNumber'));
              accountDiv.innerText = 'Bijwerken niet gelukt';
              accountDiv.style.color = '#E00000';
            }
          });
          var toAccountsDiv = document.getElementById('to-accounts-btn');
          toAccountsDiv.style.display = 'block';
        });
      }

      // accounts.forEach(function(account) {
      //   if(account.get('bankConsentActive') && !account.get('archived')){
      //     Ember.$.ajax({
      //       type: 'GET',
      //       url: '/api/v3/automaticimport?procedure=importtransactions&accountid=' + account.id,
      //     }).success(response => {
      //       let accountDiv = document.getElementById('updating_' + account.get('accountNumber'));
      //       currentUser.send('reloadUser');
      //       accountDiv.innerText = 'Rekening is succesvol bijgewerkt';
      //       accountDiv.style.color = 'green';
      //     }).error(error => {
      //       let accountDiv = document.getElementById('updating_' + account.get('accountNumber'));
      //       accountDiv.innerText = 'Bijwerken niet gelukt';
      //       accountDiv.style.color = 'red';
      //     });
      //   }
      // });
      // Ember.$(document).ajaxStop(function () {
      //   let toAccountsDiv = document.getElementById('to-accounts-btn');
      //   toAccountsDiv.style.display = 'block';
      //   //Unbind the ajaxStop event so it won't run on other pages
      //   Ember.$(this).unbind('ajaxStop');
      // });
    },

    actions: {
      saveImportSettings: function saveImportSettings() {
        var selectCollection = document.getElementsByClassName('automatic-import-setting');
        for (var i = 0; i < selectCollection.length; i++) {
          var value = selectCollection[i].options[selectCollection[i].selectedIndex].value;
          var accountId = selectCollection[i].children[2].value;
          _ember['default'].$.ajax({
            type: 'GET',
            url: '/api/v3/automaticimport?procedure=saveimportsetting&accountid=' + accountId + '&option=' + value
          }).success(function (response) {
            //Success
          }).error(function (error) {
            //Error
          });
        }
        _ember['default'].$(document).ajaxStop(function () {
          location.reload();
          _ember['default'].$(this).unbind('ajaxStop');
        });
      }
    }
  });
});