define("front-end-cli/pods/components/label-select/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/pods/components/label-select/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "LabelSelect-curtain");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "front-end-cli/pods/components/label-select/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "isUpdating"]], [], 0, null], ["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "selectizeFilterLabels"]], []], "optionValuePath", "content.name", "optionLabelPath", "content.name", "optionFunction", ["subexpr", "@mut", [["get", "customOptionDiv"]], []], "itemFunction", ["subexpr", "@mut", [["get", "selectizeItemDiv"]], []], "sortField", "sortProperty", "sortDirection", "asc", "selection", ["subexpr", "@mut", [["get", "currentFilterLabels"]], []], "multiple", true, "add-item", "addLabel", "remove-item", "removeLabel", "placeholder", "Filter op labels", "loading", ["subexpr", "@mut", [["get", "isUpdating"]], []], "maxOptions", 10, "selectOnTab", true, "plugins", ["subexpr", "@mut", [["get", "selectizePlugins"]], []]]]],
      locals: [],
      templates: [child0]
    };
  })());
});