define('front-end-cli/models/misc-savings-insurance', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    miscInsurance: _emberData['default'].belongsTo('misc-insurance'),

    insurerName: _emberData['default'].attr('string'),
    insuranceNumber: _emberData['default'].attr('string'),
    contractor: _emberData['default'].attr('string'),
    insured: _emberData['default'].attr('string'),
    beneficiary: _emberData['default'].attr('string'),
    beginDate: _emberData['default'].attr('string'),
    endDate: _emberData['default'].attr('string'),
    periodicDeposit: _emberData['default'].attr('number'),
    depositPeriod: _emberData['default'].attr('number'),
    guaranteedCapital: _emberData['default'].attr('number'), // ja/nee
    endCapital: _emberData['default'].attr('number'),
    endAmountEstimate: _emberData['default'].attr('number'),
    currentCapital: _emberData['default'].attr('number'),
    paymentOnDecease: _emberData['default'].attr('number'),
    paymentOnDeceaseAmount: _emberData['default'].attr('number'),
    premiumExcemption: _emberData['default'].attr('number'),
    pledge: _emberData['default'].attr('number'),
    pledgee: _emberData['default'].attr('string'),
    isPledged: (function () {
      var type = this.get('pledge');
      return type == 1;
    }).property('pledge'),
    isPaymentOnDecease: (function () {
      var type = this.get('paymentOnDecease');
      return type == 1;
    }).property('paymentOnDecease'),
    isGuaranteedCapital: (function () {
      var type = this.get('guaranteedCapital');
      return type == 1;
    }).property('guaranteedCapital'),
    isEstimateCapital: (function () {
      var type = this.get('guaranteedCapital');
      return type == 2;
    }).property('guaranteedCapital')
  });
});