define('front-end-cli/pods/components/category-select/category-select-li/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'li',
    classNameBindings: ['isHighlit', 'isActive', 'isSubcategorySelectionOnly'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    category: null,
    thisCategory: null,
    highlitCategory: null,
    leaveTimer: null,
    isSubcategorySelectionOnly: null,

    /**-------------------------
      Properties
    ---------------------------*/

    enterTimer: null,
    prevX: 0,
    prevY: 0,
    horizontal: false,
    preloadActiveClass: false,

    /**-------------------------
      Calculated properties
    ---------------------------*/

    isHighlit: (function () {
      return this.get('thisCategory.name') == this.get('highlitCategory.name') || this.get('preloadActiveClass');
    }).property('highlitCategory', 'preloadActiveClass'),

    isActive: (function () {
      return this.get('thisCategory.name') == this.get('category.name');
    }).property('category'),

    // {{if isHighlit " is-blueFill" " is-gray"}}{{if isActive " is-blue"}}
    categoryStateClass: _ember['default'].computed('isHighlit', 'isActive', function () {
      var clss = '';
      if (this.get('isHighlit')) clss += 'is-blueFill';else clss += 'is-gray';
      if (this.get('isActive')) clss += clss ? ' is-blue' : 'is-blue';
      return clss;
    }),

    // Position the subcategoryContainer
    // setSubcategoryLeft: function() {
    //   var width = Ember.$('.CategorySelect').outerWidth();
    //   Ember.$('.CategorySelect-subcategoryContainer')
    //   .css({left: width});
    // }.on('didInsertElement'),

    /**-------------------------
      Observers
    ---------------------------*/

    /**-------------------------
      Events
    ---------------------------*/

    mouseMove: function mouseMove(e) {

      var towardRight = true,
          // e.pageX > this.get('prevX'),// turned of for now
      predominantlyHorizontal = Math.abs(e.pageX - this.get('prevX')) > Math.abs(e.pageY - this.get('prevY'));

      if (towardRight && predominantlyHorizontal) {
        this.set('horizontal', true);
      } else {
        this.set('horizontal', false);
      }

      this.setProperties({
        prevX: e.pageX,
        prevY: e.pageY
      });
    },

    mouseEnter: function mouseEnter(e) {

      if (!this.get('leaveTimer')) {
        this.set('preloadActiveClass', true);
      }

      // Increase delay when moving horizontally
      var delay = this.get('horizontal') ? 300 : 100;

      // When comming from subcategory container, we want to switch highlit immediately to prevent multple active classes
      if (_ember['default'].$(e.relatedTarget).length && _ember['default'].$(e.relatedTarget).parents('.CategorySelect-subcategoryContainer').length) delay = 0;

      this.set('enterTimer', _ember['default'].run.later(this, function () {
        _ember['default'].run.cancel(this.get('leaveTimer'));
        this.set('leaveTimer', null);
        this.set('highlitCategory', this.get('thisCategory'));
        this.set('preloadActiveClass', false);
        this.set('enterTimer', null);
      }, delay));

      this.setProperties({
        prevX: e.pageX,
        prevY: e.pageY
      });
    },

    mouseLeave: function mouseLeave(e) {

      var target = e.relatedTarget;

      if (!target) return;

      var $target = _ember['default'].$(target),
          targetIsLi = !!/li/i.test(target.tagName) || !!$target.parents('li').length,
          targetIsNotSubcatContainer = !($target.parents('.CategorySelect-subcategoryContainer').length || $target.hasClass('CategorySelect-subcategoryContainer'));

      if ($target.parents('.CategorySelect').length) {
        _ember['default'].run.cancel(this.get('enterTimer'));
        this.set('enterTimer', null);
      }

      this.set('preloadActiveClass', false);

      if (this.get('leaveTimer')) return;

      // if (this._state !== 'destroying') { // cat-select might be destroyed already
      //   return;
      // }

      // When the cursor-movement is predominantly horzontal,
      // we'll timeout de-selection. This provides an error margin
      // in moving from cat to subcat menu.

      if (targetIsLi && targetIsNotSubcatContainer) {

        if (this.get('horizontal')) {
          this.set('leaveTimer', _ember['default'].run.later(this, function () {
            this.set('highlitCategory', null);
            this.set('leaveTimer', null);
          }, 500));
        } else {
          this.set('highlitCategory', null);
        }
      } else {
        _ember['default'].run.cancel(this.get('leaveTimer'));
        this.set('leaveTimer', null);
      }
    },

    click: function click() {
      // chooseCategory
      if (!Modernizr.touch) {
        this.sendAction();
      } else {
        this.set('highlitCategory', this.get('thisCategory'));
      }
    }
  });
});