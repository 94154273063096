define('front-end-cli/controllers/application', ['exports', 'ember', 'front-end-cli/models/preferences'], function (exports, _ember, _frontEndCliModelsPreferences) {
  exports['default'] = _ember['default'].Controller.extend({

    queryParams: ['bwaSuccess', 'updatedMultipleAccounts'],
    bwaSuccess: null,
    updatedMultipleAccounts: null,

    // Localstorage preference model
    preferences: _frontEndCliModelsPreferences['default'],
    hasSimpleListmenu: null,

    /**
      When initializing, we’ll fetch `hasSimpleListmenu`
      from localstorage and set it on the controller.
    */
    initializeSimpleListmenu: _ember['default'].on('init', function () {
      var hasSimpleListmenu = this.get('preferences.hasSimpleListmenu');
      this.set('hasSimpleListmenu', hasSimpleListmenu);
    }),

    /**
      Automatically show extendedListmenu when
      in one of its routes, but don’t persist this
      in localstorage.
       The controller value can be different from
      localstorage when entering any of the routes
      it is concealing.
    */
    autoToggleSimpleListmenu: _ember['default'].observer('currentPath', function () {
      var currentPath = this.get('currentPath').replace(/legacy\.|\.index/g, '');
      if (/contracts|receipts|paycheck|whatif\.retirement/.test(currentPath)) {
        this.set('hasSimpleListmenu', false);
      } else {
        this.set('hasSimpleListmenu', this.get('preferences.hasSimpleListmenu'));
      }

      // When user leaves current route on tablet, we will hide the sidebar
      if (Modernizr.touch && window.innerHeight > window.innerWidth) {
        _ember['default'].run.schedule('afterRender', this, function () {
          this.send('hideSidebar');
        });
      }
    }),

    /**
      Other classes can adjust the
      topbarHeigt by setting an integer
      value to this property.
    */
    topBarHeightAdjustment: null,
    topBarHeightAdjustmentStyle: (function () {

      var topBarHeightAdjustment = this.get('topBarHeightAdjustment'),
          style;

      if (topBarHeightAdjustment) {
        style = 'height:' + topBarHeightAdjustment + 'px;';
      } else {
        style = '';
      }
      return style.htmlSafe();
    }).property('topBarHeightAdjustment'),

    renderBwaSuccessModal: (function () {

      // check to make sure if user is logged in
      if (!this.get('currentUser.model')) return;

      if (!this.get('bwaSuccess')) return;

      // queryParams: ``?bwaSuccess&updatedMultipleAccounts`
      _ember['default'].run.next(this, function () {

        var template = 'application/bwa-success',
            controller = null,
            model = {
          updatedMultipleAccounts: this.get('updatedMultipleAccounts')
        };

        // Render the bwa-success template in the modal-route
        this.send('toggleModal', template, controller, model);

        // Remove queryParams
        // TODO remove timeout when we switch from
        // hard reload to store refresh.(`bwaImportSuccess`)
        _ember['default'].run.later(this, function () {
          this.transitionToRoute({
            queryParams: {
              bwaSuccess: null,
              updatedMultipleAccounts: null
            }
          });
        }, 1000);
      });
    }).observes('bwaSuccess'),

    activationLoader: null,
    iframeUpdateTimer: null,
    modal: null,

    // Used for going in full-screen mode (no control)
    sidebarlessed: false,

    // Used for classBinding and observing
    hasSidebar: true,

    // Toggled from the applicationRoute to show the line-loader
    loading: false,

    isBeta: (function () {
      return (/^beta/.test(window.location.hostname)
      );
    }).property(),

    hasSession: (function () {
      /**
        Normally, loading.hbs is rendered until the model hook resolves.
        Additionally –until authentication is ember native– we have an
        addional loader in application.hbs which shows until we have a
        currentUser model.
        A/ We already have an excisting session, making this return true and
           our extra loader hides.
        B/ The login page fires an instrumentation call
           notifying us when we have a succesfull login and
           sets the currenUser.
      */
      return this.get('currentUser.model.id');
    }).property('currentUser.model.id'),

    contentId: (function () {
      return this.get('currentPath').replace(/\.index/g, '')
      // Route specific replaces
      .replace(/(income-expenses).*/, '$1')
      // Dasherize
      .replace(/\./g, '-');
    }).property('currentPath'),

    contentClass: (function () {
      return this.get('currentPath').replace(/\.index/g, '').replace(/\./g, '-')
      // Route specific replaces
      .replace(/(income-expenses).*/, '$1')
      // Classify
      .classify();
    }).property('currentPath'),

    pageTitle: (function () {

      var titles = {
        'index': ' ', // we don't want a title on index
        'account.profile': 'Profiel',
        'overview': 'Overzicht',
        'transactions': 'Transacties',
        'contracts': 'Contracten',
        'receipts': 'Bonnetjes',
        'reports': 'Rapporten',
        'paycheck': 'Loonstroken',
        'whatif.retirement': 'Pensioen',
        'profile': 'Financieel Profiel',
        'control': 'Beheer',
        'accounts': 'Rekeningen',
        'accounts.update': 'Rekeningen',
        'plus': 'Plus',
        'settings': 'Instellingen Huishoudboekje',
        'confirmregistration': 'Bevestig Aanmelding',
        'plugin': 'Bijwerk Assistent',
        'report': 'Rapporten',

        'budget': 'Budgetten',
        'budget.yeartotals': 'Jaar totalen',
        'budget.matrix': 'Budgetten - instellen',
        'timeline': null,

        'accountmanagement': 'Beheer je bankrekeningen',
        'accountmanagement.cashbook': 'Kasboek toevoegen',
        'accountmanagement.lock-accounts': 'Rekeningen kiezen',
        'accountmanagement.setup': ' ', //Voeg een bankrekening toe',
        'accountmanagement.setup-success': ' ',
        'accountmanagement.update': ' ', //Werk je bankrekening bij'
        'accountmanagement.error': 'Er is iets misgegaan', //Werk je bankrekening bij'
        'income-expenses.expenses': 'Uitgaven',
        'income-expenses.income': 'Inkomsten',

        'automatic-import.external-bank-consent': 'Account toevoegen',
        'automatic-import.psd2-information': 'PSD2 informatie',
        'automatic-import.update-account': 'Rekening bijwerken',
        'automatic-import.new-user-setup': 'Import methode kiezen',
        'automatic-import.update-account-wizard': 'Transacties importeren',
        'automatic-import.consent-expired': 'Bank consent verlopen',
        'automatic-import.update-all-accounts': 'Alle rekeningen bijwerken via PSD2',
        'automatic-import.has-feature-access': 'PSD2 geactiveerd',

        'nibud': 'Nibud vergelijking',
        'nibud.edit': 'Nibud subcategorieën aanpassen'
      };

      // CurrentPath into key
      var key = this.get('currentPath').toLowerCase().replace(/settings.*/, 'settings').replace(/legacy\.|\.index|\.catch/g, '') || 'index';

      // Use title or turn into titlecase
      var title = titles[key] || key.capitalize();

      // Custom title parsing
      if (key === 'timeline') {
        var periodDay = this.get('currentUser.model.state.startDayFinancialMonth') || 1,
            currentDay = moment().date();

        periodDay = periodDay * 1;

        var startDate = currentDay >= periodDay ? moment().date(periodDay) : moment().date(periodDay).subtract(1, 'months'),
            endDate = currentDay >= periodDay ? moment().date(periodDay).add(1, 'months') : moment().date(periodDay);

        var periodSuffix = startDate.format('D MMMM') + ' - ' + endDate.subtract(1, 'days').format('D MMMM');
        title = moment().format('MMMM') + '<span class="Timeline-pageTitleYear">' + moment().format('YYYY') + '&nbsp;&nbsp;(' + periodSuffix + ')</span>';
      }

      return title;
    }).property('currentPath'),

    isAccountRoute: (function () {
      return (/settings|profile|control|plus/.test(this.get('currentPath'))
      );
    }).property('currentPath'),

    noSessionObserver: (function (attribute) {
      // Transition when not a legacy route
      if (this.get('currentPath') && !this.get('currentPath').match(/legacy/)) {
        _ember['default'].$('#initializing').addClass('spinner');

        this.transitionToRoute('legacy.login');
        // App.set('currentUser.noSession', false);
      }
    }).observes('currentUser.noSession'),

    actions: {

      delayedContentUpdate: function delayedContentUpdate(activated) {

        // Only run on legacy pages
        if (!this.get('currentPath').match(/legacy/)) {
          return;
        }

        this.send('showActivationLoader', activated);

        _ember['default'].run.cancel(this.get('iframeUpdateTimer'));

        this.set('iframeUpdateTimer', _ember['default'].run.later(this, function () {
          this.set('iframeUpdateTimer', null);
          //RELOAD THE IFRAME
          _ember['default'].$('#legacy').attr('src', _ember['default'].$('#legacy').attr('src'));
        }, 1));
      },

      showActivationLoader: function showActivationLoader(activated) {

        _ember['default'].$('#will-reload').remove();
        _ember['default'].$('#legacy').css({ opacity: 0.2 });
        var text = activated ? 'Gedeelde Account' : 'Gedeelde Account';
        _ember['default'].$('#legacy-wrap').append(_ember['default'].$('<div/>', { id: 'will-reload' }).append(_ember['default'].$('<span/>', { text: text })));

        _ember['default'].run.cancel(this.get('activationLoader'));

        this.set('activationLoader', _ember['default'].run.later(this, function () {
          this.set('activationLoader', null);
          _ember['default'].$('#will-reload').remove();
          _ember['default'].$('#legacy').css({ opacity: '' });
        }, 1500));
      },

      /**
        Used when legacy routes need a hashed url to
        trigger some legacy javascript state. Hacky...
      */
      setLegacyHashSource: function setLegacyHashSource(pathname, route) {
        var href = window.location.protocol + '//' + window.location.hostname + '/app/' + pathname;

        // Make sure we're in legacy
        if (!this.get('currentPath').match(/legacy/)) {
          this.transitionToRoute(route);
          _ember['default'].$(window).one('hashchange', function () {
            _ember['default'].$('#legacy').prop('src', href);
          });
        } else {
          _ember['default'].$('#legacy').prop('src', href);
        }
      },

      showSidebar: function showSidebar() {

        if (Modernizr.csstransitions) {
          this.set('hasSidebar', true);
        } else {
          var navWidth = _ember['default'].$('#app > nav').width();
          this.set('hasSidebar', true);
          _ember['default'].$('#app > main, #app > main > header, #legacy-wrap').animate({
            left: navWidth
          }, 200);
        }
      },

      hideSidebar: function hideSidebar() {

        if (Modernizr.csstransitions) {
          this.set('hasSidebar', false);
        } else {
          this.set('hasSidebar', false);
          _ember['default'].$('#app > main, #app > main > header, #legacy-wrap').animate({
            left: 0
          }, 200);
        }
      },

      toggleSideBar: function toggleSideBar() {
        if (_ember['default'].$('#app').hasClass('sidebar')) {
          this.send('hideSidebar');
        } else {
          this.send('showSidebar');
        }
      },

      /**
        Toggle `hasSimpleListmenu` property on our
        localstorage preference model, and apply the
        outcome to our controller value.
      */
      toggleSimpleListmenu: function toggleSimpleListmenu() {
        this.toggleProperty('preferences.hasSimpleListmenu');
        this.set('hasSimpleListmenu', this.get('preferences.hasSimpleListmenu'));
      }
    }
  });
});