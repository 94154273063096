define('front-end-cli/pods/components/transaction-details/receipt/receipt-pane/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['ReceiptPane'],

    files: null,
    previewUrl: null,
    receiptFile: null,
    childTransactionReceiptFile: null,

    detailsComponent: null,
    // Actions
    closeDetail: null,

    // Show ‘library’ images when there are unlinked images
    containsUnusedReceipts: _ember['default'].computed(function () {
      var containsUnused = false;
      this.get('detailsComponent.receipts').forEach(function (receipt) {
        if (receipt.get('transaction') === null) {
          containsUnused = true;
        }
      });
      return containsUnused;
    }),

    setPreviewUrl: (function () {
      if (this.get('transaction.id') === null) {
        this.get('detailsComponent').set('splitReceiptFile', this.get('files')[0]);
        this.get('detailsComponent').set('splitReceiptPreviewUrl', this.get('previewUrl'));
      } else {
        this.get('detailsComponent').set('receiptFile', this.get('files')[0]);
        this.get('detailsComponent').set('receiptPreviewUrl', this.get('previewUrl'));
      }
      this.get('detailsComponent').send('cancelEditDetail');
    }).observes('previewUrl'),

    actions: {
      setZoomedReceipt: function setZoomedReceipt(url) {
        this.set('zoomedReceipt', url);
      },

      linkReceipt: function linkReceipt(receipt) {
        this.set('transaction.receipt', receipt);
        this.get('detailsComponent').send('cancelEditDetail');
      }
    }
  });
});