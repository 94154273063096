define('front-end-cli/views/profiler/money-input', ['exports', 'ember', 'front-end-cli/mixins/profiler/changed-state'], function (exports, _ember, _frontEndCliMixinsProfilerChangedState) {
  exports['default'] = _ember['default'].View.extend(_frontEndCliMixinsProfilerChangedState['default'], {
    tagName: 'div',
    classNames: ['money'],
    classNameBindings: ['isFocused:focus'],
    isFocused: false,
    templateName: 'profiler/mixin/money',
    attributeBindings: ['style', 'placeholder', 'name'],
    inputElement: null,
    displayElement: null,
    value: null,
    /**
    By default negative values are made positive. You can override this
    by setting allowNegative="true" on the `ProfileMoneyInput` view
    declaration in a Handlebars template.
    */
    allowNegative: null,
    didInsertElement: function didInsertElement() {

      // PREPARE FOR MONEY FORMATING
      this.inputElement = this.$().find('.input');
      this.displayElement = this.$().find('.display');
    },
    onFocus: (function () {

      // TEMP REPLACE DECIMAL DOTS FOR COMMA"S
      var val = this.inputElement.prop('value');
      if (!val || val === 'undefined') {
        val = null;
      } else {
        val = this.inputElement.prop('value').replace(/\./, ',').replace(/,00/, '');
      }

      this.inputElement.prop('value', val);

      // TOGGLE DISPLAY
      this.set('isFocused', true);
    }).on('focusIn'),
    keyUp: function keyUp() {

      var val = this.inputElement.prop('value');
      if (!val || val === 'undefined') {
        val = null;
      } else {
        val = val.replace(/\.([0-9]{1,2})$/, ',$1').replace(/[^0-9.,-]/g, '');
      }

      if (val && !this.get('allowNegative')) val = val.replace(/^\-/, '');

      this.set('value', val);
      if (this.inputElement.prop('value') !== val) this.inputElement.prop('value', val);
    },
    onBlur: (function () {
      // REVERT TO DOT DECIMALS (CONSIDERING DOTS ARE USED IN STEAD OF COMMAS)
      var val = this.inputElement.prop('value');
      if (!val || val === 'undefined') {
        val = null;
      } else {
        val = this.inputElement.prop('value').replace(/\.([0-9]{1,2})$/, ',$1').replace(/\./g, '').replace(/\,/, '.');
      }

      // UPDATE VALUE
      if (this.get('value') != val) {
        // prevent unwanted dirty state
        // ROUND MONEY
        val = val ? accounting.toFixed(val, 2) : '';
        // OPTIONALLY MAKE POSITIVE
        if (!this.get('allowNegative')) val = Math.abs(val);

        this.set('value', val);
        this.inputElement.prop('value', val);
      }

      // TOGGLE DISPLAY
      this.set('isFocused', false);

      //PING TO KEEP SESSION ALIVE
      this.get('controller').send('ping');
    }).on('focusOut'),

    displayValue: (function () {
      if (this.get('value')) {
        return accounting.formatMoney(this.get('value'));
      } else {
        return null;
      }
    }).property('value')
  });
});