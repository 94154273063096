define('front-end-cli/views/accountmanagement/edit/evented-form', ['exports', 'ember'], function (exports, _ember) {
  /**
    Container view which emits a pressedEnter event
  */
  exports['default'] = _ember['default'].View.extend({
    keyDown: function keyDown(e) {
      if (e.which === 13) {
        this.get('controller').send('pressedEnter');
      }
    }
  });
});