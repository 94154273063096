define('front-end-cli/controllers/application/sidebaraccounts-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    needs: ['application', 'application/sidebaraccounts'],

    isActivatingAnAccount: null,

    firstAccount: (function () {
      var account;
      if (this.get('model.accounts.length')) {
        account = this.get('model.accounts.firstObject');
      } else {
        account = this.get('model');
      }
      return account;
    }).property('model'),

    bank: null,
    image: (function () {
      this.get('firstAccount.bank').then((function (bank) {
        this.set('bank', bank);
      }).bind(this));
    }).observes('model'),

    modalTitle: (function () {
      var title;
      // Banklogin has accounts array
      if (this.get('model.accounts')) {
        if (this.get('model.accounts.length') > 1) {
          var bank = this.get('model.bank'),
              suffix = ' internetbankieren';

          title = bank.get('title') + suffix;
        } else {
          title = this.get('model.accounts.firstObject.description') || this.get('model.accounts.firstObject.accountNumber');
        }
      } else {
        title = this.get('model.description') || this.get('model.accountNumber');
      }
      return title;
    }).property('model.bank'),

    isCashAccount: (function () {
      return this.get('model.bank.id') == 22;
    }).property('model.bank'),

    hasMultipleAccounts: (function () {
      return this.get('isBankLogin') && this.get('model.accounts.length') > 1;
    }).property('model.accounts'),

    timeAgo: (function () {

      // Get lastest update date from accounts array
      var latestUpdate = 0;
      if (this.get('isBankLogin')) {
        latestUpdate = 0;
        this.get('model.accounts').forEach(function (account) {
          if (!latestUpdate) {
            latestUpdate = moment();
          }
          if (moment(account.get('lastupdate')).isBefore(latestUpdate)) {
            latestUpdate = account.get('lastupdate');
          }
        });
      } else {
        latestUpdate = this.get('model.lastupdate');
      }

      // Make sure we only use days
      var dateLastUpdate = moment(latestUpdate, 'YYYY-MM-DD'),
          dateToday = moment(moment().format('YYYY-MM-DD')),
          daysAgoHuman = dateLastUpdate.from(dateToday),
          daysAgo = dateToday.diff(dateLastUpdate, 'days');

      if (daysAgo > 0) {
        return daysAgoHuman;
      } else {
        return 'vandaag';
      }
    }).property('model.lastupdate', 'model.accounts.@each.lastupdate'),

    isBankLogin: (function () {
      // bankLogin has an accounts relation
      return this.get('model.accounts.length');
    }).property('model.accounts'),

    actions: {

      update: function update() {

        // Accountmanagement.update uses an account as model.
        // So we'll just use the first account for now.
        var account;
        if (this.get('isBankLogin')) {
          account = this.get('model.accounts.firstObject');
        } else {
          account = this.get('model');
        }

        // Transition
        this.transitionToRoute('accountmanagement.update', account);

        // Hide modal
        _ember['default'].run.later(this, (function () {
          this.send('hideModal', true);
        }).bind(this), 200);
      },

      updateAuto: function updateAuto() {
        var account = undefined;
        if (this.get('isBankLogin')) {
          account = this.get('model.accounts.firstObject');
        } else {
          account = this.get('model');
        }

        this.transitionToRoute('automatic-import.update-account', account);

        // let baseUrl = window.location.origin;
        // window.top.location =  baseUrl+'#/psd2/importeren/'+account.id;
        // Hide modal
        _ember['default'].run.later(this, (function () {
          this.send('hideModal', true);
        }).bind(this), 200);
      },

      redirectToFeaturePage: function redirectToFeaturePage() {
        this.transitionToRoute('automatic-import.psd2-information');
      },

      toggleActivated: function toggleActivated(account) {

        // Prevent firing calls before any running
        // activation toggle on this account is finished.
        if (this.get('isActivatingAnAccount')) {
          _ember['default'].Logger.debug('Blocking consecutive account activation calls until finished');
          return;
        } else {
          this.set('isActivatingAnAccount', true);
        }

        account.toggleProperty('activated');

        var action = account.get('activated') ? 'activate' : 'deactivate';

        _ember['default'].$.ajax({
          url: '/app/async/common.modifyaccounts.' + action,
          type: 'post',
          data: { id: account.get('id') },
          dataType: 'json'
        }).then((function (jqXHR, textStatus, a) {
          // Then: reload the App to reflect the User's new data.
          var currentPath = this.get('controllers.application.currentPath');
          if (/legacy/.test(currentPath)) {
            // legacy iframe reload including loader
            this.send('delayedContentUpdate', account.get('activated'));
          } else if (/income|expenses|trends|timeline/.test(currentPath)) {
            // Or route refresh for native routes
            this.send('refreshApplicationRoute');
          }
        }).bind(this)).fail(function (jqXHR, textStatus, errorThrown) {
          // Fail: undo the action the we've promised we'd perform for the User.
          if (action == 'activate') {
            account.set('activated', true);
          } else {
            account.set('activated', false);
          }
          throw new Error('Can’t toggle account’s activated state: ' + errorThrown);
        }).always((function (jqXHR, textStatus, mixed) {
          // Always: allow the User to interact with the App again.
          account.set('activating', account.get('activated') ? 'inschakelen' : 'uitschakelen');
          this.set('isActivatingAnAccount', false);
          account.set('activating', null);
        }).bind(this));
      },

      delayedContentUpdate: function delayedContentUpdate(activated) {

        // Only run on account related legacy pages
        if (!this.get('controllers.application.currentPath').match(/overview|transactions|settings\.balance/)) return;

        // this.send('showActivationLoader', activated);

        _ember['default'].run.cancel(this.get('iframeUpdateTimer'));

        this.set('iframeUpdateTimer', _ember['default'].run.later(this, function () {
          this.set('iframeUpdateTimer', null);
          //RELOAD THE IFRAME
          _ember['default'].$('#legacy').attr('src', _ember['default'].$('#legacy').attr('src'));
        }, 1000));
      },

      showActivationLoader: function showActivationLoader(activated) {

        _ember['default'].$('#will-reload').remove();
        // Ember.$('#legacy').css({opacity: 0.2});
        var text = activated ? 'Rekening wordt geactiveerd' : 'Rekening wordt gedeactiveerd';
        _ember['default'].$('#legacy-wrap').append(_ember['default'].$('<div/>', { id: 'will-reload' }).append(_ember['default'].$('<span/>', { text: text })));

        _ember['default'].run.cancel(this.get('activationLoader'));

        this.set('activationLoader', _ember['default'].run.later(this, function () {
          this.set('activationLoader', null);
          _ember['default'].$('#will-reload').remove();
          _ember['default'].$('#legacy').css({ opacity: '' });
        }, 2000));
      }
    }
  });
});