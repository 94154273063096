define('front-end-cli/transforms/pojo', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
      // if (serialized) {
      //   return JSON.stringify(serialized);
      // } else {
      //   return '';
      // }
    },

    serialize: function serialize(deserialized) {
      return deserialized;
      // if (deserialized) {
      //   return JSON.stringify(deserialized);
      // } else {
      //   return [];
      // }
    }
  });
});