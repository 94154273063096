define('front-end-cli/pods/components/category-dissect-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['CategoryDissectList'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    type: null, // income or expenses
    categories: null, // Already filterd in categoryDissectComponent!
    category: null,
    subcategory: null,
    preview: null,
    // Set in CategoryDissectComponent
    showDetails: null,
    showDetailsToggle: null,

    /**-------------------------
      Calculated Properties
    ---------------------------*/

    filteredCategories: (function () {

      // get all categories enabled true

      // income categories
      // show categories in list when below statements are true

      // currentNetted > 0
      // currentNetted = 0 && averageNetted > 0 (this means there are transactions but disabled)
      // currentNetted = 0 (the result of the category is 0)

      // only when showDetails = true
      // currentNetted = null && expectedNetted > 0 (this means no activity but the user has a budget)

      var type = this.get('type'),
          showDetails = this.get('showDetails'),
          filteredCategories = this.get('categories').filterBy('enabled', true).filter(function (category) {
        var expectedNetted = category.get('expectedNetted');
        var currentNetted = category.get('currentNetted');
        var averageNetted = category.get('averageNetted');

        if (type === 'income') {
          if (expectedNetted < 0) {
            category.set('removeFromBudgetResult', true);
          } else {
            category.set('removeFromBudgetResult', false);
          }
          if (currentNetted < 0) {
            category.set('removeFromNettedResult', true);
          } else {
            category.set('removeFromNettedResult', false);
          }
        } else if (type === 'expenses') {
          if (expectedNetted > 0) {
            category.set('removeFromBudgetResult', true);
          } else {
            category.set('removeFromBudgetResult', false);
          }
          if (currentNetted > 0) {
            category.set('removeFromNettedResult', true);
          } else {
            category.set('removeFromNettedResult', false);
          }
        }

        // If type = income, show the categories where the netted is positive. Also show those with a positive expectedNetted, if the user is in the detail view. Do the reverse for expense.
        if (type === 'income') {
          if (showDetails && expectedNetted !== null && expectedNetted > 0 || currentNetted !== null && currentNetted > 0) {
            return true;
          } else if (currentNetted === 0 && averageNetted > 0) {
            return true;
          }
        } else if (type === 'expenses') {
          if (showDetails && expectedNetted !== null && expectedNetted < 0 || currentNetted !== null && currentNetted < 0) {
            return true;
          } else if (currentNetted === 0 && averageNetted < 0) {
            return true;
          }
        }
      });

      // Sort the filtered categories by currentNetted

      if (type === 'income') {
        filteredCategories = filteredCategories.sort(function (a, b) {
          return (a.get('currentNetted') === null) - (b.get('currentNetted') === null) || -(a.get('currentNetted') > b.get('currentNetted')) || +(a.get('currentNetted') < b.get('currentNetted'));
        });
      } else {
        filteredCategories = filteredCategories.sort(function (a, b) {
          return (a.get('currentNetted') === null) - (b.get('currentNetted') === null) || +(a.get('currentNetted') > b.get('currentNetted')) || -(a.get('currentNetted') < b.get('currentNetted'));
        });
      }

      return filteredCategories;
    }).property('categories.[]', 'type', 'showDetails'),

    sortedSubcategories: (function () {
      var _this = this;

      var showDetails = this.get('showDetails');

      if (!this.get('category')) {
        return;
      }

      var sorted = this.get('category.subcategories').filterBy('enabled', true).filter(function (subcategory) {

        if (subcategory.get('currentNetted') !== null) {
          return true;
        }
        if (subcategory.get('currentNetted') === null) {
          if (subcategory.get('name') == _this.get('subcategory.name')) {
            _this.set('preview', _this.get('subcategory'));
            return true;
          }
          if (showDetails && subcategory.get('expectedNetted') !== null || subcategory.get('currentNetted') !== null) {
            return true;
          }
        }
      });

      if (this.get('type') === 'income') {
        sorted = sorted.sort(function (a, b) {
          return (a.get('currentNetted') === null) - (b.get('currentNetted') === null) || -(a.get('currentNetted') > b.get('currentNetted')) || +(a.get('currentNetted') < b.get('currentNetted'));
        });
      } else {
        sorted = sorted.sort(function (a, b) {
          return (a.get('currentNetted') === null) - (b.get('currentNetted') === null) || +(a.get('currentNetted') > b.get('currentNetted')) || -(a.get('currentNetted') < b.get('currentNetted'));
        });
      }

      return sorted;
    }).property('category', 'category.subcategories.@each.currentNetted', 'type', 'showDetails'),

    isCategoriesIndex: (function () {
      return !this.get('category');
    }).property('category'),

    isCategoryIndex: (function () {
      return this.get('category') && !this.get('subcategory');
    }).property('category', 'subcategory'),

    // expectedNettedTotalArray: Ember.computed.mapBy('filteredCategories', 'expectedNetted'),
    // expectedNettedTotal: Ember.computed.sum('expectedNettedTotalArray'),

    expectedNettedTotalFiltered: (function () {
      return this.get('filteredCategories').filterBy('removeFromBudgetResult', false).reduce(function (previousValue, item) {
        return previousValue + item.get('expectedNetted');
      }, 0);
    }).property('filteredCategories.@each.expectedNetted'),

    subExpectedNettedTotal: (function () {
      if (!this.get('category')) return 0;
      return this.get('category.subcategories').filterBy('enabled', true).reduce(function (previousValue, item) {
        return previousValue + item.get('expectedNetted');
      }, 0);
    }).property('category.subcategories.@each.expectedNetted'),

    // currentNettedTotalArray: Ember.computed.mapBy('filteredCategories', 'currentNetted'),
    // currentNettedTotal: Ember.computed.sum('currentNettedTotalArray'),

    currentNettedTotalFiltered: (function () {
      return this.get('filteredCategories').filterBy('removeFromNettedResult', false).reduce(function (previousValue, item) {
        return previousValue + item.get('currentNetted');
      }, 0);
    }).property('filteredCategories.@each.currentNetted'),

    subCurrentNettedTotal: (function () {
      if (!this.get('category')) return 0;
      return this.get('category.subcategories').filterBy('enabled', true).reduce(function (previousValue, item) {
        return previousValue + item.get('currentNetted');
      }, 0);
    }).property('category.subcategories.@each.currentNetted'),

    resultTotal: (function () {
      return this.get('filteredCategories').reduce(function (previousValue, item) {
        if (item.get('removeFromNettedResult') && !item.get('removeFromBudgetResult')) {
          return previousValue + (0 - item.get('expectedNetted'));
        } else if (item.get('removeFromBudgetResult') && !item.get('removeFromNettedResult')) {
          return previousValue + item.get('currentNetted');
        } else if (item.get('removeFromBudgetResult') && item.get('removeFromNettedResult')) {
          return previousValue + 0;
        } else {
          return previousValue + (0 - (item.get('expectedNetted') - item.get('currentNetted')));
        }
      }, 0);
    }).property('filteredCategories.@each'),

    subResultTotal: (function () {
      if (!this.get('category')) return 0;
      return this.get('category.subcategories').filterBy('enabled', true).reduce(function (previousValue, item) {
        return previousValue + item.get('result');
      }, 0);
    }).property('category.subcategories.@each.result'),

    isIncome: (function () {
      return this.get('type') === 'income';
    }).property('type'),

    catIndexOpacity: (function () {
      /**
       * The Donut chart shows categories/subcategories based on currentNetted. Beside the chart we show a list of categories/subcategories based on
       * whether expectedNetted is set or currentNetted is set. Therefore this list is always as large or even larger than the categories/subcategories shown in the Donut chart.
       * Only categories/subcategories shown in the Donut chart need a color reference. Therefore we count the amount of categories/subcategories that are in the Donut chart through currentNetted.
       * Luckily the list is always ordered on currentNetted, so it is easy to determine which categories/subcategories need a color reference. Namely: the first in the array.
       */
      _ember['default'].run.scheduleOnce('render', this, function () {

        var type = this.get('type');
        var categoriesShownInChart = 0;
        if (this.get('category') !== null) {
          if (this.get('sortedSubcategories.length')) {
            this.get('sortedSubcategories').forEach(function (item, index, enumerable) {
              var currentNetted = item.get('currentNetted');
              if (currentNetted !== null && (type === 'expenses' && currentNetted < 0 || type === 'income' && currentNetted > 0)) {
                categoriesShownInChart++;
              }
            });
          }
        } else {
          this.get('filteredCategories').forEach(function (item, index, enumerable) {
            var currentNetted = item.get('currentNetted');
            if (currentNetted !== null && (type === 'expenses' && currentNetted < 0 || type === 'income' && currentNetted > 0)) {
              categoriesShownInChart++;
            }
          });
        }

        var subcategory = this.get('subcategory');
        var itemsDrawn = 0;
        var indicators = this.$('.CategoryDissectList-indicator');

        if (indicators === undefined || indicators.length === 0) return;

        indicators.each(function (index) {
          var opacity;
          if (itemsDrawn < categoriesShownInChart) {
            opacity = 1 / (itemsDrawn + 1);

            if (itemsDrawn === 0) {
              opacity -= 0.2;
            }
          } else {
            opacity = 0;
          }

          _ember['default'].$(this).css({ opacity: opacity }).toggleClass('has-activeSubcategory', !!subcategory);
          itemsDrawn++;
        });
      });
    }).on('didInsertElement').observes('category', 'subcategory', 'filteredCategories', 'sortedSubcategories'),

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {

      setCategory: function setCategory(category) {
        this.set('category', category);
      },

      setSubcategory: function setSubcategory(subcategory) {
        this.set('subcategory', subcategory);
      },

      toggleDetails: function toggleDetails() {
        this.sendAction();
      },

      toggleNullValues: function toggleNullValues() {
        alert('nullll');
      }
    },

    /**-------------------------
      Events
    ---------------------------*/

    mouseLeave: function mouseLeave() {
      this.set('preview', null);
    }

  });
});