define('front-end-cli/controllers/profiler/debt', ['exports', 'ember', 'front-end-cli/mixins/profiler/sub-model-switch'], function (exports, _ember, _frontEndCliMixinsProfilerSubModelSwitch) {
  exports['default'] = _ember['default'].Controller.extend(_frontEndCliMixinsProfilerSubModelSwitch['default'], {

    needs: ['profiler'],
    selectObjects: _ember['default'].computed.alias('controllers.profiler.selectObjects'),
    /**
      Create and delete the appropriate
      type-records when the type changes.
       debtMortgage
      debtStudentLoan
      debtConsumerCredit
    */
    typeObserver: (function () {
      var type = this.get('model.type'),
          recordName,
          record;

      if (type == 1) {
        recordName = 'debtMortgage';
        this.deleteRecords(['debtStudentLoan', 'debtConsumerCredit']);
      } else if (type == 2) {
        recordName = 'debtStudentLoan';
        this.deleteRecords(['debtMortgage', 'debtConsumerCredit']);
      } else if (type == 3) {
        recordName = 'debtConsumerCredit';
        this.deleteRecords(['debtMortgage', 'debtStudentLoan']);
      }

      record = this.store.createRecord(recordName, {
        debt: this.get('model')
      });

      /**
        Parent model needs to be saved to be able to the declare relation
      */
      if (!this.get('model.id')) {

        this.storeRelationlessRecord({
          recordName: recordName,
          record: record,
          parentName: 'debt',
          parent: this.get('model')
        });
      }
    }).observes('model.type')
  });
});