define('front-end-cli/views/profiler/profile', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({
    classNames: ['profiler'],

    /**
      Connect profile-navigation with profile-content
    */
    didInsertElement: function didInsertElement() {

      var iframed = window.parent != window,
          page = iframed ? _ember['default'].$(window.parent.document).find('html, body') : _ember['default'].$('html, body'),
          correction = iframed ? 195 : -17;

      _ember['default'].$('#section_nav').on('mousedown', 'li', function (e) {

        var index = _ember['default'].$('#section_nav li').index(_ember['default'].$(this)),
            top = _ember['default'].$('.profiler > section').eq(index).offset().top + correction;

        page.animate({
          scrollTop: top
        }, 400);
      });
    },
    willDestroyElement: function willDestroyElement() {
      _ember['default'].$('#section_nav').off('mousedown'); // clear Ember.$.cache, not sure if needed
    }
  });
});