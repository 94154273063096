define("front-end-cli/templates/profiler/mixin/date", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "front-end-cli/templates/profiler/mixin/date.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        return morphs;
      },
      statements: [["inline", "view", ["profiler/label-select-input"], ["optionLabelPath", "content.label", "optionValuePath", "content.id", "content", ["subexpr", "@mut", [["get", "App.dayselect.content"]], []], "width", "24%", "prompt", "dag"]], ["inline", "view", ["profiler/label-select-input"], ["optionLabelPath", "content.label", "optionValuePath", "content.id", "content", ["subexpr", "@mut", [["get", "App.monthselect.content"]], []], "width", "40%", "prompt", "maand"]], ["inline", "view", ["profiler/label-select-input"], ["optionLabelPath", "content.label", "optionValuePath", "content.id", "content", ["subexpr", "@mut", [["get", "App.yearselect.content"]], []], "width", "36%", "prompt", "jaar"]]],
      locals: [],
      templates: []
    };
  })());
});