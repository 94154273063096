define('front-end-cli/models/capital', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    accountType: _emberData['default'].attr('string'),
    yearAmount: _emberData['default'].attr('number'),
    accountNumber: _emberData['default'].attr('string'),
    adminType: _emberData['default'].attr('number'),

    incomeNow: _emberData['default'].belongsTo('income-now'),
    incomeLater: _emberData['default'].belongsTo('income-later')
  });
});