define('front-end-cli/pods/components/content-editable/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['contenteditable'],
    classNameBindings: ['value::is-placeholder'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    value: null,
    transactionId: null,
    isEditing: null,
    isFocussed: null,

    /**-------------------------
      Properties
    ---------------------------*/

    valueProxy: null,
    placeholder: null,
    allowNewlines: false,

    contenteditable: (function () {
      return this.get('isEditing');
    }).property('isEditing'),

    /**-------------------------
      Methods
    ---------------------------*/

    /**
      Set the component’s value in the DOM
      when first rendering.
    */
    setInitialValue: (function () {
      if (this.get('isFocussed')) {
        this.$().text(this.get('value') || '');
        this.$().focus();
      } else {
        this.$().text(this.get('value') || this.get('placeholder'));
      }
    }).on('didInsertElement'),

    /**
      Since the value in the template is not bound,
      we need a way of updating it when the model
      delivering its value changes.
       Well check if the value is completely
      different, if so we'll update the DOM.
    */
    valueObserver: (function () {
      var value = this.get('value'),
          text = this.$().text();

      if (value) {
        var rgx = new RegExp('^.+$');
        if (!rgx.test(value)) {
          this.$().text(value);
        }
      } else {
        if (!this.get('isFocussed')) {
          var placeholder = this.get('placeholder');
          this.$().text(placeholder);
        }
      }
    }).observes('value'),

    /**-------------------------
      Events
    ---------------------------*/

    keyPress: function keyPress(e) {
      // Prevent new lines (enter key)
      if (!this.get('allowNewlines')) {
        return e.which != 13;
      }
    },

    focusIn: function focusIn() {
      // Clear the placeholder
      if (this.$().text() === this.get('placeholder')) {
        this.$().text('');
        this.set('isFocussed', true);
      }
    },

    focusOut: function focusOut() {
      if (!this.$().text()) {
        // Set the placeholder
        this.$().text(this.get('placeholder'));
      }
      this.set('isFocussed', false);
    },

    keyDown: function keyDown(e) {

      // TODO
      if (e.which === 37) {// Left arrow

      } else if (e.which === 38) {// Up arrow

        } else if (e.which === 39) {// Right arrow

          } else if (e.which === 40) {// Down arrow

            }
    },

    keyUp: function keyUp(event) {
      this.set('value', this.$().text());
    }
  });
});