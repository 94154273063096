define('front-end-cli/mixins/scroll-top', ['exports', 'ember'], function (exports, _ember) {
  /**
  This mixins adds a scroll event/action trigger to
  its target view or component. It has a build in debounce
  time of 100ms. To change this, set the `scrollTopDebounceTime'
  property in the targetview (milliseconds)`.
  
  Usage:
  ```
  import ScrollTopMixin from '/appname/mixins/scroll-top';
  Ember.view.extend({
  scroll: function() {
  // fires on window scroll event
  },
  runMeWhenScrolled: function() {
  // fires on window scroll event
  }.on('scroll'),
  });
  ```
  */

  exports['default'] = _ember['default'].Mixin.create({

    // Default scollcontainer
    containerSelector: '#app > main',
    // Default debounce time
    scrollTopDebounceTime: 100,

    _trigger: function _trigger() {
      this.trigger('scroll');
    },

    _onScroll: function _onScroll() {
      _ember['default'].run.debounce(this, this._trigger, this.get('scrollTopDebounceTime'));
    },

    bindScrolling: (function () {
      _ember['default'].assert('ScrollTopMixin can only be set on a view (or component)', this.elementId);

      var containerSelector = this.get('containerSelector');
      _ember['default'].$(containerSelector).on('scroll.' + this.elementId, _ember['default'].$.proxy(this._onScroll, this));
      _ember['default'].$(containerSelector).on('touchmove.' + this.elementId, _ember['default'].$.proxy(this._onScroll, this));
    }).on('didInsertElement'),

    unbindScrolling: (function () {
      var containerSelector = this.get('containerSelector');
      _ember['default'].$(containerSelector).off('scroll.' + this.elementId, this._onScroll);
      _ember['default'].$(containerSelector).off('touchmove.' + this.elementId, this._onScroll);
    }).on('willDestroyElement')

  });
});