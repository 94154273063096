define('front-end-cli/pods/components/budget/period-select/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['BudgetPeriodSelect'],

    // External Bindings
    isYearsOnly: null,
    startYear: null,
    month: null,
    year: null,
    // Action
    setPeriodParam: null,

    // Properties
    showPeriodSelect: false,
    selectedYear: null,
    selectedMonth: null,
    currentMonth: null,
    currentYear: null,
    startDayFinancialMonth: null,
    hasNextYear: (function () {
      var hasNextYears = this.get('selectedYear') < this.get('currentYear') + 1;
      return this.get('isPlusUser') && hasNextYears;
    }).property('isPlusUser'),
    hasPrevYear: (function () {
      var hasPreviousYears = this.get('selectedYear') > this.get('startYear');
      return this.get('isPlusUser') && hasPreviousYears;
    }).property('isPlusUser'),

    didInsertElement: function didInsertElement() {
      this.notifyPropertyChange('year');
    },

    initialize: _ember['default'].observer('year', 'month', 'startDayFinancialMonth', function () {

      var isYearsOnly = this.get('isYearsOnly'),
          selectedYear = this.get('year'),
          selectedMonth = this.get('month'),
          startDayFinancialMonth = this.get('startDayFinancialMonth') * 1,
          customFinancialMonth = startDayFinancialMonth >= 15,
          currentMonth = undefined,
          currentYear = undefined;
      if (customFinancialMonth && moment().date() >= startDayFinancialMonth) {
        currentMonth = moment().add(1, 'month').format('MM');
        currentYear = moment().add(1, 'month').year();
      } else {
        currentMonth = moment().format('MM');
        currentYear = moment().year();
      }

      this.set('currentMonth', currentMonth);
      this.set('currentYear', currentYear);

      if (!isYearsOnly && !selectedYear && !selectedMonth) {
        selectedMonth = currentMonth;
      }

      if (!selectedYear) {
        selectedYear = currentYear;
      }

      // index year-overview
      if (!selectedMonth) {
        selectedMonth = true;
      }

      this.set('selectedYear', selectedYear);
      this.set('selectedMonth', selectedMonth);
    }),

    years: _ember['default'].computed('year', 'selectedYear', 'isYearsOnly', 'isPlusUser', 'currentYear', function () {
      var years = [],
          currentYear = this.get('currentYear'),
          startYear = this.get('startYear') || currentYear;

      if (!this.get('isPlusUser')) {
        return [{ v: currentYear, selected: true }];
      }

      var s = startYear;

      var selected = undefined;
      while (startYear <= currentYear + 1) {
        selected = startYear == this.get('selectedYear');
        years.push({ v: startYear, selected: selected });
        startYear++;
      }

      return years;
    }),

    months: _ember['default'].computed('selectedMonth', function () {
      var s = this.get('selectedMonth');
      var months = [{ v: '01', t: 'januari', a: true, c: s === '01' }, { v: '02', t: 'februari', a: true, c: s === '02' }, { v: '03', t: 'maart', a: true, c: s === '03' }, { v: '04', t: 'april', a: true, c: s === '04' }, { v: '05', t: 'mei', a: true, c: s === '05' }, { v: '06', t: 'juni', a: true, c: s === '06' }, { v: '07', t: 'juli', a: true, c: s === '07' }, { v: '08', t: 'augustus', a: true, c: s === '08' }, { v: '09', t: 'september', a: true, c: s === '09' }, { v: '10', t: 'oktober', a: true, c: s === '10' }, { v: '11', t: 'november', a: true, c: s === '11' }, { v: '12', t: 'december', a: true, c: s === '12' }];
      return months;
    }),

    showButton: _ember['default'].computed('year', 'month', 'selectedYear', 'selectedMonth', 'currentYear', 'currentMonth', function () {

      var selectedYear = this.get('selectedYear'),
          selectedMonth = this.get('selectedMonth'),
          year = this.get('year'),
          month = this.get('month');

      var dirty = false;

      if (!year && selectedYear != this.get('currentYear')) {
        dirty = true;
      } else if (!month && selectedMonth && selectedMonth != this.get('currentMonth')) {
        dirty = true;
      } else if (year && selectedYear && selectedYear != year) {
        dirty = true;
      } else if (month && selectedMonth && selectedMonth != month) {
        dirty = true;
      } else if (month && !selectedMonth) {
        dirty = true;
      } else if (!this.get('isYearsOnly') && !year && !month && !selectedMonth) {
        dirty = true;
      } else if (!this.get('isYearsOnly') && year && !month && selectedMonth) {
        dirty = true;
      }

      return dirty;
    }),

    /**
      Hide and reset period-select when the
      event-target was outside the component.
    */
    _mouseDownResponder: function _mouseDownResponder(e) {
      if (this.get('showPeriodSelect')) {

        var notWithinPeriodSelect = !_ember['default'].$(e.target).parents('.BudgetPeriodSelect').length,
            onCurtain = _ember['default'].$(e.target).hasClass('BudgetPeriodSelect-curtain');

        if (onCurtain || notWithinPeriodSelect) {
          this.setProperties({
            showPeriodSelect: false
          });
          this.notifyPropertyChange('year');
        }
      }
    },

    setMouseDownListeners: (function () {
      this.mdr = this._mouseDownResponder.bind(this);
      _ember['default'].$(document).on('mousedown touchend', this.mdr);
    }).on('didInsertElement'),

    tearDown: (function () {
      _ember['default'].$(document).off('mousedown', this.mdr);
    }).on('willDestroyElement'),

    actions: {
      togglePeriodSelect: function togglePeriodSelect() {
        // if (this.get('isPlusUser')) {
        this.toggleProperty('showPeriodSelect');
        // }
      },

      setYear: function setYear(year) {
        if (this.get('isYearsOnly')) {
          this.set('selectedYear', year);
          this.sendAction('setPeriodParam', year);

          // Close PeriodSelect
          this.set('showPeriodSelect', false);
        } else {
          this.set('selectedMonth', null);
          this.set('selectedYear', year);
        }
      },

      setMonth: function setMonth(month) {
        this.set('selectedMonth', month);
      },

      setYearMonth: function setYearMonth() {

        var year = this.get('selectedYear'),
            month = this.get('selectedMonth');

        this.sendAction('setPeriodParam', year, month);

        // Close PeriodSelect
        this.set('showPeriodSelect', false);
      },

      prevMonth: function prevMonth() {
        var _this = this;

        var months = this.get('months'),
            year = this.get('year') || this.get('currentYear'),
            prev = undefined;

        months.forEach(function (month) {
          if (month.c) {
            if (!prev) {
              prev = months[11];
              year -= 1;
            }
            _this.sendAction('setPeriodParam', year, prev.v);
          }
          prev = month;
        });
      },

      nextMonth: function nextMonth() {
        var _this2 = this;

        var months = this.get('months'),
            year = this.get('year') || this.get('currentYear'),
            nxtIsUp = undefined;

        months.forEach(function (month) {
          if (month.c) {
            if (month.v === '12') {
              _this2.sendAction('setPeriodParam', year * 1 + 1, '01');
            } else {
              nxtIsUp = true;
            }
          } else if (nxtIsUp) {
            _this2.sendAction('setPeriodParam', year, month.v);
            nxtIsUp = false;
          }
        });
      },

      prevYear: function prevYear() {
        if (!this.get('isYearsOnly') || this.get('isPlusUser')) {
          var hasPreviousYears = this.get('selectedYear') > this.get('startYear');
          if (hasPreviousYears) {
            var year = this.get('year') || this.get('currentYear');
            this.sendAction('setPeriodParam', year * 1 - 1);
          }
        }
      },

      nextYear: function nextYear() {
        if (!this.get('isYearsOnly') || this.get('isPlusUser')) {
          var hasNextYears = this.get('selectedYear') < this.get('currentYear') + 1;
          if (hasNextYears) {
            var year = this.get('year') || this.get('currentYear');
            this.sendAction('setPeriodParam', year * 1 + 1);
          }
        }
      }
    }
  });
});