define('front-end-cli/controllers/accountmanagement/lock-accounts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    needs: ['application'],

    wantsNoPlus: false,
    accountSavePromises: [],

    hasAlreadyChosen: (function () {
      return this.get('currentUser.model.accounts').filter(function (item, index, enumerable) {
        if (item.get('locked') === true || item.get('locked') === false) {
          return true;
        }
      }).get('length') > 0;
    }).property('currentUser.model.accounts.@each.id'),

    cashAccounts: (function () {
      var cashAccounts = this.get('currentUser.model.accounts').filter(function (item, index, enumerable) {
        return item.get('bank.id') == 22 && !item.get('mobileWallet');
      });

      cashAccounts.forEach(function (item, index, enumerable) {
        if (item.get('selected') === undefined) {
          item.set('selected', false);
        }
      });
      return cashAccounts;
    }).property('currentUser.model.accounts.@each.id'),

    bankAccounts: (function () {
      var bankAccounts = this.get('currentUser.model.accounts').filter(function (item, index, enumerable) {
        return item.get('bank.id') != 22;
      });

      bankAccounts.forEach(function (item, index, enumerable) {
        if (item.get('selected') === undefined) {
          item.set('selected', false);
        }
      });

      return bankAccounts;
    }).property('currentUser.model.accounts.@each.id'),

    mobileWallet: (function () {
      var mobileWallet = this.get('currentUser.model.accounts').filterBy('mobileWallet', true);

      mobileWallet.forEach(function (item, index, enumerable) {
        if (item.get('selected') === undefined) {
          item.set('selected', true);
        }
      });

      return mobileWallet;
    }).property('currentUser.model.accounts.@each.id'),

    hasBankAccounts: (function () {
      return this.get('bankAccounts.length') > 0;
    }).property('bankAccounts.length'),

    hasCashAccounts: (function () {
      return this.get('cashAccounts.length') > 0;
    }).property('cashAccounts.length'),

    hasMobileWallet: (function () {
      return this.get('mobileWallet.length') > 0;
    }).property('mobileWallet.length'),

    notSelectedMaximumAccounts: (function () {
      var bankAccounts = this.get('bankAccounts'),
          cashAccounts = this.get('cashAccounts');

      return !((cashAccounts.get('length') === 0 || cashAccounts.filterBy('selected').get('length') === 1) && (bankAccounts.filterBy('selected').get('length') === this.get('currentUser.model.state.amountOfAccounts') || bankAccounts.filterBy('selected').get('length') === bankAccounts.get('length')));
    }).property('bankAccounts.@each.selected', 'cashAccounts.@each.selected'),

    actions: {

      toggleBankAccount: function toggleBankAccount(bankAccount) {
        if (bankAccount.get('selected') === false && this.get('bankAccounts').filterBy('selected', true).get('length') < this.get('currentUser.model.state.amountOfAccounts')) {
          bankAccount.set('selected', true);
        } else {
          bankAccount.set('selected', false);
        }
      },

      toggleCashAccount: function toggleCashAccount(cashAccount) {
        if (cashAccount.get('selected') === false && this.get('cashAccounts').filterBy('selected', true).get('length') < 1) {
          cashAccount.set('selected', true);
        } else {
          cashAccount.set('selected', false);
        }
      },

      chooseLockedAccounts: function chooseLockedAccounts() {
        this.set('wantsNoPlus', true);
      },

      chooseAgain: function chooseAgain() {
        this.set('wantsNoPlus', false);
      },

      saveLockedAccounts: function saveLockedAccounts() {
        if (this.get('hasBankAccounts')) {
          this.send('saveLockedAccountType', this.get('bankAccounts'));
        }

        if (this.get('hasCashAccounts')) {
          this.send('saveLockedAccountType', this.get('cashAccounts'));
        }

        if (this.get('hasMobileWallet')) {
          this.send('saveLockedAccountType', this.get('mobileWallet'));
        }

        _ember['default'].RSVP.all(this.get('accountSavePromises')).then((function () {
          this.get('currentUser.model').reload().then((function () {
            this.send('goToPreviousRoute');
          }).bind(this));
        }).bind(this));
      },

      saveLockedAccountType: function saveLockedAccountType(accounts) {
        accounts.forEach((function (item, index, enumerable) {
          if (item.get('selected')) {
            item.set('locked', false);
          } else {
            item.set('locked', true);
          }

          this.get('accountSavePromises').pushObject(item.save());
        }).bind(this));
      }

    }
  });
});