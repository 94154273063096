define('front-end-cli/views/automatic-import/psd2-information', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({
    generateConsentUrl: function generateConsentUrl() {
      _ember['default'].$.ajax({
        type: 'GET',
        url: '/api/v3/automaticimport?procedure=generateconsenturl'
      }).success(function (response) {
        window.location.replace(response.requestData.url);
      }).error(function (error) {
        //Nothing here
      });
    }
  });
});