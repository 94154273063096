define('front-end-cli/models/misc-damage-insurance', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    miscInsurance: _emberData['default'].belongsTo('misc-insurance'),

    realEstateInsurance: _emberData['default'].attr('number'), //opstal
    homeContentsInsurance: _emberData['default'].attr('number'), //inboedel
    liabilityInsurance: _emberData['default'].attr('number'), //aansprakelijkheidsverzekering
    lagalInsurance: _emberData['default'].attr('number'), //Rechtsbijstand
    travelInsurance: _emberData['default'].attr('number'),
    carInsurance: _emberData['default'].attr('number'),
    healthInsurance: _emberData['default'].attr('number')
  });
});