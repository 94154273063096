define('front-end-cli/pods/components/transaction-details/component', ['exports', 'ember', 'ember-uploader', 'front-end-cli/mixins/scroll-top', 'front-end-cli/mixins/window-resize', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _ember, _emberUploader, _frontEndCliMixinsScrollTop, _frontEndCliMixinsWindowResize, _emberKeyboardShortcutsMixinsComponent) {
  exports['default'] = _ember['default'].Component.extend(_frontEndCliMixinsScrollTop['default'], _frontEndCliMixinsWindowResize['default'], _emberKeyboardShortcutsMixinsComponent['default'], {

    classNames: ['TransactionDetails', 'RelatedTransactionEdit'], // TODO Remove `RelatedTransactionEdit`
    classNameBindings: ['isFixed', 'isEditing', 'isVisible'],

    /**-------------------------
      External bindings
    ---------------------------*/

    categories: null,
    labels: null,
    transaction: null,
    receipts: null,
    hasSidebar: null,

    // Actions
    toggleModal: null,
    closeTransactionEdit: null,
    refreshRoute: null,

    /**-------------------------
      Properties
    ---------------------------*/

    receiptPreviewUrl: null,
    receiptFile: null,

    scrollTopDebounceTime: 5, // overwite scroll-top mixin default
    isSaved: false,
    isVisible: false,
    statusMessage: null,
    // Dirty state related props
    initialJsonString: null,
    transactionIsDirty: null,
    // Rollback related props
    initialRelations: null,
    initialLabels: null,

    isEditingTransactionDate: false,
    validationError: null,
    deletedExistingChildTransactions: null,
    splitsAreValid: true,
    splitsChoosingSubcategory: false, // Keeps track of if a split transaction is selecting a subcategory. This is done through the parent component in order to facilitate the correct behavior of the 'annuleren' button.
    routeNeedsRefresh: null,
    error: null,

    /**-------------------------
      Calculated Properties
    ---------------------------*/

    /**
      HACK Create a reference to this
      component to be bound to nested
      components for quick prototyping.
    */
    detailsComponent: _ember['default'].computed('transaction', function () {
      return this;
    }),

    title: _ember['default'].computed('isEditDetail', function () {
      var title = 'Transactie Details',
          isEditDetail = this.get('isEditDetail');
      if (isEditDetail) {
        var editDetail = this.get('editDetail');
        if (/categories-pane$/.test(editDetail)) {
          title = 'Wijzig de Categorie';
        } else if (/disable-pane$/.test(editDetail)) {
          title = 'Transactie Uitschakelen';
        } else if (/remove-pane$/.test(editDetail)) {
          title = 'Transactie Verwijderen';
        } else if (/restore-pane$/.test(editDetail)) {
          title = 'Transactie Herstellen';
        } else if (/receipt-pane$/.test(editDetail)) {
          title = 'Bonnetje Toevoegen';
        } else if (/splits-pane$/.test(editDetail)) {
          title = 'Transactie Splitsen';
        } else if (/related-pane$/.test(editDetail)) {
          title = 'Soortgelijke Transacties';
        }
      }
      return title;
    }),

    isSplitsPane: (function () {
      return (/splits-pane$/.test(this.get('editDetail'))
      );
    }).property('isEditDetail'),

    isSplitsPaneAndInvalid: (function () {
      return (/splits-pane$/.test(this.get('editDetail')) && !this.get('splitsAreValid')
      );
    }).property('isEditDetail', 'splitsAreValid'),

    isSplitsPaneAndValidEdit: (function () {
      return (/splits-pane$/.test(this.get('editDetail')) && this.get('splitsAreValid')
      );
    }).property('isEditDetail', 'splitsAreValid'),

    /**-------------------------
      Methods & Observers
    ---------------------------*/

    toggleVisibility: (function () {

      var relatedTransactionsTop = this.$().parent().offset().top,
          mainScrollTop = _ember['default'].$('#app > main').scrollTop();

      this.set('relatedTransactionsTop', relatedTransactionsTop + mainScrollTop);

      if (this.get('transaction')) {
        this.send('showTransactionEdit');
        this.defineContainerPosition();
      } else {
        this.send('closeTransactionEdit');
      }
    }).on('didInsertElement'),

    /**
      When the TransactionDetails pane is scrolled beyond
      the top of window, fixed position is set. When its
      position is fixed the scroll events won’t bubble up to
      the <main> container, creating an awkward  experience.
       This method resolves this by ignoring any pointer-
      events whenever scrolling on the TransactionsDetails
      while it has a scrolltop of 0.
       This works in all modern browsers as well as IE11
      and up. iOS does not have issue to begin with.
    */
    observeDetailScrolling: (function () {
      var _this2 = this;

      _ember['default'].run.next(function () {
        _this2.$().on('mousewheel', function () {

          /**
            Long lists of categories might need scrolling
            inside the details-pane. Setting `pointerEvents`
            to none will prevent this, thus making the
            lower rows inaccessible.
             To prevent this we don’t play our
            little pointerEvents trick when in
            the categories detail.
          */
          var noActiveCategoriesDetail = !_this2.get('isEditDetail') || !/categories-pane$/.test(_this2.get('editDetail'));

          var detrailTop = _this2.$('.TransactionDetails-scrollContainer').scrollTop();

          if (noActiveCategoriesDetail && event.deltaY < 0 && !detrailTop) {
            _this2.$().css({ pointerEvents: 'none' });

            _ember['default'].run.debounce(function () {
              _this2.$().css({ pointerEvents: '' });
            }, 200);
          }
        });
      });
    }).on('didInsertElement'),

    setMouseListeners: (function () {
      this.mdr = this.mouseDownResponder.bind(this);

      _ember['default'].$(document).on('mousedown touchend', this.mdr);
    }).on('didInsertElement'),

    /**
      We want the `RelatedTransactionEdit` container’s left side
      to fall into our grid, while the right side sticks to the right
      side of the page. For the box to comply with our responsive grid,
      it uses our grid classes from within a zero-height grid container.
       We'll do this by calculating the `right` and `width` on:
      - insertion
      - window resize
      - sidebar toggling
    */
    defineContainerWidthAndPadding: (function () {

      // Reset any previously customized values
      // NOTE switched to getting the width from
      // the parent element (since width was
      // incorrect when fixed), so perhaps this
      //  can be removed.
      this.$().css({
        width: '',
        paddingRight: ''
      });

      // Get box size & position
      var offsetLeft = this.$().offset().left,
          width = this.$().parent().width(),
          windowWidth = _ember['default'].$(window).innerWidth();

      // Set a custom width and right-padding, the latter makes
      // sure the content’s right aligns with our grid.
      this.$().css({
        width: width
      });
      // paddingRight: windowWidth - offsetLeft - width
      this.set('componentWidth', ('width: ' + (windowWidth - offsetLeft) + 'px;').htmlSafe());
    }).on('didInsertElement', 'windowResize'),

    _fadeOut: function _fadeOut() {
      var element = _ember['default'].$('.RelatedTransactionEdit-commentCharactersLeft');
      element.fadeOut(500);
    },

    /**
      When receipts have been added to the current-
      transaction, we’ll upload them and continue
      saving when the upload has been completed.
    */
    _uploadAndFindReceipt: function _uploadAndFindReceipt() {
      var _this3 = this;

      var deferred = _ember['default'].RSVP.defer();

      // TODO save receipts!
      // Receipt saving
      var receiptFile = this.get('receiptFile');
      if (receiptFile) {

        var uploader = _emberUploader['default'].Uploader.create({
          url: '/api/v3/receipts'
        });

        uploader.upload(receiptFile).then(function (receiptJsonString) {

          var receiptJson = JSON.parse(receiptJsonString).receipts,
              store = _this3.get('container').lookup('store:main'),
              // TODO ...
          receipt = store.find('receipt', receiptJson.id);

          receipt.then(function (resolvedReceipt) {
            deferred.resolve(resolvedReceipt);
          });
        }, function (error) {
          _ember['default'].Logger.error('Receipt upload crapped out', error);
          // image.rollback();
          deferred.reject();
        });
      } else {
        deferred.resolve();
      }

      return deferred.promise;
    },

    _saveTransaction: function _saveTransaction(force) {
      var _this4 = this;

      // Only save when dirty.
      if (!this.get('transactionIsDirty') && !force) {
        this.set('isEditing', false);
        return;
      }

      // Update initial transaction JSON
      // (removes rollback UI instantly).
      this._saveInitialTransactionJson();

      // We'll reset `routeNeedsRefresh` right away
      // to avoid overwriting any consecutive calls
      // when the save promise resolves.
      var routeNeedsRefresh = this.get('routeNeedsRefresh');
      this.set('routeNeedsRefresh', false);

      var transactionWasRestored = this.get('transaction.restore');

      /**
       * If the transaction is splitted, or if it is the child of another Transaction, the backend expects the transactions as a batch so that they can be validated.
       */
      if (this.get('transaction.childTransactions.length') > 0 || this.get('transaction.parentTransaction') !== null) {
        var transactionsToSave = [];

        transactionsToSave.pushObject(this.get('transaction'));

        // Make sure that for all transactions their parents are loaded.
        var parentTransaction;
        transactionsToSave.forEach(function (item, index, enumerable) {
          parentTransaction = item.get('parentTransaction');
          if (parentTransaction !== null) {
            if (transactionsToSave.findBy('id', parentTransaction.get('id')) === undefined) {
              transactionsToSave.pushObject(parentTransaction);
            }
          }
        });

        // Make sure that for all transactions their children are loaded.
        transactionsToSave.forEach(function (item, index, enumerable) {
          if (item.get('childTransactions.length') > 0) {
            item.get('childTransactions').forEach(function (child, childIndex, childEnumerable) {
              if (child.get('id') === null || transactionsToSave.findBy('id', child.get('id')) === undefined) {
                transactionsToSave.pushObject(child);
              }
            });
          }
        });

        var transactionJSON = [],
            itemJSON;
        transactionsToSave.forEach(function (item, index, enumerable) {
          itemJSON = item.toJSON({ includeId: true });

          // Ember model toJSON for some reason does not include hasMany relations with an inverse. Therefore we manually convert childTransactions.
          if (item.get('childTransactions.length') > 0) {
            var childArray = [];
            item.get('childTransactions').forEach(function (item, index, enumerable) {
              childArray.push(item.get('id'));
            });
            itemJSON['childTransactions'] = childArray;
          }

          if (item.get('labels.length') > 0) {
            var labelArray = [];
            item.get('labels').forEach(function (item, index, enumerable) {
              labelArray.push(item.get('id'));
            });
            itemJSON['labels'] = labelArray;
          }

          transactionJSON.pushObject(itemJSON);
        });
        transactionJSON = {
          'transactions': transactionJSON
        };
        transactionJSON = JSON.stringify(transactionJSON);

        _ember['default'].$.ajax({
          url: '/api/v3/transactions',
          type: 'post',
          dataType: 'json',
          data: transactionJSON,
          success: (function (response) {
            if (this._state === 'destroying') {
              this.get('container').lookup('route:income-expenses').send('refreshRoute');
            } else {
              this.sendAction('refreshRoute');
            }
          }).bind(this),
          error: function error(response) {
            var message = response.statusText;
            _this4.set('error', message);
            throw new Error('Something went wrong while saving the transaction.', response.errorThrown);
            // throw new Error('transaction-details/component.js: Something went wrong while saving the transactions.');
            // throw new Error(`Something went wrong while saving the transactions. (${message})`, 'transaction-details/component.js');
          }

        });
      } else {

          // TODO: validate
          this.get('transaction').save().then((function () {
            if (routeNeedsRefresh) {

              // Refresh the route so all incomeExpenses
              // views reflect the current data.

              // This component could be destroyed at
              // this point, if so, we'll lookup the
              // targeted route in the container.
              if (this._state === 'destroying') {
                this.get('container').lookup('route:income-expenses').send('refreshRoute');
              } else {
                this.sendAction('refreshRoute');
              }
            }
          }).bind(this))['catch'](function (response) {
            var message = response.statusText;
            _this4.set('error', message);
            throw new Error('Something went wrong while saving the transaction.', response.errorThrown);
            // throw new Error('transaction-details/component.js: Something went wrong while saving the transaction.');
          });
        }
      this.set('receiptFile', null);
      this.set('receiptPreviewUrl', null);
      this.set('deletedExistingChildTransactions', []);
    },

    /**
      Before swapping the transaction model,
      we’ll save any changes to the
      current record.
    */
    savePreviousTransaction: (function () {

      this.set('error', null);
      if (this.get('isEditing')) {
        this._unObserveAllTransactionProperties();

        // Turned of in favor of expicit save action
        if (this.get('transactionIsDirty')) {
          this._rollbackTransaction();
        } else if (this._state !== 'destroying') {
          this.set('isEditing', false);
          this.set('receiptPreviewUrl', null);
          this.send('cancelEditDetail');
        }
      }
      // this.send('saveTransaction');
    }).observesBefore('transaction'),

    /**
      When the application’s sidebar is toggled we need
      to re-position the edit container.
       Since it uses the container’s initial offset for
      calculating it’s new position, we need to await
      the sidebar’s animation to finish.
    */
    sidebarObserver: (function () {
      _ember['default'].run.later(this, this.defineContainerWidthAndPadding, 450);
    }).observes('hasSidebar'),

    /**
      Every time a new transaction is set
      we save its initial transaction record
      in stringefied JSON format.
      By diffing on every change we know
      exacly when the record is dirty,
      taking relations, reverted states
      and null to empty string into account.
    */
    _saveInitialTransactionJson: function _saveInitialTransactionJson() {

      var transaction = this.get('transaction');
      if (!transaction) {
        return;
      }

      var json = transaction.toJSON({ includeId: true });

      // Ember model toJSON for some reason does not include hasMany relations with an inverse. Therefore we manually convert childTransactions.
      if (transaction.get('childTransactions.length') > 0) {
        var childArray = [];
        transaction.get('childTransactions').forEach(function (item, index, enumerable) {
          childArray.push(item.toJSON({ includeId: true }));
        });
        json['childTransactions'] = childArray;
      }

      var jsonString = JSON.stringify(json);

      this.set('initialJsonString', jsonString);
      this.set('transactionIsDirty', false);
    },

    /**
      Every time a new transaction is set we add
      an observer to all its keys. Every time any of
      the properties is modified, this observer
      triggers our `_areWeDirty` method.
    */
    _observeAllTransactionProperties: function _observeAllTransactionProperties() {

      var transaction = this.get('transaction');
      if (!transaction) {
        return;
      }

      var _this = this;
      _ember['default'].keys(transaction.get('data')).forEach(function (key) {
        if (_ember['default'].typeOf(transaction.get(key)) !== 'function') {
          _this.addObserver('transaction.' + key, function () {
            _this._defineTransactionDirtyness();
          });
        }
      });
    },

    _unObserveAllTransactionProperties: function _unObserveAllTransactionProperties() {

      var transaction = this.get('transaction');
      if (!transaction) {
        return;
      }

      var _this = this;
      _ember['default'].keys(transaction.get('data')).forEach(function (key) {
        if (_ember['default'].typeOf(transaction.get(key)) !== 'function') {
          _this.removeObserver('transaction.' + key, function () {
            _this._defineTransactionDirtyness();
          });
        }
      });
    },

    _observeReceiptFile: _ember['default'].observer('receiptFile', function () {
      this._defineTransactionDirtyness();
    }),

    /**
      Manually mark a transaction dirty when any
      of it's properties or relationship changed.
       This will be done by ED when it ships.
      https://github.com/emberjs/data/issues/2545
    */
    _defineTransactionDirtyness: function _defineTransactionDirtyness() {

      var transaction = this.get('transaction');
      if (!transaction) {
        return;
      }

      // Make sure the transactions dirty state
      // is not set when not desirable, by setting
      // empty string values back to `null`.
      if (!transaction.get('comment')) {
        transaction.set('comment', null);
      }
      if (!transaction.get('userDate')) {
        transaction.set('userDate', null);
      }

      var currentJson = transaction.toJSON({ includeId: true });

      // Ember model toJSON for some reason does not include hasMany relations with an inverse. Therefore we manually convert childTransactions.
      if (transaction.get('childTransactions.length') > 0) {
        var childArray = [];
        transaction.get('childTransactions').forEach(function (item, index, enumerable) {
          childArray.push(item.toJSON({ includeId: true }));
        });
        currentJson['childTransactions'] = childArray;
      }

      var currentJsonString = JSON.stringify(currentJson),
          initialJsonString = this.get('initialJsonString');

      var hasReceiptFile = this.get('receiptFile');

      if (currentJsonString !== initialJsonString || hasReceiptFile) {
        this.set('transactionIsDirty', true);
        this._defineNeedForRouteRefresh();
      } else {
        this.set('transactionIsDirty', false);
      }
    },

    /**
      This method determines if the income.expenses
      route nbeeds to be refreshed.
       The following property changes qualify for routeRefresh:
      TODO: implement all properties!
      - subcategory
      - userDate
      - disabled
      - split
       It is triggered from `_defineTransactionDirtyness`.
    */
    _defineNeedForRouteRefresh: function _defineNeedForRouteRefresh() {
      var transaction = this.get('transaction'),
          currentJson = transaction.toJSON({ includeId: true });

      // Ember model toJSON for some reason does not include hasMany relations with an inverse. Therefore we manually convert childTransactions.
      if (transaction.get('childTransactions.length') > 0) {
        var childArray = [];
        transaction.get('childTransactions').forEach(function (item, index, enumerable) {
          childArray.push(item.toJSON({ includeId: true }));
        });
        currentJson['childTransactions'] = childArray;
      }

      var initialJsonString = this.get('initialJsonString'),
          initialJson = JSON.parse(initialJsonString);

      var routeNeedsRefresh = false;
      if (currentJson.subcategory !== initialJson.subcategory) {
        // TODO Check if the change moved the transaction
        // out of the current category selection (smoother)
        routeNeedsRefresh = true;
      }
      if (currentJson.userDate !== initialJson.userDate) {
        // TODO Check if the change moved the transaction
        // out of the current periodSelection (smoother)
        routeNeedsRefresh = true;
      }
      if (currentJson.enabled !== initialJson.enabled) {
        routeNeedsRefresh = true;
      }

      if (currentJson.labels !== initialJson.labels) {
        routeNeedsRefresh = true;
      }

      if (this.get('deletedExistingChildTransactions.length') > 0) {
        routeNeedsRefresh = true;
      }

      // Set or unset `routeNeedsRefresh`
      this.set('routeNeedsRefresh', routeNeedsRefresh);
    },

    /**
      We want the `TransactionDetails` container to stick
      (`position: fixed;`) to the top whenever it is scrolled
      beyond the page’s top.
       The `scroll` event is triggered from the
      `scroll-top` mixin.
    */
    defineContainerPosition: (function () {

      var windowScrollTop = _ember['default'].$('#app > main').scrollTop(),
          topBarHeight = _ember['default'].$('main > header').height(),
          relatedTransactionsTop = this.get('relatedTransactionsTop'),
          relatedTransactionEditTop = -2;

      var breakpoint = windowScrollTop + topBarHeight,
          top = relatedTransactionsTop + relatedTransactionEditTop;

      this.set('isFixed', top < breakpoint);

      // Set the fixed top position
      // of the edit component.
      var rtTopBarHeight = _ember['default'].$('.RelatedTransactions-topbar').outerHeight();
      if (top < breakpoint) {
        this.$().css({ top: topBarHeight + rtTopBarHeight + 1 });
      } else {
        this.$().css({ top: rtTopBarHeight + 1 });
      }
    }).on('scroll'),

    mouseDownResponder: function mouseDownResponder(e) {

      // Hide select when the event-target was outside the component
      var notWithinRelatedTransactionsList = !_ember['default'].$(e.target).parents('.RelatedTransactions-column').length,
          notWithinRelatedTransactionEdit = !_ember['default'].$(e.target).parents('.RelatedTransactionEdit-container').length,
          notWithinDialog = !_ember['default'].$(e.target).parents('.Dialog').length,
          notOnHtml = !/^html$/i.test(e.target.tagName),
          notOnDestroyedElement = !!_ember['default'].$(e.target).parent().length,
          notOnMainPageScrollBar = _ember['default'].$(e.target).attr('id') !== 'income-expenses';

      if (notWithinRelatedTransactionsList && notWithinRelatedTransactionEdit && notWithinDialog && notOnHtml && notOnDestroyedElement && notOnMainPageScrollBar) {
        this.send('closeTransactionEdit');
      }

      // Hide datepicker when the event-target
      // was outside datpicker-component and
      // the datepicker is active
      var notWithinDatepicker = !_ember['default'].$(e.target).parents('.RelatedTransactionEdit-dateEdit ').length;

      if (notWithinDatepicker) {
        if (this.get('isEditingTransactionDate')) {
          this.set('isEditingTransactionDate', false);
        }
      }
    },

    _rollbackTransaction: function _rollbackTransaction() {
      var transaction = this.get('transaction'),
          initialRelations = this.get('initialRelations') || [];

      // Rollback relations
      initialRelations.forEach(function (relation) {
        var key = _ember['default'].keys(relation)[0];
        transaction.set(key, relation[key]);
      });

      this._rollbackChildTransactions();

      // Resetting the labels
      var currentLabels = [],
          initialLabels = this.get('initialLabels');
      transaction.get('labels').forEach(function (item, index, enumerable) {
        currentLabels.pushObject(item);
      });
      transaction.get('labels').removeObjects(currentLabels).addObjects(initialLabels);

      // Rollback properties
      transaction.rollback();

      if (this._state !== 'destroying') {
        // Reset receipt preview
        this.set('receiptFile', null);
        this.set('receiptPreviewUrl', null);

        this.set('splitsChoosingSubcategory', false);

        this.set('isEditing', false);
        this.set('transactionIsDirty', false);
        this.send('cancelEditDetail');
      }
    },

    _rollbackChildTransactions: function _rollbackChildTransactions() {
      var transaction = this.get('transaction');
      // Remove any newly created split transactions that have not been saved yet.
      transaction.get('childTransactions').filterBy('id', null).invoke('destroyRecord');

      var deletedExistingChildTransactions = this.get('deletedExistingChildTransactions') || [];

      // Restore any removed existing split transactions that have been saved before.
      deletedExistingChildTransactions.forEach(function (item, index, enumerable) {
        item.set('parentTransaction', transaction);
        item.set('noShow', false);
      });
      this.set('deletedExistingChildTransactions', []);
      transaction.get('childTransactions').forEach(function (item, index, enumerable) {
        item.rollback();
      });

      this.get('initialChildRelations').forEach(function (item, index, enumerable) {
        var childTransaction = transaction.get('childTransactions').findBy('id', item.id);

        if (childTransaction !== undefined) {
          childTransaction.set('subcategory', item.subcategory);

          var childCurrentLabels = [];
          childTransaction.get('labels').forEach(function (label, index, enumerable) {
            childCurrentLabels.pushObject(label);
          });
          childCurrentLabels.forEach(function (label, index, enumerable) {
            childTransaction.get('labels').removeObject(label);
          });
          item.labels.forEach(function (label, index, enumerable) {
            childTransaction.get('labels').addObject(label);
          });
        }
      });
    },

    /**
      Before the component is destroyed, we’ll check
      if it has unsaved changes, and save them if needed.
    */
    willDestroy: function willDestroy() {
      if (this.get('isEditing') && this.get('transactionIsDirty')) {
        this._rollbackTransaction();
      }
    },

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {

      startEditing: function startEditing() {
        if (this.get('isEditing')) {
          return;
        }

        this._saveInitialTransactionJson();
        this._observeAllTransactionProperties();

        var transaction = this.get('transaction');
        this.set('initialRelations', [{ subcategory: transaction.get('subcategory') }, { receipt: transaction.get('receipt') }, { parentTransaction: transaction.get('parentTransaction') }]);

        var initialLabels = [];
        transaction.get('labels').forEach(function (item, index, self) {
          initialLabels.pushObject(item);
        });
        this.set('initialLabels', initialLabels);

        var initialChildRelations = [];
        this.get('transaction.childTransactions').forEach(function (item, index, enumerable) {
          var initialChildLabels = [];
          item.get('labels').forEach(function (item, index, self) {
            initialChildLabels.pushObject(item);
          });
          initialChildRelations.pushObject({
            id: item.get('id'),
            subcategory: item.get('subcategory'),
            labels: initialChildLabels
          });
        });
        this.set('initialChildRelations', initialChildRelations);

        this.set('isEditing', true);
        this.set('deletedExistingChildTransactions', []);
        this.set('splitsChoosingSubcategory', false);
      },

      enableAndStartEditing: function enableAndStartEditing() {
        this.send('startEditing');
        this.set('transaction.enabled', true);
      },

      saveEdits: function saveEdits() {
        var _this5 = this;

        if (this.get('splitsAreValid')) {
          this._unObserveAllTransactionProperties();

          this._uploadAndFindReceipt().then(function (receipt) {
            if (receipt) {
              _this5.set('transaction.receipt', receipt);
            }
            _this5._saveTransaction();
            _this5.set('isEditing', false);
            _this5.send('cancelEditDetail');
          });
        } else {
          this.set('validationError', 'Het bedrag van de gesplitste transacties komt niet overeen met het geheel!');
        }
      },

      cancelEditing: function cancelEditing() {
        this._unObserveAllTransactionProperties();
        this._rollbackTransaction();
      },

      toEditDetail: function toEditDetail(detail) {
        this.set('isEditDetail', true);
        this.set('editDetail', 'transaction-details/' + detail);
      },

      cancelEditDetail: function cancelEditDetail() {
        if (!this.get('splitsChoosingSubcategory')) {
          if (this.get('isEditDetail') && this.get('editDetail')) {
            if (/splits-pane$/.test(this.get('editDetail'))) {
              this._rollbackChildTransactions();
              this._defineTransactionDirtyness();
            } else {}
          }

          this.set('isEditDetail', false);
          // Ember.run.debounce(this, function() {
          //   this.set('editDetail', null);
          // }, 2000);
        } else {
            this.set('splitsChoosingSubcategory', false);
          }
      },

      acceptEditDetail: function acceptEditDetail() {
        this.set('isEditDetail', false);
        this._defineTransactionDirtyness();
      },

      // Animate-in via VelocityJS
      showTransactionEdit: function showTransactionEdit() {

        // Set initial translateX value
        _ember['default'].$.Velocity(this.element, {
          translateX: '150%'
        }, 0);

        _ember['default'].run.later(this, function () {
          this.set('isVisible', true);
        }, 50);

        // Animate the edit-container itself.
        _ember['default'].$.Velocity(this.element, {
          translateX: '0%'
        }, [150, 20]).then((function () {
          // In order to use fixed positioning inside the
          // edit-box (rollback notice), we have to remove
          // the css transform.
          this.$().css({
            '-webkit-transform': '',
            '-mos-transform': '',
            '-ms-transform': '',
            transform: ''
          });
        }).bind(this));
      },

      // Animate-out via VelocityJS
      closeTransactionEdit: function closeTransactionEdit() {

        // Trigger the close animation of
        // related transaction list.
        this.sendAction('close');

        if (!this.element) {
          this.set('transaction', null);
        } else {

          // Animate the edit-container itself.
          _ember['default'].$.Velocity(this.element, {
            translateX: '150%'
          }, [50, 20]).then((function () {
            // Unset the `transaction` (`editingTransaction`)
            // When user just triggered deleteTransaction
            // it will not exist anymore.
            if (this.get('transaction')) {
              this.set('transaction', null);
            }
          }).bind(this));
        }

        // Unset event listeners
        _ember['default'].$(document).off('mousedown touchend', this.mdr);
      },

      /**-------------------------
        CRUD Actions
      ---------------------------*/

      deleteTransaction: function deleteTransaction() {

        var childTransactions = this.get('transaction.childTransactions');

        if (childTransactions.length) {
          childTransactions.forEach(function (child) {
            child.destroyRecord();
          });
        }

        this.get('transaction').destroyRecord().then((function (data) {
          this.sendAction('refreshRoute');
          this.set('routeNeedsRefresh', false);
          this.send('closeTransactionEdit');
        }).bind(this))['catch'](function (error) {
          _ember['default'].Logger.error(error);
          return true;
        });
      },

      confirmDeleteTransaction: function confirmDeleteTransaction() {
        var transactionNamePart = this.get('descriptionProxy').substr(0, 15) + '...';
        var template = 'application/dialog',
            controller = this,
            model = {
          header: 'Transactie verwijderen?',
          content: 'Weet je zeker dat je de transactie “' + transactionNamePart + '” wilt verwijderen? Je kunt dit niet ongedaan maken.',
          buttons: [{ title: 'Verwijder', action: 'deleteTransaction', close: true }, { title: 'Annuleer', action: '', close: true, primary: true }]
        };

        // Render the bwa-success template in the modal-route
        _ember['default'].run.next(this, function () {
          this.sendAction('toggleModal', template, controller, model);
        });
      },

      goToTransactionRoute: function goToTransactionRoute() {
        // Temp until spitting is added
        _ember['default'].instrument('transitionToEmberRoute', { route: 'transactions' });
      },

      goToReceiptsRoute: function goToReceiptsRoute() {
        // Temp until spitting is added
        _ember['default'].instrument('transitionToEmberRoute', { route: 'receipts' });
      },

      splitTransaction: function splitTransaction() {
        var template = 'application/dialog',
            controller = this,
            model = {
          header: 'Transactie Splitsen',
          content: 'Het splitsen van transacties moet je voorlopig nog op de transactie pagina doen.',
          buttons: [
          // { title: 'Annuleer', close: true, },
          { title: 'Open “Transacties”', action: 'goToTransactionRoute', close: true }]
        };

        // Render the bwa-success template in the modal-route
        _ember['default'].run.next(this, function () {
          this.sendAction('toggleModal', template, controller, model);
        });
      },

      addReceiptToTransaction: function addReceiptToTransaction() {
        var template = 'application/dialog',
            controller = this,
            model = {
          header: 'Bonnetjes Toevoegen',
          content: 'Het toevoegen van een bonnetje aan een transactie, moet je voorlopig nog op de ‘bonnetjes’ pagina doen.',
          buttons: [
          // { title: 'Annuleer', close: true, },
          { title: 'Open Bonnetjes', action: 'goToReceiptsRoute', close: true }]
        };

        // Render the bwa-success template in the modal-route
        _ember['default'].run.next(this, function () {
          this.sendAction('toggleModal', template, controller, model);
        });
      },

      restoreTransaction: function restoreTransaction() {
        this._rollbackTransaction();
        this.get('transaction').set('restore', true);
        this.get('transaction').save().then((function (transaction) {

          // HACK Restore ought to be reset just by receiving
          // its response, but somehow it doesn’t. So for now..
          transaction.set('restore', false);

          transaction.get('relatedTransactions').reload();
          this.set('transactionIsDirty', false);
          this.set('isEditing', false);
          if (this._state === 'destroying') {
            this.get('container').lookup('route:income-expenses').send('refreshRoute');
          } else {
            this.sendAction('refreshRoute');
          }
        }).bind(this));
      },

      disableTransaction: function disableTransaction(disabled) {
        this._rollbackTransaction();
        this.get('transaction').set('enabled', !disabled);
        this._saveInitialTransactionJson();

        this.get('transaction').save().then((function (transaction) {

          this.set('transactionIsDirty', false);
          this.set('isEditing', false);
          // Refresh the route’s models so the charts and numbers refrect the new state.
          if (this._state === 'destroying') {
            this.get('container').lookup('route:income-expenses').send('refreshRoute');
          } else {
            this.sendAction('refreshRoute');
          }
        }).bind(this));
      },

      resetTransactionDescription: function resetTransactionDescription() {
        this.set('descriptionProxy', this.get('descriptionOriginal'));
      },

      showAttachment: function showAttachment() {
        // console.log('show tab attachment');
      },

      addSubcategory: function addSubcategory(newSubcategoryName, category) {
        this.sendAction('addSubcategory', newSubcategoryName, category);
      },

      dismissError: function dismissError() {
        window.location.reload(true);
      }
    },

    /**-------------------------
      Events
    ---------------------------*/

    doubleClick: function doubleClick() {
      this.send('startEditing');
    },

    keyboardShortcuts: {
      'return': 'startEditing',
      'esc': 'cancelEditing',

      'ctrl+s': function ctrlS() {

        this.send('saveEdits');

        // return false to prevent default browser behavior
        // and stop event from bubbling
        return false;
      },
      'command+s': function commandS() {

        this.send('saveEdits');

        // return false to prevent default browser behavior
        // and stop event from bubbling
        return false;
      }
    }
  });
});