define('front-end-cli/pods/nibud/edit/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        loading: false,
        responseIsError: false,
        responseErrorId: null,
        data: {},
        categories: [],
        subCategoriesToMany: [],
        nibudSubcategoriesToMany: [],
        oneToOnes: [],
        unlinkedSubcategories: [],
        unlinkedNibudSubcategories: [],
        actions: {
            fetchCategories: function fetchCategories() {
                this._clearError();
                var _this = this;

                _this.set('loading', true);

                _ember['default'].$.ajax({
                    type: 'POST',
                    url: 'api/v3/nibud?procedure=GetNibudCategoryEditDetails',
                    data: {
                        'categoryId': null
                    }
                }).success(function (data) {
                    _this.set('loading', false);
                    _this.setProperties({
                        categories: data.response.category.entries,
                        data: data.response
                    });
                    _this._extractDataFromResponse(data.response);
                }).error(function (error) {
                    _this._setError(error.responseJSON ? error.responseJSON['X-Correlation-Id'] : null);
                    _this.set('loading', false);
                });
            },
            fetchSubcategories: function fetchSubcategories(event) {
                this._clearError();
                var _this = this;

                var categoryId = document.getElementById('Nibud-CategorySelector').value;

                _this.set('loading', true);

                _ember['default'].$.ajax({
                    type: 'POST',
                    url: 'api/v3/nibud?procedure=GetNibudCategoryEditDetails',
                    data: {
                        'categoryId': categoryId
                    }
                }).success(function (data) {
                    _this.set('loading', false);
                    if (typeof data.response.xCorrelationId != 'undefined') {
                        _this._setError(data.response.xCorrelationId);
                    } else {
                        _this._extractDataFromResponse(data.response);
                    }
                }).error(function (error) {
                    _this._setError(error.responseJSON ? error.responseJSON['X-Correlation-Id'] : null);
                    _this.set('loading', false);
                });
            },
            updateCategory: function updateCategory(subCategoryId, nibudSubCategoryId) {
                var _this = this;

                var categoryId = document.getElementById('Nibud-CategorySelector').value;

                this.set('loading', true);
                this._clearError();

                _ember['default'].$.ajax({
                    type: 'POST',
                    url: 'api/v3/nibud?procedure=UpdateNibudCategoryBy',
                    data: {
                        'selectedCategoryId': categoryId,
                        'subCategoryId': subCategoryId,
                        'nibudSubCategoryId': nibudSubCategoryId
                    }
                }).success(function (data) {
                    _this.set('loading', false);
                    if (typeof data.response.stackTrace != 'undefined') {
                        _this._setError(data.response['X-Correlation-Id']);
                    } else {
                        _this._extractDataFromResponse(data.response);
                    }
                }).error(function (error) {
                    _this._setError(error.responseJSON ? error.responseJSON['X-Correlation-Id'] : null);
                    _this.set('loading', false);
                });
            }
        },
        _extractDataFromResponse: function _extractDataFromResponse(response) {
            var oneToMany = [],
                oneToOne = [],
                oneToNone = [],
                nibudOneToMany = [],
                nibudOneToNone = [];
            if (response.subCategoriesTable.subCategories.length) {
                oneToMany = response.subCategoriesTable.subCategories.filter(function (item, index) {
                    return item.linkedItems.length > 1;
                });
                oneToOne = response.subCategoriesTable.subCategories.filter(function (item, index) {
                    return item.linkedItems.length == 1;
                });
                oneToNone = response.subCategoriesTable.subCategories.filter(function (item, index) {
                    return item.linkedItems.length == 0;
                });
            }
            if (response.subCategoriesTable.nibudSubCategories.length) {
                nibudOneToMany = response.subCategoriesTable.nibudSubCategories.filter(function (item, index) {
                    return item.linkedItems.length > 1;
                });
                nibudOneToNone = response.subCategoriesTable.nibudSubCategories.filter(function (item, index) {
                    return item.linkedItems.length == 0;
                });
            }
            this.setProperties({
                subCategoriesToMany: oneToMany,
                nibudSubcategoriesToMany: nibudOneToMany,
                oneToOnes: oneToOne,
                unlinkedSubcategories: oneToNone,
                unlinkedNibudSubcategories: nibudOneToNone
            });
        },
        _setError: function _setError(errorId) {
            this.set('responseIsError', true);
            this.set('responseErrorId', errorId);
        },
        _clearError: function _clearError() {
            this.set('responseIsError', false);
            this.set('responseErrorId', null);
        }
    });
});