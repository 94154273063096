define('front-end-cli/views/profiler/toggle-input', ['exports', 'ember', 'front-end-cli/mixins/profiler/changed-state'], function (exports, _ember, _frontEndCliMixinsProfilerChangedState) {
  exports['default'] = _ember['default'].View.extend(_frontEndCliMixinsProfilerChangedState['default'], {
    tagName: 'div',
    classNames: ['toggle'],
    templateName: 'profiler/mixin/toggle',

    didInsertElement: function didInsertElement() {

      // ADD FOCUS, ARROW, SPACE EVENTSLISTENERS
      var catcher = this.$('.focus-catcher'),
          toggle = this.$();

      catcher.on('focus', function () {
        toggle.addClass('focus');
      }).on('blur', function () {
        toggle.removeClass('focus');
      }).on('keydown', function (e) {

        // SPACE, ARROWS, RETURN
        if (e.which.toString().match(/32|37|38|39|40|13/)) {
          // TRIGGER TOGGLE
          toggle.mousedown();
          // MAKE SURE UP,DOWN,SPACE DONT TRIGGER SCROLLING
          e.preventDefault();
        }
      });
    },

    willDestroyElement: function willDestroyElement() {
      this.$('.focus-catcher').remove(); // clear Ember.$.cache, not sure if needed
    },

    ToggleItemView: _ember['default'].View.extend({

      tagName: 'span',
      classNameBindings: ['isSelected::dim'],

      isSelected: (function () {
        return this.get('value') == this.get('parentView.value');
      }).property('parentView.value'),

      init: function init() {

        //Setting default option and value paths
        if (typeof this.get('parentView.optionLabelPath') == 'undefined') {
          this.set('parentView.optionLabelPath', 'content.label');
        }
        if (typeof this.get('parentView.optionValuePath') == 'undefined') {
          this.set('parentView.optionValuePath', 'content.id');
        }

        this.labelPathDidChange();
        this.valuePathDidChange();

        this._super();
      },
      labelPathDidChange: _ember['default'].observer(function () {
        var labelPath = _ember['default'].get(this, 'parentView.optionLabelPath');

        if (!labelPath) {
          return;
        }

        _ember['default'].defineProperty(this, 'label', _ember['default'].computed(function () {
          return _ember['default'].get(this, labelPath);
        }).property(labelPath));
      }, 'parentView.optionLabelPath'),

      valuePathDidChange: _ember['default'].observer(function () {
        var valuePath = _ember['default'].get(this, 'parentView.optionValuePath');

        if (!valuePath) {
          return;
        }

        _ember['default'].defineProperty(this, 'value', _ember['default'].computed(function () {
          return _ember['default'].get(this, valuePath);
        }).property(valuePath));
      }, 'parentView.optionValuePath'),

      mouseDown: function mouseDown() {

        // FOCUS THE 'CATCHER'
        this.$().parent().find('.focus-catcher').focus();

        // SET THIS VALUE
        this.set('parentView.value', this.get('value'));

        this.get('parentView').send('updateChangeStatus');

        // STOP BUBBLING
        return false;
      }
    }),

    mouseDown: function mouseDown() {

      var content = this.get('content'),
          currentID = this.get('value') * 1 || 0,
          valuePath = this.get('optionValuePath').replace(/content./, '');

      // BACK TO START
      if (currentID + 1 > content.get('length')) currentID = 1;else currentID++;

      // GET NEW VALUE
      var index = currentID - 1,
          newValue = content.objectAt(index).get(valuePath);

      // FOCUS THE 'CATCHER'
      this.$('.focus-catcher').focus();

      // SET NEW VALUE
      this.set('value', newValue);

      this.send('updateChangeStatus');

      // STOP BUBBLING
      return false;
    },
    onBlur: (function () {

      //PING TO KEEP SESSION ALIVE
      this.get('controller').send('ping');
    }).on('focusOut'),

    actions: {
      updateChangeStatus: function updateChangeStatus() {
        // Component
        if (this.get('targetObject')) {
          this.get('targetObject').send('setChangeStatus');
        }
        // View
        else {
            this.get('controller').send('setChangeStatus');
          }
      }
    }
  });
});