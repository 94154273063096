define('front-end-cli/pods/promo/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actieCode: null,
    statusMessage: null,
    emptyPromoCode: null,
    params: false,

    emptyPromoCodeTitle: (function () {
      return !this.get('actieCode');
    }).property('actieCode'),

    actions: {
      clearStatusMessage: function clearStatusMessage() {
        this.set('statusMessage', null);
      },

      saveActionCode: function saveActionCode() {
        _ember['default'].$.post('app/user.promocode', { 'promo_code': this.get('actieCode') }, (function (data) {

          if (data.error.message) {
            this.set('statusMessage', data.error.message);
          } else {
            if (this.get('currentUser.model.state.plus')) {
              this.set('statusMessage', 'Promotie verzilverd! Plus Abonnement verlengd t/m ' + data.data.end);
            } else {
              this.set('currentUser.model.state.plus', true);
              this.set('statusMessage', 'Promotie verzilverd! Plus geactiveerd t/m ' + data.data.end);
            }
          }
        }).bind(this));
      }
    },

    buttonText: (function () {
      return this.get('statusMessage') || 'Inwisselen';
    }).property('statusMessage'),

    isPromoStatus: (function (params) {
      return params;
    }).property()
  });
});