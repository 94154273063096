define('front-end-cli/pods/nibud/edit/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            this.set('current', transition.targetName);
        },

        setupController: function setupController(controller, model) {
            controller.send('fetchCategories');
        }
    });
});