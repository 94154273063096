define('front-end-cli/views/accountmanagement/edit/auto', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({

    setGuideHeight: (function () {
      var credentialHeight = this.$('.auto-credentials-form').height();
      this.$('.guide').css({
        minHeight: credentialHeight
      });
    }).on('didInsertElement')
  });
});