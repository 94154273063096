define('front-end-cli/router', ['exports', 'ember', 'front-end-cli/config/environment'], function (exports, _ember, _frontEndCliConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _frontEndCliConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    // CREATE A ROUTE FOR EVERY PATHNAME
    this.route('legacy', { path: window.location.pathname });

    /**
    Native Ember routes
    */
    // this.route('index', { path: '/' });// needed to override the above legacy rule

    // account routes
    this.resource('account', { path: '/account' }, function () {

      // accept url's with and without person_id
      this.route('profile', { path: '/profiel' });
      // this.route('profile', { path: '/profiel/partner/' });
      // this.route('profile', { path: '/profiel/:person_id' });
    });

    this.route('timeline', { path: '/' });
    this.route('trends', { path: '/trends' });

    this.route('income-expenses', { path: '/categorieen' }, function () {
      this.route('income', { path: '/inkomsten' });
      this.route('expenses', { path: '/uitgaven' });
    });

    // this.resource('whatif', { path: '/wat-als' }, function() {
    //   this.route('retirement', { path: '/pensioen' });
    // });

    this.resource('accountmanagement', { path: '/rekeningen' }, function () {
      this.route('setup', { path: '/toevoegen' });
      this.route('setup-success', { path: '/toevoegen/succes' });
      this.route('update', { path: '/bijwerken/:account_id' });
      this.route('cashbook', { path: '/toevoegen-kasboek' });
      this.route('lock-accounts', { path: '/kiezen' });
    });

    this.route('budget', { path: '/budgetten' }, function () {
      this.route('yeartotals', { path: '/jaar-totalen' });
      this.route('matrix', { path: '/instellen' });
    });

    /*
    this.resource('nibud', { path: '/nibud'}, function() {
      this.route('edit', { path: '/edit'});
    });
    */

    /**
     * PSD2
     */
    this.resource('automatic-import', { path: '/psd2' }, function () {
      this.route('update-account', { path: '/importeren/:account_id' });
      this.route('update-account-wizard', { path: '/accounts-importeren' });
      this.route('consent-expired', { path: '/consent-verlopen' });
      this.route('psd2-information', { path: '/psd2-informatie' });
      this.route('new-user-setup', { path: '/nieuwe-gebruiker' });
      this.route('edit-consent', { path: '/toegang-verlopen/:account_id' });
      this.route('update-all-accounts', { path: '/alle-rekeningen' });
      this.route('has-feature-access', { path: '/psd2-geactiveerd' });
    });

    this.route('promo');

    this.route('report', { path: '/rapport-maken' });

    /**
    Legacy routes
    */
    this.resource('legacy', { path: '/app' }, function () {

      /**
      Regular legacy page routes
      */
      this.resource('overview', { path: '/overzicht' }, function () {
        // this.route('index', { path: '/index' });
        this.route('catch', { path: '*:' });
      });

      this.resource('transactions', { path: '/transacties' }, function () {
        this.route('related', { path: '/soortgelijk/:transaction_id' });
        this.route('catch', { path: '*:' });
      });

      this.resource('contracts', { path: '/contracten' }, function () {
        this.route('catch', { path: '*:' });
      });
      this.resource('receipts', { path: '/bonnetjes' }, function () {
        this.route('catch', { path: '*:' });
      });
      this.resource('paycheck', { path: '/salariswijzer' }, function () {
        this.route('catch', { path: '*:' });
      });
      this.resource('paycheck', { path: '/loonstroken' }, function () {
        this.route('catch', { path: '*:' });
      });
      this.resource('download', { path: '/download' }, function () {
        this.route('catch', { path: '*:' });
      });

      /**
      Legacy account routes
      */
      this.resource('settings', { path: '/instellingen' }, function () {
        this.route('balance', { path: '/saldo' });
        this.route('plus', { path: '/plus' });
        this.route('categories', { path: '/categorieen' });
        this.route('labels', { path: '/labels' });
        this.route('importrules', { path: '/importeerregels' });
        this.route('export', { path: '/exporteren' });
        this.route('catch', { path: '*:' });
      });

      /**
      Legacy control routes
      */
      this.resource('accounts', { path: '/rekeningen' }, function () {
        this.route('update', { path: '/bijwerken' });
        this.route('catch', { path: '*:' });
      });

      this.resource('plus', { path: '/plus' }, function () {
        this.route('catch', { path: '*:' });
      });

      /**
      Standalone routes
      */
      this.route('login', { path: '/inloggen' });

      this.resource('register', { path: '/aanmelden' }, function () {
        this.route('catch', { path: '*:' });
      });

      this.resource('support', { path: '/support' }, function () {
        this.route('catch', { path: '*:' });
      });

      this.resource('confirmregistration', { path: '/bevestig-aanmelding' }, function () {
        this.route('catch', { path: '*:' });
      });

      this.resource('confirmaccountsharing', { path: '/bevestig-delen' }, function () {
        this.route('catch', { path: '*:' });
      });

      this.resource('openid', { path: '/aanmelden' }, function () {
        this.route('catch', { path: '*:' });
      });

      this.resource('forgotpassword', { path: '/wachtwoord-vergeten' }, function () {
        this.route('catch', { path: '*:' });
      });

      this.resource('resetpassword', { path: '/herstel-wachtwoord' }, function () {
        this.route('catch', { path: '*:' });
      });

      this.resource('confirmemailchange', { path: '/bevestig-emailwijziging' }, function () {
        this.route('catch', { path: '*:' });
      });

      this.resource('accountdeleted', { path: '/account-verwijderd' }, function () {
        this.route('catch', { path: '*:' });
      });

      /**
      Misc routes
      */
      this.route('plugin', { path: '/bijwerk-assistent' });

      /**
      404
      */
      this.route('404', { path: '*:' });
    });

    this.route('error', { path: '/error/:error_id' });

    this.route('404', { path: '*:' });
  });

  exports['default'] = Router;
});