define('front-end-cli/controllers/accountmanagement/setup', ['exports', 'ember', 'front-end-cli/controllers/accountmanagement/edit/base'], function (exports, _ember, _frontEndCliControllersAccountmanagementEditBase) {
  exports['default'] = _frontEndCliControllersAccountmanagementEditBase['default'].extend({
    application: _ember['default'].inject.controller(),

    isSetupController: true,
    stepLength: 7,
    error: null,
    syncing: null,
    step: null,

    banks: null,

    /**
      When stepping back to step 1,
      reset the bankChoice.
    */
    resetBankChoice: (function () {
      if (this.get('step') == 1) this.set('bankChoice.name', null);
    }).observes('step'),

    actions: {

      uploadDone: function uploadDone(accounts) {
        /* Fix for when adding multiple files for uploading and the responses to these file upload responses are received in the wrong order:
         * check which accounts were already set to be updated, and then add the new ones to it.
         */
        var availableAccounts = this.get('bankChoice.availableAccounts');

        if (availableAccounts !== null) {
          availableAccounts.forEach(function (item, index, enumerable) {
            if (accounts.findBy('accountNumber', item.accountNumber) === undefined) {
              accounts.pushObject(item);
            }
          });
        }

        this.send('setAvailableAccounts', accounts);
        this.set('syncing', false);
      },

      createChosenAccounts: function createChosenAccounts() {

        var promises = [];
        if (this.get('syncing')) return;
        this.set('syncing', true);

        this.get('bankChoice.availableAccounts').filterBy('sync', true).filterBy('update', false).forEach(function (newAccountData, index) {

          var account = this.store.createRecord('account', {
            user: this.get('currentUser.model'),
            bankLogin: this.get('bankChoice.bankLogin'),
            accountNumber: newAccountData.get('accountNumber'),
            description: newAccountData.get('accountNumber'),
            /* These properties are overwritten when the save
              response comes back and are set to have an up to
              date view even before save call resolves.
            */
            balance: newAccountData.get('balance'),
            lastupdate: moment().format('YYYY-MM-DD')
          });

          this.incrementProperty('bankChoice.createdAccountsLength');

          var promise = account.save();
          promise.then(function (value) {
            /**
              Wait with success handling until all promises
              are resolved with Ember.RSVP.all
            */
            return true;
          }, function (reason) {
            /**
              Unload the account from the applicationStore
              when the POST action fails.
            */
            account.unloadRecord();
          });

          promises.push(promise);
        }, this);

        _ember['default'].RSVP.all(promises).then((function (accounts) {
          // Reset the import wizard after creating accounts. This is done in sync, because otherwise the (session-based) reset is overwritten.
          var defer = _ember['default'].RSVP.defer();
          this.send('resetImportWizard', defer);

          defer.promise.then((function () {
            /* End: Temp fix for Belgian IBAN mistake (Front-end, pt 3/3). Remove me at August 10, 2015 */
            // this.get('currentUser').send('reloadUser');
            /* End: Temp fix for Belgian IBAN mistake (Front-end, pt 3/3). Remove me at August 10, 2015 */
            this.set('syncing', false);

            /**
             When we've got pending balance-benchmarks,
             and new transactions, we'll go to step 6,
             else we'll go to step 7.
             */
            var requestBalance = false;

            accounts.forEach(function (a) {

              if (a.get('transcount') !== 0) {

                var accountId = a.get('accountNumber').toString(),
                    account = this.get('currentUser.model.accounts').findBy('accountNumber', accountId);

                _ember['default'].assert('The account (' + a.get('accountNumber') + ') was not found in the store', account);

                if (!account.get('isCashAccount') && account.get('requestBalanceBenchmark')) {
                  requestBalance = true;
                }
              }
            }, this);

            this.send('showStep', requestBalance ? 6 : 7);
          }).bind(this));
        }).bind(this), (function (array) {
          // deal with the failure here
          this.set('syncing', false);
          this.set('error', true); // default choose-accounts error
        }).bind(this));
      },

      showTour: function showTour() {
        this.get('controllers.application').send('showSidebar');
        this.set('isTourVisible', true);
      }
    }
  });
});