define('front-end-cli/pods/components/file-upload/component', ['exports', 'ember', 'ember-uploader'], function (exports, _ember, _emberUploader) {
  exports['default'] = _emberUploader['default'].FileField.extend({
    url: '',
    multiple: true,
    previewUrl: null,
    filesDidChange: (function () {

      var files = this.get('files');

      if (!_ember['default'].isEmpty(files)) {
        this.set('file', files[0]);

        // fileUrl allows for previewing the file
        var previewUrl = window.URL.createObjectURL(files[0]);
        this.set('previewUrl', previewUrl);
      }
    }).observes('files')
  });
});