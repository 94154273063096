define('front-end-cli/pods/components/timeline-today-chart/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'svg',
    classNames: ['TimelineToday-chart'],
    width: 270,
    height: 270,

    /**-------------------------
      External Bindings
    ---------------------------*/

    expected: null,
    realized: null,
    remaining: null,
    projectedSpend: null,
    trafficLight: null,
    trafficLightColor: null,

    /**-------------------------
      Methods
    ---------------------------*/

    renderChart: (function () {

      var id = this.$().attr('id');
      var width = this.get('width');
      var height = this.get('height');
      var radius = Math.min(width, height) / 2;

      // We get 2 values: expected, realized
      var expected = this.get('expected') || 1;
      var realized = this.get('realized') || 0;
      var remaining = this.get('remaining') || 1;
      var projectedSpend = this.get('projectedSpend') || 0;

      var startData = [0, projectedSpend, expected];

      var color = d3.scale.ordinal().range(['#397FC4', '#ccc', '#f00', '#0f0', '#00f', '#ff0', '#0ff']);

      var arc = d3.svg.arc().outerRadius(radius - 10).innerRadius(radius - 20);

      var pie = d3.layout.pie().sort(null).value(function (d) {
        return d;
      });

      var svg = d3.select('#' + id).attr('viewBox', '-135 -135 270 270').attr('preserveAspectRatio', 'xMinYMin meet').attr('height', function () {
        var h = null;
        if (bowser.msie) {
          // Bugfix, number doesn’t matter,
          // as long as it’s set : /
          h = 600;
        }
        return h;
      }).append('g').attr('id', 'pieChart');

      var path = svg.selectAll('path').data(pie(startData)).enter().append('path').attr('fill', '#FCFCFC');

      path.transition().duration(500).attr('class', function (d, i) {
        var className = 'TimelineToday-chartArc' + (i === 0 ? ' TimelineToday-chartArc--realized' : '');

        var percentage = expected ? Math.round(100 - remaining * 100 / expected) : 0;
        var isExpenseChart = expected <= 0;
        if (isExpenseChart && i === 0) {
          if (percentage > 100 && percentage < 110) {
            className += ' is-over-budget';
          } else if (percentage >= 110) {
            className += ' is-way-over-budget';
          }
        }
        return className;
      }).attr('fill', function (d, i) {
        // Overruled by class, but somehow needed.
        return color(d.data);
      }).attr('d', arc).each(function (d) {
        this._current = d;
      }); // Store the initial angles

      this.set('pie', pie);
      this.set('arc', arc);
      this.set('path', path);

      // Wait a little before animating
      // the chart to its given values.
      _ember['default'].run.later(this, function () {
        this.refreshChart();
      }, 10 + 700); // from timeline-today’s scroll animation
    }).on('didInsertElement'),

    refreshChart: (function () {

      if (this._state === 'destroying') return;

      var realized = this.get('realized') || 0;
      var remaining;
      var projectedSpend = this.get('projectedSpend') || 0;
      var expected = this.get('expected') || 1;

      if (Math.round(realized) <= Math.round(expected)) {
        projectedSpend = 0;
      }

      if (this.get('remaining') === 0) {
        remaining = 0;
      } else {
        remaining = this.get('remaining') || 1;
      }

      // TEST INCOME OVER BUDGET
      // realized += 500;
      // remaining -= 500;
      // TEST EXPENSES OVER BUDGET
      // realized -= 1000;
      // remaining += 2000;

      this.setProperties({
        incomeOverBudget: false,
        expensesOverBudget: false
      });

      // When no remaining value, don’t
      // show any progress in chart.
      if (!remaining && realized) {
        // 100% realized
        // realized = 1;
      } else if (!realized) {
          // 0% realized
          remaining = 1;
        } else {
          if (realized <= 0 && remaining > 0) {
            this.set('expensesOverBudget', realized < 0);
            remaining = 0;
          } else if (realized > 0 && remaining < 0) {
            this.set('incomeOverBudget', realized > 0);
            remaining = 0;
          }
        }

      var data = [realized, projectedSpend, remaining];

      var path = this.get('path');
      var arc = this.get('arc');
      var pie = this.get('pie');

      // Store the displayed angles in _current.
      // Then, interpolate from _current to the new angles.
      // During the transition, _current is updated in-place by d3.interpolate.
      function arcTween(a) {
        var i = d3.interpolate(this._current, a);
        this._current = i(0);
        return function (t) {
          return arc(i(t));
        };
      }

      path.data(pie(data));
      path.transition().duration(1250).attrTween('d', arcTween); // redraw the arcs

      _ember['default'].run.later(this, function () {

        //Change the color of projectedSpend via DOM because the d3 color method gets overwritten
        var graph = document.getElementsByClassName('TimelineToday-chart');
        if (graph[1].childNodes[1].childNodes[1]) {
          graph[1].childNodes[1].childNodes[1].setAttribute('style', 'fill: rgb(57, 127, 196, 0.6);');
        }

        var el = this.$('.TimelineToday-chartArc--realized');
        if (el) {

          var currClass = el.attr('class') || '';
          var cleanClass = currClass.replace(/ ?is-(way-?)over-budget/g, '');
          var newClass = cleanClass;

          if (this.get('trafficLight') === 'Orange') {
            newClass += ' is-over-budget';
          } else if (this.get('trafficLight') === 'Red') {
            newClass += ' is-way-over-budget';
          }

          el.attr('class', newClass);
        }
      }, 0);
    }).observes('data', 'realized', 'remaining', 'projectedSpend')
  });
});