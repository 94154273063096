define('front-end-cli/views/accountmanagement/edit/base', ['exports', 'ember', 'front-end-cli/config/environment', 'front-end-cli/mixins/window-resize'], function (exports, _ember, _frontEndCliConfigEnvironment, _frontEndCliMixinsWindowResize) {
  exports['default'] = _ember['default'].View.extend(_frontEndCliMixinsWindowResize['default'], {
    tagName: 'div',
    elementId: 'account-setup',

    logoLight: (function () {
      var image = _frontEndCliConfigEnvironment['default'].IMAGEURL + '/banklogos/onlight/' + this.get('controller.bankChoice.name') + '.png';
      return ('background-image: url(\'' + image + '\');').htmlSafe();
    }).property('controller.bankChoice.name'),

    setIndexHeight: (function () {

      var wh = _ember['default'].$(window).height(),
          hh = _ember['default'].$('main > header').height();

      _ember['default'].$('.vertical-centered-container').css({
        height: wh
      });
    }).on('didInsertElement', 'windowResize')
  });
});
// views/accountmanagement/edit