define('front-end-cli/views/range-select-block', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({
    tagName: 'div',
    classNameBindings: ['dim'],
    classNames: ['select'],
    attributeBindings: ['style'],

    style: (function () {
      return ('width:' + this.get('width')).htmlSafe();
    }).property('width'),

    focusIn: function focusIn() {
      this.$().addClass('focus');
    },
    focusOut: function focusOut() {
      this.$().removeClass('focus');
    }
  });
});