define('front-end-cli/pods/nibud/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    /* MidasCloud Api Variables */
    title: null,
    filters: null,
    calculate: null,
    beforeResult: null,

    expandButton: null,
    collapseButton: null,
    editCategoriesButton: null,
    editCategoriesButtonIcon: null,
    table: null,
    categoryRows: null,
    total: null,

    loading: true,
    renderSearchFilters: null,
    renderComparisonPage: null,

    responseIsError: null,
    responseErrorId: null,
    current: null,

    /* Methods */
    _findCheckedButtonByTypeName: function _findCheckedButtonByTypeName(name) {
      var boxes = document.getElementsByName(name);
      var value = ''; // default value = empty string

      if (boxes.length > 0) {
        boxes.forEach(function (box) {
          if (box.id === 'year-filter') {
            for (var i = 0; i < box.options.length; i++) {
              if (box.options[i].selected) {
                value = box.options[i].value;
              }
            }
          } else {
            if (box.checked) {
              value = box.value;
            }
          }
        });
      }
      return value;
    },
    _setError: function _setError(errorId) {
      this.set('responseIsError', true);
      this.set('responseErrorId', errorId);
    },
    _clearError: function _clearError() {
      this.set('responseIsError', false);
      this.set('responseErrorId', null);
    },

    actions: {
      fetchSearchFilters: function fetchSearchFilters() {
        var _this = this;
        var data = null;

        _ember['default'].$.get('api/v3/nibud?procedure=getSearchFilterDetails', function (result) {
          data = result.searchFilterDetails;
        }).success(function () {
          _this.setProperties({
            title: data.title,
            filters: data.filters,
            calculate: data.calculate,
            beforeResult: data.beforeResult
          });
          _this.set('loading', false);
          _this.set('renderSearchFilters', true);
        }).error(function (error) {
          _this._setError(error.responseJSON ? error.responseJSON['X-Correlation-Id'] : null);
        });
      },

      sendSearchFilters: function sendSearchFilters() {
        this._clearError();
        var _this = this;

        var mode = this._findCheckedButtonByTypeName('Mode');
        var comparisonType = this._findCheckedButtonByTypeName('ComparisonType');
        var budgetComparisonYear = this._findCheckedButtonByTypeName('BudgetComparisonYear');
        var valueType = this._findCheckedButtonByTypeName('ValueType');
        var netIncome = document.getElementById('NetIncome') ? document.getElementById('NetIncome').value : 0;
        // In case of empty value, sent the last value again for a valid response
        if (netIncome < 0 && this.get('filters') && this.get('filters').netIncome) {
          netIncome = this.get('filters').netIncome.value;
          if (document.getElementById('NetIncome')) {
            document.getElementById('NetIncome').value = this.get('filters').netIncome.value;
          }
        }

        _this.set('loading', true);
        _this.set('renderComparisonPage', false);

        _ember['default'].$.ajax({
          type: 'POST',
          url: 'api/v3/nibud?procedure=GetNibudDetailsBy',
          data: {
            'mode': mode,
            'comparisonType': comparisonType,
            'budgetComparisonYear': budgetComparisonYear,
            'valueType': valueType,
            'netIncome': netIncome
          }
        }).success(function (data) {
          _this.set('loading', false);
          _this.set('renderComparisonPage', true);
          if (typeof data.response.errors != 'undefined') {
            _this._setError(data.response['X-Correlation-Id']);
          } else {
            _this.setProperties({
              table: data.response.table,
              categoryRows: data.response.categoryRows,
              total: data.response.total,
              expandButton: data.response.expandButton,
              collapseButton: data.response.collapseButton,
              editCategoriesButton: data.response.editCategoriesButton ? data.response.editCategoriesButton : null,
              editCategoriesButtonIcon: data.response.editCategoriesButtonIcon ? data.response.editCategoriesButtonIcon : null
            });
          }
        }).error(function (error) {
          _this._setError(error.responseJSON ? error.responseJSON['X-Correlation-Id'] : null);
        });
      }
    }

  });
});