define('front-end-cli/models/user', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    state: _emberData['default'].belongsTo('state'),
    accounts: _emberData['default'].hasMany('account', { async: true }),
    profile: _emberData['default'].belongsTo('profile', { inverse: null }),
    partnerProfile: _emberData['default'].belongsTo('profile', { inverse: null }),
    csrf: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),

    hasNoAccounts: (function () {
      var amountOfAccounts = 0;
      this.get('accounts').forEach(function (account) {
        if (!account.get('isCashAccount')) {
          amountOfAccounts++;
        }
      });
      return amountOfAccounts === 0;
    }).property('accounts@each'),

    hasNoPsd2Accounts: (function () {
      var amountOfPsd2Accounts = 0;
      this.get('accounts').forEach(function (account) {
        if (!account.get('isCashAccount') && account.get('bankConsentActive') && !account.get('archived')) {
          amountOfPsd2Accounts++;
        }
      });
      return amountOfPsd2Accounts === 0;
    }).property('accounts@each'),

    daysSinceLastAccountUpdate: (function () {
      var max = moment('2007'); // (Yunoo founding)

      this.get('accounts').forEach(function (account) {
        var lastupdate = moment(account.get('lastupdate'));
        max = lastupdate.isAfter(max) ? lastupdate : max;
      });

      /*
        We expect this to run only when daysSinceLastlogin is
        larger than 1. Else it will return 'a few seconds ago'.
      */

      var today = moment().format('YYYY-MM-DD'),
          daysAgoHuman = moment(max, 'YYYY-MM-DD').from(today);

      return daysAgoHuman;
    }).property('accounts.@each.lastupdate'),

    csrfUpdated: (function () {
      /**
        Set a csrf-token-header for all future Ember.$.ajax calls.
      */

      if (!this.get('csrf')) return;
      _ember['default'].$.ajaxSetup({
        headers: {
          'X-AFAS-PERSONAL-CSRF': encodeURIComponent(this.get('csrf'))
        }
      });
    }).observes('csrf').on('init'),

    needsToUpdateImportSettings: (function () {
      var needsToUpdate = false;
      this.get('accounts').forEach(function (account) {
        if (account.get('bankConsentActive')) {
          if (account.get('needsToUpdateImportSettings')) {
            needsToUpdate = true;
          }
        }
      });

      return needsToUpdate;
    }).property('accounts.@each.bankConsentActive,accounts.@each.hasImportSetting')
  });
});