define('front-end-cli/controllers/automatic-import/update-account', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      saveImportSettings: function saveImportSettings() {
        var account = this.get('psd2Account');
        var select = document.getElementById('automatic-import-setting');
        var value = select.options[select.selectedIndex].value;
        _ember['default'].$.ajax({
          type: 'GET',
          url: '/api/v3/automaticimport?procedure=saveimportsetting&accountid=' + account.id + '&option=' + value
        }).success(function (response) {
          location.reload();
        }).error(function (error) {
          //Error
        });
      },

      importTransactions: function importTransactions() {
        var _this = this;

        var account = this.get('psd2Account');
        var updateButton = document.getElementById('update-button');
        var syncLoader = document.getElementById('syncLoader');
        var init = document.getElementById('init');
        var currentUser = this.get('currentUser');

        updateButton.style.display = 'none';
        init.style.display = 'none';
        syncLoader.style.display = 'block';

        _ember['default'].$.ajax({
          type: 'GET',
          url: '/api/v3/automaticimport?procedure=importtransactions&accountid=' + account.id
        }).success(function (response) {
          currentUser.send('reloadUser');
          _this.transitionToRoute('transactions');
        }).error(function (error) {
          syncLoader.style.display = 'none';
          var errorDiv = document.getElementById('error-message');
          errorDiv.style.display = 'block';
          _ember['default'].run.later(_this, (function () {
            errorDiv.style.display = 'none';
            updateButton.style.display = 'block';
            init.style.display = 'block';
          }).bind(_this), 2000);
        });
      }
    }
  });
});