define('front-end-cli/pods/components/trends-balance-toggle/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['TrendsBalanceToggle'],
    classNameBindings: ['isDisabled'],

    /**-------------------------
     External Bindings
     ---------------------------*/

    account: null,
    selectedAccounts: null,
    // Actions
    setHoverAccount: null,
    balanceSettings: null,

    /**-------------------------
     Calculated Properties
     ---------------------------*/

    hasBalance: (function () {
      return this.get('account.balance') !== undefined && this.get('account.balance') !== null;
    }).property('account'),

    isDisabled: (function () {
      return this.get('account.balance') === null;
    }).property('account'),

    accountInSelectedAccounts: (function () {
      return this.get('selectedAccounts').findBy('id', this.get('account.id')) !== undefined && this.get('account.balance') !== null;
    }).property('selectedAccounts.[]', 'account'),

    /**-------------------------
     Events
     ---------------------------*/

    mouseEnter: function mouseEnter(e) {
      this.sendAction('setHoverAccount', this.get('account'));
    },

    mouseLeave: function mouseLeave(e) {
      this.sendAction('setHoverAccount', null);
    },

    mouseDown: function mouseDown(e) {
      if (this.get('account.balance') !== null) {
        var selectedAccounts = this.get('selectedAccounts'),
            account = this.get('account'),
            selectedAccount = selectedAccounts.findBy('id', account.get('id'));

        if (selectedAccount === undefined) {
          selectedAccounts.pushObject(account);
        } else {
          selectedAccounts.removeObject(selectedAccount);
        }

        this.set('selectedAccounts', selectedAccounts);
      }
    },

    /**-------------------------
     Actions
     ---------------------------*/

    actions: {
      balanceSettings: function balanceSettings() {
        this.sendAction('balanceSettings');
      }
    }
  });
});