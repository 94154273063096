define('front-end-cli/models/insurance', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    incomeLater: _emberData['default'].belongsTo('income-later'),

    type: _emberData['default'].attr('number'),
    contractor: _emberData['default'].attr('string'),
    insured: _emberData['default'].attr('string'),
    insurerName: _emberData['default'].attr('string'),
    insuranceNumber: _emberData['default'].attr('string'),

    insuranceEndDate: _emberData['default'].attr('string'),
    insuranceEndAge: _emberData['default'].attr('number'),
    insurancePaymentType: _emberData['default'].attr('number'), //periodieke inleg/koopsom
    premiumInterval: _emberData['default'].attr('number'),
    // little bit strange name but paymentType is assigned if this insurancePrice
    // is a premium per month/year etc. or one time payment (koopsom)
    insurancePrice: _emberData['default'].attr('number'),
    insurancePremium: _emberData['default'].attr('number'),
    waitingTime: _emberData['default'].attr('number'),
    insuredAmountPerYear: _emberData['default'].attr('number'),
    insuredAmountPerMonth: _emberData['default'].attr('number'),
    paymentBy: _emberData['default'].attr('number'),
    paymentDurationType: _emberData['default'].attr('number', { defaultValue: 1 }),
    paymentAge: _emberData['default'].attr('number'), //uitkering t/m leeftijd
    paymentYears: _emberData['default'].attr('number'), //uitkering t/m aantal jaren

    isAge: (function () {
      // 1 age, 2 years, 1 is default
      var durationType = this.get('paymentDurationType');
      return durationType < 2;
    }).property('paymentDurationType'),

    isType: (function () {
      return this.get('type');
    }).property('type'),

    isAoOrHome: (function () {
      var type = this.get('type');
      return type == 1 || type == 2;
    }).property('type'),

    isHome: (function () {
      return this.get('type') == 2;
    }).property('type'),

    showPremium: (function () {
      var insurancePaymentType = this.get('insurancePaymentType');
      return insurancePaymentType == 1;
    }).property('insurancePaymentType'),

    isUnEmployed: (function () {
      return this.get('type') == 3;
    }).property('type')
  });
});