define('front-end-cli/controllers/accountmanagement/edit/choose-method', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    bankChoice: _ember['default'].computed.alias('parentController.bankChoice'),

    actions: {
      // edentifier 1 should not get here, not yet supported
      chooseMethod: function chooseMethod(method) {
        this.set('bankChoice.data.username', null);
        this.set('bankChoice.loginMethod', method);

        // Reset the importwizard when adding a new autosync bank account. This is done in sync, because otherwise the (session-based) reset is overwritten.
        var defer = _ember['default'].RSVP.defer();
        this.send('resetImportWizard', defer);

        defer.promise.then((function () {
          this.send('showStep', 4);
        }).bind(this));
      }
    }
  });
});