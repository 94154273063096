define('front-end-cli/pods/components/budget/yeartotals-table/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: ['table'],
    classNames: ['table', 'YearTotalsTable'],

    model: null,
    type: null,
    year: null,

    isIncomeUnknown: true,
    isExpensesUnknown: true,
    isResultsUnkown: true,

    isResults: _ember['default'].computed('type', function () {
      return this.get('type') === 'results';
    }),

    isIncome: _ember['default'].computed('type', function () {
      return this.get('type') === 'income';
    }),

    isExpenses: _ember['default'].computed('type', function () {
      return this.get('type') === 'expenses';
    }),

    showOutsideBudget: (function () {

      var _isIncomeUnknown = false,
          _isExpensesUnknown = false,
          _isResultsUnkown = false,
          amountIncome = 0,
          amountExpenses = 0,
          amountResults = 0;

      this.get('model').forEach(function (i) {
        if (i.incomeUnknown !== 0) {
          _isIncomeUnknown = true;
        }
        if (i.expensesUnknown !== 0) {
          _isExpensesUnknown = true;
        }
        if (i.resultsUnknown !== 0) {
          _isResultsUnkown = true;
        }
      });

      this.set('isIncomeUnknown', _isIncomeUnknown);
      this.set('isExpensesUnknown', _isExpensesUnknown);
      this.set('isResultsUnkown', _isResultsUnkown);
    }).observes('model.@each.incomeUnknown', 'model.@each.expensesUnknown', 'model').on('init')
  });
});