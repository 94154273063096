define('front-end-cli/pods/components/psd2-information-hasaccess-needspayment/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    currentUser: null,
    actions: {
      linkToPfpUrl: function linkToPfpUrl() {
        var pfpUrl = this.get('currentUser.model.state.pfpUrl');
        window.location.replace(pfpUrl + '/abonnementen');
      },

      clearConsent: function clearConsent() {
        var pfmUrl = this.get('currentUser.model.state.pfmUrl');
        _ember['default'].$.ajax({
          type: 'GET',
          url: '/api/v3/automaticimport?procedure=clearconsent'
        }).success(function (response) {
          window.location.href = pfmUrl + '/#/app/rekeningen';
          window.setTimeout(function () {
            window.location.reload(true);
          }, 3000);
        }).error(function (error) {
          //Nothing here
        });
      }
    }
  });
});