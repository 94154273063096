define('front-end-cli/controllers/profiler/incomenow-socialsecurity', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    needs: ['profiler'],
    selectObjects: _ember['default'].computed.alias('controllers.profiler.selectObjects'),

    hasWW: (function () {
      var value = this.get('model.wwChoice');
      return value == 1;
    }).property('model.wwChoice'),

    hasWIA: (function () {
      var value = this.get('model.wiaChoice');
      return value == 1;
    }).property('model.wiaChoice'),

    hasANW: (function () {
      var value = this.get('model.anwChoice');
      return value == 1;
    }).property('model.anwChoice')
  });
});