define('front-end-cli/models/profile', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    // Switched to `one to none`!
    // user:                   DS.belongsTo('user', {inverse: 'profile'}),
    personal: _emberData['default'].belongsTo('personal'),
    incomeNow: _emberData['default'].belongsTo('income-now'),
    incomeLater: _emberData['default'].belongsTo('income-later'),
    children: _emberData['default'].hasMany('child'),
    debts: _emberData['default'].hasMany('debt'),
    financialMeansMoney: _emberData['default'].hasMany('financial-means-money'),
    financialMeansAssets: _emberData['default'].hasMany('financial-means-asset'),
    miscInsurances: _emberData['default'].hasMany('misc-insurance'),
    // timestamps:             DS.hasMany('timestamp'),
    becameError: function becameError() {
      _ember['default'].instrument('modelError');
    }
  });
});