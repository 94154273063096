define('front-end-cli/helpers/moment-date', ['exports', 'ember'], function (exports, _ember) {

  /**
    Example usage:
    {{moment-date mydate sourceFormat="YYYY-MM-DD" format="MMM"}}
  
    Or when dealing with js source dates:
    {{moment-date myjsdate sourceFormat="js" format="MMM"}}
  */
  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(function (sourceDate, options) {

    sourceDate = sourceDate || moment();

    var sourceFormat = options.hash.sourceFormat || 'YYYY-MM-DD',
        format = options.hash.format || 'LL';

    var m;
    if (/^js$/i.test(sourceFormat)) {
      m = moment(sourceDate);
    } else {
      m = moment(sourceDate, sourceFormat);
    }
    return m.format(format);
  });
});