define('front-end-cli/routes/automatic-import/update-all-accounts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      if (this.get('currentUser.model.state.needsToLockAccounts')) {
        this.transitionTo('accountmanagement.lock-accounts');
      } else if (this.get('currentUser.model.hasNoPsd2Accounts')) {
        this.transitionTo('accounts');
      }
    }
  });
});