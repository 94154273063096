define('front-end-cli/initializers/current-user', ['exports', 'front-end-cli/controllers/current-user'], function (exports, _frontEndCliControllersCurrentUser) {
  exports.initialize = initialize;

  function initialize(container, application) {
    application.register('user:current', _frontEndCliControllersCurrentUser['default'], { singleton: true });
    application.inject('route', 'currentUser', 'user:current');
    application.inject('controller', 'currentUser', 'user:current');
  }

  exports['default'] = {
    name: 'current-user',
    initialize: initialize
  };
});