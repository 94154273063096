define('front-end-cli/controllers/accountmanagement/edit/manual-steps', ['exports', 'ember', 'front-end-cli/mixins/steps'], function (exports, _ember, _frontEndCliMixinsSteps) {
  exports['default'] = _ember['default'].Controller.extend(_frontEndCliMixinsSteps['default'], _ember['default'].TargetActionSupport, {
    needs: ['accountmanagement/setup'],
    bankChoice: _ember['default'].computed.alias('parentController.bankChoice'),
    error: _ember['default'].computed.alias('parentController.error'),

    stepLength: 8,
    step: 1,

    currentGuide: (function () {
      return 1;
    }).property(),

    useDefaultGuide: (function () {
      var customGuide = this.get('bankChoice.isRabobank') || this.get('bankChoice.isIng') || this.get('bankChoice.isAbn') || this.get('bankChoice.isSns');
      return !customGuide;
    }).property('bankChoice.name'),

    guide1: _ember['default'].computed.equal('currentGuide', 1),
    guide2: _ember['default'].computed.equal('currentGuide', 2),
    guide3: _ember['default'].computed.equal('currentGuide', 3),
    guide4: _ember['default'].computed.equal('currentGuide', 4),
    guide5: _ember['default'].computed.equal('currentGuide', 5),
    guide6: _ember['default'].computed.equal('currentGuide', 6),
    guide7: _ember['default'].computed.equal('currentGuide', 7),
    guide8: _ember['default'].computed.equal('currentGuide', 8),
    guide9: _ember['default'].computed.equal('currentGuide', 9),
    guide10: _ember['default'].computed.equal('currentGuide', 10),

    actions: {

      setCurrentGuide: function setCurrentGuide(guide) {
        this.set('currentGuide', guide);
      },

      // EventedForm view sends triggers action on enter
      pressedEnter: function pressedEnter() {
        if (this.get('bankChoice.data.isComplete')) {
          this.send('createAndSyncBankLogin');
        }
      },

      showManualUploadStep: function showManualUploadStep() {
        // Reset the importwizard when creating or updating a standard (e.g. ABN) bank account the manual way. This is done in sync, because otherwise the (session-based) reset is overwritten.
        var defer = _ember['default'].RSVP.defer();
        this.send('resetImportWizard', defer);

        defer.promise.then((function () {
          // Uses `Ember.TargetActionSupport` mixin
          this.triggerAction({
            action: 'showNextStep',
            target: this.get('parentController')
          });
        }).bind(this));
      }

    }
  });
});