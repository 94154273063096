define('front-end-cli/pods/components/trends-chart-index/component', ['exports', 'ember'], function (exports, _ember) {

  /**-------------------------
    Trends INDEX
  ---------------------------*/

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['TrendsNetted'],
    data: null,
    height: 180, // Default

    // actions
    transitionAction: null,

    /**-------------------------
    Properties
    ---------------------------*/

    periodDetails: null,

    /**-------------------------
    Methods
    ---------------------------*/

    didInsertElement: function didInsertElement() {
      // The view is rendered before the async
      // data is resolved. But we still need to
      // redraw when the element gets re-inserted
      // after a relatedTransaction close.

      if (this.get('data')) {
        this._updateChart();
      }
      this.send('insertDetails');
    },

    willDestroyElement: function willDestroyElement() {
      // Make sure detail-DOM is removed
      // when this componenent is destroyed.
      this.get('periodDetails').remove();
    },

    updateChart: (function () {
      _ember['default'].run.debounce(this, function () {
        this._updateChart();
      }, 50);
    }).observes('data'),

    _updateChart: function _updateChart() {

      var _this = this,
          svg,
          data = this.get('data'),
          dataItems = this.get('data.length') - 1,
          width = this.$().width(),
          height = this.get('height'),
          margin = {
        left: 60,
        right: 60,
        top: 35,
        bottom: 35
      },
          widthNoMargin = width - margin.left - margin.right,
          heightNoMargin = height - margin.top - margin.bottom,
          parseDate = d3.time.format('%Y-%m-%d').parse;

      var startDate = moment(_this.get('startDate'), 'YYYY-MM-DD'),
          endDate = moment(_this.get('endDate'), 'YYYY-MM-DD');

      _ember['default'].assert('We’ll need data in order to draw a chart', data);

      // Flush the components contents.
      this.$('#TrendsChartIndex').remove();

      var min = d3.min(data, function (d) {
        return d.expense;
      });
      var max = d3.max(data, function (d) {
        return d.income;
      });

      var _y = d3.scale.linear().range([heightNoMargin, 0]).domain([min, max]).nice(),
          _x = d3.scale.ordinal().rangeRoundBands([0, widthNoMargin], 0.45).domain(data.map(function (d) {
        return d.period;
      }));

      var xAxis = d3.svg.axis().scale(_x).tickFormat(function (d, i) {
        return moment(d, 'YYYY-MM').format('MMM').replace(/\./, '');
      });

      var yAxis = d3.svg.axis().scale(_y).orient('left').tickFormat(function (d, i) {
        return accounting.formatMoney(d, { precision: 0 });
      }).ticks(5);

      if (bowser.msie) {

        // SVG viewBox in IE needs a fixed height

        svg = d3.select('#' + this.elementId).append('svg').attr('id', 'TrendsChartIndex').classed('TrendsChartIndex', true).attr('viewBox', '0 0 ' + ' ' + width + ' ' + height).attr('height', height).attr('preserveAspectRatio', 'none meet').append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
      } else {

        svg = d3.select('#' + this.elementId).append('svg').classed('TrendsChartIndex', true).attr('id', 'TrendsChartIndex').attr('viewBox', '0 0 ' + ' ' + width + ' ' + height).attr('preserveAspectRatio', 'none meet').append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
      }

      svg.selectAll('.TrendsChartNetted-rectBackground').data(data).enter().append('rect').attr({
        'class': 'TrendsChartIndex-rectBackground',
        'rx': 2,
        'x': function x(d) {
          return _x(d.period);
        },
        'y': function y(d) {
          return _y(max);
        },
        height: function height(d) {
          return Math.abs(_y(max) - _y(0));
        },
        width: function width() {
          return _x.rangeBand();
        }
      }).on('mouseover', function (d) {
        if (!Modernizr.touch) {
          _this.send('showDetails', d, d3.event);
        }
      }).on('mouseout', function (d) {
        if (!Modernizr.touch) {
          _this.send('hideDetails');
        }
      }).on('click', function (d, i) {
        _this.send('transitionFromRect', d.period, 'income');
      });

      svg.selectAll('.TrendsChartNetted-rectBackground').data(data).enter().append('rect').attr({
        'class': 'TrendsChartIndex-rectBackground',
        'rx': 2,
        'x': function x(d) {
          return _x(d.period);
        },
        'y': function y(d) {
          var isPositive = d.expense > 0;
          return isPositive ? _y(min) : _y(0) + 15 + 5 + 5;
        },
        height: function height(d) {
          return Math.abs(_y(min) - _y(0));
        },
        width: function width() {
          return _x.rangeBand();
        }
      }).on('mouseover', function (d) {
        d3.select(this).classed('TrendsChartIndex-rectHover', true);
        if (!Modernizr.touch) {
          _this.send('showDetails', d, d3.event);
        }
      }).on('mouseout', function (d) {
        d3.select(this).classed('TrendsChartIndex-rectHover', false);
        if (!Modernizr.touch) {
          _this.send('hideDetails');
        }
      }).on('touchstart', function (d) {
        if (Modernizr.touch) {
          _this.send('showDetails', d, d3.event);
        }
      }).on('touchend', function (d) {
        if (Modernizr.touch) {
          _this.send('hideDetails');
        }
      }).on('click', function (d, i) {
        _this.send('transitionFromRect', d.period, 'income');
      });

      svg.selectAll('.TrendsChartIndex-rectExpense').data(data).enter().append('rect').attr('class', function (d, i) {
        return 'TrendsChartIndex-rectExpense';
      }).attr('rx', 2).attr('x', function (d) {
        return _x(d.period);
      }).attr('width', _x.rangeBand()).attr('y', function (d) {
        var isPositive = d.expense > 0;
        return isPositive ? _y(d.expense) : _y(0) + 15 + 5 + 5;
      }).attr('height', function (d) {
        return Math.abs(_y(d.expense) - _y(0));
      }).on('mouseover', function (d) {
        if (!Modernizr.touch) {
          d3.select(this).classed('TrendsChartIndex-rectHover', true);
        }
        _this.send('showDetails', d, d3.event);
      }).on('mouseout', function (d) {
        if (!Modernizr.touch) {
          d3.select(this).classed('TrendsChartIndex-rectHover', false);
          _this.send('hideDetails');
        }
      }).on('click', function (d, i) {
        _ember['default'].run.next(this, function () {
          _this.send('transitionFromRect', d.period, 'expenses');
        });
      }).on('touchstart', function (d) {
        _this.send('showDetails', d, d3.event);
      }).on('touchend', function (d) {
        _this.send('hideDetails');
      });

      svg.selectAll('.TrendsChartIndex-rectIncome').data(data).enter().append('rect').attr('class', function (d, i) {
        return 'TrendsChartIndex-rectIncome';
      }).attr('rx', 2).attr('x', function (d) {
        return _x(d.period);
      }).attr('width', _x.rangeBand()).attr('y', function (d) {
        var isPositive = d.income > 0;
        return isPositive ? _y(d.income) : _y(0) + 15 + 5 + 5;
      }).attr('height', function (d) {
        return Math.abs(_y(d.income) - _y(0));
      }).on('mouseover', function (d) {
        if (!Modernizr.touch) {
          d3.select(this).classed('TrendsChartIndex-rectHover', true);
          _this.send('showDetails', d, d3.event);
        }
      }).on('mouseout', function (d) {
        if (!Modernizr.touch) {
          d3.select(this).classed('TrendsChartIndex-rectHover', false);
          _this.send('hideDetails');
        }
      }).on('click', function (d, i) {
        _this.send('transitionFromRect', d.period, 'income');
      });

      // Draw the X-axis tick backgrounds
      svg.selectAll('.TrendsChartNetted-rectXTickBg').data(data).enter().append('rect').attr('class', function (d, i) {
        return 'TrendsChartNetted-rectXTickBg';
      }).attr('x', function (d) {
        return _x(d.period);
      }).attr('y', _y(0) + 5).attr('rx', 2).attr('height', 15).attr('width', _x.rangeBand()).on('click', function (d, i) {
        if (!Modernizr.touch) {
          _this.send('transitionFromRect', d.period);
        }
      }).on('mouseover', function (d) {
        if (!Modernizr.touch) {
          _this.send('showDetails', d, d3.event);
        }
      }).on('mouseout', function (d) {
        if (!Modernizr.touch) {
          _this.send('hideDetails');
        }
      });

      // Draw the X-axis.
      svg.append('g').attr('class', 'x axis TrendsChartNetted-xAxis').attr('transform', function (d, i) {
        // Position the x-axis on the zero line
        return 'translate(' + 0 + ',' + (_y(0) - 2) + ')';
      }).call(xAxis);

      // Draw the Y-axis
      svg.append('g').attr('class', 'y axis TrendsChartNetted-yAxis').attr('transform', 'translate(20,0)').call(yAxis);

      d3.selectAll('g.y.axis g.tick').attr('transform', function (d, i) {
        var push = 0;
        if (d === 0) push = 13;else if (d < 0) push = 26;
        return 'translate(0, ' + (_y(d) + push) + ')';
      });

      svg.selectAll('.TrendsChartIndex-rectResult').data(data).enter().append('rect').attr('class', function (d, i) {
        if (d.netted > 0) {
          return 'TrendsChartIndex-rectResult positive';
        } else {
          return 'TrendsChartIndex-rectResult negative';
        }
      }).attr('rx', 2).attr('x', function (d) {
        return _x(d.period);
      }).attr('width', _x.rangeBand()).attr('y', function (d) {
        var isPositive = d.netted > 0;
        return isPositive ? _y(d.netted) : _y(0) + 15 + 5 + 5;
      }).attr('height', function (d) {
        return Math.abs(_y(d.netted) - _y(0));
      }).on('mouseover', function (d) {
        if (!Modernizr.touch) {
          _this.send('showDetails', d, d3.event);
        } else {
          if (d.netted > 0) {
            _this.send('transitionFromRect', d.period, 'income');
          } else {
            _this.send('transitionFromRect', d.period, 'expenses');
          }
        }
      }).on('mouseout', function (d) {
        if (!Modernizr.touch) {
          _this.send('hideDetails');
        }
      }).on('click', function (d, i) {
        if (!Modernizr.touch) {
          if (d.netted > 0) {
            _this.send('transitionFromRect', d.period, 'income');
          } else {
            _this.send('transitionFromRect', d.period, 'expenses');
          }
        }
      });

      // var lineFunction = d3.svg.line()
      // .x(function(d) {
      //   return x(d.period);
      // })
      // .y(function(d) {
      //   if (d.netted > 0) {
      //
      //     return `${y(d.netted)}`;
      //   } else {
      //
      //     return `${y(d.netted)}`;
      //   }
      // })
      //
      // var lineGraph = svg.append('path')
      // .attr('d', lineFunction(data))
      // .attr('stroke', 'blue')
      // .attr('stroke-width', 2)
      // .attr('fill', 'none');
    },

    actions: {

      transitionFromRect: function transitionFromRect(period, type) {
        this.sendAction('action', period, type);
      },

      insertDetails: function insertDetails() {
        var periodDetails = _ember['default'].$('<div class="TrendsChartNettedDetails"></div>');
        this.set('periodDetails', periodDetails);
        _ember['default'].$('body').append(periodDetails);
      },

      showDetails: function showDetails(d, event) {
        var m = moment(d.period, 'YYYY-MM'),
            month = m.format('MMMM'),
            year = m.format('YYYY'),
            netted = d.netted,
            income = d.income,
            expense = d.expense,
            target = _ember['default'].$(event.target),
            periodType = this.get('periodType'),
            amountType = 'Resultaat',
            periodDetails = this.get('periodDetails');

        income = accounting.formatMoney(income, { precision: 0 });
        expense = accounting.formatMoney(expense, { precision: 0 });
        netted = accounting.formatMoney(netted, { precision: 0 });

        var htmlString = '\n      <div class="TrendsChartNettedDetails-date">\n      <span class="TrendsChartNettedDetails-month">' + month + '</span>\n      <span class="TrendsChartNettedDetails-year">' + year + '</span>\n      </div>\n      <div class="TrendsChartNettedDetails-title">' + amountType + '</div>\n      <div class="TrendsChartNettedDetails-result">' + netted + '</div>\n\n      <div class="TrendsChartNettedDetails-sub">\n      <div class="TrendsChartNettedDetails-subTitle">Inkomsten</div>\n      <div class="TrendsChartNettedDetails-subSum">' + income + '</div>\n      </div>\n      <span class="TrendsChartNettedDetails-subOperator">-</span>\n      <div class="TrendsChartNettedDetails-sub">\n      <div class="TrendsChartNettedDetails-subTitle">Uitgaven</div>\n      <div class="TrendsChartNettedDetails-subSum">' + expense + '</div>\n      </div>';

        // Make sure amounts in tooltip are negative
        // when in expense route

        periodDetails.addClass('is-resultsChart');
        var _top = 15;

        periodDetails.css({
          top: _top,
          left: target.offset().left - periodDetails.width() / 2 + target.attr('width') / 2
        }).html(htmlString).addClass('is-active');
      },

      hideDetails: function hideDetails() {
        this.get('periodDetails').removeClass('is-active').empty();
      }
    }
  });
});