define('front-end-cli/controllers/accountmanagement/cashbook', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    application: _ember['default'].inject.controller(),
    newUser: null,
    cashbooktitle: null,
    isTourVisible: false,
    emptyCashbookTitle: (function () {
      return !this.get('cashbooktitle');
    }).property('cashbooktitle'),

    canNotAddMoreCashAccounts: (function () {
      var doesNotHaveAccessToAllaccounts = !this.get('currentUser.model.state.hasAccessToAllAccounts'),
          hasCashAccounts = this.get('currentUser.model.accounts').filterBy('isCashAccount', true).filter(function (item, index, enumerable) {
        return item.get('locked') !== true;
      }).get('length') > 0;

      return doesNotHaveAccessToAllaccounts && hasCashAccounts;
    }).property('currentUser.model.accounts', 'currentUser.model.state.plus'),

    actions: {
      saveNewCashAccount: function saveNewCashAccount() {

        var cashAccount = this.store.createRecord('account', {
          user: this.get('currentUser.model'),
          description: this.get('cashbooktitle'),
          balance: 0.00
        });

        this.store.find('bank', 22).then((function (bank) {
          cashAccount.set('bank', bank);

          if (this.get('currentUser.model.state.newUser')) {
            this.get('application').send('showSidebar');
            this.set('isTourVisible', true);

            this.set('currentUser.model.state.newUser', false);
            this.get('currentUser.model.state').save();
            cashAccount.save();
          } else {
            // Make sure our new cashAccount is persited before
            // going to the accounts route.
            cashAccount.save().then((function () {
              this.transitionToRoute('accounts');
            }).bind(this));
          }
        }).bind(this));
      }
    }
  });
});