define('front-end-cli/controllers/automatic-import/consent-expired', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            generateConsentUrl: function generateConsentUrl() {
                _ember['default'].$.ajax({
                    type: 'GET',
                    url: '/api/v3/automaticimport?procedure=generateconsenturl'
                }).success(function (response) {
                    window.location.replace(response.requestData.url);
                }).error(function (error) {
                    //Nothing here
                });
            }
        }
    });
});