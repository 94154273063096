define('front-end-cli/pods/components/transaction-details/categories/categories-pane/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    incomeExpenses: _ember['default'].inject.service('income-expenses'),
    store: _ember['default'].inject.service('store'),

    classNames: ['DetailsPane', 'CategoriesPane'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    transaction: null,
    detailsComponent: null,

    /**-------------------------
     ---------------------------*/

    previewedCategory: _ember['default'].computed('incomeExpenses.categories', function () {
      return this.get('incomeExpenses.categories').filterBy('enabled', true).get('firstObject');
    }),

    /**
      Focus the seacrh input after the pane transition
      completed to prevent rendering glitches.
    */
    focusSearchInput: _ember['default'].on('didInsertElement', function () {
      var _this = this;

      _ember['default'].run.later(function () {
        _this.$('.CategorySelect-searchInput').focus();
      }, 500);
    }),

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {

      previewCategory: function previewCategory(category) {
        this.set('previewedCategory', category);
      },
      categoryWasSelected: function categoryWasSelected() {
        this.get('detailsComponent').send('cancelEditDetail');
      },
      addSubcategory: function addSubcategory(newSubcategoryName, category) {

        var newSubRgx = new RegExp('^' + newSubcategoryName + '$', 'i'),
            valid = true;

        // A name and category is required
        if (!newSubcategoryName || !category) return false;

        // Check if subcategory name is already
        // added in selected category.. again.
        this.get('store').all('category').forEach(function (category) {
          category.get('subcategories').forEach(function (subcategory) {
            if (newSubRgx.test(subcategory.get('name'))) {
              valid = false;
            }
          });
        });

        if (valid) {

          var cat = this.get('store').createRecord('subcategory', {
            name: newSubcategoryName,
            category: category,
            enabled: true
          });

          cat.save();
        } else {
          // No valid newSubcategoryName
        }
      }
    }
  });
});