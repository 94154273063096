define('front-end-cli/pods/trends/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      bankrekening: {
        refreshModel: true
      }
    },

    trendFinalMonth: null,
    trendFinalYear: null,

    model: function model(params) {
      var startDayFinancialMonth = this.get('currentUser.model.state.startDayFinancialMonth') * 1,
          customFinancialMonth = startDayFinancialMonth >= 15,
          periodType = this.get('periodType');

      var today = moment(),
          trendFinalMonth = this.get('trendFinalMonth') ? this.get('trendFinalMonth') : null,
          trendFinalYear = this.get('trendFinalYear') ? this.get('trendFinalYear') : today.year();
      if (trendFinalMonth == null) {
        trendFinalMonth = today.month() + 1;
        if (trendFinalMonth < 10) trendFinalMonth = '0' + trendFinalMonth;
      }

      var start = moment().month(parseInt(trendFinalMonth) - 1).year(trendFinalYear).startOf('month').subtract(1, 'years'),
          end = moment().month(parseInt(trendFinalMonth) - 1).year(trendFinalYear).endOf('month');

      if (customFinancialMonth) {
        start = start.date(startDayFinancialMonth);
        end = end.date(startDayFinancialMonth).subtract(1, 'days');
        if (moment().date() >= startDayFinancialMonth) {
          start = start.add(1, 'months');
          end = end.add(1, 'months');
        }
      }

      start = start.format('YYYY-MM-DD');
      end = end.format('YYYY-MM-DD');

      return _ember['default'].RSVP.hash({
        trendsnetted: _ember['default'].$.get('/api/v3/trendsnetted?startDate=' + start + '&endDate=' + end + '&scope=P1M'),
        trendsbalance: _ember['default'].$.get('/api/v3/trendsbalance?scope=P1M&startDate=' + start + '&endDate=' + end)
      });
    },
    setupController: function setupController(controller, model) {
      var accounts = this.store.all('account');
      var selectedAccounts = accounts.filterBy('activated', true);
      var timeFormat = d3.time.format('%Y-%m');
      var today = moment(),
          trendFinalMonth = this.get('trendFinalMonth') ? this.get('trendFinalMonth') : null,
          trendFinalYear = this.get('trendFinalYear') ? this.get('trendFinalYear') : today.year();

      if (trendFinalMonth == null) {
        trendFinalMonth = today.month() + 1;
        if (trendFinalMonth < 10) trendFinalMonth = '0' + trendFinalMonth;
      }

      model.trendsbalance.forEach(function (item, index, self) {
        item.period = timeFormat.parse(item.period);
      });

      controller.setProperties({
        trendsnetted: model.trendsnetted,
        trendsbalance: model.trendsbalance,
        accounts: accounts,
        selectedAccounts: selectedAccounts,
        trendFinalMonth: trendFinalMonth,
        trendFinalYear: trendFinalYear
      });
    },
    actions: {
      balanceSettings: function balanceSettings() {
        this.transitionTo('settings.balance');
      },
      toggleAcccounts: function toggleAcccounts() {
        this.toggleProperty('controller.toggleAccounts');
      },
      transitionToPeriode: function transitionToPeriode(periode, type) {
        var periodParam = moment(periode, 'YYYY-MM');

        this.set('controller.resetSettings', true);

        if (type == 'income') {
          this.transitionTo('income-expenses.income', { queryParams: { periode: periodParam.format('MM-YYYY') } });
        } else {
          this.transitionTo('income-expenses.expenses', { queryParams: { periode: periodParam.format('MM-YYYY') } });
        }
      },
      setRouterPeriodParam: function setRouterPeriodParam(year, month) {
        this.set('trendFinalMonth', month);
        this.set('trendFinalYear', year);
        this.refresh();
      }

      // charts: function(account) {
      //   //console.log(account.get('id'));
      //   this.transitionTo({queryParams: {bankrekening: account.get('id')}});
      // }
    }
  });
});