define('front-end-cli/controllers/profiler/labor', ['exports', 'ember', 'front-end-cli/mixins/profiler/sub-model-switch'], function (exports, _ember, _frontEndCliMixinsProfilerSubModelSwitch) {
  exports['default'] = _ember['default'].Controller.extend(_frontEndCliMixinsProfilerSubModelSwitch['default'], {
    needs: ['profiler'],
    selectObjects: _ember['default'].computed.alias('controllers.profiler.selectObjects'),
    /**
      Create and delete the appropriate
      type-records when the type changes.
       employed
      selfEmployed
      shareholder
    */
    typeObserver: (function () {
      var type = this.get('model.type'),
          recordName,
          record;

      if (type == 1) {
        recordName = 'employed';
        this.deleteRecords(['selfEmployed', 'shareholder']);
      } else if (type == 2) {
        recordName = 'selfEmployed';
        this.deleteRecords(['employed', 'shareholder']);
      } else if (type == 3) {
        recordName = 'shareholder';
        this.deleteRecords(['selfEmployed', 'employed']);
      }

      record = this.store.createRecord(recordName, {
        labor: this.get('model')
      });

      /**
        Parent model needs to be saved to be able to declare the relation.
        If not, wel store the relationless record and declare the relation later.
       */
      if (!this.get('model.id')) {

        this.storeRelationlessRecord({
          recordName: recordName,
          record: record,
          parentName: 'labor',
          parent: this.get('model')
        });
      }
    }).observes('model.type')
  });
});