define('front-end-cli/pods/components/transaction-details/receipt/receipt-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'transaction-detail-row',
    classNames: ['TransactionDetails-row', 'TransactionDetails-rowOptional', 'ReceiptDetailRow'],
    classNameBindings: ['isFilledOutOrEditing', 'isEditing'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    isEditing: null,
    transaction: null,
    previewUrl: null,

    // Actions
    toEditDetail: null,

    /**-------------------------
      Properties
    ---------------------------*/

    isFilledOutOrEditing: (function () {
      return !!this.get('transaction.receipt.attachmentUrl') || this.get('isEditing') || this.get('previewUrl');
    }).property('transaction.receipt.attachmentUrl', 'isEditing', 'previewUrl'),

    hasReceipt: _ember['default'].computed('transaction.receipt.attachmentUrl', 'previewUrl', function () {
      return this.get('transaction.receipt.attachmentUrl') || this.get('previewUrl');
    }),

    receiptUrlStyle: _ember['default'].computed('transaction.receipt.attachmentUrl', 'previewUrl', function () {
      var receiptUrl = this.get('transaction.receipt.attachmentUrl') || this.get('previewUrl');
      return ('background-image: url(' + receiptUrl + ');').htmlSafe();
    }),

    actions: {

      gotoReceiptPane: function gotoReceiptPane() {

        var attachmentUrl = this.get('transaction.receipt.attachmentUrl'),
            previewUrl = this.get('previewUrl');

        if (this.get('isEditing') && !previewUrl && !attachmentUrl) {
          this.sendAction('toEditDetail', 'receipt/receipt-pane');
        }
      },

      removeReceipt: function removeReceipt() {
        this.set('transaction.receipt', null);
      },

      removeNewReceipt: function removeNewReceipt() {
        this.setProperties({
          files: null,
          previewUrl: null
        });
      },

      openReceiptInNewWindow: function openReceiptInNewWindow() {
        var attachmentUrl = this.get('transaction.receipt.attachmentUrl'),
            previewUrl = this.get('previewUrl');

        var url = attachmentUrl || previewUrl;
        window.open(url);
      }
    }
  });
});