define('front-end-cli/pods/components/report-generate/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // External bindings
    application: null,
    currentUser: null,

    generating: null,
    status: null,
    email: false,
    isSelected: 'standard',
    selectedPeriod: null,
    generatedPeriod: null,
    resetReport: false,
    reportReady: false,
    errorMessage: null,
    showStatus: null,

    generatedReportPath: null,

    period: _ember['default'].A([{ id: 'month', text: 'maand' }, { id: 'quarter', text: 'kwartaal' }, { id: 'year', text: 'jaar' }]),

    periodsData: _ember['default'].Object.create({
      weeks: [],
      months: [],
      quarters: [],
      years: []
    }),

    getData: (function () {

      var self = this;

      _ember['default'].$.get('/api/v3/reports', function (data) {

        data.weeks.forEach(function (w) {
          self.get('periodsData.weeks').pushObject(w);
        });

        data.months.forEach(function (m) {
          self.get('periodsData.months').pushObject(m);
        });

        data.quarters.forEach(function (q) {
          self.get('periodsData.quarters').pushObject(q);
        });

        data.years.forEach(function (y) {
          self.get('periodsData.years').pushObject(y);
        });
      });

      this.set('selectedPeriod', 'month');
    }).on('init'),

    proxiedModel: _ember['default'].computed.map('currentUser.model.accounts', function (model) {
      return _ember['default'].ObjectProxy.create({
        content: model,
        checked: true
      });
    }),

    proxiedCheckedItems: _ember['default'].computed.filterBy('proxiedModel', 'checked', true),

    checkedItems: _ember['default'].computed.mapBy('proxiedCheckedItems', 'content'),

    setSelect: (function () {

      this.set('selectedPeriod2', '');

      _ember['default'].run.later(this, function () {

        var weeks = this.get('periodsData.weeks');
        var months = this.get('periodsData.months');
        var quarters = this.get('periodsData.quarters');
        var years = this.get('periodsData.years');

        var period = this.get('selectedPeriod');
        if (period === 'week') {
          this.set('generatedPeriod', weeks);
        }
        if (period === 'month') {
          this.set('generatedPeriod', months);
        }
        if (period === 'quarter') {
          this.set('generatedPeriod', quarters);
        }
        if (period === 'year') {
          this.set('generatedPeriod', years);
        }
      }, 10);
    }).observes('selectedPeriod'),

    isCustomEmail: (function () {
      var _email = this.get('currentUser.model.email');

      if (this.get('email')) {
        _ember['default'].$('#customEmail').show();
        this.set('customEmail', _email);
      } else {
        _ember['default'].$('#customEmail').hide();
      }
    }).property('email'),

    actions: {

      clearReport: function clearReport() {
        this.set('reportReady', false);
        this.set('generating', null);
        this.set('selectedPeriod', null);
        this.set('selectedPeriod2', null);
        this.set('generatedReportPath', null);
      },

      getPlus: function getPlus() {
        this.transitionTo('plus');
      },

      generateReport: function generateReport() {

        this.set('showStatus', false);
        this.set('errorMessage', '');

        var bankAccounts = [];
        var selectedBankAccounts = _ember['default'].computed.mapBy('proxiedCheckedItems', 'content');

        this.get('checkedItems').forEach(function (a) {
          bankAccounts.push(a.id);
        });

        if (bankAccounts.length === 0) {
          this.set('errorMessage', 'Geen rekeningen geselecteerd!');
          this.set('showStatus', true);
        } else {

          var value = null,
              week = null,
              year = null,
              scope = null,
              quarter = null,
              month = null,
              type = this.get('isSelected'),
              period = this.get('selectedPeriod'),
              selectedPeriod = this.get('selectedPeriod2');

          switch (period) {
            case 'year':
              year = selectedPeriod;
              break;
            case 'quarter':
              value = selectedPeriod.split('-');
              quarter = value[0];
              year = value[1];
              break;
            case 'month':
              value = selectedPeriod.split('-');
              month = value[0];
              year = value[1];
              break;
            case 'week':
              value = selectedPeriod.split('-');
              week = value[0];
              year = value[1];
              break;
          }

          var reportData = {
            'type': type,
            'scope': period,
            'quarter': quarter,
            'month': month,
            'year': year,
            'week': week,
            'activeAccounts': bankAccounts,
            'email': this.get('email'),
            'email_address': this.get('customEmail')
          };

          var url = '/app/async/report.generate';
          var self = this;
          this.set('generating', true);

          _ember['default'].$.post(url, reportData, function (data) {

            if (!data || data.error && data.error.message) {
              var message = data ? data.error.message : 'No response from server.';
              self.set('errorMessage', message);
              self.set('showStatus', true);
            } else {
              var reportPath = window.location.protocol + '//' + window.location.hostname + '/app/download-report/' + data.data.id;
              self.set('generatedReportPath', reportPath);
              self.set('status', 'success');
              self.set('reportReady', true);
            }

            self.set('generating', null);
          });
        }
      }
    }
  });
});