define('front-end-cli/pods/components/balance-benchmark-account/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'section',
    classNameBindings: [':balance-benchmark', ':panel', ':panel-default'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    account: null,
    benchmark: null,

    /**-------------------------
      Properties
    ---------------------------*/

    benchmarkObserver: (function () {
      if (this.get('benchmark') && this.get('account')) {

        var benchmark = this.get('benchmark'),
            balance;

        // Allow numeric and comma only
        benchmark = benchmark.replace(/[^0-9,\-]/, '');

        // Remove first comma if multiple
        if (/.,.*,./.test(benchmark) != -1) {
          var last = benchmark.lastIndexOf(',');
          var butLast = benchmark.substring(0, last).replace(/\,/g, '');
          benchmark = butLast + benchmark.substring(last);
        }

        var matches = benchmark.match(/(?:.+\-)/);
        if (matches) {
          var secondpart = benchmark.substring(1).replace(/\-/g, '');
          benchmark = benchmark.substring(0, 1) + secondpart;
        }

        // Allow 2 decimals only
        var decimalMatches = benchmark.match(/(?:^\-)?[0-9]*,[0-9]{2}/);
        if (decimalMatches) benchmark = decimalMatches[0];

        // replace comma's for actual balance value
        balance = benchmark.replace(/,/, '.') * 1;

        this.set('benchmark', benchmark);
        this.set('account.balance', balance);
      }
    }).observes('benchmark'),

    multipleTransactions: (function () {
      return this.get('account.latestTransactions.length') > 1;
    }).property('account.latestTransactions.length'),

    latestTransactionDate: (function () {
      return this.get('account.latestTransactions.firstObject.date');
    }).property('account.latestTransactions.length'),

    actions: {}
  });
});