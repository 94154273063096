define('front-end-cli/adapters/application', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].RESTAdapter.extend({
    namespace: 'api/v3',
    headers: {
      // 'API_TOKEN': 'secret key'
    },

    /**
      Overwriting ajaxSucces gets us to the response headers.
    */
    ajaxSuccess: function ajaxSuccess(jqXHR, jsonPayload) {

      var xApiVersion = jqXHR.getResponseHeader('X-APP-VERSION');

      // Wait for the applicationRoute to finish transitioning
      // before sending the `checkApiVersion` action.
      var applicationRoute = this.get('container').lookup('route:application');
      if (applicationRoute.get('initialTransitionComplete')) {
        applicationRoute.send('checkApiVersion', xApiVersion);
      }

      return jsonPayload;
    }
  });
});