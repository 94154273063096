define('front-end-cli/views/profiler/animated-plus-block', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({
    tagName: 'ul',

    classNames: ['add-group'],

    didInsertElement: function didInsertElement() {

      // App.ProfilePlusAnimation.create({view: this});

      /** when profiler is proper module this should go*/
      // App.set('animateProfilerAddBlock', false);
    }
  });
});