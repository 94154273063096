define('front-end-cli/models/income-later-retirement-annuity', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    incomeLater: _emberData['default'].belongsTo('income-later'),

    description: _emberData['default'].attr('string'),
    provider: _emberData['default'].attr('string'),

    annuityType: _emberData['default'].attr('number'),
    purpose: _emberData['default'].attr('number'),
    policyNr: _emberData['default'].attr('string'),
    accountNr: _emberData['default'].attr('string'),
    startDate: _emberData['default'].attr('string'),
    endDate: _emberData['default'].attr('string'),
    singleOrPeriodicInvestment: _emberData['default'].attr('string'),

    // SINGLEINVESTMENT ONLY
    deposit: _emberData['default'].attr('number'),

    // PERIODICINVESTMENT ONLY
    periodicAmount: _emberData['default'].attr('string'),
    periodicInterval: _emberData['default'].attr('string'),

    guaranteedCapitalChoice: _emberData['default'].attr('string'),
    guaranteedCapital: _emberData['default'].attr('number'),
    amountPrognosed: _emberData['default'].attr('number'),
    currentCapital: _emberData['default'].attr('number'),
    insuranceOwner: _emberData['default'].attr('string'),
    beneficiary: _emberData['default'].attr('string'),

    // ANNUITY SAVINGS && SINGLEINVESTMENT ONLY
    paymentWhenDeceasedChoice: _emberData['default'].attr('string'),
    paymentWhenDeceased: _emberData['default'].attr('string'),

    // EXCEPT ANNUITY INSURENCE && PERIODICINVESTMENT
    premiumExemption: _emberData['default'].attr('number'),

    // ONLY SAVINGS && SINGLEINVESTMENT
    balanceWhenDeceased: _emberData['default'].attr('number'),
    isCapital: (function () {
      return this.get('guaranteedCapitalChoice') === 1;
    }).property('guaranteedCapitalChoice')
  });
});