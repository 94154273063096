define('front-end-cli/helpers/money-format', ['exports', 'ember'], function (exports, _ember) {

  /**
    Basic usage:
    ```
      {{money-format value}}
    ```
    This uses the app‘s default accountingjs options
    declared in: `app/initializers/accounting`.
  
    Advanced usage:
    ```
      {{money-format value symbol="$" precision=0 thousand="," decimal="." format="%s%v" negative=false' }}
    ```
    More at: http://openexchangerates.github.io/accounting.js/#documentation
  */

  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(function (value, options) {

    var accnt = accounting.settings.currency;
    var symbol = !_ember['default'].isEmpty(options.hash.symbol) ? options.hash.symbol : accnt.symbol,
        precision = !_ember['default'].isEmpty(options.hash.precision) ? options.hash.precision : accnt.precision,
        thousand = !_ember['default'].isEmpty(options.hash.thousand) ? options.hash.thousand : accnt.thousand,
        decimal = !_ember['default'].isEmpty(options.hash.decimal) ? options.hash.decimal : accnt.decimal,
        format = !_ember['default'].isEmpty(options.hash.format) ? options.hash.format : accnt.format,

    // Force a plus symbol to be rendered when positive
    addPlus = !_ember['default'].isEmpty(options.hash.addPlus) ? options.hash.addPlus : false,

    // Prevent
    noMinus = !_ember['default'].isEmpty(options.hash.noMinus) ? options.hash.noMinus : false;

    var formatObject = {
      pos: format,
      neg: '%s -%v',
      zero: format
    };

    if (addPlus) {
      var plusFormat = {
        pos: format.replace(/%v/, '+%v'),
        zero: format.replace(/%v/, '%v')
      };
      formatObject = _ember['default'].merge(formatObject, plusFormat);
    }
    if (noMinus) {
      var negFormat = {
        neg: format
      };
      formatObject = _ember['default'].merge(formatObject, negFormat);
    }

    return accounting.formatMoney(value, symbol, precision, thousand, decimal, formatObject);
  });
});