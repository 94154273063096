define('front-end-cli/pods/components/transaction-details/splits/splits-pane/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['SplitsPane'],

    storeService: _ember['default'].inject.service('store-service'),

    editingChildTransaction: null,
    splitDetailsComponent: (function () {
      return this;
    }).property(),

    splitsAddUp: null,
    splitsAreValid: true,
    remainingAmount: null,

    // Keeps track of if a split transaction is selecting a subcategory. This is done through the parent component in order to facilitate the correct behavior of the 'annuleren' button.
    isEditDetail: (function () {
      return this.get('detailsComponent.splitsChoosingSubcategory');
    }).property('detailsComponent.splitsChoosingSubcategory'),

    labels: [],

    _createNewSplit: function _createNewSplit(options) {
      if (!options) options = {};
      var amount = options.amount || this.get('remainingAmount');

      var transaction = this.get('transaction');

      return this.get('storeService').store.createRecord('transaction', {
        parentTransaction: transaction,
        name: transaction.get('name'),
        description: transaction.get('description'),
        manDescription: transaction.get('manDescription') || transaction.get('descriptionOriginal'),
        date: transaction.get('date'),
        userDate: transaction.get('userDate'),
        counteraccount: transaction.get('counteraccount'),
        account: transaction.get('account'),
        subcategory: transaction.get('subcategory'),
        enabled: true,
        restore: false
      });
    },

    splitAmountObserver: (function () {
      var parent = this.get('transaction');

      if (!parent) return;

      var parentAmount = parent.get('amount') * 1,
          children = parent.get('childTransactions'),
          childAmounts = _ember['default'].A(),
          childrenAmount = 0,
          hasEmptyDescription = false,
          hasEmptyAmount = false;

      children.forEach(function (child) {
        var childAmount = accounting.toFixed(child.get('amount'), 2) * 1;
        if (child) {
          childAmounts.push(childAmount);
        }

        if (childAmount === 0) {
          hasEmptyAmount = true;
        }

        if (child.get('manDescription') === '') {
          hasEmptyDescription = true;
        }
      });

      if (childAmounts.length) {
        childrenAmount = childAmounts.reduce(function (prevVal, amount) {
          return (prevVal || 0) + (amount || 0);
        });
      }

      childrenAmount = accounting.toFixed(childrenAmount, 2) * 1 || 0;

      this.set('remainingAmount', parentAmount - childrenAmount);

      var splitsAddUp = parentAmount == childrenAmount;
      this.set('splitsAddUp', splitsAddUp);

      var splitsAreValid = children.length === 0 || children.length > 1 && splitsAddUp && !hasEmptyAmount && !hasEmptyDescription;
      this.set('splitsAreValid', splitsAreValid);
      this.set('detailsComponent.splitsAreValid', splitsAreValid);
    }).on('init').observes('transaction.childTransactions.length', 'transaction.childTransactions.@each.amount', 'transaction.childTransactions.@each.manDescription'),

    selectizePlugins: [{ name: 'remove_button', options: { title: 'Verwijderen' } }],
    selectizeItemDiv: function selectizeItemDiv(data, escape) {
      return '<div data-value="' + data.label + '" class="Label">' + data.label + '</div>';
    },
    createNewLabelDiv: function createNewLabelDiv(data, escape) {
      return '<div class="create">Nieuw label <strong>' + escape(data.input) + '</strong> toevoegen</div>';
    },

    /**-------------------------
     Actions
     ---------------------------*/

    actions: {

      doneEditingSplits: function doneEditingSplits() {
        if (this.get('splitsAreValid')) {
          this.get('detailsComponent').send('acceptEditDetail');
        }
      },

      addSplit: function addSplit() {
        var length = this.get('transaction.childTransactions.length');

        // When there where no childTransactions,
        // create 2 and focus the first.
        if (!length) {
          // this._createNewSplit();
        }
        var newChild = this._createNewSplit();

        this.send('normalizeChildren', newChild);

        // Select the amount text in the newly created split
        _ember['default'].run.later(this, function () {
          var lastInput = this.$().find('input').last()[0];
          lastInput.focus();
          lastInput.setSelectionRange(0, lastInput.value.length);
        }, 100);
      },

      normalizeChildren: function normalizeChildren(childTransaction) {

        if (!childTransaction) return;

        var amount = childTransaction.get('amount') * 1 || 0,
            parent = childTransaction.get('parentTransaction'),
            parentAmount = parent.get('amount') * 1,
            children = parent.get('childTransactions'),
            childAmounts = _ember['default'].A(),
            childrenAmount = undefined;

        children.forEach(function (child) {
          childAmounts.push(child.get('amount') * 1);
        });

        if (childAmounts.length) {
          childrenAmount = childAmounts.reduce(function (prevVal, amount) {
            return (prevVal || 0) + (amount || 0);
          });
        } else {
          childrenAmount = 0;
        }

        if (!childrenAmount) childrenAmount = 0;

        var newAmount = Math.round((parentAmount - childrenAmount + amount) * 100) / 100;

        childTransaction.set('amount', newAmount);
      },

      deleteChildTransaction: function deleteChildTransaction(childTransaction) {
        var deletedExistingChildTransactions = this.get('detailsComponent.deletedExistingChildTransactions');

        // If the childTransaction has an ID, it exists server-side. Therefore, if the user wants to save the transaction, the child has to be deleted. For real.
        if (childTransaction.get('id') !== null) {
          childTransaction.set('parentTransaction', null);
          childTransaction.set('noShow', true);
          deletedExistingChildTransactions.pushObject(childTransaction);
          this.set('detailsComponent.deletedExistingChildTransactions', deletedExistingChildTransactions);
        } else {
          childTransaction.deleteRecord();
        }

        if (deletedExistingChildTransactions.length > 0 && this.get('transaction.childTransactions.length') === 0) {
          this.set('transaction.enabled', true);
        }
      },

      chooseChildCategory: function chooseChildCategory(childTransaction) {
        this.set('editingChildTransaction', childTransaction);
        this.set('detailsComponent.splitsChoosingSubcategory', true);
        this.set('editDetail', 'transaction-details/categories/categories-pane');
      },
      cancelEditDetail: function cancelEditDetail() {
        this.set('detailsComponent.splitsChoosingSubcategory', false);
      }
    }
  });
});