define('front-end-cli/models/income-now-retirement-employer', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    incomeNow: _emberData['default'].belongsTo('income-now'),

    description: _emberData['default'].attr('string'),
    provider: _emberData['default'].attr('string'),
    registrationNo: _emberData['default'].attr('string'),
    yearAmount: _emberData['default'].attr('number'),
    ageOrSurvivor: _emberData['default'].attr('string'),
    temporaryChoice: _emberData['default'].attr('string'),
    endAge: _emberData['default'].attr('string'),

    isTemporary: (function () {
      return this.get('temporaryChoice') == 2;
    }).property('temporaryChoice')
  });
});