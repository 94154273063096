define('front-end-cli/models/shareholder', ['exports', 'ember-data', 'front-end-cli/models/income'], function (exports, _emberData, _frontEndCliModelsIncome) {
  exports['default'] = _frontEndCliModelsIncome['default'].extend({
    labor: _emberData['default'].belongsTo('labor'),
    dividendChoice: _emberData['default'].attr('number'),
    dividendOneTimeChoice: _emberData['default'].attr('number'),
    dividendGross: _emberData['default'].attr('number'),
    dividendGross1: _emberData['default'].attr('number'), // year 1
    dividendGross2: _emberData['default'].attr('number'), // year 2
    dividendGross3: _emberData['default'].attr('number'), // year 3

    hasShareholderMiscchoice: (function () {
      var value = this.get('miscchoice');
      return value == 1;
    }).property('miscchoice'),
    isShareholderOneTimeMisc: (function () {
      var value = this.get('onetimemiscchoice');
      return value == 1;
    }).property('onetimemiscchoice'),
    isShareholderRegularMisc: (function () {
      var value = this.get('onetimemiscchoice');
      return value == 2;
    }).property('onetimemiscchoice'),
    hasShareholderDivident: (function () {
      var value = this.get('dividendChoice');
      return value == 1;
    }).property('dividendChoice'),
    isDividendOneTime: (function () {
      var value = this.get('dividendOneTimeChoice');
      return value == 1;
    }).property('dividendOneTimeChoice'),
    isDividendRegular: (function () {
      var value = this.get('dividendOneTimeChoice');
      return value == 2;
    }).property('dividendOneTimeChoice')
  });
});