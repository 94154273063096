define('front-end-cli/pods/components/transaction-details/labels/labels-wrapper/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['LabelDetailPane'],
    storeService: _ember['default'].inject.service('store-service'),

    /**-------------------------
     External Bindings
     ---------------------------*/

    transaction: null,
    labels: [],

    selectizePlugins: [{ name: 'remove_button', options: { title: 'Verwijderen' } }],
    selectizeItemDiv: function selectizeItemDiv(data, escape) {
      return '<div data-value="' + data.label + '" class="Label">' + data.label + '</div>';
    },
    createNewLabelDiv: function createNewLabelDiv(data, escape) {
      return '<div class="create">Nieuw label <strong>' + escape(data.input) + '</strong> toevoegen</div>';
    },

    /**-------------------------
     Actions
     ---------------------------*/

    actions: {
      createLabel: function createLabel(labelName) {
        labelName = labelName.toLowerCase();
        var label = this.get('labels').findBy('name', labelName);

        if (label === undefined) {
          label = this.get('storeService').store.createRecord('label', {
            name: labelName.toLowerCase()
          });

          label.save().then((function () {
            this.get('labels').addObject(label);
            this.get('transaction.labels').addObject(label);
          }).bind(this));
        } else {
          if (this.get('transaction.labels').findBy('id', label.get('id')) === undefined) {
            this.get('transaction.labels').addObject(label);
          }
        }
      }
    }
  });
});