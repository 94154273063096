define('front-end-cli/views/accountmanagement/edit/choose-sync-type', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({
    didInsertElement: function didInsertElement() {
      this.$('.synctype-info').popover({
        trigger: 'hover',
        html: true
      });
    },

    willDestroyElement: function willDestroyElement() {
      this.$('.synctype-info').popover('destroy');
    }
  });
});