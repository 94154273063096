define('front-end-cli/controllers/whatif/retirement', ['exports', 'ember'], function (exports, _ember) {

  /*jshint curly:false, quotmark: false, camelcase:false */

  var _chartConfig = {
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    chart: {
      //type: 'areaspline'
      type: 'column',
      backgroundColor: 'transparent',
      events: {
        load: function load(event) {

          // MAKE CATEGORY CLICK EVENT FIRE EMBER HIGHCHARTYEARSELECT
          var xAxis = this.xAxis[0];
          var onXaxisRedraw = function onXaxisRedraw() {
            _ember['default'].$.each(xAxis.ticks, function (i, tick) {
              if (!tick.label) return;
              var $element = _ember['default'].$(tick.label.element),
                  year = $element.text() * 1;

              $element.off('click');

              $element.on('click', function () {
                _ember['default'].instrument('highchartYearSelect', { year: year });
              });
            });
          };

          onXaxisRedraw();
          xAxis.redraw(onXaxisRedraw);
        }
      }
    },
    xAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      tickWidth: 0,
      // remove space between chart and labels
      minPadding: 0,
      maxPadding: 0,
      // categories: [1999,2000],
      //min: 60,
      //startOnTick: 2022,
      //tickInterval: 1,
      //minPadding: 0,
      //tickmarkPlacement: 'on',
      title: {
        enabled: false
      },
      labels: {
        y: 15,
        formatter: function formatter() {

          //if (this.axis.categories[5] === this.value.year) {
          if (this.value.hasEvent) return '<span class="event-label">' + this.value.year + '</span>';else if (this.value.firstLast) return '<span class="firstlast-label">' + this.value.year + '</span>';else return '<span class="non-event-label">' + this.value.year + '</span>';
        },
        useHTML: true,
        rotation: 55,
        style: {
          fontFamily: '"DIN Next W01 Regular", Helvetica, sans-serif'
        }
      }
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      tickPositions: [-1000, 2000],
      // tickInterval: 100,
      startOnTick: false,
      endOnTick: false,
      minPadding: 0,
      maxPadding: 0,
      minRange: 0,
      maxRange: 0,
      min: -4000,
      max: 4000,
      title: {
        //text: 'Inkomsten/Uitgaven'
        text: ''
      },
      labels: {
        // enabled: false,
        formatter: function formatter() {
          return this.value;
        },
        align: 'right',
        useHTML: true,
        rotation: 0,
        // x: 515,
        x: 26,
        style: {
          position: 'absolute',
          fontFamily: '"DIN Next W01 Regular", Helvetica, sans-serif',
          fontSize: '10px',
          color: '#C6C6C6'
        }
      }
    },
    tooltip: {
      enabled: false,
      shared: false,
      valueSuffix: ' millions',
      formatter: function formatter() {
        var content = this.series.name + " " + this.point.y;
        if (typeof this.point.details != 'undefined') {
          content = content + "<br/> vermogen: " + Highcharts.numberFormat(this.point.details.capital_balance, 0);
        }
        if (this.series.name == "Inkomsten") {
          if (this.point.aow != null) {
            content = content + "<br/>" + "AOW " + Highcharts.numberFormat(Math.abs(this.point.aow) / 12, 0);
          }
        }
        return content;
      }
    },
    plotOptions: {
      animation: false,
      column: {
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        marginLeft: 0,
        marginRight: 0,
        events: {
          legendItemClick: function legendItemClick() {
            return false;
          }
        }
      },
      series: {
        cursor: 'pointer',
        animation: false,
        allowPointSelect: false,
        states: {
          hover: {
            enabled: false
          }
        },
        point: {
          events: {
            /* When clicking/tapping columns*/
            click: function click(e) {
              _ember['default'].instrument("highchartYearSelect", { year: this.category.year });
            }
          }
        },
        events: {
          click: function click(event) {}
        },
        lineWidth: 3,
        stacking: 'normal',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        }
      }
    },

    series: [{
      // EXTRACTED FROM CAPITAL
      color: 'rgb(144, 197, 245)', // a bit lighter than @capital_blue
      stacking: 'normal',
      states: {
        select: {
          enabled: false
        }
      },
      data: []
    }, {
      name: 'Inkomsten',
      color: '#3d82c6',
      states: {
        select: {
          enabled: false
        }
      },
      data: []
    }, //data: []
    {
      name: 'Uitgaven',
      title: {
        text: 'dkdkdk'
      },
      color: '#D3D3D3',
      states: {
        select: {
          enabled: false
        }
      },
      data: []
    }, {
      name: 'Saldo',
      type: 'spline',
      data: [],
      color: 'rgb(58, 58, 58)',
      // color: '#ffaa45',
      // negativeColor: '#f00',
      // color: 'transparent',
      // negativeColor: 'transparant'
      negativeColor: '#ffaa45'
    }]
  };

  /**
    Extension/plugin for Highcharts to make it
    bubble mouse events out of its container.
  
    Added to make modals close on highchart
    click events.
  */
  (function (Highcharts) {
    "use strict";

    Highcharts.wrap(Highcharts.Pointer.prototype, 'onContainerClick', function (original, e) {
      var pointer = this,
          parent = pointer.chart.container.parentNode,
          bubbleUp = true;

      // Add a method to the event to allow event handlers to prevent propagation if desired
      e.swallowByHighCharts = function () {
        bubbleUp = false;
      };

      // Call the original event
      original.apply(this, Array.prototype.slice.call(arguments, 1));

      // Trigger the event on the container's parent (to bubble the event out of highcharts)
      // unless the user wanted to stop it
      if (bubbleUp && typeof parent !== 'undefined' && parent) {
        // TODO: remove when no further issues arise.
        // This gave an error on Safari and its purpose is unclear to me:
        // TypeError: undefined is not a function (evaluating 'type.indexOf(".")'
        // Ember.$(parent).trigger(e);
      }
    });
  })(Highcharts);

  exports['default'] = _ember['default'].Controller.extend({

    application: _ember['default'].inject.controller(),
    profiler: _ember['default'].inject.controller(),
    user: _ember['default'].computed.alias('application.model'),

    // PROPERIES
    years: null, // model
    requiredData: null,
    activeWizard: null,
    selectedYear: null,
    selectedYearObject: null,
    partnerBirthYear: null,
    chart: null,
    hasPartner: null,
    hasCapital: null,
    msg: null,

    // NATIVE
    init: function init() {

      var _this = this;
      _ember['default'].subscribe('highchartYearSelect', {
        before: function before(name, timestamp, payload) {
          if (payload) _this.set('selectedYear', payload.year);
        },
        after: function after(name, timestamp, payload) {}
      });

      this._super();
    },

    // METHODS
    getDemoData: function getDemoData() {

      var postData = {
        user: {
          birthDate: '1979-1-31',
          hasPartner: 0,
          retirements: [{
            age: 70,
            annual_amount: 13000.00,
            for_life: 1
          }],
          annuities: [{
            initial_amount: 120000.00,
            contract_end_year: 2060
          }],
          yearlyGrossIncome: 42000,
          expectedExtraCapital: 60000,
          expectedMonthlyExpenditure: 2300.00
        }
      };

      this.set('requiredData', true);
      if (this.get('activeWizard') === null) this.set('activeWizard', false);

      this.fetchRetirementData(postData);
    },

    parsePostData: function parsePostData() {

      var userProfile = this.get('currentUser.model.profile'),
          partnerProfile = this.get('currentUser.model.partnerProfile'),
          profiles = [userProfile, partnerProfile],
          postData = {},
          _this = this;

      profiles.forEach(function (profile, i) {

        var key = i === 0 ? 'user' : 'partner';

        // ONLY FILL IN THE PARTNER PROPERTIES IF WE HAVE ONE SET
        if (key == 'partner' && postData['user'].hasPartner === 0) {
          return;
        }
        postData[key] = {};

        //if (typeof profile.get('personal.birthDate') != 'undefined' && profile.get('personal.birthDate') != '')
        postData[key].birthDate = profile.get('personal.birthDate');
        var bdate;
        if (userProfile.get('personal.civilState') > 1 && typeof partnerProfile.get('personal.birthDate') != 'undefined') {
          postData[key].hasPartner = 1;
          _this.set('hasPartner', true);
        } else {
          postData[key].hasPartner = 0;
          _this.set('hasPartner', false);
        }

        var o1 = typeof profile.get('incomeLater.aowBeenAbroadChoice') != 'undefined',
            o2 = profile.get('incomeLater.aowBeenAbroadChoice') == 1,
            o3 = !isNaN(profile.get('incomeLater.aowAbroadYears'));

        if (o1 && o2 && o3) {
          postData[key].taxYearsAbroad = profile.get('incomeLater.aowAbroadYears');
        }

        // CURRENT CAPITAL
        if (profile.get('financialMeansMoney.length')) {
          var currentCapital = 0;
          profile.get('financialMeansMoney').forEach(function (account, i) {
            currentCapital += account.get('balance') * 1;
          });
          if (currentCapital) postData[key].currentCapital = currentCapital;
        }

        // SOME UBER BASIC VALIDATION TO AVOID
        // ERROR MESSAGES FROM THE SERVER

        /*
          PHP
          $l_iStartYear = $l_aRetirementPlan['age'] + $l_iBirthYear;
          if ( ( !$l_aRetirementPlan['for_life']) && ($l_iStartYear >= $l_aRetirementPlan['expiration_date'])) {
            $l_aErrorMessages[] = "Eindleeftijd van pensioen ligt voor beginleeftijd pensioen.";
          }
        */

        /*
          this.get('currentUser.model.profile.incomeLater.incomeLaterRetirementEmployer').forEach(function(rtr) {
            // console.log(rtr.toJSON());
            var birthYear = this.get('currentUser.model.profile.personal.birthDate').match(/\d{4}/)[0];
            var startYear = rtr.get('age')? rtr.get('age') + birthYear : 0;
            var endYear = rtr.get('endAge')? rtr.get('endAge') + birthYear : 0;
             if(startYear && endYear && rtr.get('endAgeChoice') == 2 && (startYear >= endYear)) {
              alert('resetting endAge('+rtr.get('endAge')+')');
              // reset endAge
              rtr.set('endAge', null);
            }
          }, this);
        */

        // EMPLOYER RETIREMENTS
        if (profile.get('incomeLater.incomeLaterRetirementEmployer.length')) {
          var retirements = [];
          profile.get('incomeLater.incomeLaterRetirementEmployer').forEach(function (r) {
            var birthDate = parseInt(profile.get('personal.birthDate').substring(0, 4));
            var rtrmnt = {};

            if (r.get('retirementAge') != null && r.get('amountByRetirement') != null && r.get('endAgeChoice') != null) {
              rtrmnt.age = r.get('retirementAge') + 48;
              rtrmnt.annual_amount = r.get('amountByRetirement');
              rtrmnt.for_life = r.get('endAgeChoice') == 1 ? 1 : 0;
              if (r.get('endAgeChoice') != 1 && r.get('endAge') != null) {
                rtrmnt.expiration_date = birthDate + r.get('endAge') + 48;
              }
            }
            retirements.push(rtrmnt);
          });
          if (retirements.length) postData[key].retirements = retirements;
        }

        // ANNUITIES
        if (profile.get('incomeLater.incomeLaterRetirementAnnuity.length')) {
          var annuities = [];
          profile.get('incomeLater.incomeLaterRetirementAnnuity').forEach(function (a) {
            var annuity = {};
            if ((a.get('amountPrognosed') != null || a.get('guaranteedCapital') != null) && a.get('endDate') != null) {
              annuity.initial_amount = a.get('guaranteedCapitalChoice') == 1 ? a.get('guaranteedCapital') : a.get('amountPrognosed');
              annuity.contract_end_year = a.get('endDate').substring(0, 4);
            }
            annuities.push(annuity);
          });
          if (annuities.length) postData[key].annuities = annuities;
        }

        // INCOME
        if (profile.get('incomeNow.labors')) {
          var yearlyGrossIncome = 0;
          profile.get('incomeNow.labors').forEach(function (labor) {

            if (labor.get('isEmployed') && labor.get('employed.grossannual')) {
              // employed
              yearlyGrossIncome += labor.get('employed.grossannual') * 1;
            } else if (labor.get('is_thisemployed') && labor.get('_thisemployed.profitEstimate')) {
              // _thisemployed
              yearlyGrossIncome += labor.get('_thisemployed.profitEstimate') * 1;
            } else if (labor.get('isShareholder') && labor.get('shareholder.grossannual')) {
              // shareholder
              yearlyGrossIncome += labor.get('shareholder.grossannual') * 1;
            }
          });
          if (yearlyGrossIncome) postData[key].yearlyGrossIncome = yearlyGrossIncome;
        }

        // EXPECTED EXTRA CAPITAL
        if (profile.get('incomeLater.capitals')) {
          var expectedExtraCapital = 0;
          profile.get('incomeLater.capitals').forEach(function (c) {
            if (c.get('yearAmount')) expectedExtraCapital += c.get('yearAmount') * 1;
          });
          if (expectedExtraCapital) postData[key].expectedExtraCapital = expectedExtraCapital;
        }

        // EXTRA INCOME AFTER RETIREMENT
        if (profile.get('incomeLater.labors')) {
          var incomes = [];
          profile.get('incomeLater.labors').forEach(function (labor) {

            var yearAmount = 0;

            if (labor.get('isEmployed') && labor.get('employed.grossannual')) {
              // employed
              yearAmount = labor.get('employed.grossannual') * 1;
            } else if (labor.get('is_thisemployed') && labor.get('_thisemployed.profitEstimate')) {
              // _thisemployed
              yearAmount = labor.get('_thisemployed.profitEstimate') * 1;
            } else if (labor.get('isShareholder') && labor.get('shareholder.grossannual')) {
              // shareholder
              yearAmount = labor.get('shareholder.grossannual') * 1;
            }

            if (yearAmount && labor.get('endAge')) {
              incomes.push({
                year_amount: yearAmount,
                end_age: labor.get('endAge') + 48
              });
            }
          });
          if (incomes.length) postData[key].extraIncomeAfterRetirement = incomes;
        }

        // MONTHLY EXPENSES
        if (typeof profile.get('personal.monthlyExpectedExpenses') != 'undefined') {
          postData[key].expectedMonthlyExpenditure = profile.get('personal.monthlyExpectedExpenses');
        }
      });
      return postData;
    },

    /**
      Fetch retirement data from server
    */
    fetchRetirementData: function fetchRetirementData(postData) {

      //////////////////////////////////
      // REQUEST WHATIF-RETIREMENT JSON

      var _this = this;

      _ember['default'].$.ajax({
        url: '/app/what-if/retirement',
        data: postData,
        type: 'post',
        dataType: 'json',
        success: function success(response) {

          // TEMP ERROR HANDLING
          if (response.error && response.error.length) {
            var msg = '';
            _ember['default'].$.each(response.error, function (i, error) {
              msg += error + '\n';
            });
            if (_this.get('msg') != msg) {
              if (typeof console == "object") console.error(msg);
              _this.set('msg', msg);
            }
            return false;
          } else _this.processRetirementData(response);
        },
        error: function error(j, textStatus) {
          alert('Unexpected ajax response (' + textStatus + ')');
        }
      });
    },

    processRetirementData: function processRetirementData(response) {

      var income = [],
          expense = [],
          capitalextaction = [],
          balance = [],
          yearAxis = [],
          selectedNonEventYear,
          selectedEventYear,
          min = 0,
          max = 0,
          _this = this;

      // CREATE SERIES ARRAYS
      var years = response.years || [];

      // LOOP ALL YEARS IN RESPONSE
      _ember['default'].$.each(years, function (i, year) {

        /////////////////////////////////
        // CREATE HICHARTS SERIES ARRAYS

        // LEADING YEARS
        if (i === 0 || i == 1) {
          income.push({ y: year.income, color: '#d3e0ea', details: year.details });
          expense.push({ y: year.expense, color: '#e5e5e5' });
        }
        // TRAILING YEARS
        else if (i == response.years.length - 1 || i == response.years.length - 2) {
            income.push({ y: year.income, color: '#d3e0ea', details: year.details });
            expense.push({ y: year.expense, color: '#e5e5e5' });
          } else {
            income.push({ y: year.income, details: year.details });
            expense.push(year.expense);
          }

        capitalextaction.push({ y: year.details.capital_compensation || 0 });
        balance.push({ y: year.balance + 35 });

        ////////////////////////////////////////////////
        // ADD EVENTS TO YEARS AND DEFINE SELECTED YEAR

        // USE FISRT YEAR WHEN NO EVENT YEARS ARE FOUND
        if (i === 2) selectedNonEventYear = year.year;

        // APPEND EVENTS
        var hasEvent = false;

        if (i < years.length - 1 && typeof response.events[year.year] != 'undefined') {

          // SELECT FIRST YEAR WITH EVENTS ON INIT
          if (!selectedEventYear) selectedEventYear = year.year;

          year.events = response.events[year.year];
          hasEvent = true;
        } else year.events = false;

        year.index = i;

        ////////////////////////////////////
        // ADD YEAR TO HIGHCHART CATEGORIES
        var length = response.years.length,
            firstLast = i === 0 || i == 1 || i >= length - 2 ? true : false;

        yearAxis.push({ year: year.year, hasEvent: hasEvent, firstLast: firstLast });

        // STORE MIN AN MAX VALUES (EXTREMES)
        max = year.income > max ? year.income : max;
        min = year.expense < min ? year.expense : min;
      });

      // DEFINE SERIES
      _chartConfig.series[0].data = capitalextaction;
      _chartConfig.series[1].data = income;
      _chartConfig.series[2].data = expense;
      _chartConfig.series[3].data = balance;

      // DEFINE YEAR LABELS
      _chartConfig.xAxis.categories = yearAxis;

      // SET EXTREME (ABSOLUTE) ON CHART
      var extreme = Math.abs(min) < max ? max : Math.abs(min);
      _chartConfig.yAxis.min = extreme * -1;
      _chartConfig.yAxis.max = extreme;

      // SET Y LABEL VALUES ON CHART
      _chartConfig.yAxis.tickPositions = [min, '', max];

      // BUILD CHART
      var chart = _ember['default'].$('#whatif_chart').highcharts(_chartConfig);
      _this.set('chart', chart.highcharts());

      // SET CONTROLLER MODEL
      _this.set('years', years);

      // CHECK IF THERE IS A SELECTEDYEAR SET AND IF IT EXCIST
      var excisting = _this.get('selectedYear') && years.filterProperty('year', _this.get('selectedYear')).get('firstObject');

      // SET SELECTED YEAR, IF NOT SET OR NON EXCISTING
      if (!_this.get('selectedYear') || !excisting) {
        _this.set('selectedYear', selectedEventYear || selectedNonEventYear);
      } else _this.setChartSelection();

      // OUTPUT DEBUG INFO (TEMP!)
      _ember['default'].$('#debuginfo').html(response.debug);
    },

    keyNav: (function () {
      var w = 37; //TODO DEGLOBALIZE App.get('keydown');
      var inputFocus = /input/i.test(document.activeElement.tagName);
      if (inputFocus) return;

      // 37 left arrow
      if (w == 37) this.send('previousYear');
      // 39 right arrow
      else if (w == 39) this.send('nextYear');
        // 40 down arrow
        else if (w == 40) this.send('nextEvent');
          // 38 up arrow
          else if (w == 38) this.send('previousEvent');
    }).observes(), //'App.keydown'

    /**
      updateModel when plus changes
    */
    plusObserver: (function (attribute) {
      this.send('updateModel');
    }).observes('currentUser.model.state.plus'),

    actions: {

      updateModel: function updateModel() {

        if (!this.get('currentUser.model.state.plus')) {
          return false;
        }

        ///////////////////////////////////////////////
        // PARSE ANALYSE FINANCIAL INTO POSTDATA OBJECT

        var postData = this.parsePostData();

        var postDataString = JSON.stringify(postData, function (key, value) {
          return typeof value === 'function' ? value() : value;
        });

        ////////////////////
        // DEFINE PROPERTIES

        // DETERMINE IF REQUIRED DATA IS GIVEN
        var personal = this.get('currentUser.model.profile.personal'),
            birthDate = personal.get('birthDate'),
            expense = personal.get('monthlyExpectedExpenses'),
            partnerRequirements = personal.get('civilState') == 1 || personal.get('birthDate') !== false && personal.get('birthDate') != null;

        var requiredData = birthDate && expense && partnerRequirements;
        this.set('requiredData', requiredData === true);

        if (this.get('activeWizard') === null) this.set('activeWizard', requiredData !== true);

        // STOP WHEN NO DATA
        if (!requiredData) return false;

        // WAS THERE ANY CAPITAL FILLED OUT
        var c1 = postData.user.expectedExtraCapital,
            c2 = postData.partner && postData.partner.expectedExtraCapital,
            c3 = postData.user.currentCapital,
            c4 = postData.partner && postData.partner.currentCapital,
            hasCapital = c1 || c2 || c3 || c4;

        this.set('hasCapital', hasCapital);

        this.fetchRetirementData(postData);
      },

      previousYear: function previousYear() {
        var current = this.get('selectedYear'),
            prev = current - 1 >= this.get('years.firstObject.year') ? current - 1 : current;

        this.set('selectedYear', prev);
      },

      nextYear: function nextYear() {
        var current = this.get('selectedYear'),
            next = current + 1 <= this.get('years.lastObject.year') ? current + 1 : current;

        this.set('selectedYear', next);
      },

      nextEvent: function nextEvent(fallback) {
        var nextUp,
            nextEventYear,
            current = this.get('selectedYear');

        this.get('years').forEach(function (year) {
          if (!nextEventYear) {
            if (nextUp && year.events) nextEventYear = year.year;else if (year.year == current) nextUp = true;
          }
        });
        if (nextEventYear) this.set('selectedYear', nextEventYear);else if (fallback) this.send('nextYear');
      },

      previousEvent: function previousEvent(fallback) {
        var prev,
            previousEventYear,
            current = this.get('selectedYear');

        this.get('years').forEach(function (year) {
          if (!previousEventYear) {
            if (prev && year.year == current) previousEventYear = prev.year;else if (year.events) prev = year;
          }
        });
        if (previousEventYear) this.set('selectedYear', previousEventYear);else if (fallback) this.send('previousYear');
      },

      toggleProfilerWizard: function toggleProfilerWizard() {

        var state = this.get('activeWizard');
        this.set('activeWizard', !state);

        // SAVE IF HIDING THE WIZARD
        if (state) this.get('profiler').send('saveProfile');
      }
    },

    setChartSelection: (function (year) {

      if (!this.get('years')) return false;

      // SET CURRENT YEAR OBJECT (FOR DETAILS)
      var current = this.get('selectedYear'),
          yearObject = this.get('years').filterProperty('year', current).get('firstObject');

      if (!yearObject) return false;

      this.set('selectedYearObject', yearObject);

      // SET SELECTION IN HIGHCHART
      var chart = this.get('chart');

      _ember['default'].$.each(chart.xAxis[0].categories, function (i, cat) {
        // SELECT
        if (current == cat.year) {
          var gridGutter = 10,
              leftOffset = _ember['default'].$('#whatif-retirement .container').offset().left + gridGutter;
          // SELECTION ARROW PLACEMENT
          var $selectedElement = _ember['default'].$(chart.series[1].data[i].graphic.element);
          _ember['default'].$('#whatif_selection_arrow').css({
            left: $selectedElement.offset().left - leftOffset,
            display: 'block'
          }).children('div').css({
            width: $selectedElement.attr('width')
          });
        }
      });
    }).observes('selectedYear'),

    // CALCULATED PROPERTIES
    hasActiveEventlist: (function () {
      if (this.get('requiredData') === null) return false;else if (this.get('requiredData') !== true) return false;else return this.get('activeWizard') !== true;
    }).property('activeWizard', 'requiredData'),

    hasActiveWizard: (function () {
      if (this.get('requiredData') === null) return false;else if (this.get('requiredData') !== true) return true;else return this.get('activeWizard') === true;
    }).property('activeWizard', 'requiredData'),

    hasActiveIntro: (function () {
      if (this.get('requiredData') === null) return false;else return !this.get('requiredData');
    }).property('requiredData'),

    hasRequiredData: (function () {
      return this.get('requiredData');
    }).property('requiredData')

  });
});