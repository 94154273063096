define('front-end-cli/models/subcategorybudget', ['exports', 'ember-data', 'ember', 'front-end-cli/models/base-category'], function (exports, _emberData, _ember, _frontEndCliModelsBaseCategory) {
  exports['default'] = _frontEndCliModelsBaseCategory['default'].extend({
    categorybudget: _emberData['default'].belongsTo('categorybudget'),
    budgets: _emberData['default'].attr('pojo'),
    isGenerated: _emberData['default'].attr('boolean'),
    year: _emberData['default'].attr('number'),
    enabled: _emberData['default'].attr('boolean'),
    type: _emberData['default'].attr('number'),
    /**
      1. if no cache, create  cache
      2. else if budgets differ from cache, return dirty
       CLEAR CACHE ON SAVE!
    */
    budgetsAreDirty: _ember['default'].computed('budgets.@each.amount', 'budgetsCache', function () {

      var isDirty = false;

      var budgetsCache = this.get('budgetsCache');
      var budgets = this.get('budgets');

      var invalidateBudgetCache = this.get('invalidateBudgetCache');

      if (invalidateBudgetCache) {
        budgetsCache = false;
        this.set('invalidateBudgetCache', null);
      }

      if (!budgetsCache) {
        budgetsCache = _ember['default'].$.extend(true, [], budgets);
        this.set('budgetsCache', budgetsCache);
      } else {

        budgets.forEach(function (b, i) {
          var cache = budgetsCache[i];
          if (b.amount != cache.amount) {
            b.isDirty = true;
            isDirty = true;
          } else {
            b.isDirty = false;
          }
        });
      }
      return isDirty;
    }),

    budgetsSum: _ember['default'].computed('budgets.@each.amount', function () {
      var total = 0;
      this.get('budgets').forEach(function (budget) {
        total += budget.amount * 1;
      });
      return total;
    })
  });
});