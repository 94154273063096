define('front-end-cli/pods/components/timeline-today/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'section',
    classNames: ['TimelineToday'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    categories: null,
    latestUpdate: null,
    transitionAction: null, // action
    resetCategory: null,

    /**-------------------------
      Properties
    ---------------------------*/

    expectedIncome: null,
    realizedIncome: null,
    remainingIncome: null,
    incomeBudgetPercentage: null,
    remainingBudgetPercentage: null,

    expectedExpenses: null,
    realizedExpenses: null,
    remainingExpenses: null,
    expensesBudgetPercentage: null,
    remainingExpensesBudgetPercentage: null,

    //API
    totalSpent: null,
    totalBudget: null,
    available: null,
    projectedSpend: null,
    trafficLight: null,
    trafficLightColor: null,

    activeBudgetWarning: null,

    realizedResult: (function () {
      // 1000 + -1100 = -100
      // 1100 + -1000 = 100
      return this.get('realizedIncome') + this.get('realizedExpenses');
    }).property(),

    /**-------------------------
      Methods
    ---------------------------*/

    setApiTotals: (function () {
      var totalSpent = 0;
      var totalBudget = 0;
      var available = 0;
      var projectedSpend = 0;
      var trafficLight = 'Green';
      var trafficLightColor = '#00000';
      var _this = this;

      //Fetch expense details from the API
      _ember['default'].$.get('api/v3/timeline', function (data) {
        totalSpent = data.periodTracker.totalSpent;
        totalBudget = data.periodTracker.totalBudget;
        available = data.periodTracker.available;
        projectedSpend = data.periodTracker.projectedSpend;
        trafficLight = data.periodTracker.trafficLight.name;
        trafficLightColor = data.periodTracker.trafficLight.color;

        if (available < 0) {
          available = 0;
        } else if (available > 0) {
          available = -1 * available;
        }
        if (projectedSpend > 0) {
          projectedSpend = 0;
        }
      }).then(function () {
        _this.setProperties({
          totalSpent: totalSpent,
          totalBudget: totalBudget,
          available: available,
          projectedSpend: projectedSpend,
          trafficLight: trafficLight,
          trafficLightColor: trafficLightColor
        });
      });
    }).on('init'),

    calculateTotals: (function () {

      var expectedIncome = 0,
          realizedIncome = 0,
          expectedExpenses = 0,
          realizedExpenses = 0,
          expectedAverageExpenses = 0,
          expectedAverageIncome = 0;
      // Add up income and expenses from all categories
      this.get('categories').filterBy('enabled', true).forEach(function (category) {

        var averageNetted = category.get('averageNetted');
        var expectedNetted = category.get('expectedNetted');
        var currentNetted = category.get('currentNetted');

        if (averageNetted < 0) {
          expectedAverageExpenses += averageNetted;
        } else if (averageNetted > 0) {
          expectedAverageIncome += averageNetted;
        }

        if (expectedNetted > 0) {
          expectedIncome += expectedNetted;
        } else {
          expectedExpenses += expectedNetted;
        }

        if (currentNetted > 0) {
          realizedIncome += currentNetted;
        } else {
          realizedExpenses += currentNetted;
        }
      });

      var incomeExpectationIsAvergage = false,
          expensesExpectationIsAvergage = false;

      // If we have no budget we’ll warn the user
      if (!expectedExpenses || !expectedIncome) {
        this.set('activeBudgetWarning', true);
      }

      if (!expectedExpenses) {
        expectedExpenses = expectedAverageExpenses;
        expensesExpectationIsAvergage = true;
      }
      if (!expectedIncome) {
        expectedIncome = expectedAverageIncome;
        incomeExpectationIsAvergage = true;
      }

      var canUseBudgets = this.get('canUseBudgets');

      // If user doesn't have access to budgets, set it to average
      if (!canUseBudgets) {
        incomeExpectationIsAvergage = true;
        expensesExpectationIsAvergage = true;
        expectedIncome = expectedAverageIncome;
        expectedExpenses = expectedAverageExpenses;
      }

      // What is the remaining income expectation
      var remainingIncome = expectedIncome - realizedIncome;
      // Income received percentage
      var incomeBudgetPercentage = expectedIncome ? Math.round(100 - remainingIncome * 100 / expectedIncome) : 0;
      // Calculate the remaining budget
      var remainingBudgetPercentage = 100 - incomeBudgetPercentage;
      if (remainingBudgetPercentage < 0) {
        remainingBudgetPercentage = 0;
      } else if (remainingBudgetPercentage > 100) {
        remainingBudgetPercentage = 100;
      }
      // Cap
      if (remainingIncome < 0) {
        // this.set('incomeOverBudget', Math.abs(remainingIncome));
        remainingIncome = 0;
      }

      // What is the remaining expenses expectation
      var remainingExpenses = expectedExpenses - realizedExpenses;
      // Expenses spend percentage
      var expensesBudgetPercentage = expectedExpenses ? Math.round(100 - remainingExpenses * 100 / expectedExpenses) : 0;
      // Caluculate remaning percentage
      var remainingExpensesBudgetPercentage = 100 - expensesBudgetPercentage;
      if (remainingExpensesBudgetPercentage < 0) {
        remainingExpensesBudgetPercentage = 0;
      } else if (remainingExpensesBudgetPercentage > 100) {
        remainingExpensesBudgetPercentage = 100;
      }

      // Cap
      if (remainingExpenses > 0) {
        // this.set('expensesOverBudget', -Math.abs(remainingExpenses));
        remainingExpenses = 0;
      }

      this.setProperties({
        expectedIncome: expectedIncome,
        realizedIncome: realizedIncome,
        remainingIncome: remainingIncome,
        incomeBudgetPercentage: incomeBudgetPercentage,
        remainingBudgetPercentage: remainingBudgetPercentage,
        incomeExpectationIsAvergage: incomeExpectationIsAvergage,

        expectedExpenses: expectedExpenses,
        realizedExpenses: realizedExpenses,
        remainingExpenses: 0,
        expensesBudgetPercentage: expensesBudgetPercentage,
        remainingExpensesBudgetPercentage: 0,
        expensesExpectationIsAvergage: expensesExpectationIsAvergage.valueOf()

      });
    }).on('init').observes('categories.@each.currentNetted'),

    /**
      When inserting the view, center the `timelineToday` component
    */
    setScrollTop: (function () {

      var todayTop = this.$().offset().top,
          windowHeight = _ember['default'].$(window).height(),
          scrollHeight = _ember['default'].$(document).height();

      var targetTop = todayTop - 35 - 100;

      // When the scrollHeight is too small to
      // fit the targetted top, we’ll use the
      // maximum available height
      if (scrollHeight - todayTop - 30 < windowHeight) {
        targetTop = scrollHeight - windowHeight - 30;
      }

      _ember['default'].$('html').velocity('scroll', {
        duration: 700,
        offset: targetTop,
        delay: 100,
        easing: [200, 20]
      });
    }).on('didInsertElement'),

    /**-------------------------
      Calculated Properties
    ---------------------------*/

    /**
      `latestUpdate` contains the date at which any
      of the users accounts was last updated.
       It is used to set a warning when the `Timeline` might
      not be fully up to date (via `outDatedWarning`).
       And it is used to `block the page with the notice
      there is no data` when the latest account update was
      before the current period (via outOfScopeWarning).
    */
    outDatedWarning: null,
    outOfScopeWarning: null,

    latestUpdateObserver: (function () {

      var latestUpdate = moment(this.get('latestUpdate'), 'YYYY-MM-DD'),
          withinPeriod,
          timeAgo;

      if (latestUpdate.isBefore(moment().startOf('month'))) {
        withinPeriod = false;
      } else {
        withinPeriod = true;
      }
      // withinPeriod = false;// DEBUG

      this.set('outOfScopeWarning', !withinPeriod);

      if (moment().diff(latestUpdate, 'days') >= 1 && withinPeriod) {
        timeAgo = latestUpdate.fromNow();
      } else {
        timeAgo = null;
      }
      this.set('outDatedWarning', timeAgo);
    }).on('init').observes('latestUpdate'),

    isOverBudget: _ember['default'].computed('expensesBudgetPercentage', function () {
      var expensesPercentage = this.get('expensesBudgetPercentage');
      return expensesPercentage > 100 && expensesPercentage < 110;
    }),

    isWayOverBudget: _ember['default'].computed('expensesBudgetPercentage', function () {
      var expensesPercentage = this.get('expensesBudgetPercentage');
      return expensesPercentage >= 110;
    }),

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {

      transitionToIncome: function transitionToIncome() {
        this.set('resetCategory', true);
        this.sendAction('transitionAction', 'income-expenses.income', { queryParams: { periode: null, filterLabelsString: null } });
      },

      transitionToExpenses: function transitionToExpenses() {
        this.set('resetCategory', true);
        this.sendAction('transitionAction', 'income-expenses.expenses', { queryParams: { periode: null, filterLabelsString: null } });
      },

      transitionToMonthBudget: function transitionToMonthBudget() {
        this.sendAction('transitionAction', 'budget', { queryParams: { year: null, month: null } });
      }
    }
  });
});