define('front-end-cli/controllers/whatif/retirement/details', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    // NATIVE
    needs: ['application', 'whatif/retirement'],

    // PROPERTIES
    detailed: null,

    // ACTIONS
    actions: {
      toggleDetailed: function toggleDetailed() {
        var detailed = JSON.parse(localStorage.getItem('WhatifRetirementDetailed'));
        localStorage.setItem('WhatifRetirementDetailed', !detailed);
        this.set('detailed', !detailed);
      }
    },

    // CALCULATED PROPERTIES
    userAge: (function () {
      var userBirthDate = this.get('currentUser.model.profile.personal.birthDate');
      if (!userBirthDate) return '';
      return this.get('controllers.whatif/retirement.selectedYear') - userBirthDate.substring(0, 4);
    }).property('controllers.whatif/retirement.selectedYear', 'currentUser.model.profile.personal.birthDate'),

    partnerAge: (function () {
      var partnerBirthDate = this.get('currentUser.model.partnerProfile.personal.birthDate');
      if (!partnerBirthDate) return '';else return this.get('controllers.whatif/retirement.selectedYear') - partnerBirthDate.substring(0, 4);
    }).property('controllers.whatif/retirement.selectedYear', 'currentUser.model.partnerProfile.personal.birthDate'),

    showPartnerAge: (function () {
      var userHasPartner = this.get('currentUser.model.profile.personal.civilState') > 1,
          partnerBirthDate = this.get('currentUser.model.partnerProfile.personal.birthDate');
      return userHasPartner && partnerBirthDate;
    }).property('currentUser.model.profile.personal.civilState', 'currentUser.model.partnerProfile.personal.birthDate'),

    isActive: (function () {
      return this.get('controllers.whatif/retirement.selectedYear') == this.get('model.year');
    }).property('controllers.whatif/retirement.selectedYear'),

    isDetailed: (function () {
      if (this.get('detailed') === null) {
        return JSON.parse(localStorage.getItem('WhatifRetirementDetailed'));
      } else return this.get('detailed');
    }).property('detailed'),

    isNegative: (function () {
      return this.get('balance') < 0;
    }).property('balance')
  });
});