define('front-end-cli/pods/budget/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    budget: _ember['default'].inject.controller(),

    // The (sub)category wich is active
    selectedCategory: null,

    hasNoBudgets: _ember['default'].computed('model', function () {
      var hasBudgets = false;
      var categories = this.get('model') || [];
      categories.forEach(function (cat) {
        if (!hasBudgets && cat.get('expectedNetted')) {
          hasBudgets = true;
        }
      });
      return !hasBudgets;
    }),

    hasBudgets: _ember['default'].computed('model', function () {
      var hasNoBudgets = false;
      var categories = this.get('model') || [];
      categories.forEach(function (cat) {
        if (!hasNoBudgets && cat.get('expectedNetted')) {
          hasNoBudgets = false;
        }
      });
      return !hasNoBudgets;
    }),

    incomeCategories: _ember['default'].computed('model', function () {
      var categories = this.get('model').filter(function (cat) {
        var isIncome = cat.get('expectedNetted') > 0;
        var isBudget = !!cat.get('expectedNetted');

        return isIncome && isBudget;
      });

      return categories.sortBy('progress');
    }),

    expensesCategories: _ember['default'].computed('model', function () {
      var categories = this.get('model').filter(function (cat) {
        var isExpense = cat.get('expectedNetted') < 0;
        var isBudget = !!cat.get('expectedNetted');

        return isExpense && isBudget;
      });

      return categories.sortBy('progress').reverse();
    })

  });
});