define('front-end-cli/pods/components/category-select/component', ['exports', 'ember', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _ember, _emberKeyboardShortcutsMixinsComponent) {
  exports['default'] = _ember['default'].Component.extend(_emberKeyboardShortcutsMixinsComponent['default'], {
    tagName: 'div',
    classNames: ['CategorySelect'],

    /**-------------------------
      External bindings
    ---------------------------*/

    categories: null,
    category: null,
    subcategory: null,
    showSelect: false,
    modal: null,
    type: null,

    // Action
    categoryWasSelected: null,
    // Optional, we’ll pass this to the
    // `category-select-li` component so it
    // can prevent selecting
    // non-subcategories.
    isSubcategorySelectionOnly: null,
    optionAddSubcats: false,

    /**-------------------------
      Properties
    ---------------------------*/

    categorySearchTerm: null,
    leaveTimer: null,
    highlitCategory: null,
    highlitSubcategory: null,
    preventEnter: false,
    costType: null,
    showAllCategoriesAsEnabled: false,

    /**-------------------------
      Calculated properties
    ---------------------------*/

    enabledCategories: (function () {
      return this.get('showAllCategoriesAsEnabled') ? this.get('categories') : this.get('categories').filterBy('enabled', true);
    }).property('categories.@each.id'),

    // returns a combined array of all categories and subcategories
    catAndSubcatUnion: (function () {
      var a = [];
      this.get('enabledCategories').forEach(function (cat) {
        a.pushObject(cat);
        cat.get('subcategories').filterBy('enabled', true).forEach(function (subcat) {
          a.pushObject(subcat);
        }, this);
      }, this);
      return a;
    }).property('enabledCategories'),

    searchResults: (function () {

      if (!this.get('categorySearchTerm')) return;

      var results = [],
          reg = new RegExp('^' + this.get('categorySearchTerm'), 'i'),
          reg2 = new RegExp(this.get('categorySearchTerm'), 'i'),
          allSubcats,
          cap = 11,
          count = 0,
          isSubcategorySelectionOnly = this.get('isSubcategorySelectionOnly');

      // First find entries which start with the search-query
      this.get('enabledCategories').forEach(function (cat) {

        if (reg.test(cat.get('name')) && count < cap) {
          if (!isSubcategorySelectionOnly) {
            results.pushObject(cat);
            count++;
          }
          allSubcats = true;
        } else {
          allSubcats = false;
        }

        cat.get('subcategories').filterBy('enabled', true).forEach(function (subcat) {
          if (reg.test(subcat.get('name')) || allSubcats === true && count < cap) {
            results.pushObject(subcat);
            count++;
          }
        });
      });

      // If there still is room add mid-string matches, excluding duplicated
      if (count < cap) {
        this.get('enabledCategories').forEach(function (cat) {
          if (reg2.test(cat.get('name')) && count < cap) {
            if (!isSubcategorySelectionOnly) {
              results.pushObject(cat);
              count++;
            }
          }
          cat.get('subcategories').filterBy('enabled', true).forEach(function (subcat) {
            if (reg2.test(subcat.get('name')) && count < cap) {
              results.pushObject(subcat);
              count++;
            }
          });
        });
      }

      // Highlight the first result
      if (results.length) {
        this.set('preventEnter', false);
        var firstResult = results[0];
        if (firstResult && firstResult.get('subcategories')) {
          if (!this.get('isSubcategorySelectionOnly')) {
            this.set('highlitCategory', firstResult);
          }
        } else {
          this.set('highlitSubcategory', firstResult);
        }
      } else {
        this.set('preventEnter', true);
      }

      return results.uniq();
    }).property('enabledCategories', 'categorySearchTerm', 'isSubcategorySelectionOnly'),

    // When no subcat is selected
    activeSubcategoryIndex: (function () {
      var active = false;
      if (this.get('category.name') && !this.get('subcategory.name')) {
        if (this.get('category.name') == this.get('highlitCategory.name')) {
          active = true;
        }
      }
      return active;
    }).property('highlitCategory', 'category', 'subcategory'),

    // When switching costTypes, verify that the selected category has subcategories in that type
    subcategoriesBelongToCurrentFilters: (function () {
      var subcategory = this.get('subcategory');
      var highlitCategory = this.get('highlitCategory');
      var belong = true;
      // Only in this case may belong be false
      if (subcategory == null && highlitCategory != null) {
        belong = false;
        this.get('enabledCategories').forEach(function (cat) {
          if (cat.get('name') == highlitCategory.get('name')) {
            belong = true;
          }
        });
      }
      return belong;
    }).property('categories', 'highlitCategory', 'costType'),

    /**-------------------------
      Observers
    ---------------------------*/

    setInitialHighlight: _ember['default'].on('init', function () {
      _ember['default'].run.next(this, function () {
        if (this._state !== 'destroying') {
          if (this.get('category')) {
            this.set('highlitCategory', this.get('category'));
          } else if (this.get('isSubcategorySelectionOnly')) {
            this.set('highlitCategory', this.get('enabledCategories').get('firstObject'));
          } else {
            this.set('highlitCategory', null);
            this.set('categoryIndexIsHighlit', true);
          }
        }
      });
    }),

    setHeight: (function () {
      /* Put the function in the run.next loop, because otherwise
       * the height of the containers might not have been defined correctly yet. (e.g. when hovering 'alle categorieën')
       */
      _ember['default'].run.next(this, function () {
        if (!bowser.msie) {
          var modalOverflow = this.$('.CategorySelect-modalOverflow'),
              maxHeight = modalOverflow.css('maxHeight'),
              height = undefined;

          height = this.$('.CategorySelect-categoryContainer').outerHeight();

          if (this.get('highlitCategory') !== null) {
            var candidateHeight = this.$('.CategorySelect-subcategoryContainer').outerHeight();

            if (this.get('optionAddSubcats')) {
              candidateHeight += 35;
            }

            if (candidateHeight > height) {
              height = candidateHeight;
            }
          }

          height += this.$('.CategorySelect-searchContainer').outerHeight();
          height += 1; // Why add 1? If we don't, the modalOverflow quickly adds a scrollbar when the highlitcategory is set, to remove it when the run.next loop is ran.

          if (height > maxHeight) {
            modalOverflow.css({ height: maxHeight });
          } else {
            modalOverflow.css({ height: height });
          }
        }

        if (this.$().parents('.DetailsPane.CategoriesPane')) {
          this.$('.CategorySelect-modalOverflow').css({
            maxHeight: this.$().parents('.DetailsPane.CategoriesPane').height()
          });

          if (bowser.msie) {
            this.$('.CategorySelect-modalOverflow').css({
              height: this.$().parents('.DetailsPane.CategoriesPane').height()
            });
          }
        }
      });
    }).observes('highlitCategory', 'categorySearchTerm'),

    resetInitialHighlight: _ember['default'].observer('category', 'showSelect', function () {
      _ember['default'].run.next(this, function () {
        this.set('categorySearchTerm', null);

        if (this.get('showSelect')) {
          // Set the maxHeight for the categoryselect in the IncomeExpensesHeader when opening it.
          if (this.$().hasClass('IncomeExpensesHeader-categorySelect')) {
            var _innerHeight = _ember['default'].$(window).innerHeight(),
                scrollTop = _ember['default'].$('#income-expenses').scrollTop(),
                modalTop = this.$('.CategorySelect-modalOverflow').offset().top;

            var modal = this.$('.CategorySelect-modalOverflow');
            modal.css({
              maxHeight: _innerHeight - modalTop - 8
            });
            if (bowser.msie) {
              modal.css({
                height: _innerHeight - modalTop - 8
              });
            } else {
              _ember['default'].run.once(this, 'setHeight');
            }
          }
          if (this.get('costType')) {
            if (_ember['default'].$('#CostType-' + this.get('costType')).length) {
              _ember['default'].$('#CostType-' + this.get('costType')).attr('checked', 'checked');
            }
          }
        }
      });
    }),

    resetCategoryIndex: (function () {
      // if(this.get('showSelect')) {
      //   this.send('highlightCategoryIndex');
      // }
    }).observes('showSelect'),

    categoryIndexStateClass: _ember['default'].computed('category', 'highlitCategory', function () {
      var clss = '';
      if (!this.get('category')) clss += 'is-active';
      if (this.get('highlitCategory')) clss += clss ? ' is-highlit' : 'is-highlit';
      return clss;
    }),

    subcategoryIndexStateClass: _ember['default'].computed('subcategoryIndexIsHighlit', 'activeSubcategoryIndex', function () {
      var clss = '';
      if (!this.get('subcategoryIndexIsHighlit')) clss += 'is-highlit';
      if (this.get('activeSubcategoryIndex')) clss += clss ? ' is-active' : 'is-active';
      return clss;
    }),

    unsetCategoryIndex: (function () {
      if (this.get('highlitCategory')) {
        this.set('categoryIndexIsHighlit', false);
      }
      if (this.get('highlitSubcategory')) {
        this.set('subcategoryIndexIsHighlit', false);
      }
    }).observes('highlitCategory', 'highlitSubcategory'),

    unsetSubcategoryHighlight: (function () {
      if (this.get('highlitSubcategory')) {
        this.set('highlitSubcategory', false);
      }
    }).observes('highlitCategory'),

    /**
      When a category is chosen we’ll
      close the category-select, and reset
      the searchTerm.
    */
    categorySelectedObserver: (function () {
      // Make sure other mousedown listeners
      // run before removing it from the DOM.
      _ember['default'].run.next(this, function () {
        if (this._state !== 'destroying') {
          // cat-select might be destroyed already

          this.set('categorySearchTerm', null);
          this.sendAction('categoryWasSelected');
          if (this.get('modal')) {
            // Some selections do not want to close the modal
            var keepShowSelect = this.get('keepShowSelect');
            if (keepShowSelect) {
              this.set('keepShowSelect', false);
            } else {
              this.set('showSelect', false);
            }
          }
        }
      });
    }).observes('category', 'subcategory'),

    /**-------------------------
      Custom event listeners
    ---------------------------*/

    keyPressResponder: function keyPressResponder(e) {

      if (!this.get('showSelect') || this.get('showAddSubcategory')) {
        return;
      }

      var regex = new RegExp('^[a-zA-Z0-9]+$');
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(str)) {

        if (!this.get('categorySearchTerm')) {
          this.set('categorySearchTerm', str);
        }
        this.$('.CategorySelect-searchInput').focus();
        return true;
      }
    },

    keyDownResponder: function keyDownResponder(e) {

      if (!this.get('showSelect')) {
        return;
      }
      // escape key
      if (e.which === 27) {
        // close
        this.set('showSelect', false);
        e.preventDefault();
      }
      // // down key
      // else if (e.which === 40) {
      //   this.send('selectNext');
      //   e.preventDefault();
      // }
      // // up key
      // else if (e.which === 38) {
      //   this.send('selectPrev');
      //   e.preventDefault();
      // }
      // right arrow key
      // else if (e.which === 39) {
      //   this.send('enterSelectedCategory');
      //   e.preventDefault();
      // }
      // // left arrow key
      // else if (e.which === 37) {
      //   this.send('leaveSelectedCategory');
      //   e.preventDefault();
      // }
      // // return key
      // else if (e.which === 13) {
      //   this.send('activateSelected');
      //   e.preventDefault();
      // }
    },

    mouseDownResponder: function mouseDownResponder(e) {
      // Hide select when the event-target
      // was outside the component.

      if (!this.get('showSelect')) return;

      var notWithinCategorySelect = !_ember['default'].$(e.target).parents('.CategorySelect').length,
          onCurtain = _ember['default'].$(e.target).hasClass('PeriodSelect-curtain'),
          notOnTitle = !_ember['default'].$(e.target).parents('.CategorySelect-title');

      if (onCurtain || notOnTitle && notWithinCategorySelect) {
        // Make sure other mousedown listeners
        // run before removing it from the DOM.
        _ember['default'].run.next(this, function () {
          this.set('showSelect', false);
        });
      }
    },

    setKeyListeners: (function () {
      this.kpr = this.keyPressResponder.bind(this);
      this.kdr = this.keyDownResponder.bind(this);

      _ember['default'].$(document).on('keypress', this.kpr);
      _ember['default'].$(document).on('keydown', this.kdr);
    }).on('didInsertElement'),

    setMouseListeners: (function () {
      this.mdr = this.mouseDownResponder.bind(this);

      _ember['default'].$(document).on('mousedown touchend', this.mdr);
    }).on('didInsertElement'),

    /**-------------------------
      Tear down
    ---------------------------*/

    removeEventListeners: (function () {
      _ember['default'].$(document).off('keypress', this.kpr);
      _ember['default'].$(document).off('keydown', this.kdr);
      _ember['default'].$(document).off('mousedown touchend', this.mdr);
    }).on('willDestroyElement'),

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {

      toggleVisibility: function toggleVisibility() {
        this.toggleProperty('showSelect');
      },

      hideSelectIfModal: function hideSelectIfModal() {
        if (this.get('modal')) {
          this.set('showSelect', false);
        }
      },

      // Arrow-key navigation
      selectNext: function selectNext() {
        if (this.get('categorySearchTerm')) {
          this.send('_selectPrevOrNextST', 'next');
        } else if (this.get('highlitSubcategory') || this.get('subcategoryIndexIsHighlit')) {
          this.send('_selectPrevOrNextSub', 'next');
        } else {
          this.send('_selectPrevOrNext', 'next');
        }
      },

      selectPrev: function selectPrev() {
        if (this.get('categorySearchTerm')) {
          this.send('_selectPrevOrNextST', 'prev');
        } else if (this.get('highlitSubcategory') || this.get('subcategoryIndexIsHighlit')) {
          this.send('_selectPrevOrNextSub', 'prev');
        } else {
          this.send('_selectPrevOrNext', 'prev');
        }
      },

      _selectPrevOrNext: function _selectPrevOrNext(direction) {
        var categories = this.get('enabledCategories'),
            sorted = direction === 'next' ? categories : categories.toArray().reverse(),
            advancedSelection = false;

        if (this.get('highlitCategory') === null) {
          advancedSelection = true;
        } else {
          var nextIndex = sorted.indexOf(this.get('highlitCategory')) + 1;

          if (nextIndex < sorted.length) {
            this.set('highlitCategory', sorted.objectAt(nextIndex));
          } else {
            advancedSelection = true;
          }
        }

        if (advancedSelection) {
          if (this.get('isSubcategorySelectionOnly')) {
            this.set('highlitCategory', sorted.get('firstObject'));
          } else {
            if (this.get('categoryIndexIsHighlit')) {
              this.set('categoryIndexIsHighlit', false);
              this.set('highlitCategory', sorted.get('firstObject'));
            } else {
              this.set('highlitCategory', null);
              this.set('categoryIndexIsHighlit', true);
            }
          }
        }
      },

      _selectPrevOrNextSub: function _selectPrevOrNextSub(direction) {
        var categories = this.get('highlitCategory.subcategories').filterBy('enabled', true),
            sorted = direction === 'next' ? categories : categories.toArray().reverse(),
            advancedSelection = false;

        if (this.get('highlitSubcategory') === null) {
          advancedSelection = true;
        } else {
          var nextIndex = sorted.indexOf(this.get('highlitSubcategory')) + 1;

          if (nextIndex < sorted.length) {
            this.set('highlitSubcategory', sorted.objectAt(nextIndex));
          } else {
            advancedSelection = true;
          }
        }

        if (advancedSelection) {
          if (this.get('isSubcategorySelectionOnly')) {
            this.set('highlitSubcategory', sorted.get('firstObject'));
          } else {
            if (this.get('subcategoryIndexIsHighlit')) {
              this.set('subcategoryIndexIsHighlit', false);
              this.set('highlitSubcategory', sorted.get('firstObject'));
            } else {
              this.set('highlitSubcategory', null);
              this.set('subcategoryIndexIsHighlit', true);
            }
          }
        }
      },

      _selectPrevOrNextST: function _selectPrevOrNextST(direction) {
        var categories = this.get('searchResults'),
            deviation = direction === 'next' ? 1 : -1,
            nextIndex;

        if (this.get('highlitCategory') !== null && categories.indexOf(this.get('highlitCategory')) !== undefined) {
          nextIndex = categories.indexOf(this.get('highlitCategory')) + deviation;
        } else if (this.get('highlitSubcategory') !== null && categories.indexOf(this.get('highlitSubcategory')) !== undefined) {
          nextIndex = categories.indexOf(this.get('highlitSubcategory')) + deviation;
        } else {
          nextIndex = 0;
        }

        if (nextIndex > categories.length - 1) {
          nextIndex = 0;
        } else if (nextIndex < 0) {
          nextIndex = categories.length - 1;
        }

        var selectedCategory = categories.objectAt(nextIndex);

        if (selectedCategory.get('subcategories')) {
          this.set('highlitSubcategory', null);
          this.set('highlitCategory', selectedCategory);
        } else {
          this.set('highlitCategory', null);
          this.set('highlitSubcategory', selectedCategory);
        }
      },

      // Select the first item in the subcategory of the current categorie.
      // Triggerend when pressing right-arrow.
      enterSelectedCategory: function enterSelectedCategory() {
        // Select the subcategories index item
        this.set('subcategoryIndexIsHighlit', true);
        if (this.get('isSubcategorySelectionOnly')) {
          this.send('selectNext');
        }
      },

      // Unselect the subcategorie
      // Triggerend when pressing left-arrow.
      leaveSelectedCategory: function leaveSelectedCategory() {
        this.set('subcategoryIndexIsHighlit', null);
        this.set('highlitSubcategory', null);
      },

      // Load the selected category/subcategory
      // Triggerend when pressing return.
      activateSelected: function activateSelected() {
        if (this.get('highlitSubcategory')) {
          this.set('subcategory', this.get('highlitSubcategory'));
          this.set('highlitSubcategory', null);
        } else if (this.get('highlitCategory')) {
          if (this.get('isSubcategorySelectionOnly')) return;

          this.set('category', this.get('highlitCategory'));
          this.set('highlitCategory', null);
          this.set('subcategory', null);
        } else if (this.get('categoryIndexIsHighlit')) {
          this.send('setCategoryIndex');
        }
      },

      highlightCategoryIndex: function highlightCategoryIndex() {
        this.set('categoryIndexIsHighlit', true);
        this.set('highlitCategory', null);
      },

      setCategoryIndex: function setCategoryIndex() {
        if (this.get('isSubcategorySelectionOnly')) return;

        this.set('category', null);
        this.set('subcategory', null);
      },

      highlightSubcategoryIndex: function highlightSubcategoryIndex() {
        // this.set('subcategoryIndexIsHighlit', true); // done via hover or keyevent
        this.set('highlitSubcategory', null);
      },
      setSubcategoryIndex: function setSubcategoryIndex(highlitCategory) {
        this.set('category', highlitCategory);
        this.set('subcategory', null);
      },

      chooseCategory: function chooseCategory() {
        if (this.get('isSubcategorySelectionOnly')) return;

        this.set('category', this.get('highlitCategory'));
        this.set('subcategory', null);
      },

      chooseSubcategory: function chooseSubcategory() {

        // Search-result calls need their `selectedCategoryName` to be set first.
        // (The `catAndSubcatUnion` property adds this extra category attribute to
        // our subcategories. It'd be nicer if subcats can retrieve this relation
        // from their model)
        if (!this.get('highlitCategory')) {}
        // var parentCategory = this.get('catAndSubcatUnion').findBy('name', this.get('selectedSubcategoryName'));
        // this.set('selectedCategoryName', parentCategory.get('category.name'));

        // this.set('category', this.get('selectedCategory'));
        this.set('subcategory', this.get('highlitSubcategory'));
      },

      showAddSubcategory: function showAddSubcategory() {
        this.set('showAddSubcategory', true);
      },

      hideAddSubcategory: function hideAddSubcategory() {
        this.set('newSubcategoryName', null);
        this.set('showAddSubcategory', false);
      },

      addSubcategory: function addSubcategory() {

        var newSubcategoryName = this.get('newSubcategoryName'),
            validNewSubcategoryName = this.get('validNewSubcategoryName'),
            highlitCategory = this.get('highlitCategory');

        if (validNewSubcategoryName && highlitCategory) {
          this.sendAction('addSubcategory', newSubcategoryName, highlitCategory);
          // Reset
          this.set('newSubcategoryName', null);
          this.set('showAddSubcategory', false);
        }
      },

      setCostType: function setCostType(type) {
        var subcategory = this.get('subcategory');
        if (type != 'all' && subcategory != null) {
          this.set('keepShowSelect', true);
          this.set('subcategory', null);
        }
        this.set('costType', type);
        this.get('container').lookup('route:income-expenses').send('setCostTypeAction', type);
      },
      resetCostType: function resetCostType() {
        this.set('costType', null);
        this.get('container').lookup('route:income-expenses').send('setCostTypeAction', null);
        _ember['default'].$('input[type=radio][name=CostType]:checked').removeAttr('checked');
      }
    },

    newSubcategoryName: null,
    validNewSubcategoryName: _ember['default'].computed('newSubcategoryName', function () {
      var _this = this;

      // Check if subcategory name is already
      // added in selected category.
      var newSubcategoryName = this.get('newSubcategoryName'),
          newSubRgx = new RegExp('^' + newSubcategoryName + '$', 'i'),
          valid = true;

      if (!newSubcategoryName) return false;

      this.get('categories').forEach(function (cat) {
        cat.get('subcategories').forEach(function (subcategory) {
          if (newSubRgx.test(subcategory.get('name')) && cat.get('id') == _this.get('category.id')) {
            valid = false;
          }
        });
      });

      return valid;
    }),

    keyboardShortcuts: {
      'up': function up(e) {
        if (this.get('showSelect')) {
          this.send('selectPrev');
          return false;
        }
      },
      'down': function down() {
        if (this.get('showSelect')) {
          this.send('selectNext');
          return false;
        }
      },
      'right': function right() {
        if (this.get('showSelect')) {
          if (this.get('highlitCategory') !== null && this.get('highlitCategory.subcategories.length') > 0) {
            this.send('enterSelectedCategory');
          }
          return false;
        }
      },
      'left': function left() {
        if (this.get('showSelect')) {
          this.send('leaveSelectedCategory');
          return false;
        }
      },
      'enter': function enter(e) {

        if (this.get('preventEnter') === true) {
          return true;
        }

        if (this.get('showAddSubcategory')) {
          return true;
        }

        if (this.get('showSelect')) {
          this.send('activateSelected');
          return false;
        }
      }
    }
  });
});