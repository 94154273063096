define('front-end-cli/pods/components/transaction-details/related/related-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'transaction-detail-row',
    classNames: ['TransactionDetails-row', 'TransactionDetails-rowOptional', 'RelatedRow'],
    classNameBindings: ['isFilledOutOrEditing', 'isEditing', 'relatedTransactionsCount::NonEeditableRow'],

    hasRelatedTransactions: (function () {
      return this.get('transaction.relatedTransactions.length');
    }).property('transaction.relatedTransactions.length'),

    relatedTransactionsCount: (function () {
      return this.get('transaction.relatedTransactions.length');
    }).property('transaction.relatedTransactions.length'),

    actions: {
      toEditDetail: function toEditDetail() {
        if (this.get('relatedTransactionsCount')) {
          this.sendAction('toEditDetail', 'related/related-pane');
        }
      }
    }
  });
});