define('front-end-cli/views/application/sidebaraccounts', ['exports', 'ember', 'front-end-cli/mixins/window-resize'], function (exports, _ember, _frontEndCliMixinsWindowResize) {
  exports['default'] = _ember['default'].View.extend(_frontEndCliMixinsWindowResize['default'], {
    elementId: 'sidebaraccounts',
    templateName: 'application/sidebaraccounts',
    resizeTimeout: null,
    // classNameBindings: ['isFourPlus:four-plus'],
    // isFourPlus: function() {
    /*
    Add a down arrow at bottom which triggers an event
    which enlarges the height of the sidebaraccounts section
    so all accounts fit.
     When enlarged, the trigger becomes reduce trigger
     This should be remembered so users can choose to show
    all by default, as long as it fits.
     If the sidebaraccounts-section become higher than the
    window (or beyond main-menu-items) make it scrollable.
    */
    // return this.get('currentUser.model.accounts').length > 4;
    // }.property('currentUser.model.accounts.length'),

    setHeight: (function () {
      /*
      <nav>'s bottom should allways comply to `sidebaraccounts`
      height so sidebar scrolling kick in accurately. I don't know
      a CSS way to handle this, so we handle it here.
      */
      if (!this.$()) return; // model triggers before inserted

      var sidebarAccounts = this.$(),
          accountList = sidebarAccounts.find('ul:first');

      // makes sure height immediately responds to dom insertion
      sidebarAccounts.css({ height: '' });

      sidebarAccounts.css({ height: '' });
      accountList.css({ height: '' });

      var h,
          headerHeight = accountList.css('top').replace(/px/i, '') * 1 || 37,
          footerHeight = 26;

      // When sidebaraccount pane is larger than half the window
      if (sidebarAccounts.height() > _ember['default'].$(window).height() / 2) {

        h = _ember['default'].$(window).height() / 2 - headerHeight - footerHeight;
        accountList.css({ height: h });
        h += headerHeight + 2 + 26;
      } else {
        h = sidebarAccounts.outerHeight();
      }

      _ember['default'].$('#sidebar-scroll-container').css({ bottom: h });
      sidebarAccounts.css({ height: h });
    }).on('didInsertElement'),

    /**
      This will fire multiple times when unloading the accounts
      via the applicationRoutes instrument call. :(
    */
    accountsLengthObserver: (function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.setHeight();
      });
    }).observes('controller.currentUser.model.accounts'),

    /**
      The windowResizeMixin will trigger this and will cause
      the `setHeight` method to trigger after resize is done
      (to prevent glitches).
    */
    windowResize: function windowResize() {

      if (this.get('resizeTimeout')) {
        _ember['default'].run.cancel(this.get('resizeTimeout'));
      }

      var runLater = _ember['default'].run.later(this, function () {
        this.setHeight();
      }, 300);

      _ember['default'].run.cancel(this.get('resizeTimeout'));

      this.set('resizeTimeout', runLater);
    },

    didInsertElement: function didInsertElement() {
      this.get('controller').on('showModalMist', this, this.showModalMist);
      this.get('controller').on('hideModalMist', this, this.hideModalMist);
    },

    willClearRender: function willClearRender() {
      this.get('controller').off('showModalMist', this, this.showModalMist);
      this.get('controller').off('hideModalMist', this, this.hideModalMist);
    },

    /**
      When any modal is closed, we will drop
      any `active` class in our modalTriggers.
    */
    modalObserver: (function () {

      if (this.$() && !this.get('controller.application.modal')) {
        this.$().find('.active-modaltrigger').removeClass('active-modaltrigger');
        this.hideModalMist();
      }
    }).observes('controller.application.modal'),

    showModalMist: function showModalMist() {

      if (!_ember['default'].$('#modal-mist').length) {

        _ember['default'].$('#sidebar-triangle').addClass('misty');

        _ember['default'].$('#app > main').append(_ember['default'].$('<div/>', {
          id: 'modal-mist'
        }));

        _ember['default'].$('#modal-mist').animate({
          opacity: 0.16
        }, 500, function () {
          _ember['default'].$('#main-outlet').addClass('blur');
        });
      }
    },

    hideModalMist: function hideModalMist() {

      if (_ember['default'].$('#modal-mist').length) {

        _ember['default'].$('#sidebar-triangle').removeClass('misty');

        _ember['default'].$('#modal-mist').animate({
          opacity: 0
        }, 280, function () {
          _ember['default'].$(this).remove();
        });

        _ember['default'].$('#main-outlet').removeClass('blur');
      }
    }
  });
});