define('front-end-cli/models/income-now', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    profile: _emberData['default'].belongsTo('profile'),

    wwChoice: _emberData['default'].attr('string'),
    wiaChoice: _emberData['default'].attr('string'),
    anwChoice: _emberData['default'].attr('string'),

    socialSecurityWw: _emberData['default'].belongsTo('social-security-ww'),
    socialSecurityWia: _emberData['default'].belongsTo('social-security-wia'),
    socialSecurityAnw: _emberData['default'].belongsTo('social-security-anw'),

    insuranceAo: _emberData['default'].belongsTo('insurance-ao'),

    aowChoice: _emberData['default'].attr('number'),
    aowPeriodicAmount: _emberData['default'].attr('number'),

    incomeNowRetirementEmployer: _emberData['default'].hasMany('income-now-retirement-employer'),
    incomeNowRetirementAnnuity: _emberData['default'].hasMany('income-now-retirement-annuity'),

    capitals: _emberData['default'].hasMany('capital'),

    labors: _emberData['default'].hasMany('labor'),

    // used when renderering labor.hbs
    isNow: (function () {
      return true;
    }).property()
  });
});