define('front-end-cli/pods/components/category-dissect/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['CategoryDissect'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    type: null, // income or expenses, readonly
    categories: null,
    category: null,
    subcategory: null,
    preferences: null,
    // Set in incomeExpensesBaseController
    // according to selectedPeriod
    periodType: null,
    lastUpdateIsBeforeStartDate: null,
    hasNoTransactions: null,
    hasNoEnabledTransactions: null,

    /**-------------------------
      Properties
    ---------------------------*/

    collapsed: _ember['default'].computed.alias('preferences.categoryDissectCollapsed'),
    preview: null,
    showDetails: null,

    filteredCategories: (function () {

      var type = this.get('type');

      var filteredCategories = this.get('categories').filterBy('enabled', true).filter(function (category) {
        var expectedNetted = category.get('expectedNetted');
        var currentNetted = category.get('currentNetted');

        if (type === 'income') {
          // Include all categories with a positive budget or else
          // for categories with a positive netted value
          // if (expectedNetted != null) {
          //   if (expectedNetted > 0) {
          //     return true;
          //   }
          // } else
          if (currentNetted != null) {
            if (currentNetted > 0) {
              return true;
            }
          }
        } else if (type === 'expenses') {
          // Include all categories with a negative budget or else
          // for categories with a negative netted value.

          // Since expectedNetted and currentNetted have a `null`
          // value when there are no transactions, we'll include `0`
          // values assuming they coincidentally have a total of `0`.
          // if (expectedNetted != null) {
          //   if (expectedNetted < 0 || expectedNetted === 0) {
          //     return true;
          //   }
          // } else
          if (currentNetted != null) {
            if (currentNetted < 0) {
              //|| currentNetted === 0
              return true;
            }
          }
        }
      });

      // Sort the filtered categories by currentNetted
      var sortedFilteredCategories = filteredCategories.sortBy('currentNetted');
      // Reverse the sorting when we're in the
      // income version of this component.
      if (type == 'income') {
        sortedFilteredCategories = sortedFilteredCategories.reverse();
      }

      return sortedFilteredCategories;
    }).property('categories.[]', 'type'),

    periodTypeObserver: (function () {
      var isQuarter = this.get('periodType') === 2;
      var isYear = this.get('periodType') === 3;
      var isMonth = this.get('periodType') === 1,
          showDetails = !this.get('collapsed') && isMonth || !this.get('collapsed') && isYear || !this.get('collapsed') && isQuarter;

      this.setProperties({
        showDetailsToggle: isMonth || isYear || isQuarter,
        showDetails: showDetails
      });
    }).on('init').observes('collapsed', 'periodType'),

    showDetailsToggle: (function () {
      return this.get('periodType') === 1 || this.get('periodType') === 2 || this.get('periodType') === 3;
    }).property('periodType'),

    isIncome: (function () {
      if (!this.get('currentUser.model.state.budgets')) {
        this.set('collapsed', true);
      }
      return this.get('type') === 'income';
    }).property('type'),

    isExpenses: (function () {
      if (!this.get('currentUser.model.state.budgets')) {
        this.set('collapsed', true);
      }
      return this.get('type') === 'expenses';
    }).property('type'),

    totalCurrentNettedArray: _ember['default'].computed.mapBy('filteredCategories', 'currentNetted'),
    totalCurrentNetted: _ember['default'].computed.sum('totalCurrentNettedArray'),

    currentNettedSum: (function () {

      var value;
      if (this.get('preview')) {
        value = this.get('preview.currentNetted');
      } else {
        if (this.get('subcategory')) {
          value = this.get('subcategory.currentNetted');
        } else if (this.get('category')) {
          value = this.get('category.currentNetted');
        } else {
          value = this.get('totalCurrentNetted');
        }
      }
      return value;
    }).property('category', 'subcategory', 'preview', 'totalCurrentNetted'),

    currentNettedSumFormatted: _ember['default'].computed('currentNettedSum', function () {
      return accounting.formatMoney(this.get('currentNettedSum'));
    }),

    currentNettedSumPercentage: _ember['default'].computed('currentNettedSum', function () {
      if (this.get('currentNettedSum') === 0 && this.get('totalCurrentNetted') === 0) {
        return 0;
      } else {
        var percentage = this.get('currentNettedSum') * 100 / this.get('totalCurrentNetted');
        return Math.round(percentage * 10) / 10;
      }
    }),

    isClickable: (function () {
      return this.get('category') !== null || this.get('subcategory') !== null;
    }).property('category', 'subcategory'),

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {

      toggleDetails: function toggleDetails() {
        // You can only toggle details when
        // `income-expenses` has periodType 1 (month)
        //2020-01-05: Added periodType 3 (year) where its possible to toggle.
        if (this.get('periodType') == 1 || this.get('periodType') == 2 || this.get('periodType') == 3) {
          this.toggleProperty('showDetails');
          // save the state in localStorage
          this.set('collapsed', !this.get('showDetails'));
        }
      },

      unsetCategory: function unsetCategory() {
        if (!this.get('subcategory')) {
          this.set('category', null);
        }
        this.set('subcategory', null);
      }
    }
  });
});