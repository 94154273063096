define('front-end-cli/controllers/automatic-import/psd2-information', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    application: _ember['default'].computed.alias('accountmanagement'),

    canNotAddMoreAccounts: (function () {
      var isOverAccountLimit = this.get('currentUser.model.accounts').filterBy('isCashAccount', false).filter(function (item, index, enumerable) {
        return item.get('locked') !== true;
      }).get('length') >= parseInt(this.get('currentUser.model.state.amountOfAccounts'));
      return isOverAccountLimit;
    }).property('currentUser.model.accounts.length'),

    featureFeeIsAboveZero: (function () {
      var featureFee = this.get('currentUser.model.state.psd2Fee');
      return parseFloat(featureFee) > 0;
    }).property('currentUser.model.state.psd2Fee'),

    pfpUrl: (function () {
      var pfpUrl = this.get('currentUser.model.state.pfpUrl');
      return pfpUrl + '/abonnementen';
    }).property('currentUser.model.state.pfpUrl')

  });
});