define("front-end-cli/pods/nibud/edit/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "LoadingBackground");
          dom.setAttribute(el1, "class", "vertical-centered-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "vertical-centered-content");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-sm-offset-3 col-sm-6");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("img");
          dom.setAttribute(el5, "src", "/images/application/loading_diap.gif");
          dom.setAttribute(el5, "alt", "loading");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 12
              },
              "end": {
                "line": 22,
                "column": 12
              }
            },
            "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element26 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element26, 'value');
            morphs[1] = dom.createMorphAt(element26, 0, 0);
            return morphs;
          },
          statements: [["attribute", "value", ["concat", [["get", "category.value"]]]], ["content", "category.label"]],
          locals: ["category"],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 24
                },
                "end": {
                  "line": 42,
                  "column": 24
                }
              },
              "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "content");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element20 = dom.childAt(fragment, [1]);
              var element21 = dom.childAt(element20, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element21, 'data-id');
              morphs[1] = dom.createMorphAt(element21, 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element20, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "data-id", ["concat", [["get", "item.id"]]]], ["content", "item.title"], ["inline", "nibud/action-renderer", [], ["item", ["subexpr", "@mut", [["get", "item"]], []], "isNibudCategory", true, "updateCategory", "updateCategory", "icons", ["subexpr", "@mut", [["get", "data.subCategoriesTable.table.icons"]], []]]]],
            locals: ["item"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 12
              },
              "end": {
                "line": 46,
                "column": 12
              }
            },
            "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "Nibud-UserCategoriesTable");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "content");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "right");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "content sign");
            var el3 = dom.createElement("object");
            dom.setAttribute(el3, "width", "40");
            dom.setAttribute(el3, "height", "40");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "Nibud-NibudCategoriesTable");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element22 = dom.childAt(fragment, [1]);
            var element23 = dom.childAt(element22, [1, 1, 1]);
            var element24 = dom.childAt(element23, [0]);
            var element25 = dom.childAt(element22, [3, 0]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element24, 'data-id');
            morphs[1] = dom.createMorphAt(element24, 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element23, [1]), 0, 0);
            morphs[3] = dom.createAttrMorph(element25, 'data');
            morphs[4] = dom.createMorphAt(dom.childAt(element22, [5, 1]), 1, 1);
            return morphs;
          },
          statements: [["attribute", "data-id", ["concat", [["get", "subcat.id"]]]], ["content", "subcat.title"], ["inline", "nibud/action-renderer", [], ["item", ["subexpr", "@mut", [["get", "subcat"]], []], "updateCategory", "updateCategory", "icons", ["subexpr", "@mut", [["get", "data.subCategoriesTable.table.icons"]], []]]], ["attribute", "data", ["concat", [["get", "data.subCategoriesTable.table.icons.subCategories"]]]], ["block", "each", [["get", "subcat.linkedItems"]], [], 0, null]],
          locals: ["subcat"],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 24
                },
                "end": {
                  "line": 53,
                  "column": 24
                }
              },
              "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "content");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element14 = dom.childAt(fragment, [1]);
              var element15 = dom.childAt(element14, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element15, 'data-id');
              morphs[1] = dom.createMorphAt(element15, 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element14, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "data-id", ["concat", [["get", "item.id"]]]], ["content", "item.title"], ["inline", "nibud/action-renderer", [], ["item", ["subexpr", "@mut", [["get", "item"]], []], "updateCategory", "updateCategory", "icons", ["subexpr", "@mut", [["get", "data.subCategoriesTable.table.icons"]], []]]]],
            locals: ["item"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 12
              },
              "end": {
                "line": 63,
                "column": 12
              }
            },
            "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "Nibud-UserCategoriesTable");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "content sign");
            var el3 = dom.createElement("object");
            dom.setAttribute(el3, "width", "40");
            dom.setAttribute(el3, "height", "40");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "Nibud-NibudCategoriesTable");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "content");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "right");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var element17 = dom.childAt(element16, [3, 0]);
            var element18 = dom.childAt(element16, [5, 1, 1]);
            var element19 = dom.childAt(element18, [0]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(dom.childAt(element16, [1, 1]), 1, 1);
            morphs[1] = dom.createAttrMorph(element17, 'data');
            morphs[2] = dom.createAttrMorph(element19, 'data-id');
            morphs[3] = dom.createMorphAt(element19, 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element18, [1]), 0, 0);
            return morphs;
          },
          statements: [["block", "each", [["get", "subcat.linkedItems"]], [], 0, null], ["attribute", "data", ["concat", [["get", "data.subCategoriesTable.table.icons.nibudSubCategories"]]]], ["attribute", "data-id", ["concat", [["get", "subcat.id"]]]], ["content", "subcat.title"], ["inline", "nibud/action-renderer", [], ["item", ["subexpr", "@mut", [["get", "subcat"]], []], "isNibudCategory", true, "updateCategory", "updateCategory", "icons", ["subexpr", "@mut", [["get", "data.subCategoriesTable.table.icons"]], []]]]],
          locals: ["subcat"],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 74,
                  "column": 24
                },
                "end": {
                  "line": 76,
                  "column": 24
                }
              },
              "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "content");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element9, 'data-id');
              morphs[1] = dom.createMorphAt(element9, 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "data-id", ["concat", [["get", "item.id"]]]], ["content", "item.title"], ["inline", "nibud/action-renderer", [], ["item", ["subexpr", "@mut", [["get", "item"]], []], "isNibudCategory", true, "updateCategory", "updateCategory", "icons", ["subexpr", "@mut", [["get", "data.subCategoriesTable.table.icons"]], []]]]],
            locals: ["item"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 12
              },
              "end": {
                "line": 80,
                "column": 12
              }
            },
            "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "Nibud-UserCategoriesTable");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "content");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5, "class", "right");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "content sign");
            var el3 = dom.createElement("object");
            dom.setAttribute(el3, "width", "40");
            dom.setAttribute(el3, "height", "40");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "Nibud-NibudCategoriesTable");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var element11 = dom.childAt(element10, [1, 1, 1]);
            var element12 = dom.childAt(element11, [0]);
            var element13 = dom.childAt(element10, [3, 0]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element12, 'data-id');
            morphs[1] = dom.createMorphAt(element12, 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
            morphs[3] = dom.createAttrMorph(element13, 'data');
            morphs[4] = dom.createMorphAt(dom.childAt(element10, [5, 1]), 1, 1);
            return morphs;
          },
          statements: [["attribute", "data-id", ["concat", [["get", "subcat.id"]]]], ["content", "subcat.title"], ["inline", "nibud/action-renderer", [], ["item", ["subexpr", "@mut", [["get", "subcat"]], []], "updateCategory", "updateCategory", "icons", ["subexpr", "@mut", [["get", "data.subCategoriesTable.table.icons"]], []]]], ["attribute", "data", ["concat", [["get", "data.subCategoriesTable.table.icons.subCategories"]]]], ["block", "each", [["get", "subcat.linkedItems"]], [], 0, null]],
          locals: ["subcat"],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 24
                },
                "end": {
                  "line": 91,
                  "column": 24
                }
              },
              "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "content");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var element5 = dom.childAt(element4, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element5, 'data-id');
              morphs[1] = dom.createMorphAt(element5, 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "data-id", ["concat", [["get", "subcat.id"]]]], ["content", "subcat.title"], ["inline", "nibud/action-renderer", [], ["item", ["subexpr", "@mut", [["get", "subcat"]], []], "isNibudCategory", true, "updateCategory", "updateCategory", "icons", ["subexpr", "@mut", [["get", "data.subCategoriesTable.table.icons"]], []]]]],
            locals: ["subcat"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 81,
                "column": 12
              },
              "end": {
                "line": 95,
                "column": 12
              }
            },
            "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1, "class", "Nibud-UnlinkedSubcategories");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "Nibud-UncategorizedTip");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "content sign");
            var el3 = dom.createElement("object");
            dom.setAttribute(el3, "width", "40");
            dom.setAttribute(el3, "height", "40");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "Nibud-NibudCategoriesTable");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var element7 = dom.childAt(element6, [3, 0]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element6, [1, 1]), 0, 0);
            morphs[1] = dom.createAttrMorph(element7, 'data');
            morphs[2] = dom.createMorphAt(dom.childAt(element6, [5, 1]), 1, 1);
            return morphs;
          },
          statements: [["content", "data.subCategoriesTable.table.unlinkedNibudSubCategoriesText"], ["attribute", "data", ["concat", [["get", "data.subCategoriesTable.table.icons.nibudSubCategories"]]]], ["block", "each", [["get", "unlinkedNibudSubcategories"]], [], 0, null]],
          locals: [],
          templates: [child0]
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 100,
                  "column": 24
                },
                "end": {
                  "line": 102,
                  "column": 24
                }
              },
              "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "content");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [0]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'data-id');
              morphs[1] = dom.createMorphAt(element1, 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "data-id", ["concat", [["get", "subcat.id"]]]], ["content", "subcat.title"], ["inline", "nibud/action-renderer", [], ["item", ["subexpr", "@mut", [["get", "subcat"]], []], "updateCategory", "updateCategory", "icons", ["subexpr", "@mut", [["get", "data.subCategoriesTable.table.icons"]], []]]]],
            locals: ["subcat"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 96,
                "column": 12
              },
              "end": {
                "line": 110,
                "column": 12
              }
            },
            "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1, "class", "Nibud-UnlinkedSubcategories");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "Nibud-UserCategoriesTable");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "content sign");
            var el3 = dom.createElement("object");
            dom.setAttribute(el3, "width", "40");
            dom.setAttribute(el3, "height", "40");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "Nibud-UncategorizedTip");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [3, 0]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 1]), 1, 1);
            morphs[1] = dom.createAttrMorph(element3, 'data');
            morphs[2] = dom.createMorphAt(dom.childAt(element2, [5, 1]), 0, 0);
            return morphs;
          },
          statements: [["block", "each", [["get", "unlinkedSubcategories"]], [], 0, null], ["attribute", "data", ["concat", [["get", "data.subCategoriesTable.table.icons.subCategories"]]]], ["content", "data.subCategoriesTable.table.unlinkedSubCategoriesText"]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 0
            },
            "end": {
              "line": 113,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "Nibud-CategorySelectorTooltip");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "Nibud-CategorySelectorWrapper");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "for", "Nibud-CategorySelector");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("select");
          dom.setAttribute(el2, "id", "Nibud-CategorySelector");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1, "class", "Nibud-CategoriesLinks");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("thead");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createElement("td");
          dom.setAttribute(el4, "class", "content");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4, "class", "content");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element27 = dom.childAt(fragment, [3]);
          var element28 = dom.childAt(element27, [3]);
          var element29 = dom.childAt(fragment, [5]);
          var element30 = dom.childAt(element29, [1, 1]);
          var element31 = dom.childAt(element29, [3]);
          var morphs = new Array(11);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element27, [1]), 0, 0);
          morphs[2] = dom.createAttrMorph(element28, 'onchange');
          morphs[3] = dom.createMorphAt(element28, 1, 1);
          morphs[4] = dom.createMorphAt(dom.childAt(element30, [0]), 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(element30, [2]), 0, 0);
          morphs[6] = dom.createMorphAt(element31, 1, 1);
          morphs[7] = dom.createMorphAt(element31, 2, 2);
          morphs[8] = dom.createMorphAt(element31, 3, 3);
          morphs[9] = dom.createMorphAt(element31, 4, 4);
          morphs[10] = dom.createMorphAt(element31, 5, 5);
          return morphs;
        },
        statements: [["content", "data.paragraph"], ["content", "data.category.description"], ["attribute", "onchange", ["subexpr", "action", ["fetchSubcategories"], []]], ["block", "each", [["get", "categories"]], [], 0, null], ["content", "data.subCategoriesTable.table.columns.subCategories"], ["content", "data.subCategoriesTable.table.columns.nibudSubCategories"], ["block", "each", [["get", "subCategoriesToMany"]], [], 1, null], ["block", "each", [["get", "nibudSubcategoriesToMany"]], [], 2, null], ["block", "each", [["get", "oneToOnes"]], [], 3, null], ["block", "if", [["get", "unlinkedNibudSubcategories.length"]], [], 4, null], ["block", "if", [["get", "unlinkedSubcategories.length"]], [], 5, null]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 114,
                "column": 4
              },
              "end": {
                "line": 116,
                "column": 4
              }
            },
            "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "data.subCategoriesTable.table.noResultText"]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 113,
              "column": 0
            },
            "end": {
              "line": 117,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "data.subCategoriesTable"]], [], 0, null]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 121,
                  "column": 12
                },
                "end": {
                  "line": 121,
                  "column": 93
                }
              },
              "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "data.backToNibudButton.label"]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 119,
                "column": 4
              },
              "end": {
                "line": 123,
                "column": 4
              }
            },
            "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "Nibud-BackButtonWrapper");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["nibud"], ["class", "btn btn-lg btn-green"], 0, null]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 118,
              "column": 0
            },
            "end": {
              "line": 124,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["subexpr", "is-equal", [["get", "data.backToNibudButton.status"], 2], []]], [], 0, null]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 125,
              "column": 0
            },
            "end": {
              "line": 132,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "vertical-centered-content");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-sm-offset-3 col-sm-6 Nibud-before-result");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          dom.setAttribute(el3, "class", "text-center");
          dom.setAttribute(el3, "style", "color:red;");
          var el4 = dom.createTextNode("Er ging iets mis met het ophalen van je gegevens. Probeer het later opnieuw.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "text-center");
          dom.setAttribute(el3, "style", "color:red;font-size:10px;");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]), 0, 0);
          return morphs;
        },
        statements: [["content", "responseErrorId"]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 133,
            "column": 6
          }
        },
        "moduleName": "front-end-cli/pods/nibud/edit/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element32 = dom.childAt(fragment, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element32, 1, 1);
        morphs[2] = dom.createMorphAt(element32, 2, 2);
        morphs[3] = dom.createMorphAt(element32, 3, 3);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "loading"]], [], 0, null], ["block", "if", [["get", "categories.length"]], [], 1, 2], ["block", "if", [["get", "data.backToNibudButton"]], [], 3, null], ["block", "if", [["get", "responseIsError"]], [], 4, null]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});