define('front-end-cli/views/legacy', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({
    didInsertElement: function didInsertElement() {

      /**
        Initial call to setLegacyIframeLocation loading the initial iframe
      */
      this.get('controller').send('setLegacyIframeLocation', true);

      /**
        Add a listener to the iframe which sends the new pathname
        to the `handleIframeChange` action.
      */
      var controller = this.get('controller');
      _ember['default'].$('#legacy').on('load', function () {

        var pathname = this.contentWindow.location.pathname.replace(/^\//, '');
        if (pathname == 'blank') return false; // IE load blank

        controller.send('handleIframeChange', pathname);
      });
    },
    willDestroyElement: function willDestroyElement() {
      _ember['default'].$('#legacy').off('load');
    }
  });
});