define('front-end-cli/models/misc-burial-insurance', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    miscInsurance: _emberData['default'].belongsTo('misc-insurance'),

    insurerName: _emberData['default'].attr('string'),
    insuranceNumber: _emberData['default'].attr('string'),
    contractor: _emberData['default'].attr('string'),
    insured: _emberData['default'].attr('string'),
    beginDate: _emberData['default'].attr('string'),
    premiumPerMonth: _emberData['default'].attr('number'),
    paymentByDecease: _emberData['default'].attr('number'),
    paymentOnDecease: _emberData['default'].attr('number'),
    premiumExcemption: _emberData['default'].attr('number'),

    isPaymentByMoney: (function () {
      var type = this.get('paymentByDecease');
      return type == 2;
    }).property('paymentByDecease')
  });
});