define("front-end-cli/pods/components/budget/matrix-options/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 14
              },
              "end": {
                "line": 15,
                "column": 14
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("Kopieer je budgetten ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("br");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("van vorig jaar");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element8, 'class');
            morphs[1] = dom.createElementMorph(element8);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "detail2"], "is-active"], []], ["subexpr", "if", [["get", "detail3"], " is-before"], []]]]], ["element", "action", ["showDetail", 2], ["on", "mouseEnter"]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 14
              },
              "end": {
                "line": 20,
                "column": 14
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 10
              },
              "end": {
                "line": 31,
                "column": 10
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "MatrixOptions-procedure");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2, "class", "MatrixOptions-procedureLoader");
            dom.setAttribute(el2, "src", "/images/gears.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 16
                },
                "end": {
                  "line": 42,
                  "column": 16
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "MatrixOptions-detailBody");
              var el3 = dom.createTextNode("Je budgetten voor ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" worden op basis van de gemiddelde inkomsten en uitgaven van je ingeschakelde rekeningen berekend. Deze kun je vervolgens handmatig aanpassen.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "MatrixOptions-detailNote is-warning");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "glyphicons glyphicons-warning-sign");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" Eventueel bestaande budgetten voor ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" worden overschreven!");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "MatrixOptions-buttonContainer");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2, "class", "Button Button-primary");
              var el3 = dom.createTextNode("Bereken budgetten");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var element7 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 2, 2);
              morphs[2] = dom.createElementMorph(element7);
              return morphs;
            },
            statements: [["content", "currentYear"], ["content", "currentYear"], ["element", "action", ["action1"], []]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 42,
                    "column": 16
                  },
                  "end": {
                    "line": 50,
                    "column": 16
                  }
                },
                "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "MatrixOptions-detailBody");
                var el3 = dom.createTextNode("Je budgetten van ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" worden gekopieerd naar ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(". Vervolgens kun je deze handmatig aanpassen.");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "MatrixOptions-detailNote is-warning");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "glyphicons glyphicons-warning-sign");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" Eventueel bestaande budgetten voor ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" worden overschreven!");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "MatrixOptions-buttonContainer");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "class", "Button Button-primary");
                var el3 = dom.createTextNode("Kopieer budgetten");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var element4 = dom.childAt(element3, [1]);
                var element5 = dom.childAt(fragment, [3, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element4, 1, 1);
                morphs[1] = dom.createMorphAt(element4, 3, 3);
                morphs[2] = dom.createMorphAt(dom.childAt(element3, [3]), 2, 2);
                morphs[3] = dom.createElementMorph(element5);
                return morphs;
              },
              statements: [["content", "previousYear"], ["content", "currentYear"], ["content", "currentYear"], ["element", "action", ["action2"], []]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 50,
                      "column": 16
                    },
                    "end": {
                      "line": 58,
                      "column": 16
                    }
                  },
                  "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "MatrixOptions-detailBody");
                  var el3 = dom.createTextNode("Bereken de budgetten voor alle categorieën en subcategorieën waar je in ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" nog geen budget voor hebt. Alleen ingeschakelde rekeningen worden meegenomen in de berekening.");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "MatrixOptions-detailNote");
                  var el3 = dom.createTextNode("Reeds bestaande budgetten in ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" blijven ongewijzigd.");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "MatrixOptions-buttonContainer");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("button");
                  dom.setAttribute(el2, "class", "Button Button-primary");
                  var el3 = dom.createTextNode("Bereken ontbrekende budgetten");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var element2 = dom.childAt(fragment, [3, 1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
                  morphs[2] = dom.createElementMorph(element2);
                  return morphs;
                },
                statements: [["content", "currentYear"], ["content", "currentYear"], ["element", "action", ["action3"], []]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.2",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 58,
                        "column": 16
                      },
                      "end": {
                        "line": 65,
                        "column": 16
                      }
                    },
                    "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "MatrixOptions-detailBody");
                    var el3 = dom.createTextNode("Wis al je budgetten van het jaar ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(". In de overzichten schakelt MijnGeldzaken Huishoudboekje over op jouw gemiddelden.");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "MatrixOptions-buttonContainer");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("button");
                    dom.setAttribute(el2, "class", "Button Button-primary");
                    var el3 = dom.createTextNode("Wis je budgetten");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [3, 1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                    morphs[1] = dom.createElementMorph(element0);
                    return morphs;
                  },
                  statements: [["content", "currentYear"], ["element", "action", ["action4"], []]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 58,
                      "column": 16
                    },
                    "end": {
                      "line": 65,
                      "column": 16
                    }
                  },
                  "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "detail4"]], [], 0, null]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 50,
                    "column": 16
                  },
                  "end": {
                    "line": 65,
                    "column": 16
                  }
                },
                "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "detail3"]], [], 0, 1]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 16
                },
                "end": {
                  "line": 65,
                  "column": 16
                }
              },
              "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "detail2"]], [], 0, 1]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 10
              },
              "end": {
                "line": 68,
                "column": 10
              }
            },
            "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "MatrixOptions-detail");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "MatrixOptions-detailContainer");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "detail1"]], [], 0, 1]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 77,
              "column": 0
            }
          },
          "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "MatrixOptions-modal");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "MatrixOptions-container");
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-sm-5");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "MatrixOptions-listContainer");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("ul");
          dom.setAttribute(el6, "class", "MatrixOptions-list");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("li");
          var el8 = dom.createTextNode("Bereken je budgetten");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("li");
          var el8 = dom.createTextNode("Bereken ontbrekende budgetten");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("li");
          var el8 = dom.createTextNode("Wis alle budgetten");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-sm-7");
          var el5 = dom.createTextNode("\n\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "MatrixOptions-curtain");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1, 1, 1]);
          var element10 = dom.childAt(element9, [1, 1, 1]);
          var element11 = dom.childAt(element10, [1]);
          var element12 = dom.childAt(element10, [5]);
          var element13 = dom.childAt(element10, [7]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element11, 'class');
          morphs[1] = dom.createElementMorph(element11);
          morphs[2] = dom.createMorphAt(element10, 3, 3);
          morphs[3] = dom.createAttrMorph(element12, 'class');
          morphs[4] = dom.createElementMorph(element12);
          morphs[5] = dom.createAttrMorph(element13, 'class');
          morphs[6] = dom.createElementMorph(element13);
          morphs[7] = dom.createMorphAt(element10, 9, 9);
          morphs[8] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "detail1"], "is-active"], []], ["subexpr", "if", [["get", "detail2or3"], " is-before"], []]]]], ["element", "action", ["showDetail", 1], ["on", "mouseEnter"]], ["block", "if", [["get", "hasBudgetsInPreviousYear"]], [], 0, null], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "detail3"], "is-active"], []], ["subexpr", "if", [["get", "detail4"], " is-before"], []]]]], ["element", "action", ["showDetail", 3], ["on", "mouseEnter"]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "detail4"], "is-active"], []]]]], ["element", "action", ["showDetail", 4], ["on", "mouseEnter"]], ["block", "unless", [["get", "hasBudgetsInPreviousYear"]], [], 1, null], ["block", "if", [["get", "isActiveProcedure"]], [], 2, 3]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 78,
            "column": 0
          }
        },
        "moduleName": "front-end-cli/pods/components/budget/matrix-options/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "MatrixOptions-title");
        var el2 = dom.createTextNode("Bewerk");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element14 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createElementMorph(element14);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["element", "action", ["toggleActiveState"], []], ["block", "if", [["get", "isActive"]], [], 0, null]],
      locals: [],
      templates: [child0]
    };
  })());
});