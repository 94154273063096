define('front-end-cli/controllers/accountmanagement/edit/auto', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    bankChoice: _ember['default'].computed.alias('parentController.bankChoice'),
    error: _ember['default'].computed.alias('parentController.error'),

    currentGuide: (function () {
      return 1;
    }).property(),

    guide1: _ember['default'].computed.equal('currentGuide', 1),
    guide2: _ember['default'].computed.equal('currentGuide', 2),
    guide3: _ember['default'].computed.equal('currentGuide', 3),
    guide4: _ember['default'].computed.equal('currentGuide', 4),
    guide5: _ember['default'].computed.equal('currentGuide', 5),
    guide6: _ember['default'].computed.equal('currentGuide', 6),
    guide7: _ember['default'].computed.equal('currentGuide', 7),
    guide8: _ember['default'].computed.equal('currentGuide', 8),
    guide9: _ember['default'].computed.equal('currentGuide', 9),
    guide10: _ember['default'].computed.equal('currentGuide', 10),

    disableButton: (function () {
      return !this.get('bankChoice.data.isComplete') || this.get('parentController.syncing');
    }).property('parentController.syncing', 'bankChoice.data.isComplete'),

    isPacButtonDisabled: (function () {
      return this.get('parentController.syncing') || !this.get('bankChoice.data.isPacCode');
    }).property('parentController.syncing', 'bankChoice.data.isPacCode'),

    actions: {

      setCurrentGuide: function setCurrentGuide(guide) {
        this.set('currentGuide', guide);
      },

      // EventedForm view sends triggers action on enter
      pressedEnter: function pressedEnter() {

        if (this.get('bankChoice.requestIngPacCode')) {
          if (this.get('bankChoice.data.isPacCode')) {
            this.send('verifyIngPacCode');
          }
        } else if (this.get('bankChoice.data.isComplete')) {
          this.send('createAndSyncBankLogin');
        }
      },

      verifyIngPacCode: function verifyIngPacCode() {

        // Validate
        if (!this.get('bankChoice.data.isPacCodePart1') || !this.get('bankChoice.data.isPacCodePart2')) {
          return false;
        }

        this.set('parentController.syncing', true);

        // Get user input
        var pacCodePart1 = this.get('bankChoice.data.pacCodePart1'),
            pacCodePart2 = this.get('bankChoice.data.pacCodePart2');

        // Submit user input for verification
        _ember['default'].$.ajax({
          url: 'api/v3/banklogins/paccode/' + this.get('bankChoice.bankLogin.id'),
          type: 'put',
          context: this,
          data: {
            pacCode: {
              part1: pacCodePart1,
              part2: pacCodePart2
            }
          }
        }).done(function (response) {
          /*
            Return to regular sync form, so the user can
            retry to login now that we have a our PAC verified
          */
          this.send('syncSuccess', response);
          this.set('bankChoice.requestIngPacCode', false);
        }).fail(function () {
          /*
            Show the failed notice with its button to trigger a
            restart of the the flow.
          */
          this.set('bankChoice.showPacFailedNotice', true);
          this.set('parentController.syncing', false);
        }).always(function () {

          // Reset pacCode input in case of another PAC request
          this.set('bankChoice.data.pacCodePart1', null);
          this.set('bankChoice.data.pacCodePart2', null);

          // Reset syncing state
          this.set('parentController.syncing', false);
        });
      },

      endFailedPacVerification: function endFailedPacVerification() {
        this.set('bankChoice.requestIngPacCode', false);
        this.set('bankChoice.showPacFailedNotice', false);
      }
    }
  });
});