define('front-end-cli/controllers/accountmanagement/setup-success', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    needs: ['application'],
    queryParams: ['addedMultipleAccounts'], // TODO remove `newUser` refs, just for testing

    newUser: null,
    addedMultipleAccounts: null,
    isTourVisible: null,

    fullScreenOrNot: (function () {
      if (this.get('currentUser.model.state.newUser')) {
        this.get('controllers.application').send('hideSidebar');
      }
      // Observer below is solely for testing purposes.
    }).on('init').observes('currentUser.model.state.newUser'),

    actions: {

      endAccountSetup: function endAccountSetup() {
        if (this.get('currentUser.model.hasNoAccounts')) {
          this.set('currentUser.model.hasNoAccounts', false);
          this.get('currentUser.model.state').save();
        }

        if (this.get('currentUser.model.state.newUser')) {

          this.get('controllers.application').send('showSidebar');
          // this.set('controllers.application.sidebarlessed', false);

          this.set('isTourVisible', true);

          this.set('currentUser.model.state.newUser', false);
          this.get('currentUser.model.state').save();
        }
        this.transitionToRoute('accounts');
      }
    }
  });
});