define('front-end-cli/pods/nibud/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel(transition) {
      this.set('current', transition.targetName);
      if (!this.get('currentUser.model.state.featureFlagNibud')) {
        this._redirectWhenFeatureFlagIsDisabled(transition);
      }
    },

    _redirectWhenFeatureFlagIsDisabled: function _redirectWhenFeatureFlagIsDisabled(transition) {
      if (transition.targetName === 'nibud.index') {
        this.transitionTo('timeline');
      }
    },

    setupController: function setupController(controller, model) {
      var current = this.get('current');
      controller.set('responseIsError', false);
      controller.set('renderComparisonPage', false);
      switch (current) {
        case 'nibud.index':
          controller.send('fetchSearchFilters');
          break;
        case 'nibud.categories':
          controller.set('loading', false);
          break;
      }
    }
  });
});