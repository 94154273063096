define('front-end-cli/pods/components/related-transactions/component', ['exports', 'ember', 'front-end-cli/mixins/window-resize', 'front-end-cli/mixins/scroll-top', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _ember, _frontEndCliMixinsWindowResize, _frontEndCliMixinsScrollTop, _emberKeyboardShortcutsMixinsComponent) {
  exports['default'] = _ember['default'].Component.extend(_frontEndCliMixinsWindowResize['default'], _frontEndCliMixinsScrollTop['default'], _emberKeyboardShortcutsMixinsComponent['default'], {
    tagName: 'footer',
    classNameBindings: ['isActive', 'isFixed', 'isEditing', 'isSticky', 'hasSidebar'],
    classNames: ['RelatedTransactions'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    transactions: null,

    categories: null,
    category: null,
    subcategory: null,
    receipts: null,

    hasSidebar: null,
    isActive: null,
    type: null,
    hasNoTransactions: null,
    hasNoEnabledTransactions: null,

    // Actions
    toggleModal: null,
    refreshRoute: null,

    /**-------------------------
      Properties
    ---------------------------*/

    scrollTopDebounceTime: 5,

    isFixed: true,
    editingTransaction: null,
    isEditingTransaction: false,
    searchTerm: null,
    sortBy: null,
    relatedTransactionLength: null,
    willBeOrIsActive: null, // Chevron toggler
    splittedTransaction: null,

    /**-------------------------
      Calculated Properties
    ---------------------------*/

    highlightAnimation: null,

    transactionLength: _ember['default'].observer('filteredTransactions', 'category', 'subcategory', function () {

      if (this.get('isEditing')) return;

      this.set('highlightAnimation', true);
      _ember['default'].run.later(this, function () {

        if (this._state === 'destroying') return;

        this.set('highlightAnimation', false);
      }, 400);
    }),

    isIncome: (function () {
      return this.get('type') === 'income';
    }).property('type'),

    /*
      `transactions` contains all transactions from the specified
      period. Here we bring it down to the tranactions belonging
      to the specified sub/category as well as matches to an
      optional `searchTerm`.
    */

    realisticTransactions: (function () {

      var transactions = this.get('filteredTransactions'),
          i = 0;
      if (!transactions) return [];

      transactions.filter(function (transaction) {
        if (transaction.get('childTransactions.length') === 0) {
          i++;
        }
      });
      return i;
    }).property('filteredTransactions'),

    filteredTransactions: (function () {
      var _this2 = this;

      var transactions = this.get('transactions'),
          type = this.get('type'),
          result = null,
          childTransactions = [],
          splittedTransaction = 0;

      if (!transactions) return [];

      if (this.get('subcategory')) {
        result = transactions.filter(function (transaction) {
          // Child transactions are matched and added through their parent.
          if (transaction.get('parentTransaction')) {
            return false;
          }
          var related = transaction.get('subcategory.id') === this.get('subcategory.id') && !transaction.get('childTransactions.length');
          var isEditingTransaction = this.get('isEditing') && transaction == this.get('editingTransaction');
          if (!related && isEditingTransaction) {
            transaction.set('willDisappear', true);
            related = true;
          } else {
            transaction.set('willDisappear', null);
          }

          // If the parent transaction is not already related, check if the any of his children need to be shown.
          if (!related && transaction.get('childTransactions.length')) {
            var doShow = false;
            transaction.get('childTransactions').forEach((function (item, index, enumerable) {
              if (item.get('subcategory.id') == this.get('subcategory.id')) {
                doShow = true;
              }
            }).bind(this));

            if (doShow) {
              related = true;
            }
          }

          /* Parent transactions are always shown in relation with their children.
           * In order to provide a correct count of transactions shown, add all child transactions to the result (later on)
           */
          if (related && transaction.get('childTransactions.length')) {
            transaction.get('childTransactions').forEach(function (item, index, enumerable) {
              childTransactions.push(item);
            });
          }

          return related;
        }, this);
      } else if (this.get('category')) {
        result = transactions.filter(function (transaction) {
          // Child transactions are matched and added through their parent.
          if (transaction.get('parentTransaction')) {
            return false;
          }
          var related = false;

          // Loop over the selected category's subcategories
          // and check if any one of them matches this
          // transactions sucategory.id.
          // (Skip when transaction has childTransactions)
          if (!transaction.get('childTransactions.length')) {
            this.get('category.subcategories').filterBy('enabled', true).forEach(function (subcategory) {
              if (related) {
                return;
              }
              related = transaction.get('subcategory.id') === subcategory.get('id') && !transaction.get('childTransactions.length');
            });
          }

          // If transaction doesnt match filter but is editing,
          // we’ll leave it in to prevent flashing while editing
          if (!related && this.get('isEditing') && transaction == this.get('editingTransaction')) {
            transaction.set('willDisappear', true);
            // transaction.willDisappear = true;
            related = true;
          } else {
            transaction.set('willDisappear', null);
          }

          // If the parent transaction is not already related, check if the any of his children need to be shown.
          if (!related && transaction.get('childTransactions.length')) {
            var doShow = false;
            transaction.get('childTransactions').forEach((function (item, index, enumerable) {
              this.get('category.subcategories').filterBy('enabled', true).forEach((function (subcategory) {
                if (item.get('subcategory.id') === subcategory.get('id')) {
                  doShow = true;
                }
              }).bind(this));
            }).bind(this));

            if (doShow) {
              related = true;
            }
          }

          /* Parent transactions are always shown in relation with their children.
           * In order to provide a correct count of transactions shown, add all child transactions to the result (later on)
           */
          if (related && transaction.get('childTransactions.length')) {
            transaction.get('childTransactions').forEach(function (item, index, enumerable) {
              childTransactions.push(item);
            });
          }

          return related;
        }, this);
      } else {

        // When categories has a positive expectedNetted,
        // we consider it income, else we'll file it under expenses.
        result = transactions.filter(function (transaction) {
          // Child transactions are matched and added through their parent.
          if (transaction.get('parentTransaction')) {
            return false;
          }

          var subcategory = transaction.get('subcategory'),
              category = subcategory.get('category'),
              currentNetted = 0,
              expectedNetted = 0,
              related = false;

          if (category) {
            currentNetted = category.get('currentNetted');
            expectedNetted = category.get('expectedNetted');
          }

          var doShow = false;

          if (_this2.get('isEditing') && transaction == _this2.get('editingTransaction')) {
            related = true;
          } else if (type === 'income') {
            if (currentNetted >= 0) {
              if (!transaction.get('childTransactions.length')) {
                related = true;
              }
            }

            // If the parent transaction is not already related, check if the any of his children need to be shown.
            if (!related && transaction.get('childTransactions.length')) {
              transaction.get('childTransactions').forEach(function (item, index, enumerable) {
                if (item.get('subcategory.enabled') && item.get('subcategory.category.currentNetted') > 0) {
                  doShow = true;
                }
              });

              if (doShow) {
                related = true;
              }
            }
          } else if (type === 'expenses') {
            if (currentNetted <= 0) {
              if (!transaction.get('childTransactions.length')) {
                related = true;
              }
            }

            // If the parent transaction is not already related, check if the any of his children need to be shown.
            if (transaction.get('childTransactions.length')) {
              transaction.get('childTransactions').forEach(function (item, index, enumerable) {
                if (item.get('subcategory.enabled') && item.get('subcategory.category.currentNetted') <= 0) {
                  doShow = true;
                }
              });

              if (doShow) {
                related = true;
              }
            }
          }

          /* Parent transactions are always shown in relation with their children.
           * In order to provide a correct count of transactions shown, add all child transactions to the result (later on)
           */
          if (related && transaction.get('childTransactions.length')) {
            transaction.get('childTransactions').forEach(function (item, index, enumerable) {
              childTransactions.push(item);
            });
          }

          return related;
        });
      }

      // Add any childTransactions of parents that are in the result set.
      result.pushObjects(childTransactions);

      // When a subset is of the relatedTransactions is shown,
      // we'll use this property to display the total.
      // Y in “X van de Y transacties”.

      var i = 0;
      result.filter(function (transaction) {
        if (transaction.get('childTransactions.length') === 0) {
          i++;
        }
      });

      this.set('relatedTransactionLength', i);

      // Finally, apply search filter if any.
      if (this.get('searchTerm')) {
        // TODO: Give precedence to titles which match at beginning
        // and titles in general.
        var regx = new RegExp(this.get('searchTerm'), 'gi');
        result = result.filter(function (transaction) {
          return transaction.get('name').match(regx) || transaction.get('description').match(regx) || transaction.get('manDescription').match(regx) || transaction.get('subcategory.category.name').match(regx) || transaction.get('subcategory.name').match(regx);
          //TODO : transaction.get('label.name').match(regx)
        });

        /* If either a parent transaction or a child transaction is returned by the search request, the entire combi has to be shown. Therefore we add each again manually.
         * Adding just the parent transaction does not suffice, because, even though the child transactions are rendered through their parent, the count of transactions returned would not be correct otherwise (X van de Y transacties).
         */
        result.forEach(function (transaction, index, enumerable) {
          if (transaction.get('childTransactions.length')) {
            transaction.get('childTransactions').forEach(function (childTransaction, index, enumerable) {
              if (result.findBy('id', childTransaction.get('id')) === undefined) {
                result.pushObject(childTransaction);
              }
            });
          } else if (transaction.get('parentTransaction')) {
            if (result.findBy('id', transaction.get('parentTransaction.id')) === undefined) {
              result.pushObject(transaction.get('parentTransaction'));
              transaction.get('parentTransaction.childTransactions').forEach(function (childTransaction, index, enumerable) {
                if (result.findBy('id', childTransaction.get('id')) === undefined) {
                  result.pushObject(childTransaction);
                }
              });
            }
          }
        });
      }

      // This part handles disabled transactions
      // If the user has no activity we will alert the user
      // about disabled transactions

      var disabledTransactions = result.filterBy('enabled', false),
          enabledTransactions = result.filterBy('enabled', true),
          hasNoEnabledTransactions = false,
          _result = result;

      _result = _result.filter(function (item) {
        if (type === 'income' && item.get('amount') >= 0) {
          return item;
        } else if (type === 'expenses' && item.get('amount') <= 0) {
          return item;
        }
      });

      if (disabledTransactions.length > 0) {
        if (_result.length > 0 && enabledTransactions.length === 0) {
          // there's no activity to show the user, but he does have
          // disabled transactions. We will warn the user by setting
          // the property hasNoEnabledTransactions

          hasNoEnabledTransactions = true;
        }
      }

      // hasNoTransactions is bound to income-expenses
      // controller, so it can tel if there is any activity
      // in the selected period.

      this.set('hasNoTransactions', !result.length);
      this.set('hasNoEnabledTransactions', hasNoEnabledTransactions);

      // By default, related-transactions are sorted by
      // amount. When `sortyBy` is set, we’ll sort by date.
      // Sorting is always ascending.
      var sortBy = this.get('sortBy'),
          sorted;

      // Sort by date
      if (sortBy) {
        if (type === 'income') {
          // Only reverse the secundairy sort key
          sorted = result.sortBy('displayDate').reverse();
          // sorted = sorted.sortBy('date'); // TODO
        } else {
            sorted = result.sortBy('displayDate', 'amount').reverse();
          }
        // Sort by amount
      } else {
          if (type === 'income') {
            // Only reverse the primary sort key
            sorted = result.sortBy('amount').reverse();
            // sorted = sorted.sortBy('date'); // TODO
          } else {
              sorted = result.sortBy('amount', 'displayDate');
            }
        }

      return sorted;
    }).property('transactions.length', 'transactions.@each.subcategory', 'subcategory', 'category', 'searchTerm', 'sortBy', 'type', 'editingTransaction'),

    /**-------------------------
      Methods
    ---------------------------*/

    _stickyness: (function () {

      if (!this.get('isActive')) {
        return;
      }

      if (!this.get('originalTop')) {
        this.set('originalTop', this.$().offset().top + _ember['default'].$('#app > main').scrollTop());
      }

      var scrollTop = _ember['default'].$('#app > main').scrollTop(),
          originalTop = this.get('originalTop'),
          topBarHeight = this.get('container').lookup('controller:application').get('topBarHeightAdjustment') || 35;

      // console.group()
      // console.log('scrollTop', scrollTop);
      // console.log('originalTop', originalTop);
      // console.log('topBarHeight', topBarHeight);
      // console.log(`${scrollTop} + ${topBarHeight} > ${originalTop}`, (scrollTop + topBarHeight) > originalTop);
      // console.groupEnd();

      if (scrollTop + topBarHeight > originalTop) {

        if (this.get('isSticky')) return;

        // This mainly set the `is-sticky` class which
        // makes positioning to become fixed and
        // scrollContainer top become absolute.
        this.set('isSticky', true);

        // Set the components top position
        // according to the current topbar height.
        this.$('.RelatedTransactions-topSection').css({ top: topBarHeight });
      } else {
        this._stopBeingSticky();
      }
    }).on('scroll'),

    _stopBeingSticky: function _stopBeingSticky() {
      this.set('isSticky', false);
      this.$('.RelatedTransactions-topSection').css({ top: '' });
    },

    /**
      When entering editing mode we slide
      the transactions to left of the grid.
    */
    toggleEditMode: (function () {

      var isEditing = !!this.get('editingTransaction');
      this.set('isEditing', isEditing);

      var rtContainer = this.$('.RelatedTransactions-scrollContainer > .RelatedTransactions-container ');
      var list = this.$('.RelatedTransactions-list');
      this.set('rtContainer', rtContainer);

      if (isEditing) {

        // Slide left, till the left of the grid
        var gutterWidth = 10; // This needs to be same as gutter in .less
        var leftOffset = list.offset().left - rtContainer.offset().left - gutterWidth;

        _ember['default'].$.Velocity(rtContainer[0], {
          translateX: -Math.round(leftOffset) // float value cause blurry text in chrome
        }, [200, 20]);
      } else {}

      this.set('isEditingTransaction', false);
    }).observes('editingTransaction'),

    /**-------------------------
      Actions
    ---------------------------*/

    actions: {

      closeTransactionEdit: function closeTransactionEdit() {

        this.set('isEditing', false);
        this.set('editingTransaction', false);

        var rtContainer = this.get('rtContainer');

        _ember['default'].$.Velocity(rtContainer[0], {
          translateX: 0
        }, [200, 20]).then(function () {
          // Complete
        });
      },

      activate: function activate() {
        this.set('isActive', true);
      },

      changeSorting: function changeSorting(sortKey) {
        this.set('sortBy', sortKey);
      },

      deactivate: function deactivate() {
        this.set('isActive', false);
      },

      toggleActiveState: function toggleActiveState() {
        if (this.get('isActive')) {
          this.send('leaveActiveState');
        } else {
          this.send('enterActiveState');
        }
      },

      enterActiveState: function enterActiveState() {
        // References
        var _this = this,
            animationTarget = _ember['default'].$('.CategoryDissect');

        // Position values
        var scrollTop = _ember['default'].$('#app > main').scrollTop(),
            contentHeight = _ember['default'].$('#app > main').prop('scrollHeight'),
            animationTargetTop = animationTarget.offset().top,
            animationTargetHeight = animationTarget.height(),
            animationTargetBottom = animationTargetTop + animationTargetHeight,
            animationTargetBottomMargin = parseInt(animationTarget.css('marginBottom')),
            collapsedHeight = Math.abs(parseInt(this.$().css('marginTop'))),
            collapsedTop = this.$().offset().top;

        this.setProperties({
          collapsedHeight: collapsedHeight,
          contentHeight: contentHeight
        });

        // The traget top to which we will animate
        var targetTop = animationTargetBottom + animationTargetBottomMargin + collapsedHeight;

        // Will the expanded rt-pane end up out of view?
        if (targetTop - collapsedHeight > window.innerHeight + scrollTop) {
          // console.log('tt - ch', (targetTop - collapsedHeight), 'ih - st', (window.innerHeight + scrollTop));
          var scrollCompensation = targetTop - collapsedHeight - (window.innerHeight + scrollTop);

          _ember['default'].$('#income-expenses').animate({
            scrollTop: scrollCompensation + collapsedHeight + 40
          }, 300, (function () {
            this.send('enterActiveState');
          }).bind(this));
          return;
        }

        // Fix the before-animation top
        this.$().css('top', collapsedTop - scrollTop + 'px');

        // Add active class to taget so we can fade it out.
        // animationTarget.addClass('active-related-transactions');

        // Toggle the chevron before animation/rendering
        this.set('willBeOrIsActive', true);

        // VelocityJS animation
        if (!bowser.msie) {
          _ember['default'].$.Velocity(this.$()[0], {
            top: targetTop
          }, [400, 20]).then(function () {

            // Switch to relative (inline) mode
            _ember['default'].run.next(this, function () {
              _this.$().css('top', 'auto');
              _this.set('isFixed', false);
              _this.set('isActive', true);
            });
          });
        } else {
          _ember['default'].run.next(this, function () {
            _this.$().css('top', 'auto');
            _this.set('isFixed', false);
            _this.set('isActive', true);
          });
        }
      },

      leaveActiveState: function leaveActiveState() {

        var _this = this,
            categoryDissect = _ember['default'].$('.CategoryDissect');

        var scrollTop = _ember['default'].$('body').scrollTop(),
            currentTop = this.$().offset().top,
            windowHeight = _ember['default'].$(window).height(),
            catDissectBottomMargin = parseInt(categoryDissect.css('marginBottom')),
            collapsedHeight = this.get('collapsedHeight');

        // This makes sure the content stays in
        // place after the relatedTransaction
        // view gets fixed.
        _ember['default'].$('#app').css({ minHeight: 5000 });

        // Fix the current top position
        var currentFixedTop = currentTop - scrollTop + collapsedHeight;
        this.$().css('top', currentFixedTop + 'px');

        // Toggle the chevron
        this.set('willBeOrIsActive', false);
        // Switch to fixed positioning
        this.set('isFixed', true);
        // Render the
        this.set('isActive', false);

        if (scrollTop) {
          // If the content-height without relatedTransactions,
          // minus the scrollTop, is smaller than the windowHeight:
          // Scroll back up smoothly.
          var contentHeight = this.get('contentHeight');
          var space = contentHeight - scrollTop;

          if (space < windowHeight) {
            _ember['default'].$('html').velocity('scroll', {
              easing: [100, 10],
              offset: contentHeight - windowHeight + 'px'
            });
          }
        }

        // Animate the related-transactions-pane
        // to the bottom.
        if (!bowser.msie) {
          _ember['default'].$.Velocity(this.$()[0], {
            top: windowHeight
          }, [100, 10]).then(function () {
            // Reset the fixed top
            _this.$().css('top', '');
            // Reset the searchTerm
            _this.set('searchTerm', null);
            // Reset the fixed minHeight
            _ember['default'].$('#app').css({ minHeight: '' });
            // Reset the editingTransaction, if any
            _this.set('editingTransaction', null);
          });
        } else {
          // Reset the fixed top
          _this.$().css('top', '');
          // Reset the searchTerm
          _this.set('searchTerm', null);
          // Reset the fixed minHeight
          _ember['default'].$('#app').css({ minHeight: '' });
          // Reset the editingTransaction, if any
          _this.set('editingTransaction', null);
        }

        this._stopBeingSticky();
      },

      // Actions
      toggleModal: function toggleModal(template, controller, model) {
        this.sendAction('toggleModal', template, controller, model);
      },

      refreshRoute: function refreshRoute() {
        this.sendAction('refreshRoute');
      },

      addSubcategory: function addSubcategory(newSubcategoryName, category) {
        this.sendAction('addSubcategory', newSubcategoryName, category);
      }
    },

    _nudgeSelectedTransaction: function _nudgeSelectedTransaction(value) {

      var editingTransaction = this.get('editingTransaction'),
          filteredTransactions = this.get('filteredTransactions');

      var index = filteredTransactions.indexOf(editingTransaction),
          newIndex = index + value,
          newEditingTransaction;

      if (newIndex < 0) {
        newEditingTransaction = filteredTransactions.get('lastObject');
      } else if (newIndex > filteredTransactions.length - 1) {
        newEditingTransaction = filteredTransactions.get('firstObject');
      } else {
        newEditingTransaction = filteredTransactions.objectAt(newIndex);
      }

      this.set('editingTransaction', newEditingTransaction);
    },

    keyboardShortcuts: {
      'esc': function esc() {
        if (this.get('editingTransaction') && !this.get('isEditingTransaction')) {
          this.send('closeTransactionEdit');
        }
      },
      'up': function up() {
        if (this.get('editingTransaction') && !this.get('isEditingTransaction')) {
          this._nudgeSelectedTransaction(-1);
        }
        return false; // preventDefault
      },
      'down': function down() {
        if (this.get('editingTransaction') && !this.get('isEditingTransaction')) {
          this._nudgeSelectedTransaction(1);
        }
        return false; // preventDefault
      }
    }
  });
});