define('front-end-cli/routes/accountmanagement/update', ['exports', 'ember', 'front-end-cli/utils/accountmanagement/bank-choice'], function (exports, _ember, _frontEndCliUtilsAccountmanagementBankChoice) {
  exports['default'] = _ember['default'].Route.extend({
    /**
      Native Ember hooks
    */

    model: function model(params) {

      return this.store.find('account', params.account_id); // jshint ignore:line
    },

    setupController: function setupController(controller, model) {
      if (this.get('currentUser.model.state.needsToLockAccounts')) {
        this.transitionTo('accountmanagement.lock-accounts');
      }

      // Reset the setup wizard
      controller.setProperties({
        bankChoice: _frontEndCliUtilsAccountmanagementBankChoice['default'].create(),
        error: null,
        syncing: null,
        step: null,
        previousSteps: [],
        isTourVisible: null
      });

      // Call _super for default behavior
      this._super(controller, model);

      _ember['default'].$.ajax({
        url: 'api/v3/analytics/track/accountwizard-start',
        type: 'POST',
        dataType: 'json',
        data: {
          event: 1
        }
      });

      controller.prepareAccountUpdate();
    },

    actions: {
      // error: function() {
      //   // this.transitionTo('index');
      //   // window.location.reload();
      //   console.error('The `accountmanagementUpdateRoute` tried to load a non excisting account recource.');
      // }
    }
  });
});