define('front-end-cli/pods/components/sidebaraccounts-modal-trigger/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['modal-trigger'],
    classNameBindings: ['isBankLogin:bank-login:manual-account', 'isActive:active'],

    /**-------------------------
      External Bindings
    ---------------------------*/
    bankaccount: null, // can also be a banklogin!
    activeTrigger: null,
    currentPath: null,
    currentAccountId: null,
    // Actions
    showModal: null,
    swapModal: null,
    stopModal: null,

    /**
      If update route, get `model.id` and check if it fits this triggers `account`
      This replicates the `link-to` helpers `active` class.
    */
    isActive: (function () {

      if (this.get('currentPath') === 'accountmanagement.update') {

        var currentAccountId = this.get('currentAccountId'),
            isActive = false;

        if (this.get('isBankLogin')) {
          this.get('bankaccount.accounts').forEach(function (account) {
            if (account.get('id') === currentAccountId) {
              isActive = true;
            }
          });
        } else if (this.get('bankaccount.id') === currentAccountId) {
          isActive = true;
        }

        return isActive;
      }
    }).property('currentPath', 'currentAccountId'),

    // needs: ['application', 'accountmanagement/update'],// used in view

    hasMultipleBankloginAccounts: (function () {
      return this.get('bankaccount.bankLogin') && this.get('bankaccount.accounts.length') > 1;
    }).property('bankaccount.accounts.length'),

    isBankLogin: (function () {
      // bankLogin has an accounts relation
      return this.get('bankaccount.accounts.length');
    }).property('bankaccount.accounts'),

    bankLoginTitle: (function () {
      var title;
      var plural = this.get('isBankLogin') && this.get('bankaccount.accounts.length') > 1;
      var bank = this.get('bankaccount.bank');
      if (plural) {
        title = bank ? bank.get('title') + ' internetbankieren' : 'undefined';
        this.set('bankLoginTitle', title); // This freezes the calculated property!
      } else {
          title = bank.get('title') + ' rekening';
        }
      return title;
    }).property('bankaccount.bank', 'bankaccount.accounts.length'),

    bankTitle: (function () {
      if (this.get('bankaccount.bank.title') !== undefined) {
        return this.get('bankaccount.bank.title') + ' rekening';
      } else {
        return 'Rekening wordt toegevoegd!';
      }
    }).property('bankaccount.bank'),

    /**-------------------------
      Events
    ---------------------------*/

    click: function click(e) {

      var trigger = this.$(),
          target = e.relatedTarget;

      var previousTrigger = this.get('activeTrigger');

      // open
      if (!previousTrigger) {
        this.set('activeTrigger', trigger);
        this.sendAction('showModal', this.get('bankaccount'));
        trigger.addClass('active-modaltrigger');
      } else if (trigger.hasClass('active-modaltrigger')) {
        // stop
        this.set('activeTrigger', trigger);
        this.sendAction('stopModal');
        trigger.removeClass('active-modaltrigger');
        previousTrigger.removeClass('active-modaltrigger');
      } else {
        // swap
        previousTrigger.removeClass('active-modaltrigger');
        this.set('activeTrigger', trigger);
        this.sendAction('swapModal', this.get('bankaccount'));
        trigger.addClass('active-modaltrigger');
      }
    }
  });
});