define('front-end-cli/pods/components/budget/index-details/component', ['exports', 'ember', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _ember, _emberKeyboardShortcutsMixinsComponent) {
  exports['default'] = _ember['default'].Component.extend(_emberKeyboardShortcutsMixinsComponent['default'], {

    classNames: ['BudgetIndexDetails'],

    // External Bindings
    selectedCategory: null,
    isPlus: null,
    year: null,
    month: null,

    // Properties
    totalCurrentNettedExpenses: null,
    totalExpectedNettedExpenses: null,
    totalCurrentNettedIncome: null,
    totalExpectedNettedIncome: null,

    expensesOutsideBudget: null,
    incomeOutsideBudget: null,
    totalOutsideBudget: null,

    selectedYear: _ember['default'].computed('year', function () {
      return this.get('year') || moment().year();
    }),

    hasMonth: _ember['default'].computed('month', function () {
      return !!this.get('month');
    }),

    periodTitle: _ember['default'].computed('year', 'month', function () {
      var startDayFinancialMonth = this.get('startDayFinancialMonth') * 1,
          customFinancialMonth = startDayFinancialMonth >= 15,
          currentMonth = undefined,
          currentYear = undefined;

      if (this.get('year') || this.get('month')) {
        if (this.get('month')) {
          currentMonth = moment(this.get('year') + '-' + this.get('month') + '-01');
          currentYear = moment(this.get('year') + '-' + this.get('month') + '-01');
        } else {
          currentMonth = null;
          currentYear = moment(this.get('year') + '-01-01');
        }
      } else if (customFinancialMonth && moment().date() >= startDayFinancialMonth) {
        currentMonth = moment().add(1, 'month');
        currentYear = moment().add(1, 'month');
      } else {
        currentMonth = moment();
        currentYear = moment();
      }

      var title = '';
      if (currentMonth !== null) {
        title += currentMonth.format('MMM') + ' ';
      }
      title += currentYear.year();

      return title;
    }),

    totalResultCurrent: _ember['default'].computed('selectedCategory.currentExpenses', 'selectedCategory.currentIncome', function () {
      return this.get('selectedCategory.currentExpenses') + this.get('selectedCategory.currentIncome');
    }),

    totalResultBudgetCurrent: _ember['default'].computed('totalResultCurrent', 'selectedCategory.expectedNetted', function () {
      if (this.get('selectedCategory.expectedNetted') > 0) {
        return this.get('totalResultCurrent') - this.get('selectedCategory.expectedNetted');
      } else {
        // expenses total + budget = result
        return this.get('totalResultCurrent') - this.get('selectedCategory.expectedNetted');
      }
    }),

    hasIncomeOrExpenses: _ember['default'].computed('expensesOutsideBudget', 'incomeOutsideBudget', function () {
      return this.get('expensesOutsideBudget') || this.get('incomeOutsideBudget');
    }),

    isPlusAndNotYearScope: _ember['default'].computed('isPlus', 'hasMonth', function () {
      return this.get('isPlus') && this.get('hasMonth');
    }),

    totalResultIncome: _ember['default'].computed('totalCurrentNettedIncome', 'totalExpectedNettedIncome', function () {
      return this.get('totalCurrentNettedIncome') - this.get('totalExpectedNettedIncome');
    }),

    totalResultExpenses: _ember['default'].computed('totalCurrentNettedExpenses', 'totalExpectedNettedExpenses', function () {
      return this.get('totalCurrentNettedExpenses') - this.get('totalExpectedNettedExpenses');
    }),

    totalResult: _ember['default'].computed('totalCurrentNettedIncome', 'totalCurrentNettedExpenses', 'totalOutsideBudget', function () {
      return this.get('totalCurrentNettedIncome') + this.get('totalCurrentNettedExpenses') + this.get('totalOutsideBudget');
    }),

    totalResultBudget: _ember['default'].computed('totalExpectedNettedExpenses', 'totalExpectedNettedIncome', function () {
      return this.get('totalExpectedNettedIncome') + this.get('totalExpectedNettedExpenses');
    }),

    totalResultDifference: _ember['default'].computed('totalResult', 'totalResultBudget', function () {
      return this.get('totalResult') - this.get('totalResultBudget');
    }),

    currentNettedTitle: _ember['default'].computed('selectedCategory.currentNetted', function () {

      var title = undefined,
          cat = this.get('selectedCategory'),
          isIncomeCat = /inkomsten/i.test(cat.get('name')) || /inkomsten/i.test(cat.get('catagory.name')),
          forceIncome = isIncomeCat && cat.get('currentNetted') === 0;

      if (this.get('selectedCategory.currentNetted') > 0 || forceIncome) {
        title = 'inkomsten';
      } else {
        title = 'uitgaven';
      }
      return title;
    }),

    outsideBudget: (function () {

      var incomeOutside = 0,
          expensesOutside = 0,
          totalOutside = 0;

      this.get('categories').forEach(function (cat) {
        if (cat.get('expectedNetted') == null && cat.get('currentNetted') > 0) {
          incomeOutside += cat.get('currentNetted') * 1;
        }
        if (cat.get('expectedNetted') == null && cat.get('currentNetted') < 0) {
          expensesOutside += cat.get('currentNetted') * 1;
        }
      });

      totalOutside = incomeOutside + expensesOutside;

      this.set('totalOutsideBudget', totalOutside);
      this.set('incomeOutsideBudget', incomeOutside);
      this.set('expensesOutsideBudget', expensesOutside);
    }).observes('categories'),

    totalBudgetted: (function () {

      var currentNettedExpenses = 0,
          expectedNettedExpenses = 0,
          currentNettedIncome = 0,
          expectedNettedIncome = 0,
          incomeOutsideBudget = 0,
          expensesOutsideBudget = 0;

      this.get('categories').filterBy('categoryType', 'income').forEach(function (category) {
        expectedNettedIncome += category.get('expectedNetted') * 1;

        if (category.get('expectedNetted') == null) {
          incomeOutsideBudget += category.get('currentNetted') * 1;
        } else {
          currentNettedIncome += category.get('currentNetted') * 1;
        }
      });

      this.set('totalCurrentNettedIncome', currentNettedIncome);
      this.set('totalExpectedNettedIncome', expectedNettedIncome);

      this.get('categories').filterBy('categoryType', 'expenses').forEach(function (category) {
        expectedNettedExpenses += category.get('expectedNetted') * 1;

        if (category.get('expectedNetted') == null) {
          expensesOutsideBudget += category.get('currentNetted') * 1;
        } else {
          currentNettedExpenses += category.get('currentNetted') * 1;
        }
      });

      this.set('totalCurrentNettedExpenses', currentNettedExpenses);
      this.set('totalExpectedNettedExpenses', expectedNettedExpenses);
    }).observes('categories'),

    /**
      Reset selectedCategory when the event-target
      was outside the cat/subcat/detail component.
    */
    _mouseDownResponder: function _mouseDownResponder(e) {
      if (this.get('selectedCategory')) {

        var target = _ember['default'].$(e.target);
        var notWithinBudgetIndexDetails = !target.hasClass('BudgetIndexDetails') && !target.parents('.BudgetIndexDetails').length,
            notWithinBudgetIndexCat = !target.hasClass('BudgetIndexCat-item') && !target.parents('.BudgetIndexCat-item').length,
            notWithinBudgetIndexSubcat = !target.hasClass('BudgetIndexSubcat-item') && !target.parents('.BudgetIndexSubcat-item').length;

        if (notWithinBudgetIndexDetails && notWithinBudgetIndexCat && notWithinBudgetIndexSubcat) {
          this.set('selectedCategory', false);
        }
      }
    },

    setMouseDownListeners: (function () {
      this.mdr = this._mouseDownResponder.bind(this);
      _ember['default'].$(document).on('mousedown touchend', this.mdr);
    }).on('didInsertElement'),

    tearDown: (function () {
      _ember['default'].$(document).off('mousedown', this.mdr);
    }).on('willDestroyElement'),

    actions: {
      goBack: function goBack() {
        this.set('selectedCategory', false);
      }
    },

    keyboardShortcuts: {
      'escape': function escape() {

        this.get('categories').forEach(function (cat) {
          cat.set('isActive', false);
          cat.get('subcategories').forEach(function (subcat) {
            subcat.set('isActive', false);
          });
        });

        this.set('selectedCategory', null);
      }
    }
  });
});