define('front-end-cli/pods/components/transaction-details/labels/labels-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'transaction-detail-row',
    classNames: ['TransactionDetails-row', 'TransactionDetails-rowOptional', 'LabelDetailRow'],
    classNameBindings: ['isFilledOutOrEditing', 'isEditing', 'showOverflow'],

    /**-------------------------
     External Bindings
     ---------------------------*/

    transaction: null,
    isEditing: null,
    labels: [],

    disabled: (function () {
      return !this.get('isEditing');
    }).property('isEditing'),

    showOverflow: false,
    isFilledOutOrEditing: (function () {
      var isFilledOutOrEditing = this.get('transaction.labels.length') > 0 || this.get('isEditing');

      _ember['default'].run.later(this, function () {
        this.set('showOverflow', isFilledOutOrEditing);
      }, 400);

      return isFilledOutOrEditing;
    }).property('transaction.labels.length', 'isEditing'),

    /**-------------------------
     Actions
     ---------------------------*/

    actions: {}
  });
});