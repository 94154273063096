define('front-end-cli/pods/components/category-dissect-list-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['CategoryDissectListItem'],
    classNameBindings: ['isActive', 'isPreviewed'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    categories: null,
    activeCategory: null, // or sub
    cat: null,
    preview: null,
    showDetails: null,

    /**-------------------------
     Calculated Properties
     ---------------------------*/

    hasNoExpectedNetted: (function () {
      return this.get('cat.expectedNetted') === null;
    }).property('cat.expectedNetted'),

    /**-------------------------
      Methods
    ---------------------------*/

    isActive: (function () {
      return this.get('activeCategory.id') == this.get('cat.id');
    }).property('activeCategory.id'),

    isPreviewed: (function () {
      return this.get('preview.id') == this.get('cat.id');
    }).property('preview'),

    removeFromBudgetResult: (function () {
      return this.get('cat.removeFromBudgetResult');
    }).property('cat.removeFromBudgetResult'),

    removeFromNettedResult: (function () {
      return this.get('cat.removeFromNettedResult');
    }).property('cat.removeFromNettedResult'),

    /**-------------------------
      Events
    ---------------------------*/

    mouseEnter: function mouseEnter() {
      this.set('preview', this.get('cat'));
    },

    click: function click() {
      this.sendAction('action', this.get('cat'));
    }
  });
});