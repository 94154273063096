define('front-end-cli/views/automatic-import/edit-consent', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({
    generateConsentUrl: function generateConsentUrl() {
      _ember['default'].$.ajax({
        type: 'GET',
        url: '/api/v3/automaticimport?procedure=generateconsenturl'
      }).success(function (response) {
        window.location.replace(response.requestData.url);
      }).error(function (error) {
        //Nothing here
      });
    },

    convertToManual: function convertToManual() {
      var hash = window.location.hash;
      var splitArray = hash.split('/');

      _ember['default'].$.ajax({
        type: 'GET',
        url: '/api/v3/automaticimport?procedure=converttomanualimport&accountid=' + splitArray[3]
      }).success(function (response) {
        window.location.replace(response.requestData);
      }).error(function (error) {
        //Nothing here
      });
    }
  });
});