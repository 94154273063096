define('front-end-cli/models/debt-mortgage', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    debt: _emberData['default'].belongsTo('debt'),

    type: _emberData['default'].attr('number'),
    description: _emberData['default'].attr('number'),
    provider: _emberData['default'].attr('string'),
    contractNo: _emberData['default'].attr('string'),
    startDate: _emberData['default'].attr('string'),
    endDate: _emberData['default'].attr('string'),
    debtAppliesTo: _emberData['default'].attr('string'), //schuldenaren
    interestOnlyLoanChoice: _emberData['default'].attr('number'), //aflossingsvrij
    amountAtStart: _emberData['default'].attr('string'),
    kadasterAmount: _emberData['default'].attr('number'),
    remainingAmount: _emberData['default'].attr('number'),
    endDateAmount: _emberData['default'].attr('number'),
    yearInterest: _emberData['default'].attr('string'),
    interestFixed: _emberData['default'].attr('number'),
    interestEndDate: _emberData['default'].attr('string'),
    monthlyDeposit: _emberData['default'].attr('number'),
    taxDeductible: _emberData['default'].attr('number'),
    endDateTaxDeductible: _emberData['default'].attr('string'),
    voidsOnDeceasedChoice: _emberData['default'].attr('number'),
    insuredByDecease: _emberData['default'].attr('string'),
    insuredByDeceaseAmount: _emberData['default'].attr('number'),
    sourceDeathBenefit: _emberData['default'].attr('string'),

    //FUNCTIONS
    isTypeSelected: (function () {
      return this.get('type') != null;
    }).property('type'),

    isInterestOnlyLoanChoice: (function () {
      return this.get('interestOnlyLoanChoice') == 1;
    }).property('interestOnlyLoanChoice'),

    isVoidsOnDeceasedChoice: (function () {
      return this.get('voidsOnDeceasedChoice') == 1;
    }).property('voidsOnDeceasedChoice'),

    isInterestFixed: (function () {
      return this.get('interestFixed') == 1;
    }).property('interestFixed'),

    isOwnHome: (function () {
      return this.get('description') == 1;
    }).property('description')
  });
});