define("front-end-cli/pods/income-expenses/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 4,
              "column": 2
            }
          },
          "moduleName": "front-end-cli/pods/income-expenses/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "no-accounts"]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 2
              },
              "end": {
                "line": 6,
                "column": 2
              }
            },
            "moduleName": "front-end-cli/pods/income-expenses/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "no-active-accounts"]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 4
                },
                "end": {
                  "line": 87,
                  "column": 4
                }
              },
              "moduleName": "front-end-cli/pods/income-expenses/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "trends-chart-netted", [], ["currentUser", ["subexpr", "@mut", [["get", "currentUser"]], []], "chartType", ["subexpr", "@mut", [["get", "type"]], []], "data", ["subexpr", "@mut", [["get", "trendsnetted"]], []], "preferences", ["subexpr", "@mut", [["get", "preferences"]], []], "currentStartDate", ["subexpr", "@mut", [["get", "currentStartDate"]], []], "currentEndDate", ["subexpr", "@mut", [["get", "currentEndDate"]], []], "periode", ["subexpr", "@mut", [["get", "periode"]], []], "startDate", ["subexpr", "@mut", [["get", "startDate"]], []], "endDate", ["subexpr", "@mut", [["get", "endDate"]], []], "periodType", ["subexpr", "@mut", [["get", "periodType"]], []], "startDayFinancialMonth", ["subexpr", "@mut", [["get", "currentUser.model.state.startDayFinancialMonth"]], []], "transitionAction", "transitionAction"]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.2",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 2
              },
              "end": {
                "line": 89,
                "column": 2
              }
            },
            "moduleName": "front-end-cli/pods/income-expenses/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            return morphs;
          },
          statements: [["inline", "income-expenses-header", [], ["categories", ["subexpr", "@mut", [["get", "categories"]], []], "category", ["subexpr", "@mut", [["get", "category"]], []], "subcategory", ["subexpr", "@mut", [["get", "subcategory"]], []], "labels", ["subexpr", "@mut", [["get", "labels"]], []], "filterLabelsString", ["subexpr", "@mut", [["get", "filterLabelsString"]], []], "costType", ["subexpr", "@mut", [["get", "costType"]], []], "type", ["subexpr", "@mut", [["get", "type"]], []], "transactions", ["subexpr", "@mut", [["get", "transactions"]], []], "preferences", ["subexpr", "@mut", [["get", "preferences"]], []], "startDayFinancialMonth", ["subexpr", "@mut", [["get", "currentUser.model.state.startDayFinancialMonth"]], []], "startDate", ["subexpr", "@mut", [["get", "startDate"]], []], "endDate", ["subexpr", "@mut", [["get", "endDate"]], []], "periodType", ["subexpr", "@mut", [["get", "periodType"]], []], "oldestTransactionDate", ["subexpr", "@mut", [["get", "oldestTransactionDate"]], []], "hasSidebar", ["subexpr", "@mut", [["get", "hasSidebar"]], []], "transitionAction", "transitionAction", "setCostTypeAction", "setCostTypeAction"]], ["inline", "category-dissect", [], ["currentUser", ["subexpr", "@mut", [["get", "currentUser"]], []], "type", ["subexpr", "@mut", [["get", "type"]], []], "categories", ["subexpr", "@mut", [["get", "categories"]], []], "category", ["subexpr", "@mut", [["get", "category"]], []], "subcategory", ["subexpr", "@mut", [["get", "subcategory"]], []], "preferences", ["subexpr", "@mut", [["get", "preferences"]], []], "periodType", ["subexpr", "@mut", [["get", "periodType"]], []], "lastUpdateIsBeforeStartDate", ["subexpr", "@mut", [["get", "lastUpdateIsBeforeStartDate"]], []], "hasNoTransactions", ["subexpr", "@mut", [["get", "hasNoTransactions"]], []], "hasNoEnabledTransactions", ["subexpr", "@mut", [["get", "hasNoEnabledTransactions"]], []]]], ["block", "unless", [["get", "activeRelatedTransactions"]], [], 0, null]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.2",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 89,
              "column": 2
            }
          },
          "moduleName": "front-end-cli/pods/income-expenses/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasNoActiveAccounts"]], [], 0, 1]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 111,
            "column": 0
          }
        },
        "moduleName": "front-end-cli/pods/income-expenses/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["block", "if", [["get", "hasNoAccounts"]], [], 0, 1], ["inline", "related-transactions", [], ["transactions", ["subexpr", "@mut", [["get", "transactions"]], []], "categories", ["subexpr", "@mut", [["get", "categories"]], []], "category", ["subexpr", "@mut", [["get", "category"]], []], "receipts", ["subexpr", "@mut", [["get", "receipts"]], []], "subcategory", ["subexpr", "@mut", [["get", "subcategory"]], []], "labels", ["subexpr", "@mut", [["get", "labels"]], []], "isActive", ["subexpr", "@mut", [["get", "activeRelatedTransactions"]], []], "hasSidebar", ["subexpr", "@mut", [["get", "hasSidebar"]], []], "type", ["subexpr", "@mut", [["get", "type"]], []], "hasNoTransactions", ["subexpr", "@mut", [["get", "hasNoTransactions"]], []], "hasNoEnabledTransactions", ["subexpr", "@mut", [["get", "hasNoEnabledTransactions"]], []], "toggleModal", "toggleModal", "refreshRoute", "refreshRoute", "addSubcategory", "addSubcategory", "setCostTypeAction", "setCostTypeAction"]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});