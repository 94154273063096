define('front-end-cli/components/range-select', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    // sticky template name for when this component gets extended
    layoutName: 'components/range-select',

    value: null, // two way binding
    min: null,
    max: null,
    mid: null, // one way binding
    prompt: 'Kies',

    // regular range property
    range: null,

    run: (function () {
      this.send('defineRange');
    }).observes('value', 'mid').on('init'),

    actions: {
      defineRange: function defineRange() {

        var min = this.get('min'),
            max = this.get('max'),
            mid = this.get('mid');

        var rangeStart, rangeEnd, selected;

        if (_ember['default'].isNone(min) || _ember['default'].isNone(max)) {
          throw new Error('range-select component needs `min` and `max` declared in its Handlebars helper');
        }

        // convert `now` shortcut to current yyyy-mm-dd or yyyy
        if (mid) {
          if (mid === 'now') mid = new Date().toISOString().slice(0, 4);else if (mid.match(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/)) mid = mid.slice(0, 4);
        }

        // Make sure non-date ranges are numeric
        if (mid && (min + max + mid).toString().match(/\D/) || (min + max).toString().match(/\D/)) {
          throw new Error('Range-select can only parse numeric ranges');
        }

        min = min * 1;
        max = max * 1;
        mid = mid * 1;

        // Figure out the start and end of our range
        if (mid) {
          rangeStart = mid - min;
          rangeEnd = mid + max;
        } else if (this.get('minFallback') && this.get('maxFallback')) {

          rangeStart = this.get('minFallback');
          rangeEnd = this.get('maxFallback');

          if (!rangeStart || !rangeEnd) {
            console.error('date-range-select: `mid` is undefined, and so are the min/max fallbacks.');
            // Die a gracefull death
            mid = new Date().toISOString().slice(0, 10);
          }
        } else {
          rangeStart = min;
          rangeEnd = max;
        }

        // Parse the ranges
        var value = this.get('value');
        var prompt = this.get('prompt') ? this.get('prompt') : 'Kies';
        this.set('valueLabel', value || prompt);

        var width = this.get('width') ? this.get('width') + '%' : '100%';
        this.set('width', width);

        var number = rangeStart,
            range = [];
        while (number <= rangeEnd) {
          selected = number == value ? 'selected' : '';
          range.push({
            value: number,
            selected: selected
          });
          number++;
        }
        this.set('range', range);
      },

      changeValue: function changeValue(e) {
        var selects = this.$().find('select'),
            value;

        value = selects.eq(0).prop('value');
        this.set('value', value);

        /**
          Until we use ember data's isDirty, the following
          action triggers the action defined in the rage-select
          hbs helper, which marks profile model dirty.
        */
        this.sendAction('action', true);
      }
    }
  });
});