define('front-end-cli/models/receipt', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    date: _emberData['default'].attr('date'),
    description: _emberData['default'].attr('string'),
    amount: _emberData['default'].attr('string'),
    attachmentId: _emberData['default'].attr('string'),
    attachmentName: _emberData['default'].attr('string'),
    attachmentUrl: _emberData['default'].attr('string'),
    attachmentMime: _emberData['default'].attr('string'),
    attachmentMimeImage: _emberData['default'].attr('string'),
    transaction: _emberData['default'].belongsTo('transaction', { async: true }),

    escapedAttachmentUrlStyle: _ember['default'].computed('attachmentUrl', function () {
      return ('background-image: url(' + this.get('attachmentUrl') + ');').htmlSafe();
    })
  });
});