define('front-end-cli/controllers/accountmanagement/edit/install-bwa', ['exports', 'ember', 'front-end-cli/mixins/steps'], function (exports, _ember, _frontEndCliMixinsSteps) {
  exports['default'] = _ember['default'].Controller.extend(_frontEndCliMixinsSteps['default'], {

    /**
      Check if the firefox bwa is installed
    */
    bwaFFInstalledCheck: (function () {
      var image = document.createElement('img'),
          _this = this;

      image.addEventListener('load', function () {
        _this.set('currentUser.firefoxBwaInstalled', true);
      }, false);

      image.setAttribute('src', 'chrome://yunoo/content/afaspersonal.png');
    }).on('init'),

    /**
      The initial step shown in the guide.
    */
    step: (function () {
      return this.get('bwaInstalled') ? 2 : 1;
    }).property(),

    /**
      When the bwa was just installed
      make sure the step is removed from
      the install-bwa-guide.
       This can't be handled in the `step`
      property above since it is set manually
      which mutes its observer.
    */
    defineFirstStep: (function () {
      if (this.get('bwaInstalled') && this.get('step') === 1) {
        this.set('step', 2);
      }
    }).observes('bwaInstalled'),

    /**
      When the bwa get installed and
      when the bwa is loaded for any AP tab,
      An instrument call is made which set
      `chromeBwaInstalled` or
      `firefoxBwaInstalled`.
       This way the app always knows if a bwa
      plugin is installed.
    */
    bwaInstalled: (function () {
      return this.get('currentUser.chromeBwaInstalled') || this.get('currentUser.firefoxBwaInstalled');
    }).property('currentUser.chromeBwaInstalled', 'currentUser.firefoxBwaInstalled'),

    actions: {

      installBwa: function installBwa() {
        if (bowser.chrome) {
          chrome.webstore.install('https://chrome.google.com/webstore/detail/fhdjnejhhklnclpkbnfmfimijnlmghfk', function () {// jshint ignore:line
            // Success
          }, function (err) {
            // Error
          });
        } else if (bowser.firefox) {
            // Show FireFox overlay
            _ember['default'].run.next(this, function () {
              var template = 'accountmanagement/edit/install-bwa-ff-overlay',
                  controller = null,
                  model = {
                updatedMultipleAccounts: null
              };

              // Render the bwa-success template in the modal-route
              this.send('toggleModal', template, controller, model);
            });
            this.transitionToRoute({ queryParams: { stepTo: 'bwa-4' } });
            window.location = 'https://mijnhuishoudboekje.mijngeldzaken.nl/uploader/afaspersonal-uploader.xpi';
          }
      }
    }
  });
});