define("front-end-cli/templates/accountmanagement/edit/discouraged-svg", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "front-end-cli/templates/accountmanagement/edit/discouraged-svg.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "version", "1.1");
        dom.setAttribute(el1, "id", "discouraged-emblem");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el1, "x", "0px");
        dom.setAttribute(el1, "y", "0px");
        dom.setAttribute(el1, "width", "116.162px");
        dom.setAttribute(el1, "height", "99.294px");
        dom.setAttribute(el1, "viewBox", "0 0 116.162 99.294");
        dom.setAttribute(el1, "enable-background", "new 0 0 116.162 99.294");
        dom.setAttributeNS(el1, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "id", "emblem-shape-dark");
        dom.setAttribute(el2, "fill", "#2F669E");
        dom.setAttribute(el2, "d", "M106.819,1.608l0.265-0.023c0,0,1.124,0.268,1.749,2.834h-6.743\n  c1.118-0.901,1.761-1.419,1.79-1.442C105.171,1.938,106.32,1.675,106.819,1.608z M3.743,86.068c-1.043,1.289-1.31,2.438-1.377,2.937\n  l-0.024,0.264c0,0,0.742,1.719,2.693,1.719v-6.166C4.223,85.589,3.782,86.021,3.743,86.068z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "id", "emblem-shape");
        dom.setAttribute(el2, "fill", "#3B81C6");
        dom.setAttribute(el2, "d", "M57.583,1.585c0,0-1.458,0-3.403,1.67c-0.545,0.468-49.586,41.899-50,42.378\n  c-1.675,1.941-1.684,3.4-1.684,3.4L2.342,89.269c0,0,0.001-0.095,0.024-0.264c0.068-0.499,0.334-1.647,1.377-2.937\n  c0.039-0.048,0.48-0.479,1.292-1.247C9.09,80.983,92.119,12.454,102.09,4.419c1.118-0.901,1.761-1.419,1.79-1.442\n  c1.291-1.039,2.44-1.302,2.939-1.369c0.169-0.022,0.265-0.023,0.265-0.023H57.583z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("text");
        dom.setAttribute(el2, "id", "emblem-text");
        dom.setAttribute(el2, "transform", "matrix(0.7785 -0.6277 0.6277 0.7785 11.9463 64.4678)");
        dom.setAttribute(el2, "fill", "#FFFFFF");
        dom.setAttribute(el2, "font-family", "'DINNextW01-Regular'");
        dom.setAttribute(el2, "font-size", "10");
        var el3 = dom.createTextNode("Lastig en tijdrovend");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});