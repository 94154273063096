define('front-end-cli/pods/index/view', ['exports', 'ember', 'front-end-cli/mixins/window-resize'], function (exports, _ember, _frontEndCliMixinsWindowResize) {
  exports['default'] = _ember['default'].View.extend(_frontEndCliMixinsWindowResize['default'], {
    didInsertElement: function didInsertElement() {
      if (!Modernizr.input.placeholder) {
        // this.$('input, textarea').placeholder();
      }
    },
    /**
      The Bootstrap floats prevents us from using
      height for our css driven vertical alignment of
      the account-setup content.
      Setting the height manually resolves this issue.
    */
    setIndexHeight: (function () {
      var wh = _ember['default'].$(window).height(),
          hh = _ember['default'].$('main > header').height();

      _ember['default'].$('.vertical-centered-container').css({
        paddingTop: hh,
        height: wh - hh
      });

      _ember['default'].$('.vertical-centered-content').css({
        marginBottom: 35
      });
    }).on('didInsertElement', 'windowResize')
  });
});