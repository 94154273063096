define('front-end-cli/models/insurance-ao', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    incomeNow: _emberData['default'].belongsTo('income-now'),
    description: _emberData['default'].attr('string'),
    aoChoice: _emberData['default'].attr('string'),
    provider: _emberData['default'].attr('string'),
    gross: _emberData['default'].attr('number'),
    endDate: _emberData['default'].attr('string'),
    policyNr: _emberData['default'].attr('string')
  });
});