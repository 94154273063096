define('front-end-cli/pods/components/budget/matrix-table/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'table',
    classNames: ['table', 'MatrixTable'],

    // External bindings
    categories: null,
    isPlusUser: null,
    pastStartDayFinancialMonth: null,
    months: _ember['default'].computed('property', function () {
      var _this = this;

      var monthsArray = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
          monthsObjectArray = [];
      var currentMonth = this.get('currentMonth');

      monthsArray.forEach(function (m, index) {
        var mo = { name: m };
        if (_this.get('isPlusUser')) {
          mo.dim = false;
        } else {
          if (index != currentMonth) {
            mo.dim = true;
          }
        }
        monthsObjectArray.push(mo);
      });
      return monthsObjectArray;
    }),

    currentMonth: _ember['default'].computed('pastStartDayFinancialMonth', function () {
      var currentMonth = undefined;
      if (moment().month() === 11 && this.get('pastStartDayFinancialMonth')) {
        currentMonth = 0;
      } else {
        currentMonth = moment().month();
      }
      return currentMonth;
    }),

    monthTotals: _ember['default'].computed('categories.@each.budgetsSum', function () {
      var _this2 = this;

      var totals = [],
          total = 0;

      var currentMonth = this.get('currentMonth');
      this.get('categories').forEach(function (cb) {
        if (cb.get('hasSubBudgets')) {
          cb.get('calculatedSubBudgets').forEach(function (b, i) {

            if (!totals[i]) totals[i] = { amount: 0 };

            if (!_this2.get('isPlusUser')) {
              totals[i].dim = i != currentMonth;
            }

            totals[i].amount += b.amount * 1;
            total += b.amount * 1;
          });
        } else {
          cb.get('budgets').forEach(function (b, i) {
            if (!totals[i]) totals[i] = { amount: 0 };
            totals[i].amount += b.amount * 1;
            total += b.amount * 1;
          });
        }
      });

      totals[12] = { amount: total };

      return totals;
    }),

    actions: {

      /**
        This action is triggered by the whole <tr>
        and should toggle subcat visibility only when
        there are subcat-budgets.
      */
      toggleSubcategoriesWhenBudgetted: function toggleSubcategoriesWhenBudgetted(category) {
        if (category.get('hasSubBudgets')) {
          category.toggleProperty('showSubcategories');
        }
      },

      /**
        This action is triggered by the left-title
        and right-sum <td> and should toggle subcat
        visibility only when there are no dirty
        subcats-budgets.
      */
      toggleSubcategories: function toggleSubcategories(category) {
        if (!category.get('hasDirtySubBudgets')) {
          category.toggleProperty('showSubcategories');
        }
      },

      showPlusIncentive: function showPlusIncentive() {
        this.sendAction('showPlusIncentive');
      }
    }
  });
});