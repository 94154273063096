define('front-end-cli/routes/accountmanagement/cashbook', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller, model) {

      // Call _super for default behavior
      this._super(controller, model);

      // Reset the cashbook controller
      controller.setProperties({
        cashbooktitle: null
      });
    },

    resetController: function resetController(controller, isExiting, transition) {

      if (isExiting) {
        controller.setProperties({
          isTourVisible: null
        });
      }
    }
  });
});