define('front-end-cli/initializers/accounting', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() /* container, application */{
    /*
      Global settings for accounting.js money display formating.
      We'd much rather have accounting be AMD ...
    */
    window.accounting.settings = {
      currency: {
        symbol: '€', // default currency symbol is '$'
        format: '%s %v', // controls output: %s = symbol, %v = value/number (can be object: see below)
        decimal: ',', // decimal point separator
        thousand: '.', // thousands separator
        precision: 2 // decimal places
      },
      number: {
        precision: 0, // default precision on numbers is 0
        thousand: '.',
        decimal: ','
      }
    };
  }

  exports['default'] = {
    name: 'accounting',
    initialize: initialize
  };
});