define('front-end-cli/views/accountmanagement/edit/step-circle', ['exports', 'ember'], function (exports, _ember) {
  /**
    This view manages the active state of the wizard dots
  */
  exports['default'] = _ember['default'].View.extend({

    tagName: 'li',
    classNameBindings: [':step', 'active'],
    active: (function () {
      return this.get('step') == this.get('controller.step');
    }).property('controller.step')
  });
});
/*click: function() {
  this.get('controller').send('showStep', this.get('step'));
}*/