define('front-end-cli/pods/components/nibud/categories-relation-selector/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'section',

    /* MidasCloud Api Variables */
    title: null,
    filters: null,
    calculate: null,
    beforeResult: null,

    /* Page Variables */
    budgetIsSelected: false,

    actions: {
      sendSearchFilters: function sendSearchFilters() {
        this.sendAction('action');
      },
      displayOrHideYearDisplay: function displayOrHideYearDisplay() {
        var budgetInput = document.getElementById('Budget');

        if (budgetInput) {
          if (budgetInput.checked) {
            this.set('budgetIsSelected', true);
          } else {
            this.set('budgetIsSelected', false);
          }
        }
      }
    }
  });
});