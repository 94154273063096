define('front-end-cli/routes/accountmanagement/setup', ['exports', 'ember', 'front-end-cli/utils/accountmanagement/bank-choice'], function (exports, _ember, _frontEndCliUtilsAccountmanagementBankChoice) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params) {
      return this.store.find('bank');
    },

    setupController: function setupController(controller, model) {

      // Reset the setup wizard
      controller.setProperties({
        error: null,
        syncing: null,
        bankChoice: _frontEndCliUtilsAccountmanagementBankChoice['default'].create(),
        step: 1,
        previousSteps: [],
        isTourVisible: null
      });

      controller.set('banks', model);

      _ember['default'].$.ajax({
        url: 'api/v3/analytics/track/accountwizard-start',
        type: 'POST',
        dataType: 'json',
        data: {
          event: 0
        }
      });

      _ember['default'].$.ajax({
        url: 'api/v3/analytics/track/accountwizard-steps',
        type: 'POST',
        dataType: 'json',
        data: {
          event: 1,
          bank: null,
          auto: null,
          loginMethod: null
        }
      });
    }
  });
});