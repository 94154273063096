define('front-end-cli/views/profiler/input-plus', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({
    tagName: 'li',
    templateName: 'profiler/mixin/plus',
    classNames: ['plus-row'],

    click: function click() {

      /**
          This enables `add` animation only when using the plus view.
          When `profiler` is a proper module this should go!
      */
      // App.set('animateProfilerAddBlock', true);

      // FIRE THE EVENT DEFINED IN HBS VIEW DECLARATION
      this.get('controller').send(this.get('event'));

      this.get('controller').send('setChangeStatus');
    },

    touchEnd: function touchEnd(evt) {
      this.get('controller').send(this.get('event'));

      this.get('controller').send('setChangeStatus');
    }
  });
});