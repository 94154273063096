define('front-end-cli/models/debt-consumer-credit', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    debt: _emberData['default'].belongsTo('debt'),

    type: _emberData['default'].attr('number'),
    description: _emberData['default'].attr('string'),
    lenderName: _emberData['default'].attr('string'), //naam geldgever
    contractNo: _emberData['default'].attr('string'),
    contractor: _emberData['default'].attr('string'),
    credit: _emberData['default'].attr('number'),
    monthlyDepositChoice: _emberData['default'].attr('number'), //afbetaling schuld per maand?
    monthlyDeposit: _emberData['default'].attr('number'),
    yearInterest: _emberData['default'].attr('string'),
    monthInterest: _emberData['default'].attr('number'),
    balance: _emberData['default'].attr('number'),

    remainingTheoreticalEndTime: _emberData['default'].attr('string'),
    endDate: _emberData['default'].attr('string'),
    releaseAtDeathChoice: _emberData['default'].attr('string'), //Vrijval bij overlijden?
    releaseAtDeathPerson: _emberData['default'].attr('string'), //Zo ja , bij wiens overlijden?

    isTypeSelected: (function () {
      return this.get('type');
    }).property('type'),
    isContinuous: (function () {
      return this.get('type') == 1;
    }).property('type'),
    isPersonalLoan: (function () {
      return this.get('type') == 2;
    }).property('type'),
    isCreditcard: (function () {
      return this.get('type') == 3;
    }).property('type'),
    showMonthlyDeposit: (function () {
      return this.get('monthlyDepositChoice') == 1;
    }).property('monthlyDepositChoice'),
    showReleaseAtDeath: (function () {
      return this.get('releaseAtDeathChoice') == 1;
    }).property('releaseAtDeathChoice')
  });
});