define('front-end-cli/pods/components/period-select-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['isSelected', 'isSubSelected', 'isDisabled'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    value: null,
    selectedPeriodType: null,
    subSelectedValue: null,
    selectedYear: null,
    selectedQaurter: null,
    selectedMonth: null,

    // Actions
    action: null,

    isSelected: _ember['default'].computed('selectedYear', 'selectedQuarter', 'selectedMonth', 'selectedPeriodType', function () {
      var value = this.get('value');
      if (this.get('selectedYear') == value && this.get('selectedPeriodType') === 3) {
        return true;
      } else if (this.get('selectedQuarter') == value && this.get('selectedPeriodType') === 2) {
        return true;
      } else if (this.get('selectedMonth') == value && this.get('selectedPeriodType') === 1) {
        // console.log('||||||', this.get('selectedQuarter'), this.get('value'), this.get('selectedPeriodType'));
        var valueIsQuarter = /^[0-9]{1}$/.test(value);
        if (valueIsQuarter) {
          return false;
        } else {
          return true;
        }
      }
    }),

    isSubSelected: (function () {
      if (this.get('isDisabled')) {
        return false;
      }

      var selectedPeriodType = this.get('selectedPeriodType'),
          subSelectedValue = this.get('subSelectedValue'),
          value = this.get('value'),
          valueIsMonth = /^[0-9]{2}$/.test(value),
          valueIsQuarter = /^[0-9]{1}$/.test(value);

      // Subactivate all months and quarters
      // since periodType is a year.
      if (selectedPeriodType == 3 && (valueIsQuarter || valueIsMonth)) {
        return true;
      } else if (selectedPeriodType == 2 && valueIsMonth) {
        // Subactivate all months in the quarter
        if (subSelectedValue == 1) {
          return (/01|02|03/.test(value)
          );
        } else if (subSelectedValue == 2) {
          return (/04|05|06/.test(value)
          );
        } else if (subSelectedValue == 3) {
          return (/07|08|09/.test(value)
          );
        } else if (subSelectedValue == 4) {
          return (/10|11|12/.test(value)
          );
        }
      } else if (selectedPeriodType == 1 && valueIsQuarter) {
        // Month
        var selectedMonth = this.get('selectedMonth');
        if (value == 1) {
          return (/01|02|03/.test(selectedMonth)
          );
        } else if (value == 2) {
          return (/04|05|06/.test(selectedMonth)
          );
        } else if (value == 3) {
          return (/07|08|09/.test(selectedMonth)
          );
        } else if (value == 4) {
          return (/10|11|12/.test(selectedMonth)
          );
        }
      } else {
        return this.get('value') == this.get('subSelectedValue');
      }
    }).property('value', 'subSelectedValue', 'selectedPeriodType', 'isDisabled'),

    isDisabled: (function () {
      var value = this.get('value'),
          valueIsYear = /^[0-9]{4}$/.test(value),
          valueIsMonth = /^[0-9]{2}$/.test(value),
          valueIsQuarter = /^[0-9]{1}$/.test(value);

      if (valueIsYear) {
        return false;
      } else {
        var currentDate = moment(),
            currentYear = currentDate.year(),
            currentMonth = currentDate.month() + 1,
            startDate = moment(this.get('oldestTransactionDate'), 'YYYY-MM-DD'),
            startMonth = startDate.month() + 1,
            startYear = startDate.year(),
            selectedYear = this.get('selectedYear');

        if (parseInt(this.get('startDayFinancialMonth')) !== 1 && currentDate.date() >= this.get('startDayFinancialMonth')) {
          currentMonth++;

          if (currentMonth === 13) {
            // Even though we all like our 13th month... Let's just put it to january!
            currentMonth = 1;
            currentYear++;
          }
        }

        if (parseInt(this.get('startDayFinancialMonth')) !== 1 && startDate.date() >= this.get('startDayFinancialMonth')) {
          startMonth++;

          if (startMonth === 13) {
            startMonth = 1;
            startYear++;
          }
        }

        if (selectedYear !== null && selectedYear === currentYear) {
          if (valueIsMonth) {
            return value > currentMonth;
          } else {
            // valueIsQuarter
            return value > Math.ceil(currentMonth / 3);
          }
        } else if (startYear !== null && startYear === selectedYear) {
          if (valueIsMonth) {
            return value < startMonth;
          } else {
            //valueIsQuarter
            return value < Math.ceil(startMonth / 3);
          }
        } else {
          return false;
        }
      }
    }).property('value', 'selectedYear', 'startDayFinancialMonth', 'oldestTransactionDate'),

    click: function click() {
      if (!this.get('isDisabled')) {
        this.sendAction('action', this.get('value'));
      }
    }
  });
});