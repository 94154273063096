define('front-end-cli/pods/components/transaction-details/comment/comment-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'transaction-detail-row',
    classNames: ['TransactionDetails-row', 'TransactionDetails-rowOptional', 'CommentRow'],
    classNameBindings: ['isFilledOutOrEditing', 'isEditing'],

    /**-------------------------
      External Bindings
    ---------------------------*/

    isEditing: null,
    transaction: null,

    /**-------------------------
      Properties
    ---------------------------*/

    isEditable: true,
    addingNewComment: null,

    isFilledOutOrEditing: (function () {
      return !!this.get('transaction.comment') || this.get('isEditing');
    }).property('transaction.comment', 'isEditing'),

    isFilledOutOrAddingNew: (function () {
      return !!this.get('transaction.comment') || this.get('addingNewComment');
    }).property('transaction.comment', 'addingNewComment'),

    // Reset when transaction changes
    transactionObserver: _ember['default'].observer('transaction', function () {
      this.set('addingNewComment', false);
    }),

    isNotEditing: (function () {
      return !this.get('isEditing');
    }).property('isEditing'),

    showCommentCharacters: (function () {
      // var element = Ember.$('.RelatedTransactionEdit-commentCharactersLeft');
      // if (this.get('transaction.comment')) {
      //   // Show.
      //   element.fadeIn(100);
      //   // Set debounce timer.
      //   Ember.run.debounce(this, this._fadeOut, 2000);
      // } else {
      //   // hide
      //   element.fadeOut('500');
      // }
      //
      // // Handle max-length
      // if (this.get('transaction.comment.length') > 256) {
      //   this.set('transaction.comment', this.get('transaction.comment').substr(0,256));
      // }
    }).observes('transaction.comment'),

    charactersLeft: (function () {
      return 256 - this.get('transaction.comment.length');
    }).property('transaction.comment'),

    actions: {
      addNewComment: function addNewComment() {
        if (!this.get('addingNewComment')) {
          this.set('isFocussed', true);
          this.set('addingNewComment', true);
        }
      },

      cancelAddingNew: function cancelAddingNew() {
        var _this = this;

        _ember['default'].run.next(function () {
          _this.set('transaction.comment', null);
          _this.set('addingNewComment', false);
        });
      },

      clearComment: function clearComment() {
        var _this2 = this;

        _ember['default'].run.next(function () {
          _this2.set('transaction.comment', null);
          _this2.set('addingNewComment', false);
        });
      }
    }
  });
});