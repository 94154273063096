define('front-end-cli/mixins/profiler/changed-state', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    /**
    Notify the controller that changes where made
    */
    updateChangeStatus: (function () {

      // Component
      if (this.get('targetObject')) {
        this.get('targetObject').send('setChangeStatus');
      }
      // View
      else {
          this.get('controller').send('setChangeStatus');
        }
    }).on('change', 'keyUp')
  });
});