define('front-end-cli/models/income-later', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    profile: _emberData['default'].belongsTo('profile'),

    labors: _emberData['default'].hasMany('labor'),
    capitals: _emberData['default'].hasMany('capital'),
    insurances: _emberData['default'].hasMany('insurance'),

    aowBeenAbroadChoice: _emberData['default'].attr('number'),
    aowAbroadYears: _emberData['default'].attr('number'),

    incomeLaterRetirementEmployer: _emberData['default'].hasMany('income-later-retirement-employer'),
    incomeLaterRetirementAnnuity: _emberData['default'].hasMany('income-later-retirement-annuity'),

    beenAbroad: (function () {
      return this.get('aowBeenAbroadChoice') === 1;
    }).property('aowBeenAbroadChoice'),

    // used when renderering labor.hbs
    isNow: (function () {
      return false;
    }).property()
  });
});