define('front-end-cli/routes/automatic-import/update-account-wizard', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    activate: function activate() {
      if (this.get('currentUser.model.state.needsToLockAccounts')) {
        this.transitionTo('accountmanagement.lock-accounts');
      } else if (this.get('currentUser.model.hasNoPsd2Accounts')) {
        this.transitionTo('accounts');
      } else {
        if (!this.get('currentUser.model.needsToUpdateImportSettings')) {
          this.controllerFor('automatic-import.update-account-wizard').startImport();
        }
      }
    }
  });
});