define('front-end-cli/models/financial-means-asset', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    profile: _emberData['default'].belongsTo('profile'),

    type: _emberData['default'].attr('string'),

    // house
    wozvalue: _emberData['default'].attr('string'),
    houseowner: _emberData['default'].attr('string'),
    // car
    carnumber: _emberData['default'].attr('string'), //kenteken
    carowner: _emberData['default'].attr('string'),
    // other
    description: _emberData['default'].attr('string'),
    dailyvalue: _emberData['default'].attr('string'),
    formalowner: _emberData['default'].attr('string'),

    isHouse: (function () {
      return this.get('type') == 1;
    }).property('type'),

    isCar: (function () {
      return this.get('type') == 2;
    }).property('type'),

    isMisc: (function () {
      return this.get('type') == 3;
    }).property('type')
  });
});