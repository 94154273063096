define('front-end-cli/models/base-category', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('safestring'),
    enabled: _emberData['default'].attr('boolean'),
    expectedNetted: _emberData['default'].attr('number'),
    currentNetted: _emberData['default'].attr('number'),
    averageNetted: _emberData['default'].attr('number'),
    currentIncome: _emberData['default'].attr('number'),
    currentExpenses: _emberData['default'].attr('number'),
    beginBalance: _emberData['default'].attr('number'),
    endBalance: _emberData['default'].attr('number'),
    removeFromBudgetResult: _emberData['default'].attr('boolean'),
    removeFromNettedResult: _emberData['default'].attr('boolean'),

    result: _ember['default'].computed('expectedNetted', 'currentNetted', function () {
      return 0 - (this.get('expectedNetted') - this.get('currentNetted'));
    }),
    resultsFiltered: _ember['default'].computed('expectedNetted', 'currentNetted', function () {
      if (this.get('removeFromNettedResult') && !this.get('removeFromBudgetResult')) {
        return 0 - this.get('expectedNetted');
      } else if (this.get('removeFromBudgetResult') && !this.get('removeFromNettedResult')) {
        return this.get('currentNetted');
      } else if (this.get('removeFromBudgetResult') && this.get('removeFromNettedResult')) {
        return 0;
      } else {
        return 0 - (this.get('expectedNetted') - this.get('currentNetted'));
      }
    }),
    progress: _ember['default'].computed('currentNetted', 'expectedNetted', function () {
      return Math.round(this.get('currentNetted') / this.get('expectedNetted') * 100, 0);
    }),
    categoryType: _ember['default'].computed('expectedNetted', 'currentNetted', function () {
      if (this.get('expectedNetted') > 0) {
        return 'income';
      } else {
        if (this.get('expectedNetted') == null && this.get('currentNetted') > 0) {
          return 'income';
        } else {
          return 'expenses';
        }
      }
    })
  });
});